import React, {useEffect } from "react";
import { Divider, Hidden, Paper, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Box from "@mui/material/Box";
import { Link, useParams } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { common } from "helpers";
import QuickAdd from "elements/QuickAdd";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#2A3543",
    color: "rgba(157, 170, 187, 0.5)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    padding: 35,
  },
}));
function SubMenu(props) {
  let { mod } = useParams();
  const { pathname } = useLocation();
  const [value, setValue] = React.useState(mod);

  useEffect(() => {
    setValue(mod);
  }, [mod]);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  const DrawerHeader1 = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    padding: theme.spacing(2, 3),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  const LinkTab = (props) => {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  };

  return (
    <Box>
      {props.menu === "crm" && (
        <div>
          {/*<Box
            sx={{ width: "100%", typography: "body1" }}
            className="primary-header-container fixed-header"
      > */}
          <Box
            sx={{ width: "100%", typography: "body1" }}
            className="primary-header-container kanban-primary-header"
          >
            <Paper
              variant="container"
              square
              className="fixed-header"
              id="fixed-header"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  justifyContent: { xs: "center", lg: "space-between" },
                  itemsCenter: { xs: "center" },
                  py: { xs: 5, lg: 0 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    justifyContent: { xs: "center", lg: "space-between" },
                    itemsCenter: { xs: "center" },
                  }}
                  alignItems="center"
                  px={3}
                  className="tabs"
                >
                  <Box display="flex" alignItems="center">
                    <Box>
                      <img
                        src={common.loadImg("outlineCRM-chart.svg")}
                        alt="img"
                      />
                    </Box>
                    &nbsp;&nbsp;
                    <Typography className="header-crm">
                      CRM &nbsp;
                      
                      <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography
                                component={"span"}
                                color="inherit"
                                className="pop-title"
                              >
                                Help and Tips
                              </Typography>
                              <p>
                                <h6 className="pop-heading">
                                  {"This is your CRM"}
                                </h6>
                              </p>{" "}
                              {
                                "Fabio vel iudice vincam, sunt in culpa qui officia. Curabitur est gravida et libero vitae dictum.Quam diu etiam furor iste tuus nos eludet?"
                              }
                            </React.Fragment>
                          }
                        >
                          <img src={common.loadImg("InfoCircle.svg")} alt="info" />
                         
                        </HtmlTooltip>
                    </Typography>
                    <Hidden smDown>
                      <Box className="vertical-divider ml-4">
                        <Divider orientation="vertical" />
                      </Box>
                    </Hidden>
                    <TabContext value={value}>
                      <Box>
                        <TabList
                          onChange={handleChange}
                          className="ml-2 tabs-list-ph submenu-padding"
                          aria-label="lab API tabs example"
                        >
                          <LinkTab
                            label="Campaigns"
                            onClick={(e) => {
                              e.preventDefault();
                              props.selectedMenu("campaign");
                            }}
                            value={"campaign"}
                          />
                          <LinkTab
                            label="Enquiry"
                            onClick={(e) => {
                              e.preventDefault();
                              props.selectedMenu("enquiry");
                            }}
                            value={"enquiry"}
                          />
                          <LinkTab
                            label="Leads"
                            onClick={(e) => {
                              e.preventDefault();
                              props.selectedMenu("lead");
                            }}
                            value={"lead"}
                          />
                          <LinkTab
                            label="Deals"
                            onClick={(e) => {
                              e.preventDefault();
                              props.selectedMenu("deal");
                            }}
                            value={"deal"}
                          />
                          {/* <LinkTab
                            label="Quotes"
                            onClick={(e) => {
                              e.preventDefault();
                              props.selectedMenu("quote");
                            }}
                            value={"quote"}
                          />
                          <LinkTab
                            label="Sales Order"
                            onClick={(e) => {
                              e.preventDefault();
                              props.selectedMenu("order");
                            }}
                            value={"order"}
                          />
                          <LinkTab
                            label="Invoices"
                            onClick={(e) => {
                              e.preventDefault();
                              props.selectedMenu("invoice");
                            }}
                            value={"invoice"}
                          /> */}
                          <LinkTab
                            label="Contacts"
                            onClick={(e) => {
                              e.preventDefault();
                              props.selectedMenu("contacts");
                            }}
                            value={"contacts"}
                          />
                        </TabList>
                      </Box>
                    </TabContext>
                  </Box>
                </Box>
                <QuickAdd />
              </Box>
            </Paper>
          </Box>
          <DrawerHeader1 className="report-header" />
        </div>
      )}
      {props.menu === "report" && <div></div>}
      {props.menu === "dashboard" && <div></div>}
      {props.menu === "target" && <div></div>}
      {props.menu === "task" && (
        <div className="kanban-primary-header">
          <Box
            sx={{ width: "100%", typography: "body1" }}
            className="primary-header-container"
          >
            <Paper
              variant="container"
              square
              className="fixed-header"
              id="fixed-header"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  justifyContent: { xs: "center", lg: "space-between" },
                  itemsCenter: { xs: "center" },
                  py: { xs: 5, lg: 0 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    justifyContent: { xs: "center", lg: "space-between" },
                    itemsCenter: { xs: "center" },
                  }}
                  alignItems="center"
                  px={3}
                  className="tabs"
                >
                  <Box display="flex" alignItems="center">
                    <Box>
                      <img src={common.loadImg("outlineCRM-chart.svg")} alt="img" />
                    </Box>
                    &nbsp;&nbsp;
                    <Typography className="header-crm">Task</Typography>
                    <Hidden smDown>
                      <Box className="vertical-divider ml-4">
                        <Divider orientation="vertical" />
                      </Box>
                    </Hidden>
                    <ul className="flex submenu-ul">
                      <li
                        className={pathname.includes("overview") ? "active-li" : ""}
                      >
                        <Link to="/task/overview">Overview</Link>
                      </li>
                      <li
                        className={
                          pathname.includes("cardview") ? "active-li" : ""
                        }
                      >
                        <Link to="/task/cardview">Cards View</Link>
                      </li>
                      <li
                        className={
                          pathname.includes("listview") ? "active-li" : ""
                        }
                      >
                        <Link to="/task/listview">List View</Link>
                      </li>
                    </ul>
                  </Box>
                </Box>
                <QuickAdd />
              </Box>
            </Paper>
          </Box>
          <DrawerHeader1 className="report-header" />
        </div>
      )}
      {props.menu === "setting" && (
        <div>
          <Box
            sx={{ width: "100%", typography: "body1" }}
            className="primary-header-container"
          >
            <Paper
              variant="container"
              square
              className="fixed-header"
              id="fixed-header"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  justifyContent: { xs: "center", lg: "space-between" },
                  itemsCenter: { xs: "center" },
                  py: { xs: 5, lg: 0 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    justifyContent: { xs: "center", lg: "space-between" },
                    itemsCenter: { xs: "center" },
                  }}
                  alignItems="center"
                  px={3}
                  className="tabs"
                >
                  <Box display="flex" alignItems="center">
                    <Box>
                      <img src={common.loadImg("settingsIcon.svg")} alt="img" />
                    </Box>
                    &nbsp;&nbsp;
                    <Typography className="header-crm">Settings</Typography>
                    <Hidden smDown>
                      <Box className="vertical-divider ml-4">
                        <Divider orientation="vertical" />
                      </Box>
                    </Hidden>
                    <ul className="flex submenu-ul">
                      <li
                        className={pathname.includes("hrms") ? "active-li" : ""}
                      >
                        <Link to="/setting/view/hrms">HRMS</Link>
                      </li>
                      <li
                        className={
                          pathname.includes("configure") ? "active-li" : ""
                        }
                      >
                        <Link to="/setting/view/configure">Configure</Link>
                      </li>
                      <li
                        className={
                          pathname.includes("vendor") ? "active-li" : ""
                        }
                      >
                        <Link to="/setting/view/vendor">Vendor</Link>
                      </li>
                    </ul>
                  </Box>
                </Box>
                <QuickAdd />
              </Box>
            </Paper>
          </Box>
          <DrawerHeader1 className="report-header" />
        </div>
      )}
    </Box>
  );
}
export default SubMenu;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import moment from "moment";
import RCTimePicker from "rc-time-picker";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import "react-phone-input-2/lib/style.css";
import { Col, Row } from "react-bootstrap";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import Conversion from "elements/Conversion";
import FieldSelect from "elements/FieldSelect";
import StatusBar from "elements/StatusBar";
import {
  api,
  common,
  eduTechConfig,
  eduTechSerivces,
  envConfig,
  tools,
} from "helpers";
import * as Services from "helpers/service";
import { CRM_PAY_EMPLOYEE } from "helpers/service";
import DeleteIcon from "../assets/img/actionDelete.svg";

//const
const selectDefault = { label: "", value: "" };
const reviewStatus = [
  { value: "missing", label: "Missing" },
  { value: "under_review", label: "Under Review" },
  { value: "reviewed", label: "Reviewed" },
];
const canApprStatus = [
  { value: "accepted", label: "Accepted" },
  { value: "rejected", label: "Rejected" },
];

const rejectOptions = [
  { value: "Asylum Seeker", label: "Asylum Seeker" },
  {
    value: "Enrolled with an Institution",
    label: "Enrolled with an Institution",
  },
  { value: "International Student", label: "International Student" },
  { value: "Limited Leave to Remain", label: "Limited Leave to Remain" },
  { value: "Mental Issues", label: "Mental Issues" },
  {
    value: "No Qualifications or Work Experiences",
    label: "No Qualifications or Work Experiences",
  },
  { value: "Other Visa Statuses", label: "Other Visa Statuses" },
  { value: "Over Qualified", label: "Over Qualified" },
  { value: "Underage", label: "Underage" },
  { value: "Poor English", label: "Poor English" },
  {
    value: "SFE taken for more than 2 Years",
    label: "SFE taken for more than 2 Years",
  },
  { value: "Non Standard Qualification", label: "Non Standard Qualification" },
];

const sfeStatus = [
  { value: "Pending", label: "Pending", disabled: false },
  { value: "Progress", label: "Progress", disabled: false },
  { value: "Booked", label: "Booked", disabled: false },
  { value: "Completed", label: "Completed", disabled: false },
];

function ETLeadForm(props) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [status, setStatus] = useState("process");
  const [fields, setFields] = useState({}); //crm lead response
  const [leadRes, setLeadRes] = useState({});
  const [univeristies, setUniveristies] = useState([]);
  const [cancelledStatus, setCancelledStatus] = useState(false);
  const [withdrawOption, setWithdrawOption] = useState([
    { value: "Not withdrawn", label: "Not withdrawn" },
    { value: "Withdrawn by user", label: "Withdrawn by user" },
    { value: "Withdrawn by admin", label: "Withdrawn by admin" },
  ]);
  const [course1List, setCourse1List] = useState([]);
  const [course2List, setCourse2List] = useState([]);
  const [imgPreview, setImgPreview] = useState(false);
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [disableSuggestion, setDisableSuggestion] = useState(false);
  const [leadStatus, setLeadStatus] = useState(eduTechConfig.LeadStatus);
  const navigate = useNavigate();
  const [mSel, setMSel] = useState([]);
  const initialMFields = {
    field: "",
    desc: "",
    status: "missing",
  };
  const intakeFields = {
    intakeDiffer: false,
    intakeMonthYear: null,
    intakeReason: "",
  };
  const [intake, setIntake] = useState(intakeFields);
  const [courseIntMonthYear, setCourseIntMonthYear] = useState([]);
  const [mFields, setMFields] = useState([initialMFields]);
  const [missingFields, setMissingFields] = useState(
    eduTechConfig.missingFields
  );

  const [isConvertModal, setIsConvertModal] = useState(false);
  const [openEMail, setOpenEMail] = useState(false);
  const [isEmailSubmit, setIsEmailSubmit] = useState(false);
  const [emailHistory, setEmailHistory] = useState([]);
  const [appInfo, setAppInfo] = useState(null);
  const [files, setFiles] = useState([]);
  const [salesPerson, setSalesPerson] = useState({});
  // sfe
  const [sfeSlots, setSFESlots] = useState({ slot: [], bookedSlot: "" });
  const [interviewModel, setInterviewModel] = useState(false);
  const [mInterviewField, setMInterviewField] = useState({});
  const [sfeFields, setSFEFields] = useState([initialMFields]);
  const [condOrSfeSlot, setCondOrSfeSlot] = useState(true);
  const [sfeSel, setSFESel] = useState([]);
  const [isNewDateAdd, setIsNewDateAdd] = useState(false);
  const [newDate, setNewDate] = useState({ date: "", slot: [] });
  const [slotTime, setSlotTime] = useState(moment());
  const [miFields, setMIFields] = useState([]);

  //effect
  useEffect(() => {
    if (props.data.value1 == "") {
      navigate("/crm/lead/list");
    } else {
      getETUniversities();
      getEditData();
    }
  }, []);

  useEffect(() => {
    mFields.filter((n) => n);
    let selected = mFields.map((a) => a.field);
    setMSel(selected);
  }, [mFields]);

  // Select box style
  const selectStyles = {
    container: (provided, state) => ({
      ...provided,
      marginTop: 0,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: "visible",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue ? -15 : "10%",
      transition: "top 0.1s, font-size 0.1s",
      fontSize: state.hasValue || state.selectProps.inputValue ? 11 : 13,
      color: "#9daabb",
    }),
  };

  // edit
  const getEditData = () => {
    let url = envConfig.BASE_API + Services.CRM_LEADS + "/" + props.data.value1;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      async (response) => {
        if (response.status == 200) {
          // set value
          setCRMLeadData(response.data);
          setLeadRes(response.data);
          //get email history
          let result = await eduTechSerivces.getAppEmailHistory(
            response.data.Cattr1
          );
          setEmailHistory(result.data || []);

          let res = await eduTechSerivces.getApplicationInfo(
            response.data.Cattr1
          );
          setAppInfo(res);
          let courseIntake = [];
          res?.course?.intake?.map((yObj) => {
            yObj.month?.map((mObj) => {
              courseIntake.push({
                label: yObj.year + " " + common.getMonthList()[mObj - 1].label,
                value: yObj.year + " " + mObj,
              });
            });
          });
          setCourseIntMonthYear(courseIntake);
          if (res.sfeField) {
            setSFEFields(res.sfeField.missing);
            setSFESel(res.sfeField.fields.split(","));

            if (res.sfeField.interview) {
              let sfeSlot = res.sfeField.interview;
              sfeSlot?.slot.map((sObj) => {
                sObj?.slot.map((slot, idx) => {
                  sObj.slot[idx] = common.formatDate(slot, "hh:mm a");
                });
              });
              setSFESlots(sfeSlot);
            } else {
              setSFESlots({ slot: [], bookedSlot: "" });
            }
          }
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  //set CRM lead value
  const setCRMLeadData = (data) => {
    let newFields = data;
    newFields.AppCourse = data.Clattr1
      ? JSON.parse(data.Clattr1)
      : { course: "", university: "", month: "", year: "" };
    newFields.EduData = data.Clattr2 ? JSON.parse(data.Clattr2) : "";
    newFields.workData = data.Clattr3 ? JSON.parse(data.Clattr3) : "";
    newFields.testData = data.Clattr4 ? JSON.parse(data.Clattr4) : "";
    newFields.extraActivity = data.Clattr7
      ? JSON.parse(data.Clattr7)
      : { extraCurricular: [], otherActivity: [] };
    newFields.otherEduData = data.Description
      ? JSON.parse(data.Description)
      : "";
    if (data.Eattr3) {
      setDisableSuggestion(true);
    }

    newFields.CourseNameS1 = selectDefault;
    newFields.UniversityNameS1 = selectDefault;

    newFields.CourseNameS2 = selectDefault;
    newFields.UniversityNameS2 = selectDefault;

    newFields.isUpdate = false;

    let sCourses = data.Clattr5 ? JSON.parse(data.Clattr5) : [];
    if (sCourses.length > 0) {
      //setDisableSuggestion(true);
      sCourses.map((course, idx) => {
        if (idx == 0) {
          newFields.CourseNameS1 = course.course;
          newFields.UniversityNameS1 = course.university;
        } else {
          newFields.CourseNameS2 = course.course;
          newFields.UniversityNameS2 = course.university;
        }
      });
    }
    // get sales person details
    getEmpByEmpId(data.SalespersonId);

    newFields.SalespersonId = {
      label: data.SalespersonIdDesc,
      value: data.SalespersonId,
    };
    newFields.Cattr36 = data?.Cattr36
      ? { label: data?.Cattr36, value: data?.Cattr36 }
      : {};
    newFields.Cattr31 = data?.Cattr31
      ? { label: data?.Cattr31, value: data?.Cattr31 }
      : {};
    newFields.LeadSource = { label: data.LeadSource, value: data.LeadSource };

    //fields.Cattr26
    setLeadStatus(common.enableLeadStatus(data.LeadStatus));
    newFields.LeadStatus = { label: data.LeadStatus, value: data.LeadStatus };
    newFields.SalePossibility = {
      label: data.SalePossibility,
      value: data.SalePossibility,
    };
    newFields.emailContent = "";
    setFields(newFields);

    if (data.Clattr5) {
      getUniversityColleges(newFields);
    }
    if (data.Clattr6 && data.Cattr19) {
      setMFields(JSON.parse(data.Clattr6));
      setMSel(data.Cattr19.split(","));
    }

    newFields.Cattr14 = data.Cattr14
      ? { label: data.Cattr14, value: data.Cattr14 }
      : null;

    //application rejected
    if (data?.Cattr25 === "Y") {
      setWithdrawOption((prev) => [
        {
          value: "Not withdrawn",
          label: "Not withdrawn",
          disabled: true,
        },
        {
          value: "Withdrawn by user",
          label: "Withdrawn by user",
          disabled: true,
        },
        {
          value: "Withdrawn by admin",
          label: "Withdrawn by admin",
          disabled: true,
        },
      ]);
    }

    if (data?.Cattr36?.value == "accepted") {
      setWithdrawOption((prev) => [
        {
          value: "Not withdrawn",
          label: "Not withdrawn",
          disabled: true,
        },
        {
          value: "Withdrawn by user",
          label: "Withdrawn by user",
          disabled: true,
        },
        {
          value: "Withdrawn by admin",
          label: "Withdrawn by admin",
          disabled: true,
        },
      ]);
      setCancelledStatus(true);
      let dStatus = eduTechConfig.LeadStatus;
      dStatus.map((obj) => {
        obj.disabled = true;
      });

      setLeadStatus(dStatus);
    }

    let mfList = data.Clattr6 ? JSON.parse(data.Clattr6) : [];
    let mStatus = mfList.filter((a) => a.status != "reviewed" && a.field != "");

    if (data.LeadStatus.value === "New Application") {
      let dStatus = eduTechConfig.LeadStatus;
      dStatus.map((obj) => {
        if (mfList.length > 0 && mStatus.length > 0) {
          obj.disabled = true;
        } else if (obj.value == "Potential Student") {
          if (data?.Cattr36?.value == "accepted") {
            obj.disabled = true;
          } else if (data?.SalePossibility == "Profile Not Eligible") {
            obj.disabled = true;
          } else {
            obj.disabled = false;
          }
          if (data?.Cattr37 == "Y") {
            obj.disabled = false;
          } else {
            obj.disabled = true;
          }
        }
      });
      setLeadStatus((prev) => [...dStatus]);
    }

    //update Missing fields based on Highest level of Education
    if (data.Cattr17 == "Other Qualifications") {
      let exclude = [
        "education_ruWorking",
        "education_yearOfExp",
        "modal_expDocs",
        "education_countryOfEducation",
        "education_grade",
      ];
      updateMissingFields(exclude);
    } else if (data.Cattr17 == "Work Experience Only") {
      let exclude = [
        "modal_otherQualificationDocs",
        "education_otherQualificationDesc",
        "education_otherQualification",
        "education_countryOfEducation",
        "education_grade",
      ];
      updateMissingFields(exclude);
    } else {
      let exclude = [
        "modal_otherQualificationDocs",
        "education_otherQualificationDesc",
        "education_otherQualification",
        "education_ruWorking",
        "education_yearOfExp",
        "modal_expDocs",
      ];
      updateMissingFields(exclude);
    }
    setStatus("success");
  };

  const updateMissingFields = (exclude) => {
    let final = eduTechConfig.missingFields.filter((e) => {
      return !exclude.includes(e.value);
    });
    setMissingFields(final);
  };

  //text, textarea handler
  const onChange = (e) => {
    const { name, value } = e.target;
    let newFields = common.reParse(fields);
    newFields[name] = value;
    newFields.isUpdate = true;
    setFields(newFields);
  };

  const intakeChange = (e, name) => {
    let obj = common.reParse(intake);
    if (name == "intakeDiffer") {
      obj[name] = e.target.checked ? "Y" : "N";
      setIntake(obj);
    } else if (["intakeMonthYear"].includes(name)) {
      obj[name] = e;
      setIntake(obj);
    } else {
      obj[name] = e.target.value;
      setIntake(obj);
    }
  };

  const onFieldChange = (e, name) => {
    let newFields = common.reParse(fields);
    if (name == "Cattr24" && !e.target.checked) {
      setMFields([initialMFields]);
      newFields.Cattr19 = "";
      setMSel([]);
    }
    // newFields[name] = e.target.checked ? "Y" : "N";
    // newFields.isUpdate = true;

    setFields((oldPrev) => ({
      ...oldPrev,
      isUpdate: true,
      [name]: e.target.checked ? "Y" : "N",
    }));
  };

  const formInputs = async () => {
    let appData = {};
    let uFields = {};
    let valid = true;
    appData.leadId = props.data.value1;
    if (
      intake.intakeDiffer === "Y" &&
      (!intake.intakeMonthYear.value || !intake.intakeReason)
    ) {
      valid = false;
      common.snack(
        "E",
        "You have selected intake differ, please fill all intake fields"
      );
    }
    if (
      intake.intakeDiffer === "Y" &&
      intake.intakeMonthYear.value &&
      intake.intakeReason
    ) {
      let intakes = intake.intakeMonthYear.value.split(" ");
      appData.intake = {
        intakeYear: intakes[0],
        intakeMonth: intakes[1],
        intakeReason: intake.intakeReason,
      };
      appData.type = "review";
      appData.category = "intake_diff";
    }
    if (fields.Uom == "N" && fields.LeadStatus.value == "New Application") {
      // Uom as Fee Payment - Y, N
      valid = false;
      common.snack("E", "Lead status can't change before fee payment");
    } else if (
      leadRes.Cattr25 == "Y" ||
      (leadRes.Cattr31 && leadRes.Cattr36 == "accepted")
    ) {
      if (fields.LeadStatus.value != leadRes.LeadStatus) {
        valid = false;
        common.snack(
          "E",
          "Lead status can't change after application rejected or withdrawn"
        );
      }
    } else {
      uFields.Cattr24 = fields.Cattr24;
      uFields.Cattr26 = fields.Cattr26;

      //Lead status
      if (fields.LeadStatus.value) uFields.LeadStatus = fields.LeadStatus.value;

      if (fields.LeadStatus.value == "New Application" && fields.Cattr37) {
        //{ && fields.Cattr37 == "Y") {
        uFields.Cattr37 = fields.Cattr37;
      }

      //Missing Fields
      if (
        fields.LeadStatus.value == "New Application" &&
        fields.Cattr24 == "Y" &&
        (fields.Cattr25 == "N" || fields.Cattr31.value == "Not withdrawn")
      ) {
        uFields.Cattr19 = "";
        uFields.Clattr6 = null;

        //Missing Fields
        if (mFields.length > 0) {
          appData.status = "review_missing_info";
          let mData = [];
          mFields.map((obj) => {
            if (obj.field && obj.desc && obj.status) {
              mData.push({
                field: obj.field,
                description: obj.desc,
                status: obj.status,
              });
            } else {
              valid = false;
            }
          });
          if (valid == false) {
            common.snack("E", "Please fill the missing information");
          }
          appData.missingInfo = mData;

          var filtered = mSel.filter(Boolean);
          uFields.Cattr19 = filtered.join(",");
          uFields.Clattr6 = JSON.stringify(mFields);
        } else {
          valid = false;
          common.snack("E", "Please fill the missing information");
        }
      }
      //No Missing Fields
      else if (
        fields.LeadStatus.value == "New Application" &&
        fields.Cattr24 == "N"
      ) {
        uFields.Cattr19 = "";
        uFields.Clattr6 = null;
        appData.status = "review_missing_info";
        appData.missingInfo = [];
      }
      //Suggested Courses
      if (
        fields.LeadStatus.value == "New Application" &&
        fields.Cattr26 == "Y"
      ) {
        let leadSCourse = [];
        let appSCourse = [];
        let courseValid = {
          first: true,
          second: true,
        };
        //Course1 Note
        if (fields.Cattr29) {
          uFields.Cattr29 = fields.Cattr29;
          appData.feedBack = fields.Cattr29;
        }

        if (fields.CourseNameS1.value && fields.UniversityNameS1.value) {
          if (
            fields.CourseNameS1.value._id == fields.AppCourse.courseId &&
            fields.UniversityNameS1.value == fields.AppCourse.universityId
          ) {
            courseValid.first = false;
          } else {
            leadSCourse.push({
              course: fields.CourseNameS1,
              university: fields.UniversityNameS1,
            });

            uFields.Eattr4 =
              fields.CourseNameS1.label + ", " + fields.UniversityNameS1.label;

            appSCourse.push({
              course: fields.CourseNameS1.value._id,
            });
          }
        }
        if (fields.CourseNameS2.value && fields.UniversityNameS2.value) {
          if (
            fields.CourseNameS2.value._id == fields.AppCourse.courseId &&
            fields.UniversityNameS2.value == fields.AppCourse.universityId
          ) {
            courseValid.second = false;
          } else {
            leadSCourse.push({
              course: fields.CourseNameS2,
              university: fields.UniversityNameS2,
            });
            uFields.Eattr5 =
              fields.CourseNameS2.label + ", " + fields.UniversityNameS2.label;

            appSCourse.push({
              course: fields.CourseNameS2.value._id,
            });
          }
        }

        if (!courseValid.first && fields.Cattr37 != "Y" && !fields.Eattr3) {
          common.snack(
            "E",
            "Already applied course you selected in course suggestion 1"
          );
        }

        if (
          fields.CourseNameS2.value &&
          fields.UniversityNameS2.value &&
          !courseValid.second &&
          fields.Cattr37 != "Y" &&
          !fields.Eattr3
        ) {
          common.snack(
            "E",
            "Already applied course you selected in course suggestion 2"
          );
        }

        // suggested Courses
        if (appSCourse.length == 2 && leadSCourse.length == 2) {
          if (fields.CourseNameS1.value._id == fields.CourseNameS2.value._id) {
            valid = false;
            common.snack(
              "E",
              "Both suggested courses are same. Please select different courses"
            );
          } else if (appSCourse.length == 2 && leadSCourse.length == 2) {
            uFields.Clattr5 = JSON.stringify(leadSCourse);
            if (fields.Eattr3 == null) {
              uFields.Eattr3 =
                fields.AppCourse.course +
                ", " +
                fields.AppCourse.university +
                ", " +
                fields.AppCourse.year +
                ", " +
                fields.AppCourse.month;
            }
            appData.status = "review_missing_info";
            if (fields.Eattr6 == null) {
              appData.feedBack = fields.Cattr29;
              appData.suggestedCourses = appSCourse;
            }
          }
          // else {
          //   valid = false;
          //   common.snack("E", "Please fill the all details in suggested courses");
          // }
        } else if (appSCourse.length == 1 && leadSCourse.length == 1) {
          uFields.Clattr5 = JSON.stringify(leadSCourse);
          uFields.Eattr3 =
            fields.AppCourse.course +
            "," +
            fields.AppCourse.university +
            ", " +
            fields.AppCourse.year +
            ", " +
            fields.AppCourse.month;
          appData.status = "review_missing_info";
          if (fields.Eattr6 == null) {
            appData.feedBack = fields.Cattr29;
            appData.suggestedCourses = appSCourse;
          }
        } else if (
          !fields.CourseNameS1.value ||
          !fields.UniversityNameS1.value
        ) {
          valid = false;
          common.snack(
            "E",
            "Please select first suggested course and university detail"
          );
        }
      }
      // Potential Student
      else if (fields.LeadStatus.value == "Potential Student") {
        //clear missing fields
        uFields.Cattr19 = "";
        uFields.Clattr6 = null;
        if (fields.Cattr37 != "Y") {
          valid = false;
          common.snack(
            "E",
            "You can't change Lead status as Potential Student. Because course is not verified!"
          );
        } else if (
          fields.Eattr6 == null &&
          fields.Clattr5 != null &&
          fields.Eattr3 != null
        ) {
          valid = false;
          common.snack(
            "E",
            "You can't change Lead status as Potential Student. Because suggested course not picked by student!"
          );
        } else {
          uFields.Cattr37 = fields?.Cattr37;
        }
      }
    }
    //Application withdraw/cancel accept by admission team
    if (fields.Cattr31 && fields.Cattr36 && fields.Cattr36.value) {
      appData = {}; //Store rejected details only
      uFields = {};
      uFields.Cattr31 = fields.Cattr31;
      uFields.Cattr36 = fields.Cattr36.value;
      if (fields.Cattr36.value == "accepted") {
        appData.status = "cancelled";
        appData.action = "accepted";
        appData.description = fields.Cattr35;
      } else {
        appData.status = "cancelled";
        appData.action = "rejected";
        appData.description = fields.Cattr39;
        uFields.Cattr39 = fields.Cattr39;
      }
    }

    //Application withdraw/cancel
    if (fields.Cattr31?.value == "Withdrawn by admin") {
      if (fields.Cattr35) {
        uFields.Cattr35 = fields.Cattr35;
        uFields.Cattr31 = fields.Cattr31?.value;

        //Todo check with prasad
        if (fields.Cattr36 && fields.Cattr36.value) {
          if (fields.Cattr36.value == "accepted") {
            appData.status = "cancelled";
            appData.action = "accepted";
            appData.description = fields?.Cattr35;

            setWithdrawOption((prev) => [
              {
                value: "Not withdrawn",
                label: "Not withdrawn",
                disabled: true,
              },
              {
                value: "Withdrawn by user",
                label: "Withdrawn by user",
                disabled: true,
              },
              {
                value: "Withdrawn by admin",
                label: "Withdrawn by admin",
                disabled: true,
              },
            ]);
          } else {
            appData.status = "cancelled";
            appData.action = "rejected";
            appData.description = fields.Cattr39;
            uFields.Cattr39 = fields.Cattr39;
          }
        }
      } else {
        valid = false;
        common.snack("E", "Please enter application withdraw request");
      }
    }

    //Application rejected
    if (fields.Cattr25 == "Y") {
      if (fields.Cattr32) {
        appData = {}; //Store rejected details only
        uFields = {};
        appData.status = "rejected";
        appData.description = fields.Cattr32;
        uFields.Cattr32 = fields.Cattr32;
        uFields.Cattr25 = fields.Cattr25;
        uFields.SalePossibility = fields.SalePossibility.value; //reject category
      } else {
        valid = false;
        common.snack("E", "Please enter application rejected reason");
      }
    }

    //Responsible person
    if (fields.SalespersonId.value) {
      uFields.SalespersonId = fields.SalespersonId.value;
      appData.salesPersonId = fields.SalespersonId.value;
    }

    if (appData.intake?.intakeMonth) {
      if (appData.status) {
        appData.type = appData.status;
      } else {
        appData.type = appInfo?.currentStatus;
      }
    }

    //sfe 02-aug-2024
    if (fields.Cattr14?.value) {
      let mData = [];
      let sfe_MissingFields = [];
      if (sfeFields.length > 0) {
        sfeFields.map((obj) => {
          let sField = eduTechConfig.sfeDocuments.filter((val) => {
            return val.value.includes(obj.field);
          });

          if (sField.length > 0) {
            sfe_MissingFields.push(sField[0].label);
          }
          if (obj.field && obj.desc && obj.status) {
            mData.push({
              field: obj.field,
              description: obj.desc,
              status: obj.status,
            });
          } else if (sfeFields.length > 1) {
            valid = false;
          }
        });
      }
      if (valid == false) {
        common.snack("E", "Please fill the SFE documents");
        setIsSubmit(false);
      } else {
        let sfeData = {};
        sfeData.sfeStatus = fields.Cattr14?.value;
        sfeData.sfeMissing = sfe_MissingFields;
        if (appData.status) {
          sfeData.type = appData.status;
        } else {
          sfeData.type = appInfo?.currentStatus;
        }
        sfeData.status = "sfe_process";
        sfeData.description =
          "Please stay in touch as we working on your SFE loan";
        sfeData.action = fields.Cattr14?.value;
        uFields.Cattr14 = fields.Cattr14?.value;

        if (mData.length > 0) {
          sfeData.missingInfo = mData;
        }
        if (sfeSlots?.slot.length > 0) {
          let convertSlot = [];

          sfeSlots.slot.map((obj) => {
            let slotArray = [];
            obj.slot.map((s) => {
              slotArray.push(new Date(obj.date + " " + s));
            });
            convertSlot.push({ date: obj.date, slot: slotArray });
          });
          sfeData.slot = convertSlot;
          sfeData.bookedSlot = sfeSlots.bookedSlot;
          let udata = {
            slot: convertSlot,
            bookedSlot: sfeSlots.bookedSlot,
          };
          appData.sfeField = {
            interview: udata,
          };
        }

        //uFields.ApprStatus = "SFE";
        var filtered = sfeSel.filter(Boolean);
        appData.sfe = sfeData;

        appData.sfeField = {
          sfeStatus: sfeData.sfeStatus,
          sfeMissing: sfeData.sfeMissing,
          fields: filtered ? filtered.join(",") : null,
          missing: filtered ? sfeFields : null,
          interview: appData.sfeField?.interview || null,
        };
      }
    }

    return {
      leadData: uFields,
      appData: appData,
      valid: valid,
    };
  };

  // Missing Select handler
  const onMissingSelect = (data, name, idx, section = null) => {
    if (section) {
      //SFE Document Field
      setSFEFields((oldData) => {
        let newData = common.reParse(oldData);
        newData[idx][name] = name == "desc" ? data : data.value;
        return newData;
      });
      if (name == "field" && data.value && !sfeSel.includes(data.label)) {
        let selected = sfeFields.map((a) => a.field);
        selected.push(data.value);
        setSFESel(selected);
      }
    } else {
      setMFields((oldData) => {
        let newData = common.reParse(oldData);
        newData[idx][name] = name == "desc" ? data : data.value;
        return newData;
      });
    }
  };

  // Select handler
  const onSelectHandler = (data, name) => {
    let newFields = common.reParse(fields);
    newFields[name] = data;
    newFields.isUpdate = true;

    if (name == "UniversityNameS1") {
      newFields.CourseNameS1 = selectDefault;
    } else if (name == "UniversityNameS2") {
      newFields.CourseNameS2 = selectDefault;
    }
    if (
      name == "LeadStatus" &&
      data.value == "New Application" &&
      fields.Uom == "N"
    ) {
      common.snack("E", "Lead status can't change before fee payment");
    } else {
      setFields(newFields);
    }

    if (name == "UniversityNameS1" || name == "UniversityNameS2") {
      getETCourseByUId(data.value, name == "UniversityNameS1" ? 1 : 2, false);
    }
    if (name == "CourseNameS1" || name == "CourseNameS2") {
      if (
        newFields.CourseNameS1.value._id == fields.AppCourse.courseId &&
        newFields.UniversityNameS1.value == fields.AppCourse.universityId
      ) {
        common.snack(
          "E",
          "You selected applied course as suggested course 1. Please select another course"
        );
      } else if (
        newFields.CourseNameS2.value._id == fields.AppCourse.courseId &&
        newFields.UniversityNameS2.value == fields.AppCourse.universityId
      ) {
        common.snack(
          "E",
          "You selected applied course as suggested course 2. Please select another course"
        );
      } else if (
        newFields.CourseNameS1.value._id == newFields.CourseNameS2.value._id
      ) {
        common.snack(
          "E",
          "Both suggested courses are same. Please select different courses"
        );
      }
    }
  };

  const saveNewDate = (e) => {
    e.preventDefault();
    let newFields = common.reParse(mInterviewField);
    if (newDate && newDate.date !== "") {
      let isEx = newFields.slot.filter((e) => e.date == newDate.date);
      if (isEx.length == 0) {
        newFields.slot.push(newDate);
        setMInterviewField(newFields);
        setIsNewDateAdd(false);
        setNewDate({ date: "", slot: [] });
      } else {
        common.snack("E", "Selected date already exists");
      }
    } else {
      common.snack("E", "Please select date");
    }
  };

  const showNewDate = (e) => {
    e.preventDefault();
    setIsNewDateAdd(true);
    setNewDate({ date: "", slot: [] }); //resetting value
  };

  const setNewDateChange = (date) => {
    let newFields = common.reParse(newDate);
    newFields.date = date ? common.formatDate(date.toDate(), "YYYY-MM-DD") : "";
    setNewDate(newFields);
  };

  const addDelDate = (e, idx, type, data) => {
    e.preventDefault();
    let newFields = common.reParse(mInterviewField);
    if (type == "add") {
      newFields.slot.push({ date: data, slot: [] });
    } else {
      if (idx > -1) {
        newFields.slot.splice(idx, 1);
      }
    }
    setMInterviewField(newFields);
  };

  const addDelSlot = (e, idx, tdx, type, data) => {
    e.preventDefault();
    let newFields = common.reParse(mInterviewField);
    if (type == "add") {
      newFields.slot[idx].slot.push({ date: data, slot: [] });
    } else {
      if (tdx > -1) {
        newFields.slot[idx].slot.splice(tdx, 1);
      }
    }
    setMInterviewField(newFields);
  };

  const showTimePicker = (e, idx) => {
    e.preventDefault();
    setSlotTime(moment());
    let newFields = common.reParse(mInterviewField);
    newFields.slot[idx].add = true;
    setMInterviewField(newFields);
  };

  const saveSlot = (e, idx) => {
    e.preventDefault();
    if (slotTime) {
      let newFields = common.reParse(mInterviewField);
      let isEx = newFields.slot[idx].slot.filter(
        (e) => e == common.formatDate(slotTime, "hh:mm a")
      );
      if (isEx.length == 0) {
        newFields.slot[idx].slot.push(common.formatDate(slotTime, "hh:mm a"));
        newFields.slot[idx].add = false;
        setSlotTime(moment());
        setMInterviewField(newFields);
      } else {
        common.snack("E", "Selected slot time already exists");
      }
    } else {
      common.snack("E", "Please select slot time");
    }
  };

  const handleModel = () => {
    setInterviewModel(false);
  };

  const saveMockInterviewSlot = (e) => {
    e.preventDefault();
    let mockIntFields = common.reParse(miFields);
    let isEmpty = "";
    mInterviewField &&
      mInterviewField.slot.length > 0 &&
      mInterviewField.slot.map((item) => {
        if (item.slot.length == 0) {
          isEmpty = "empty";
        }
      });
    if (isEmpty != "empty") {
      if (condOrSfeSlot) {
        mockIntFields.map((obj) => {
          if (obj.id == mInterviewField.id) {
            obj.slot = mInterviewField.slot;
          }
        });
        setMIFields(mockIntFields);
      } else {
        let newFields = mInterviewField;
        setSFESlots(newFields);
      }
      setMInterviewField(null);
      setInterviewModel(false);
    } else {
      common.snack("E", "Please select slot time");
    }
  };

  //Bind university and colleges list in selectbox
  const getUniversityColleges = (field) => {
    getETCourseByUId(field.UniversityNameS1.value, 1, true, field);
    getETCourseByUId(field.UniversityNameS2.value, 2, true, field);
  };

  //Get universities from edutech
  const getETUniversities = async () => {
    let result = await eduTechSerivces.getUniversities();
    let udata = common.getOptionsData(result, "name", "_id");
    setUniveristies(udata);
  };

  //Get courses from edutech
  const getETCourseByUId = async (universityId, idx, isEdit, leadData) => {
    let result = await eduTechSerivces.getCoursesbyUniversity(universityId);
    let optionsData = [];
    optionsData.push({ label: "Select Course", value: "" });

    if (result && result.length) {
      await result.map((obj) => {
        let options = {
          label: obj.name,
          value: obj,
        };
        optionsData.push(options);
      });
    }

    if (idx == 1) setCourse1List(optionsData);
    else setCourse2List(optionsData);
  };

  const addMRow = (e, section = null) => {
    e.preventDefault();
    if (section == 4) {
      //missing field
      setSFEFields((oldData) => {
        let newData = common.reParse(oldData);
        newData.push(initialMFields);
        return newData;
      });
    } else {
      setMFields((oldData) => {
        let newData = common.reParse(oldData);
        newData.push({
          field: "",
          desc: "",
          status: "missing",
        });
        return newData;
      });
    }
  };

  const removeMRow = (e, idx, sec = null) => {
    e.preventDefault();
    if (sec == 4) {
      setSFEFields((oldData) => {
        let newData = common.reParse(oldData);
        newData.splice(idx, 1);
        return newData;
      });
    } else {
      setMFields((oldData) => {
        let newData = common.reParse(oldData);
        let removeLabel = newData[idx].field;
        newData.splice(idx, 1);
        return newData;
      });
    }
  };

  const previewImage = (e, file) => {
    e.preventDefault();
    setImgPreview(true);
    setImgPreviewSrc(file);
  };

  const handleClose = () => {
    setImgPreview(false);
    setImgPreviewSrc("");
  };

  const openModel = (e, obj) => {
    e.preventDefault();
    setInterviewModel(true);
    if (obj) {
      setMInterviewField(obj);
      setCondOrSfeSlot(true);
    } else {
      setCondOrSfeSlot(false);
      setMInterviewField({
        slot: sfeSlots.slot,
        bookedSlot: sfeSlots.bookedSlot,
      });
    }
  };

  // submit handler
  const onSubmit = async () => {
    let inputs = await formInputs();
    if (inputs.valid == true && inputs.leadData && inputs.appData) {
      setIsSubmit(true);
      let url =
        envConfig.BASE_API + Services.CRM_LEADS + "/" + props.data.value1;
      let data = {
        url: url,
        type: "dynamic",
        method: "PATCH",
        body: JSON.stringify(inputs.leadData),
        auth: "token",
        cType: 4,
      };
      api.call(
        data,
        async (response) => {
          window.scrollTo(0, 0);
          setIsSubmit(false);
          if (Object.keys(inputs.appData).length > 0) {
            await eduTechSerivces.updateApplicationInfo(
              fields.Cattr1,
              inputs.appData
            );
          }
          setLeadStatus(common.enableLeadStatus(fields.LeadStatus.value));
          setLeadRes(response.data);
          setCRMLeadData(response.data);
          setIntake(intakeFields);
          getEditData();
          common.snack("S", "Successfully Updated");
        },
        (error) => {
          setIsSubmit(false);
        },
        (final) => {}
      );
    }
  };
  const renderAttachment = (docs) => {
    const imgArr = docs.filter((e) =>
      ["jpg", "jpeg", "png"].includes(e.split(".").pop())
    );
    const pdf = docs.filter(
      (e) => !["jpg", "jpeg", "png"].includes(e.split(".").pop())
    );
    return (
      <div>
        <div className="attached-img-wrap">
          {imgArr.map((image, idx) => {
            return (
              <img
                key={idx}
                src={common.loadEduImage(image)}
                onClick={(e) => previewImage(e, image)}
              />
            );
          })}
        </div>
        {pdf.map((image, idx) => {
          return (
            <div className="attach-link" key={idx}>
              <a href={common.loadEduImage(image)} download target="_blank">
                <img
                  src={common.loadImg("external-link-icn.svg")}
                  className="me-2"
                />
                {image.split("/").pop()}
              </a>
            </div>
          );
        })}
        {docs.length == 0 && <div>-</div>}
      </div>
    );
  };

  const noData = () => {
    return (
      <Col xs={3} className="body-th">
        <div className="camp-sub-name">Documents</div>
        <div className="camp-sub-des my-2">-</div>
      </Col>
    );
  };

  //Check conversion status and show popup if not converted
  const convert = () => {
    const isEduTech = common.isEdutechAcc();
    if (
      fields.OpportunityConverted == null ||
      fields.OpportunityConverted == "N"
    ) {
      if (isEduTech) {
        if (
          fields.LeadStatus.value == "Potential Student" &&
          fields.Cattr25 != "Y" &&
          fields.Cattr31?.value == "Not withdrawn"
        ) {
          showDealInfo();
        } else {
          common.snack("E", "Potential Student only convertable to Deal");
        }
      } else {
        showDealInfo();
      }
    } else {
      common.snack("E", "Lead already converted as Deal");
    }
  };

  //Open conversion popup
  const showDealInfo = () => {
    setIsConvertModal(true);
  };

  //close conversion popup
  const closeModal = () => {
    setIsConvertModal(false);
  };

  //update conversion status
  const conversionUpdate = () => {
    let newFields = common.reParse(fields);
    newFields.OpportunityConverted = "Y";
    setFields(newFields);
  };

  const closeForm = (id, data) => {
    let ids = id ? id : props.data.value1;
    props.closeForm(false, "id", data, ids);
  };

  // Floating React select
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const openEmail = (e) => {
    e.preventDefault();
    setOpenEMail(true);
  };

  const closeEmail = (e) => {
    e.preventDefault();
    setOpenEMail(false);
  };

  //get Employee
  const getEmpByEmpId = (EmpId) => {
    let url = `${envConfig.BASE_API}${CRM_PAY_EMPLOYEE}/${EmpId}`;
    api.call(
      { url: url, type: "dynamic", method: "GET", auth: "token" },
      (response) => {
        if (response.status === 200) {
          setSalesPerson(response.data);
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const uploadFile = async () => {
    var FormDataObj = new FormData();
    files.forEach((i, k) => {
      FormDataObj.append("file", i);
    });
    let data = {
      url: "common_upload_direct",
      method: "POST",
      auth: "config",
      body: FormDataObj,
      ssr: true,
      cType: 6,
    };
    let resultData = await eduTechSerivces.call(data);
    return resultData;
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    const authInfo = common.authInfo();
    let uploadFiles = [];
    let isTrue = true;
    let formData = new FormData();
    if (fields.emailContent) {
      setIsEmailSubmit(true);
      if (files?.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append("attachment", files[i]);
        }
        isTrue = false;
        uploadFiles = await uploadFile();
        if (uploadFiles) {
          isTrue = true;
        }
      }
      //post request & refresh
      if (isTrue) {
        formData.append("salesPersonEmail", salesPerson.Email);
        formData.append("content", fields.emailContent);
        // formData.append("salesPerson", authInfo.UserName);
        formData.append("salesPerson", salesPerson.EmployeeName);
        formData.append("documents", JSON.stringify(uploadFiles?.data?.url));

        // let resultData = await eduTechSerivces.sendApplicationEmail(fields.Cattr1, formData);
        let data = {
          url: "send_email",
          method: "POST",
          auth: "config",
          query: `/${fields.Cattr1}`,
          body: formData,
          ssr: true,
          cType: 6,
        };
        let resultData = await eduTechSerivces.call(data);
        if (resultData.status == 200) {
          //add new msg to history
          let logs = common.reParse(emailHistory);
          logs.push({
            content: fields.emailContent,
            status: "sended",
            createdAt: new Date(),
          });
          setEmailHistory(logs);

          //reset text area
          let newFields = common.reParse(fields);
          newFields.emailContent = "";
          setFields(newFields);
          setFiles([]);
          common.snack("S", "Email sended successfully");
        } else {
          common.snack("E", "Sending message failed");
        }
        setIsEmailSubmit(false);
      }
    } else {
      common.snack("W", "Please enter email content");
    }
  };

  // file handler
  const onFileChange = (e) => {
    const files = e.target.files;
    setFiles((prev) => {
      let nData = [...prev, ...files];
      return nData;
    });
  };

  const delImage = (e, i) => {
    e.preventDefault();
    setFiles((oldData) => {
      let newData = [...oldData];
      newData.splice(i, 1);
      return newData;
    });
  };

  // render
  return (
    <Box>
      <Paper variant="contained dynamic-form-wrap" square>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Box py={1} px={1} className="icon-back icon-buton">
                  <IconButton onClick={(e) => closeForm()}>
                    <img
                      src={common.loadImg("backArrowKanban.svg")}
                      alt="back"
                    />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item className="df-main-head">
                <div className="detail-title">
                  {props.data.value1 ? "Update Form" : "Add Form"}
                </div>
              </Grid>
            </Grid>
          </Grid>

          {status == "success" && (
            <Grid item>
              <Box px={2}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <IconButton
                      onClick={(e) => {
                        setStatus("process");
                        getEditData();
                      }}
                    >
                      <img
                        src={common.loadImg("refresh-icon.svg")}
                        alt="document"
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Box className="verical-divider">
                      <Divider orientation="vertical" />
                    </Box>
                  </Grid>
                  {props.data.value1 &&
                    leadRes.LeadStatus.value == "Potential Student" &&
                    fields.OpportunityConverted != "Y" &&
                    fields.Cattr25 != "Y" &&
                    fields?.Cattr31?.value == "Not withdrawn" && (
                      <Grid item>
                        <Box>
                          <Button
                            className="save-note"
                            type="submit"
                            onClick={() => convert()}
                            //disabled={isSubmit ? true : false}
                          >
                            {"Convert to Deal"}
                          </Button>
                        </Box>
                      </Grid>
                    )}
                  {props.data.value1 && fields.OpportunityConverted != "Y" && (
                    <Grid item>
                      <Box className="vertical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Grid>
                  )}
                  {/* <Grid item>
                    <Box>
                      <Button
                        className="save-note"
                        type="submit"
                        onClick={() => onSubmit()}
                        disabled={isSubmit ? true : false}
                      >
                        {isSubmit
                          ? "Processing..."
                          : props.data.value1
                          ? "Update Changes"
                          : "Save Changes"}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="vertical-divider">
                      <Divider orientation="vertical" />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="revert-close">
                      <Button
                        className="save-note pe-0"
                        onClick={(e) => closeForm()}
                      >
                        Revert and Close
                        <img
                          src={common.loadImg("closeKanban.svg")}
                          alt="close"
                        />
                      </Button>
                    </Box>
                  </Grid> */}
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
        <Box>
          <Divider />
        </Box>
        <StatusBar status={status} />
        {status == "success" && (
          <>
            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title send-mail-sec mt-3">
                <span>Personal Information</span>
                <Link
                  to="#"
                  onClick={(e) => {
                    openEmail(e);
                  }}
                >
                  Send Email
                </Link>
              </div>
              <Box mt={3}>
                <Divider />
              </Box>

              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col xs={3} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">First Name</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr3 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={3} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Last Name</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr4 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={3} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Email</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Email ? (
                              <a
                                href={"mailto:" + fields.Email}
                                target="_blank"
                              >
                                {tools.hideEmail(fields.Email)}
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={3} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Mobile</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Mobile ? (
                              <a href={"tel:" + fields.Mobile}>
                                {tools.hideMobile(fields.Mobile)}
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={3} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">DoB</div>
                          <div className="camp-sub-des mt-2">
                            {fields
                              ? common.formatDate(fields.Dattr1, "DD-MM-YYYY")
                              : "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={3} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Gender</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr6 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={3} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Nationality</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr7 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={3} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">First Language</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr8 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={3} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Marital Status</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr9 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={3} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Country of Birth</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr10 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={3} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Address Line 1</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr11 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={3} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Country</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr12 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={3} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Province</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr5 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={3} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            City/Town & Postcode
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr13 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                </Row>
              </Box>
            </Box>

            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title mt-3">
                Student Finance Information
              </div>
              <Box mt={3}>
                <Divider />
              </Box>
              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Have You Applied for Student Finance before?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr21 == "Y" ? "Yes" : "No"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            How many years of funding have you taken before?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr22 || ""}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>

                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            What is your Visa Status?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.FirstName || ""}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            How long have you been living in UK?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Eattr7 || ""}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Are you a Refugee/ Asylum Seeker?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Eattr8 == "Y" ? "Yes" : "No"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Have you spent any time in public care up to the age
                            of 18?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Eattr9 == "Y" ? "Yes" : "No"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Disability/special needs?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Eattr10 == "Y" ? "Yes" : "No"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  {/* {fields.Cattr38 && ( */}
                  {appInfo?.profile && appInfo?.profile["passportDocs"] && (
                    <Col xs={6} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">
                              Passport / Birth Certificate
                            </div>
                            <div className="camp-sub-des mt-2">
                              {/* {renderAttachment(fields.Cattr38.split(","))} */}
                              {renderAttachment(
                                appInfo?.profile["passportDocs"]
                              )}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {appInfo?.profile && appInfo?.profile["brpDocs"] && (
                    <Col xs={3} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">
                              Biometric Residence Permit (BRP)
                            </div>
                            <div className="camp-sub-des mt-2">
                              {renderAttachment([appInfo?.profile["brpDocs"]])}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {appInfo?.profile && appInfo?.profile["euDocs"] && (
                    <Col xs={3} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">EU Share Code</div>
                            <div className="camp-sub-des mt-2">
                              {renderAttachment([appInfo?.profile["euDocs"]])}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {appInfo?.profile && appInfo?.profile["homeOfficeDocs"] && (
                    <Col xs={3} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">
                              Home Office Letter
                            </div>
                            <div className="camp-sub-des mt-2">
                              {renderAttachment([
                                appInfo?.profile["homeOfficeDocs"],
                              ])}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {appInfo?.profile && appInfo?.profile["poeDocs"] && (
                    <Col xs={3} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">
                              Proof of evidence of Stay
                            </div>
                            <div className="camp-sub-des mt-2">
                              {/* {renderAttachment(fields.Address1.split(","))} */}
                              {renderAttachment(appInfo?.profile["poeDocs"])}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                </Row>
              </Box>
            </Box>

            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title mt-3">
                Application Status and Source
              </div>
              <Box mt={3}>
                <Divider />
              </Box>

              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="">
                      <FormControl
                        fullWidth
                        className="select-float-label common-padding-zero-select"
                      >
                        <Select
                          className="text-box custom-react-select"
                          labelId="select"
                          isDisabled={
                            fields.Cattr25 == "Y" ||
                            (fields.Cattr31 && fields.Cattr36 == "accepted")
                          }
                          id="select"
                          label="Lead Status"
                          menuPortalTarget={document.body}
                          onChange={(data) =>
                            onSelectHandler(data, "LeadStatus")
                          }
                          options={leadStatus}
                          isOptionDisabled={(option) => option.disabled}
                          defaultValue={fields["LeadStatus"]}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          placeholder="Lead Status"
                          styles={selectStyles}
                        ></Select>
                      </FormControl>
                    </Box>
                  </Col>
                  {fields.LeadStatus.value == "New Application" &&
                    !cancelledStatus && (
                      <Col xs={6} className="body-th common-input-style">
                        <FormControlLabel
                          className="dynamic-checkbox ms-0 checkbox-space"
                          control={<Checkbox />}
                          label={"Is Missing Information?"}
                          value={fields.Cattr24}
                          labelPlacement="end"
                          disabled={
                            leadRes.Cattr24 === "Y" || fields?.Cattr25 === "Y"
                          }
                          checked={fields.Cattr24 === "Y" ? true : false}
                          onChange={(e) => onFieldChange(e, "Cattr24")}
                        />
                        <FormControlLabel
                          className="dynamic-checkbox ms-0 checkbox-space"
                          control={<Checkbox />}
                          label={"Course Suggestion?"}
                          labelPlacement="end"
                          disabled={
                            fields.Eattr3 != null ||
                            leadRes.Cattr26 === "Y" ||
                            fields?.Cattr25 === "Y" ||
                            fields.Cattr37 === "Y"
                          }
                          checked={fields.Cattr26 === "Y" ? true : false}
                          onChange={(e) => onFieldChange(e, "Cattr26")}
                        />
                      </Col>
                    )}
                </Row>
              </Box>
            </Box>

            {fields.LeadStatus.value == "New Application" &&
              fields.Cattr24 == "Y" &&
              (fields.Cattr25 == "N" ||
                fields.Cattr31.value == "Not withdrawn") && (
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Missing Information
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    {mFields.map((mObj, idx) => {
                      let clCount = idx;
                      return (
                        <Row className="m-0" key={clCount}>
                          <Col xs={3} className="body-th common-input-style">
                            <Box className="">
                              <FormControl
                                fullWidth
                                className="select-float-label common-padding-zero-select"
                              >
                                <Select
                                  className="text-box custom-react-select"
                                  labelId="select"
                                  label="Missing Field"
                                  menuPortalTarget={document.body}
                                  id={clCount}
                                  value={missingFields.find(
                                    (o) => o.value == mObj.field
                                  )}
                                  isMulti={false}
                                  onChange={(data) =>
                                    onMissingSelect(data, "field", clCount)
                                  }
                                  options={missingFields.filter(
                                    (obj) => !mSel.includes(obj.value)
                                  )}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="Missing Field"
                                  styles={{
                                    container: (provided, state) => ({
                                      ...provided,
                                      marginTop: 0,
                                    }),
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflow: "visible",
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      position: "absolute",
                                      top:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? -15
                                          : "10%",
                                      transition: "top 0.1s, font-size 0.1s",
                                      fontSize:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? 11
                                          : 13,
                                      color: "#9daabb",
                                    }),
                                  }}
                                ></Select>
                              </FormControl>
                            </Box>
                          </Col>
                          <Col xs={3} className="body-th common-input-style">
                            <Box>
                              <div className="form-floating">
                                <textarea
                                  className="form-control textarea"
                                  id={clCount}
                                  value={mObj.desc}
                                  onChange={(e) =>
                                    onMissingSelect(
                                      e.target.value,
                                      "desc",
                                      clCount
                                    )
                                  }
                                  maxLength={150}
                                ></textarea>
                                <label
                                  htmlFor="floatingTextarea"
                                  className="floating-label"
                                >
                                  Missing Field Note
                                </label>
                              </div>
                            </Box>
                          </Col>
                          <Col xs={3} className="body-th common-input-style">
                            <Box className="">
                              <FormControl
                                fullWidth
                                className="select-float-label common-padding-zero-select"
                              >
                                <Select
                                  className="text-box custom-react-select"
                                  labelId="select"
                                  label="Review Status"
                                  menuPortalTarget={document.body}
                                  id={clCount}
                                  value={reviewStatus.find(
                                    (o) => o.value == mObj.status
                                  )}
                                  isMulti={false}
                                  onChange={(data) =>
                                    onMissingSelect(data, "status", idx)
                                  }
                                  options={reviewStatus}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="Review Status"
                                  styles={{
                                    container: (provided, state) => ({
                                      ...provided,
                                      marginTop: 0,
                                    }),
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflow: "visible",
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      position: "absolute",
                                      top:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? -15
                                          : "10%",
                                      transition: "top 0.1s, font-size 0.1s",
                                      fontSize:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? 11
                                          : 13,
                                      color: "#9daabb",
                                    }),
                                  }}
                                ></Select>
                              </FormControl>
                            </Box>
                          </Col>
                          <Col
                            xs={3}
                            className="body-th common-input-style v-align-center"
                          >
                            <div className="form-group">
                              {mFields.length === 1 && idx === 0 ? (
                                <Link
                                  to="#"
                                  className="add-rule-link add-rule"
                                  onClick={(e) => {
                                    addMRow(e);
                                  }}
                                >
                                  +
                                </Link>
                              ) : (
                                idx === 0 && (
                                  <Link
                                    to="#"
                                    className="add-rule-link remove-rule"
                                    onClick={(e) => {
                                      removeMRow(e, clCount);
                                    }}
                                  >
                                    -
                                  </Link>
                                )
                              )}
                              {idx > 0 && (
                                <React.Fragment>
                                  <Link
                                    to="#"
                                    className="add-rule-link remove-rule"
                                    onClick={(e) => removeMRow(e, clCount)}
                                  >
                                    -
                                  </Link>
                                  {mFields.length - 1 == idx && (
                                    <Link
                                      to="#"
                                      className="add-rule-link add-rule"
                                      onClick={(e) => addMRow(e)}
                                    >
                                      +
                                    </Link>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </Box>
                </Box>
              )}

            {fields.LeadStatus.value == "New Application" &&
              fields.Cattr26 == "Y" &&
              (fields.Cattr25 == "N" ||
                fields.Cattr31.value == "Not withdrawn") &&
              !disableSuggestion && (
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Suggested Course 1
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="">
                          <FormControl
                            fullWidth
                            className="select-float-label common-padding-zero-select"
                          >
                            <Select
                              className="text-box custom-react-select"
                              labelId="select"
                              label="University Name"
                              menuPortalTarget={document.body}
                              options={univeristies}
                              name="UniversityNameS1"
                              defaultValue={fields["UniversityNameS1"]}
                              onChange={(data) =>
                                onSelectHandler(data, "UniversityNameS1")
                              }
                              components={{
                                ValueContainer: CustomValueContainer,
                              }}
                              placeholder="University Name"
                              styles={{
                                container: (provided, state) => ({
                                  ...provided,
                                  marginTop: 0,
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  overflow: "visible",
                                }),
                                placeholder: (provided, state) => ({
                                  ...provided,
                                  position: "absolute",
                                  top:
                                    state.hasValue ||
                                    state.selectProps.inputValue
                                      ? -15
                                      : "10%",
                                  transition: "top 0.1s, font-size 0.1s",
                                  fontSize:
                                    state.hasValue ||
                                    state.selectProps.inputValue
                                      ? 11
                                      : 13,
                                  color: "#9daabb",
                                }),
                              }}
                            ></Select>
                          </FormControl>
                        </Box>
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="">
                          <FormControl
                            fullWidth
                            className="select-float-label common-padding-zero-select"
                          >
                            <Select
                              className="text-box custom-react-select"
                              labelId="select"
                              label="Course Name"
                              menuPortalTarget={document.body}
                              options={course1List}
                              name="CourseNameS1"
                              defaultValue={fields["CourseNameS1"]}
                              onChange={(data) =>
                                onSelectHandler(data, "CourseNameS1")
                              }
                              components={{
                                ValueContainer: CustomValueContainer,
                              }}
                              placeholder="Course Name"
                              styles={{
                                container: (provided, state) => ({
                                  ...provided,
                                  marginTop: 0,
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  overflow: "visible",
                                }),
                                placeholder: (provided, state) => ({
                                  ...provided,
                                  position: "absolute",
                                  top:
                                    state.hasValue ||
                                    state.selectProps.inputValue
                                      ? -15
                                      : "10%",
                                  transition: "top 0.1s, font-size 0.1s",
                                  fontSize:
                                    state.hasValue ||
                                    state.selectProps.inputValue
                                      ? 11
                                      : 13,
                                  color: "#9daabb",
                                }),
                              }}
                            ></Select>
                          </FormControl>
                        </Box>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              )}

            {fields.LeadStatus.value == "New Application" &&
              fields.Cattr26 == "Y" &&
              (fields.Cattr25 == "N" ||
                fields.Cattr31.value == "Not withdrawn") &&
              !disableSuggestion && (
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Suggested Course 2
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="">
                          <FormControl
                            fullWidth
                            className="select-float-label common-padding-zero-select"
                          >
                            <Select
                              className="text-box custom-react-select"
                              labelId="select"
                              label="University Name"
                              menuPortalTarget={document.body}
                              options={univeristies}
                              name="UniversityNameS2"
                              defaultValue={fields["UniversityNameS2"]}
                              onChange={(data) =>
                                onSelectHandler(data, "UniversityNameS2")
                              }
                              components={{
                                ValueContainer: CustomValueContainer,
                              }}
                              placeholder="University Name"
                              styles={{
                                container: (provided, state) => ({
                                  ...provided,
                                  marginTop: 0,
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  overflow: "visible",
                                }),
                                placeholder: (provided, state) => ({
                                  ...provided,
                                  position: "absolute",
                                  top:
                                    state.hasValue ||
                                    state.selectProps.inputValue
                                      ? -15
                                      : "10%",
                                  transition: "top 0.1s, font-size 0.1s",
                                  fontSize:
                                    state.hasValue ||
                                    state.selectProps.inputValue
                                      ? 11
                                      : 13,
                                  color: "#9daabb",
                                }),
                              }}
                            ></Select>
                          </FormControl>
                        </Box>
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="">
                          <FormControl
                            fullWidth
                            className="select-float-label common-padding-zero-select"
                          >
                            <Select
                              className="text-box custom-react-select"
                              labelId="select"
                              label="Course Name"
                              menuPortalTarget={document.body}
                              options={course2List}
                              name="CourseNameS2"
                              defaultValue={fields["CourseNameS2"]}
                              onChange={(data) =>
                                onSelectHandler(data, "CourseNameS2")
                              }
                              components={{
                                ValueContainer: CustomValueContainer,
                              }}
                              placeholder="Course Name"
                              styles={{
                                container: (provided, state) => ({
                                  ...provided,
                                  marginTop: 0,
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  overflow: "visible",
                                }),
                                placeholder: (provided, state) => ({
                                  ...provided,
                                  position: "absolute",
                                  top:
                                    state.hasValue ||
                                    state.selectProps.inputValue
                                      ? -15
                                      : "10%",
                                  transition: "top 0.1s, font-size 0.1s",
                                  fontSize:
                                    state.hasValue ||
                                    state.selectProps.inputValue
                                      ? 11
                                      : 13,
                                  color: "#9daabb",
                                }),
                              }}
                            ></Select>
                          </FormControl>
                        </Box>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              )}

            {fields.LeadStatus.value == "New Application" &&
              fields.Cattr26 == "Y" &&
              (fields.Cattr25 == "N" ||
                fields.Cattr31.value == "Not withdrawn") &&
              !disableSuggestion && (
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Applied Course Not Eligibility Reason
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col xs={6} className="body-th common-input-style">
                        <div className="form-floating">
                          <textarea
                            className="form-control textarea"
                            rows={3}
                            value={fields["Cattr29"]}
                            name={"Cattr29"}
                            onChange={(e) => onChange(e)}
                            maxLength={150}
                          ></textarea>
                          <label
                            htmlFor="floatingTextarea"
                            className="floating-label"
                          >
                            Why applied course not eligible?
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              )}

            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title mt-3">
                Course Choice & University
              </div>
              <Box mt={3}>
                <Divider />
              </Box>

              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Applied Course</div>
                          <div className="camp-sub-des mt-2">
                            {fields.AppCourse.course || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Applied University
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.AppCourse.university || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Intake Month</div>
                          <div className="camp-sub-des mt-2">
                            {fields.AppCourse.month || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Intake Year</div>
                          <div className="camp-sub-des mt-2">
                            {fields.AppCourse.year || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  {fields.PhoneExtn && (
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Intake Updated Date
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.PhoneExtn || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  {leadRes.LeadStatus == "New Application" ||
                  fields.LeadStatus.value == "New Application" ? (
                    <Col xs={6} className="body-th common-input-style">
                      <FormControlLabel
                        className="dynamic-checkbox ms-0 checkbox-space"
                        control={<Checkbox />}
                        label={"Is Course Verified?"}
                        value={fields.Cattr37}
                        disabled={
                          fields.Cattr25 == "Y" ||
                          fields.Cattr31.value != "Not withdrawn" ||
                          (fields.Cattr26 === "Y" && fields.Eattr3 == null)
                        }
                        labelPlacement="end"
                        checked={fields.Cattr37 === "Y" ? true : false}
                        onChange={(e) => onFieldChange(e, "Cattr37")}
                      />
                    </Col>
                  ) : (
                    <Col xs={4} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">
                              Is Course Verified?
                            </div>
                            <div className="camp-sub-des mt-2">
                              {fields.Cattr37 == "Y" ? "Yes" : "No"}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {fields.Eattr3 && (
                    <>
                      <Col xs={12} className="body-th">
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Box>
                              <div className="camp-sub-name">
                                Previously Applied Course
                              </div>
                              <div className="camp-sub-des mt-2">
                                {fields.Eattr3 || "-"}
                              </div>
                            </Box>
                          </Grid>
                        </Grid>
                      </Col>
                    </>
                  )}
                  {fields.ReferredBy && (
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Previously Applied Intake
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.ReferredBy || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Row>
                {fields.Eattr4 && (
                  <Row className="m-0">
                    <Col xs={6} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">
                              Suggested Course 1
                            </div>
                            <div className="camp-sub-des mt-2">
                              {fields.Eattr4}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                    <Col xs={6} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">
                              Suggested Course 2
                            </div>
                            <div className="camp-sub-des mt-2">
                              {fields.Eattr5}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  </Row>
                )}
              </Box>
            </Box>

            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title mt-3">
                Application Fee
              </div>
              <Box mt={3}>
                <Divider />
              </Box>

              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col xs={4} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Payment Amount</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Nattr1 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={4} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Payment Date</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Dattr3
                              ? common.formatDate(
                                  fields.Dattr3,
                                  "DD-MM-YYYY hh:mm a"
                                )
                              : "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={4} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Promocode Applied?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr20 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={4} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Applied Promocode</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr2 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={4} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Online TransactionId
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr30 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={4} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Is Application Fee Payed?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Uom == "Y" ? "Yes" : "No"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                </Row>
              </Box>
            </Box>

            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title mt-3">
                Application Rejection
              </div>
              <Box mt={3}>
                <Divider />
              </Box>
              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col xs={6} className="body-th common-input-style">
                    <FormControlLabel
                      className="dynamic-checkbox ms-0 checkbox-space"
                      control={<Checkbox />}
                      label={"Application Rejected?"}
                      value={fields.Cattr25}
                      labelPlacement="end"
                      disabled={
                        cancelledStatus ||
                        leadRes.Cattr25 == "Y" ||
                        fields?.Cattr31?.value !== "Not withdrawn"
                      }
                      checked={fields.Cattr25 === "Y" ? true : false}
                      onChange={(e) => onFieldChange(e, "Cattr25")}
                    />
                  </Col>
                  {fields.Cattr25 === "Y" && (
                    <Col xs={6} className="body-th common-input-style">
                      <Box className="">
                        <FormControl
                          fullWidth
                          className="select-float-label common-padding-zero-select"
                        >
                          <Select
                            className="text-box custom-react-select"
                            labelId="select"
                            id="select"
                            label="Application Rejected category"
                            menuPortalTarget={document.body}
                            value={fields.SalePossibility}
                            isMulti={false}
                            onChange={(data) =>
                              onSelectHandler(data, "SalePossibility")
                            }
                            isDisabled={
                              cancelledStatus ||
                              leadRes.Cattr25 == "Y" ||
                              fields?.Cattr31?.value !== "Not withdrawn"
                            }
                            options={rejectOptions}
                            components={{
                              ValueContainer: CustomValueContainer,
                            }}
                            placeholder="Application Rejected category"
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                marginTop: 0,
                              }),
                              valueContainer: (provided, state) => ({
                                ...provided,
                                overflow: "visible",
                              }),
                              placeholder: (provided, state) => ({
                                ...provided,
                                position: "absolute",
                                top:
                                  state.hasValue || state.selectProps.inputValue
                                    ? -15
                                    : "10%",
                                transition: "top 0.1s, font-size 0.1s",
                                fontSize:
                                  state.hasValue || state.selectProps.inputValue
                                    ? 11
                                    : 13,
                                color: "#9daabb",
                              }),
                            }}
                          ></Select>
                        </FormControl>
                      </Box>
                    </Col>
                  )}
                  {fields.Cattr25 === "Y" && (
                    <Col xs={6} className="body-th common-input-style">
                      <Box className="filled-box">
                        <div className="form-floating">
                          <textarea
                            className="form-control textarea"
                            id="floatingTextarea"
                            maxLength={150}
                            rows={2}
                            name="Cattr32"
                            value={fields.Cattr32}
                            onChange={(e) => onChange(e)}
                          ></textarea>
                          <label
                            htmlFor="floatingTextarea"
                            className="floating-label"
                          >
                            Application Rejected Reason
                          </label>
                        </div>
                      </Box>
                    </Col>
                  )}
                </Row>
              </Box>
            </Box>

            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title mt-3">
                Application Withdrawn Request
              </div>
              <Box mt={3}>
                <Divider />
              </Box>
              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col xs={6} className="body-th common-input-style">
                    {/* <FormControlLabel
                      className="dynamic-checkbox ms-0 checkbox-space"
                      control={<Checkbox />}
                      label={"Is AE Withdrawn Application as per User request?"}
                      value={fields.Cattr35}
                      labelPlacement="end"
                      checked={fields.Cattr35 === "Y" ? true : false}
                      onChange={(e) => onFieldChange(e, "Cattr35")}
                      disabled={
                        cancelledStatus ||
                        fields.Cattr25 == "Y" ||
                        fields.Cattr35 !== "Y"
                      }
                    /> */}

                    <Select
                      className="text-box custom-react-select"
                      labelId="select"
                      id="select"
                      label="Application Withdraw"
                      menuPortalTarget={document.body}
                      value={fields.Cattr31}
                      isMulti={false}
                      onChange={(data) => onSelectHandler(data, "Cattr31")}
                      options={withdrawOption}
                      isDisabled={fields.Cattr25 == "Y"}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      isOptionDisabled={(option) => option.disabled}
                      placeholder="Application withdrawn category"
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                          marginTop: 0,
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          overflow: "visible",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top:
                            state.hasValue || state.selectProps.inputValue
                              ? -15
                              : "10%",
                          transition: "top 0.1s, font-size 0.1s",
                          fontSize:
                            state.hasValue || state.selectProps.inputValue
                              ? 11
                              : 13,
                          color: "#9daabb",
                        }),
                      }}
                    ></Select>
                  </Col>
                  {fields.Cattr31?.value === "Withdrawn by user" &&
                  fields.Cattr35 ? (
                    <Col xs={6} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">
                              Withdrawn Request Message
                            </div>
                            <div className="camp-sub-des mt-2">
                              {fields.Cattr35 || "-"}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  ) : (
                    fields.Cattr31?.value === "Withdrawn by admin" && (
                      <Col xs={6} className="body-th common-input-style">
                        {!cancelledStatus ? (
                          <Box className="filled-box">
                            <div className="form-floating">
                              <textarea
                                className="form-control textarea"
                                id="floatingTextarea1"
                                maxLength={150}
                                rows={2}
                                name="Cattr35"
                                value={fields.Cattr35}
                                onChange={(e) => onChange(e)}
                              ></textarea>
                              <label
                                htmlFor="floatingTextarea1"
                                className="floating-label"
                              >
                                Withdrawn Request Message
                              </label>
                            </div>
                          </Box>
                        ) : (
                          <Box className="filled-box">
                            <div className="camp-sub-name">
                              Withdrawn Request Message
                            </div>
                            <div className="camp-sub-des mt-2">
                              {fields.Cattr35 || "-"}
                            </div>
                          </Box>
                        )}
                      </Col>
                    )
                  )}
                  {cancelledStatus ||
                    (fields.Cattr35 && (
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="">
                          <FormControl
                            fullWidth
                            className="select-float-label common-padding-zero-select"
                          >
                            <Select
                              className="text-box custom-react-select"
                              labelId="select"
                              id="select"
                              label="Request Acceptance"
                              menuPortalTarget={document.body}
                              value={fields?.Cattr36}
                              isMulti={false}
                              onChange={(data) =>
                                onSelectHandler(data, "Cattr36")
                              }
                              options={canApprStatus}
                              components={{
                                ValueContainer: CustomValueContainer,
                              }}
                              placeholder="Request Acceptance"
                              styles={{
                                container: (provided, state) => ({
                                  ...provided,
                                  marginTop: 0,
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  overflow: "visible",
                                }),
                                placeholder: (provided, state) => ({
                                  ...provided,
                                  position: "absolute",
                                  top:
                                    state.hasValue ||
                                    state.selectProps.inputValue
                                      ? -15
                                      : "10%",
                                  transition: "top 0.1s, font-size 0.1s",
                                  fontSize:
                                    state.hasValue ||
                                    state.selectProps.inputValue
                                      ? 11
                                      : 13,
                                  color: "#9daabb",
                                }),
                              }}
                            ></Select>
                          </FormControl>
                        </Box>
                      </Col>
                    ))}
                  {fields.Cattr36 && fields.Cattr36.value == "rejected" && (
                    <Col xs={6} className="body-th">
                      <Box>
                        <div className="form-floating">
                          <textarea
                            className="form-control textarea"
                            id="floatingTextarea"
                            name="Cattr39"
                            rows={2}
                            value={fields.Cattr39}
                            onChange={(e) => onChange(e)}
                            maxLength={150}
                          ></textarea>
                          <label
                            htmlFor="floatingTextarea"
                            className="floating-label"
                          >
                            Request Rejected Reason
                          </label>
                        </div>
                      </Box>
                    </Col>
                  )}
                </Row>
              </Box>
            </Box>

            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title mt-3">
                Intake Differ
              </div>
              <Box mt={3}>
                <Divider />
              </Box>
              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col xs={6} className="body-th common-input-style">
                    <FormControlLabel
                      className="dynamic-checkbox ms-0 checkbox-space"
                      control={<Checkbox />}
                      label={"Intake Differ?"}
                      value={intake.intakeDiffer}
                      labelPlacement="end"
                      disabled={
                        fields.Cattr25 === "Y" ||
                        fields?.Cattr31?.value !== "Not withdrawn"
                      }
                      checked={intake.intakeDiffer == "Y" ? true : false}
                      onChange={(e) => intakeChange(e, "intakeDiffer")}
                    />
                  </Col>
                  {intake.intakeDiffer === "Y" && (
                    <Col xs={6} className="body-th common-input-style">
                      <Box className="">
                        <FormControl
                          fullWidth
                          className="select-float-label common-padding-zero-select"
                        >
                          <Select
                            className="text-box custom-react-select"
                            labelId="select"
                            id="select"
                            label="Intake Month Year"
                            menuPortalTarget={document.body}
                            value={intake.intakeMonthYear}
                            isMulti={false}
                            onChange={(data) =>
                              intakeChange(data, "intakeMonthYear")
                            }
                            options={courseIntMonthYear}
                            components={{
                              ValueContainer: CustomValueContainer,
                            }}
                            placeholder="Intake Month Year"
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                marginTop: 0,
                              }),
                              valueContainer: (provided, state) => ({
                                ...provided,
                                overflow: "visible",
                              }),
                              placeholder: (provided, state) => ({
                                ...provided,
                                position: "absolute",
                                top:
                                  state.hasValue || state.selectProps.inputValue
                                    ? -15
                                    : "10%",
                                transition: "top 0.1s, font-size 0.1s",
                                fontSize:
                                  state.hasValue || state.selectProps.inputValue
                                    ? 11
                                    : 13,
                                color: "#9daabb",
                              }),
                            }}
                          ></Select>
                        </FormControl>
                      </Box>
                    </Col>
                  )}

                  {intake.intakeDiffer === "Y" && (
                    <Col xs={6} className="body-th common-input-style">
                      <Box className="filled-box">
                        <div className="form-floating">
                          <textarea
                            className="form-control textarea"
                            id="floatingTextarea"
                            maxLength={150}
                            rows={2}
                            name="intakeReason"
                            value={intake.intakeReason}
                            onChange={(e) => intakeChange(e, "intakeReason")}
                          ></textarea>
                          <label
                            htmlFor="floatingTextarea"
                            className="floating-label"
                          >
                            Intake Differ Reason
                          </label>
                        </div>
                      </Box>
                    </Col>
                  )}
                </Row>
              </Box>
              {appInfo?.intakeDiffer?.length > 0 && (
                <Box mt={2} className="camp-edit meet-table">
                  <Row className="m-0">
                    <Col xs={3} className="body-th">
                      <div className="camp-sub-name">Intake Month</div>
                    </Col>
                    <Col xs={3} className="body-th">
                      <div className="camp-sub-name">Intake Year</div>
                    </Col>
                    <Col xs={3} className="body-th">
                      <div className="camp-sub-name">Intake Reason</div>
                    </Col>
                    <Col xs={3} className="body-th">
                      <div className="camp-sub-name">Intake Date</div>
                    </Col>
                  </Row>
                  {appInfo?.intakeDiffer?.map((obj, idx) => {
                    return (
                      <Row className="m-0" key={idx}>
                        <Col xs={3} className="body-th">
                          {obj?.intakeMonth}
                        </Col>
                        <Col xs={3} className="body-th">
                          {obj?.intakeYear}
                        </Col>
                        <Col xs={3} className="body-th">
                          {obj?.intakeReason}
                        </Col>
                        <Col xs={3} className="body-th">
                          {common.formatDate(
                            obj?.createdAt,
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                </Box>
              )}
            </Box>

            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title mt-3">
                Education Qualification and History
              </div>
              <Box mt={3}>
                <Divider />
              </Box>

              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  {!fields.otherEduData && (
                    <Col xs={4} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">
                              Country of Education
                            </div>
                            <div className="camp-sub-des mt-2">
                              {fields.Cattr16 || "-"}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  <Col xs={4} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Highest Level of Education
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr17 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  {fields.otherEduData &&
                    fields.otherEduData.type == "work" && (
                      <>
                        <Col xs={4} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                              <Box>
                                <div className="camp-sub-name">
                                  Years of Work Experience
                                </div>
                                <div className="camp-sub-des mt-2">
                                  {fields.otherEduData.experience || "-"}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                        <Col xs={4} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                              <Box>
                                <div className="camp-sub-name">
                                  Are you currently working?
                                </div>
                                <div className="camp-sub-des mt-2">
                                  {fields.otherEduData.working == "Y"
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                        <Col xs={4} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                              <Box>
                                <div className="camp-sub-name">
                                  Proof of Evidence
                                </div>
                                <div className="camp-sub-des mt-2">
                                  {renderAttachment(fields.otherEduData.docs)}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                      </>
                    )}

                  {fields.otherEduData &&
                    fields.otherEduData.type == "other" && (
                      <>
                        <Col xs={4} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                              <Box>
                                <div className="camp-sub-name">
                                  Qualification
                                </div>
                                <div className="camp-sub-des mt-2">
                                  {fields.otherEduData.qualification || "-"}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                        <Col xs={4} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                              <Box>
                                <div className="camp-sub-name">
                                  Qualification Description
                                </div>
                                <div className="camp-sub-des mt-2">
                                  {fields.otherEduData.qualificationDesc || ""}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                        <Col xs={4} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                              <Box>
                                <div className="camp-sub-name">
                                  Qualification Documents
                                </div>
                                <div className="camp-sub-des mt-2">
                                  {renderAttachment(fields.otherEduData.docs)}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                      </>
                    )}

                  {!fields.otherEduData && (
                    <Col xs={4} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">Grade</div>
                            <div className="camp-sub-des mt-2">
                              {fields.Cattr18 || "-"}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {fields.EduData &&
                    fields.EduData.map((college, idx) => {
                      return (
                        <Col xs={12} className="body-th p-0" key={idx}>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Box className="college-title">
                                <div className="camp-sub-des">
                                  College - {idx + 1}
                                </div>
                              </Box>
                              <Box mt={2} className="camp-edit meet-table">
                                <Row className="m-0">
                                  <Col xs={3} className="body-th">
                                    <div className="camp-sub-name">
                                      Name of Institution
                                    </div>
                                    <div className="camp-sub-des my-2">
                                      {college.institutionName || "-"}
                                    </div>
                                  </Col>
                                  <Col xs={3} className="body-th">
                                    <div className="camp-sub-name">
                                      Country of Education
                                    </div>
                                    <div className="camp-sub-des my-2">
                                      {college.institutionCountry?.name || "-"}
                                    </div>
                                  </Col>
                                  <Col xs={3} className="body-th">
                                    <div className="camp-sub-name">
                                      Qualification
                                    </div>
                                    <div className="camp-sub-des my-2">
                                      {college.qualification || "-"}
                                    </div>
                                  </Col>
                                  <Col xs={3} className="body-th">
                                    <div className="camp-sub-name">
                                      Degree Name
                                    </div>
                                    <div className="camp-sub-des my-2">
                                      {college.degree || "-"}
                                    </div>
                                  </Col>
                                  <Col xs={3} className="body-th">
                                    <div className="camp-sub-name">Score</div>
                                    <div className="camp-sub-des my-2">
                                      {college.score || "-"}
                                    </div>
                                  </Col>
                                  <Col xs={3} className="body-th">
                                    <div className="camp-sub-name">
                                      Grading System
                                    </div>
                                    <div className="camp-sub-des my-2">
                                      {college.gradingSystem || "-"}
                                    </div>
                                  </Col>
                                  <Col xs={3} className="body-th">
                                    <div className="camp-sub-name">
                                      Graduation Date
                                    </div>
                                    <div className="camp-sub-des my-2">
                                      {college.graduationDate
                                        ? common.formatDate(
                                            college.graduationDate,
                                            "DD-MM-YYYY"
                                          )
                                        : "-"}
                                    </div>
                                  </Col>
                                  <Col xs={3} className="body-th">
                                    <div className="camp-sub-name">
                                      State Date
                                    </div>
                                    <div className="camp-sub-des my-2">
                                      {college.startDate
                                        ? common.formatDate(
                                            college.startDate,
                                            "DD-MM-YYYY"
                                          )
                                        : "-"}
                                    </div>
                                  </Col>
                                  <Col xs={3} className="body-th">
                                    <div className="camp-sub-name">
                                      End Date
                                    </div>
                                    <div className="camp-sub-des my-2">
                                      {college.endDate
                                        ? common.formatDate(
                                            college.endDate,
                                            "DD-MM-YYYY"
                                          )
                                        : "-"}
                                    </div>
                                  </Col>
                                  {college?.docs?.length > 0 && (
                                    <Col xs={3} className="body-th">
                                      <div className="camp-sub-name">
                                        Documents
                                      </div>
                                      <div className="camp-sub-des my-2">
                                        {renderAttachment(college.docs)}
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                      );
                    })}
                </Row>
              </Box>
            </Box>

            {fields.workData.length > 0 && (
              <Box p={2} px={5} className="form-wrap">
                <div className="info-title dynamic-form-title mt-3">
                  Career History
                </div>
                <Box mt={3}>
                  <Divider />
                </Box>

                <Box mt={2} className="camp-edit meet-table">
                  <Row className="m-0">
                    {fields.workData &&
                      fields.workData.map((exp, idx) => {
                        return (
                          <Col xs={12} className="body-th p-0" key={idx}>
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Box className="college-title">
                                  <div className="camp-sub-des">
                                    Experience - {idx + 1}
                                  </div>
                                </Box>
                                <Box mt={2} className="camp-edit meet-table">
                                  <Row className="m-0">
                                    <Col xs={3} className="body-th">
                                      <div className="camp-sub-name">
                                        Company Name
                                      </div>
                                      <div className="camp-sub-des my-2">
                                        {exp.companyName || "-"}
                                      </div>
                                    </Col>
                                    <Col xs={3} className="body-th">
                                      <div className="camp-sub-name">
                                        Designation
                                      </div>
                                      <div className="camp-sub-des my-2">
                                        {exp.designation || "-"}
                                      </div>
                                    </Col>
                                    <Col xs={3} className="body-th">
                                      <div className="camp-sub-name">
                                        Date of Joining
                                      </div>
                                      <div className="camp-sub-des my-2">
                                        {exp.joinDate || "-"}
                                      </div>
                                    </Col>
                                    <Col xs={3} className="body-th">
                                      <div className="camp-sub-name">
                                        End Date
                                      </div>
                                      <div className="camp-sub-des my-2">
                                        {exp.endDate || "-"}
                                      </div>
                                    </Col>
                                    <Col xs={3} className="body-th">
                                      <div className="camp-sub-name">
                                        Line Manager Name
                                      </div>
                                      <div className="camp-sub-des my-2">
                                        {exp.lineManagerName || "-"}
                                      </div>
                                    </Col>
                                    <Col xs={3} className="body-th">
                                      <div className="camp-sub-name">
                                        Line Manager Work Email
                                      </div>
                                      <div className="camp-sub-des my-2">
                                        {exp.lineManagerEmail || "-"}
                                      </div>
                                    </Col>
                                    <Col xs={3} className="body-th">
                                      <div className="camp-sub-name">
                                        Line Manager Designation
                                      </div>
                                      <div className="camp-sub-des my-2">
                                        {exp.lineManagerDesignation || "-"}
                                      </div>
                                    </Col>
                                    <Col xs={3} className="body-th">
                                      <div className="camp-sub-name">
                                        Company Email
                                      </div>
                                      <div className="camp-sub-des my-2">
                                        {exp.companyEmail || "-"}
                                      </div>
                                    </Col>
                                    <Col xs={3} className="body-th">
                                      <div className="camp-sub-name">
                                        Company Address
                                      </div>
                                      <div className="camp-sub-des my-2">
                                        {exp.companyAddress || "-"}
                                      </div>
                                    </Col>
                                    <Col xs={3} className="body-th">
                                      <div className="camp-sub-name">
                                        Documents
                                      </div>
                                      <div className="camp-sub-des my-2">
                                        {renderAttachment(exp.docs)}
                                      </div>
                                    </Col>
                                  </Row>
                                </Box>
                              </Grid>
                            </Grid>
                          </Col>
                        );
                      })}
                  </Row>
                </Box>
              </Box>
            )}

            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title mt-3">
                Application Information
              </div>
              <Box mt={3}>
                <Divider />
              </Box>

              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Lead Name</div>
                          <div className="camp-sub-des mt-2">
                            {fields.LeadName || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Application ID</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr1 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Student Email</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Email ? (
                              <a
                                href={"mailto:" + fields.Email}
                                target="_blank"
                              >
                                {tools.hideEmail(fields.Email)}
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Agent Name</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr28 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Agent Email</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr27 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Application Date</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Dattr2
                              ? common.formatDate(
                                  fields.Dattr2,
                                  "DD-MM-YYYY hh:mm a"
                                )
                              : ""}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="">
                      <FormControl
                        fullWidth
                        className="select-float-label common-select-border text-box custom-react-select"
                      >
                        <FieldSelect
                          api="CRM_EMPLOYEES"
                          get="EmployeeName"
                          set="EmployeeId"
                          value={fields.SalespersonId}
                          isMulti={false}
                          isRender={true}
                          onSelect={(data) =>
                            onSelectHandler(data, "SalespersonId")
                          }
                          placeholder="Responsible Person*"
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Lead Source</div>
                          <div className="camp-sub-des mt-2">
                            {fields.LeadSource.value || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                </Row>
              </Box>
            </Box>

            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title mt-3">
                Document Section
              </div>
              <Box mt={3}>
                <Divider />
              </Box>
              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  {appInfo?.profile["passportDocs"] && (
                    <Col xs={12} className="body-th p-0">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box className="college-title">
                            <div className="camp-sub-des">
                              Passport or Birth Certificate
                            </div>
                          </Box>
                          <Box mt={2} className="camp-edit meet-table">
                            <Row className="m-0">
                              <Col xs={6} className="body-th">
                                <div className="camp-sub-name">Passport</div>
                                <div className="camp-sub-des my-2">
                                  {renderAttachment(
                                    appInfo?.profile["passportDocs"]
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {fields.EduData && (
                    <Col xs={12} className="body-th p-0">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box className="college-title">
                            <div className="camp-sub-des">
                              Education Qualification and History
                            </div>
                          </Box>
                          <Box mt={2} className="camp-edit meet-table">
                            {fields.EduData.length == 0
                              ? noData()
                              : fields.EduData.map((college, idx) => {
                                  return (
                                    <Col xs={6} className="body-th" key={idx}>
                                      <div className="camp-sub-name">
                                        Documents
                                      </div>
                                      <div className="camp-sub-des my-2">
                                        {renderAttachment(college.docs)}
                                      </div>
                                    </Col>
                                  );
                                })}
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}

                  {fields.workData && (
                    <Col xs={12} className="body-th p-0">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box className="college-title">
                            <div className="camp-sub-des">Career History</div>
                          </Box>
                          <Box mt={2} className="camp-edit meet-table">
                            {fields.workData.length == 0
                              ? noData()
                              : fields.workData.map((exp, idx) => {
                                  return (
                                    <Col xs={6} className="body-th" key={idx}>
                                      <div className="camp-sub-name">
                                        Documents
                                      </div>
                                      <div className="camp-sub-des my-2">
                                        {renderAttachment(exp.docs)}
                                      </div>
                                    </Col>
                                  );
                                })}
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {fields.extraActivity && (
                    <Col xs={12} className="body-th p-0">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box className="college-title">
                            <div className="camp-sub-des">
                              Extra Curricular Documents
                            </div>
                          </Box>
                          <Box mt={2} className="camp-edit meet-table">
                            {!fields.extraActivity.extraCurricular ||
                            fields.extraActivity?.extraCurricular.length == 0
                              ? noData()
                              : fields.extraActivity.extraCurricular.map(
                                  (obj, idx) => {
                                    return (
                                      <Row className="m-0" key={idx}>
                                        <Col xs={3} className="body-th">
                                          <div className="camp-sub-name">
                                            Title
                                          </div>
                                          <div className="camp-sub-des my-2">
                                            {obj.title}
                                          </div>
                                        </Col>
                                        <Col xs={3} className="body-th">
                                          <div className="camp-sub-name">
                                            Description
                                          </div>
                                          <div className="camp-sub-des my-2">
                                            {obj.description}
                                          </div>
                                        </Col>
                                        <Col xs={6} className="body-th">
                                          <div className="camp-sub-name">
                                            Documents
                                          </div>
                                          <div className="camp-sub-des my-2">
                                            {renderAttachment(obj.docs)}
                                          </div>
                                        </Col>
                                      </Row>
                                    );
                                  }
                                )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {fields.extraActivity && (
                    <Col xs={12} className="body-th p-0">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box className="college-title">
                            <div className="camp-sub-des">Other Documents</div>
                          </Box>
                          <Box mt={2} className="camp-edit meet-table">
                            {!fields.extraActivity.otherActivity ||
                            fields.extraActivity?.otherActivity.length == 0
                              ? noData()
                              : fields.extraActivity.otherActivity.map(
                                  (obj, idx) => {
                                    return (
                                      <Row className="m-0" key={idx}>
                                        <Col xs={3} className="body-th">
                                          <div className="camp-sub-name">
                                            Title
                                          </div>
                                          <div className="camp-sub-des my-2">
                                            {obj.title}
                                          </div>
                                        </Col>
                                        <Col xs={3} className="body-th">
                                          <div className="camp-sub-name">
                                            Description
                                          </div>
                                          <div className="camp-sub-des my-2">
                                            {obj.description}
                                          </div>
                                        </Col>
                                        <Col xs={6} className="body-th">
                                          <div className="camp-sub-name">
                                            Documents
                                          </div>
                                          <div className="camp-sub-des my-2">
                                            {renderAttachment(obj.docs)}
                                          </div>
                                        </Col>
                                      </Row>
                                    );
                                  }
                                )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                </Row>
              </Box>
            </Box>
            {fields.Uom == "Y" &&
              leadRes.Cattr25 != "Y" &&
              leadRes.Cattr31?.value == "Not withdrawn" && (
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    SFE Information
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="">
                          <FormControl
                            fullWidth
                            className="select-float-label common-padding-zero-select"
                          >
                            <Select
                              className="text-box custom-react-select"
                              labelId="select"
                              id="select_sfe"
                              label="SFE Status"
                              menuPortalTarget={document.body}
                              menuPlacement="auto"
                              onChange={(data) =>
                                onSelectHandler(data, "Cattr14")
                              }
                              options={sfeStatus}
                              isOptionDisabled={(option) => option.disabled}
                              defaultValue={fields["Cattr14"]}
                              components={{
                                ValueContainer: CustomValueContainer,
                              }}
                              placeholder="SFE Status"
                              styles={selectStyles}
                            ></Select>
                          </FormControl>
                        </Box>
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <label className="camp-sub-name">
                          SFE Booking slots
                        </label>
                        {sfeSlots?.slot &&
                          sfeSlots?.slot.length > 0 &&
                          sfeSlots.slot.map((obj, idx) => {
                            let bookedDate = sfeSlots.bookedSlot
                              ? common.formatDate(
                                  sfeSlots.bookedSlot,
                                  "YYYY-MM-DD"
                                )
                              : null;
                            let bookedTime = sfeSlots.bookedSlot
                              ? common.formatDate(
                                  sfeSlots.bookedSlot,
                                  "hh:mm a"
                                )
                              : null;
                            return (
                              <div key={idx} className="cond-date-wrap mt-2">
                                {obj.date || ""}
                                {obj.slot.map((time, idx) => {
                                  return (
                                    <div
                                      key={idx}
                                      className={
                                        obj.date == bookedDate &&
                                        time == bookedTime
                                          ? "cond-slot-wrap booked-slot"
                                          : "cond-slot-wrap"
                                      }
                                    >
                                      {time ? time : ""}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })}
                        <div className="mt-2">
                          <Link
                            to="#"
                            onClick={(e) => openModel(e, null)}
                            className="simple-btn"
                          >
                            Add/Edit
                          </Link>
                        </div>
                      </Col>
                    </Row>

                    {sfeFields.map((mObj, idx) => {
                      let clCount = idx;
                      return (
                        <Row className="m-0" key={clCount}>
                          <Col xs={3} className="body-th common-input-style">
                            <Box className="">
                              <FormControl
                                fullWidth
                                className="select-float-label common-padding-zero-select"
                              >
                                <Select
                                  className="text-box custom-react-select"
                                  labelId="select"
                                  label="SFE Document Request"
                                  menuPortalTarget={document.body}
                                  id={"sfe_" + clCount}
                                  value={eduTechConfig.sfeDocuments.find(
                                    (o) => o.value == mObj.field
                                  )}
                                  isMulti={false}
                                  onChange={(data) =>
                                    onMissingSelect(data, "field", clCount, 4)
                                  }
                                  options={eduTechConfig.sfeDocuments.filter(
                                    (obj) => !sfeSel.includes(obj.value)
                                  )}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="SFE Document Request"
                                  styles={{
                                    container: (provided, state) => ({
                                      ...provided,
                                      marginTop: 0,
                                    }),
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflow: "visible",
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      position: "absolute",
                                      top:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? -15
                                          : "10%",
                                      transition: "top 0.1s, font-size 0.1s",
                                      fontSize:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? 11
                                          : 13,
                                      color: "#9daabb",
                                    }),
                                  }}
                                ></Select>
                              </FormControl>
                            </Box>
                          </Col>
                          <Col xs={3} className="body-th common-input-style">
                            <Box>
                              <div className="form-floating">
                                <textarea
                                  className="form-control textarea"
                                  id={"sfet_" + clCount}
                                  value={mObj.desc}
                                  onChange={(e) =>
                                    onMissingSelect(
                                      e.target.value,
                                      "desc",
                                      clCount,
                                      4
                                    )
                                  }
                                  maxLength={150}
                                ></textarea>
                                <label
                                  htmlFor="floatingTextarea"
                                  className="floating-label"
                                >
                                  SFE Request Description
                                </label>
                              </div>
                            </Box>
                          </Col>
                          <Col xs={3} className="body-th common-input-style">
                            <Box className="">
                              <FormControl
                                fullWidth
                                className="select-float-label common-padding-zero-select"
                              >
                                <Select
                                  className="text-box custom-react-select"
                                  labelId="select"
                                  label="Review Status"
                                  menuPortalTarget={document.body}
                                  id={clCount}
                                  value={reviewStatus.find(
                                    (o) => o.value == mObj.status
                                  )}
                                  isMulti={false}
                                  onChange={(data) =>
                                    onMissingSelect(data, "status", idx, 4)
                                  }
                                  options={reviewStatus}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="Review Status"
                                  styles={{
                                    container: (provided, state) => ({
                                      ...provided,
                                      marginTop: 0,
                                    }),
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflow: "visible",
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      position: "absolute",
                                      top:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? -15
                                          : "10%",
                                      transition: "top 0.1s, font-size 0.1s",
                                      fontSize:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? 11
                                          : 13,
                                      color: "#9daabb",
                                    }),
                                  }}
                                ></Select>
                              </FormControl>
                            </Box>
                          </Col>
                          <Col
                            xs={3}
                            className="body-th common-input-style v-align-center"
                          >
                            <div className="form-group ">
                              {sfeFields.length === 1 && idx === 0 ? (
                                <Link
                                  to="#"
                                  className="add-rule-link add-rule"
                                  onClick={(e) => {
                                    addMRow(e, 4);
                                  }}
                                >
                                  +
                                </Link>
                              ) : (
                                idx === 0 && (
                                  <Link
                                    to="#"
                                    className="add-rule-link remove-rule"
                                    onClick={(e) => {
                                      removeMRow(e, clCount, 4);
                                    }}
                                  >
                                    -
                                  </Link>
                                )
                              )}
                              {idx > 0 && (
                                <React.Fragment>
                                  <Link
                                    to="#"
                                    className="add-rule-link remove-rule"
                                    onClick={(e) => removeMRow(e, clCount, 4)}
                                  >
                                    -
                                  </Link>
                                  {sfeFields.length - 1 == idx && (
                                    <Link
                                      to="#"
                                      className="add-rule-link add-rule"
                                      onClick={(e) => addMRow(e, 4)}
                                    >
                                      +
                                    </Link>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </Box>
                </Box>
              )}
          </>
        )}

        {interviewModel && (
          <Dialog
            open={interviewModel}
            onClose={handleModel}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"sm"}
            fullWidth={true}
            className="target-modal-wrap add-action-popup attachment-popup"
            // disableScrollLock
          >
            <DialogTitle
              id="responsive-dialog-title"
              className="popup-title date-selection py-2"
            >
              <div className="attach-popup-title">
                <div>
                  {condOrSfeSlot
                    ? " Mock Interview Date"
                    : "SFE Interview Slot"}
                </div>

                <IconButton onClick={handleModel}>
                  <img src={common.loadImg("filterCloseIcon.svg")} />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="popup-form-wrap imgDlgContent dialogue-height">
                <div className="list-row text-center py-3">
                  {mInterviewField.slot &&
                    mInterviewField.slot.length > 0 &&
                    mInterviewField.slot.map((obj, idx) => {
                      let bookedDate = mInterviewField.bookedSlot
                        ? common.formatDate(
                            mInterviewField.bookedSlot,
                            "YYYY-MM-DD"
                          )
                        : null;
                      let bookedTime = mInterviewField.bookedSlot
                        ? common.formatDate(
                            mInterviewField.bookedSlot,
                            "hh:mm a"
                          )
                        : null;
                      return (
                        <div
                          key={idx}
                          className="cond-date-wrap mb-2 d-flex align-items-center"
                        >
                          {" "}
                          <div className="d-flex align-items-center">
                            {" "}
                            <div className=" d-flex align-items-center">
                              {" "}
                              <div style={{ width: "40px" }}>
                                {" "}
                                {obj.date != bookedDate && (
                                  <Link
                                    to="#"
                                    className="cond-date-close me-2"
                                    onClick={(e) =>
                                      addDelDate(e, idx, "del", null)
                                    }
                                  >
                                    X
                                  </Link>
                                )}
                              </div>
                              <div style={{ width: "76px" }}>
                                {obj.date || ""}
                              </div>
                            </div>
                            <div className=" d-flex align-items-center">
                              {" "}
                              <div className=" add-slot-date-display">
                                {" "}
                                {obj.slot.map((time, tdx) => {
                                  return (
                                    <div key={tdx} className=" me-2 mt-1">
                                      {" "}
                                      <div
                                        className={
                                          obj.date == bookedDate &&
                                          time == bookedTime
                                            ? "cond-slot-wrap booked-slot"
                                            : "cond-slot-wrap"
                                        }
                                      >
                                        {" "}
                                        {time ? time : ""}
                                        {(obj.date != bookedDate ||
                                          obj.date == bookedDate) &&
                                          time != bookedTime && (
                                            <Link
                                              to="#"
                                              className="cond-slot-close"
                                              onClick={(e) =>
                                                addDelSlot(
                                                  e,
                                                  idx,
                                                  tdx,
                                                  "del",
                                                  null
                                                )
                                              }
                                            >
                                              X
                                            </Link>
                                          )}
                                      </div>
                                    </div>
                                  );
                                })}
                                {obj.add == true ? (
                                  <div className="mt-1 rc-picker-custom rc-time-position">
                                    <RCTimePicker
                                      showSecond={false}
                                      use12Hours
                                      format="hh:mm a"
                                      style={{
                                        width: 96,
                                      }}
                                      defaultValue={slotTime}
                                      onChange={(date) => setSlotTime(date)}
                                      inputReadOnly
                                    />

                                    <Link
                                      to="#"
                                      className="simple-btn px-4 py-2"
                                      onClick={(e) => saveSlot(e, idx)}
                                    >
                                      Add
                                    </Link>
                                  </div>
                                ) : (
                                  <div className="mt-2">
                                    {" "}
                                    <Link
                                      to="#"
                                      className="simple-btn "
                                      onClick={(e) => showTimePicker(e, idx)}
                                    >
                                      Add slot
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {!isNewDateAdd ? (
                    <div
                      className={
                        mInterviewField?.slot?.length != 0 ? "left" : "center"
                      }
                    >
                      {" "}
                      <Link
                        to="#"
                        className="simple-btn py-2 px-4 mt-3 "
                        onClick={(e) => showNewDate(e)}
                      >
                        Add date
                      </Link>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <Box
                        className="text-box meeting-slot-input mb-3"
                        id="label-meeting-slot"
                      >
                        <label
                          className={
                            newDate.date
                              ? "Active text-label ml-4"
                              : "text-label ml-4 camp-time-label"
                          }
                        >
                          Select Date
                        </label>

                        <Box className="ml-1 input-date-picker cust-br">
                          <div className="custom-date-pic">
                            <DatePicker
                              format="YYYY-MM-DD"
                              render={<InputIcon />}
                              placeholder="Select Date"
                              value={newDate.date}
                              onChange={(date) => setNewDateChange(date)}
                            />
                          </div>
                        </Box>
                      </Box>
                      <div className="mb-3">
                        {" "}
                        <Link
                          to="#"
                          className="simple-btn px-4 py-2"
                          onClick={(e) => saveNewDate(e)}
                        >
                          Add
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </DialogContent>
            <DialogActions className="mx-auto ">
              {" "}
              <Button
                className="save-note"
                onClick={(e) => saveMockInterviewSlot(e)}
              >
                Save and Close
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {imgPreview && (
          <Dialog
            open={imgPreview}
            // onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"sm"}
            fullWidth={true}
            className="target-modal-wrap add-action-popup attachment-popup"
            disableScrollLock
          >
            <DialogTitle
              id="responsive-dialog-title"
              className="popup-title py-2"
            >
              <div className="attach-popup-title">
                <div>
                  <a
                    href={common.loadEduImage(imgPreviewSrc)}
                    download
                    target="_blank"
                    className="cust-link"
                  >
                    <img
                      src={common.loadImg("external-link-icn.svg")}
                      className="me-2"
                    />
                    View
                  </a>
                </div>
                <IconButton onClick={handleClose}>
                  <img src={common.loadImg("filterCloseIcon.svg")} />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="popup-form-wrap imgDlgContent">
                <div className="list-row text-center">
                  <img
                    src={
                      imgPreviewSrc ? common.loadEduImage(imgPreviewSrc) : null
                    }
                    alt="image"
                    className="w-100"
                  />
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}
        {/* Convert to Deal */}
        <Dialog
          open={isConvertModal}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth={"sm"}
          fullWidth={true}
          className="target-modal-wrap add-action-popup attachment-popup"
          disableScrollLock
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="popup-title py-2"
          >
            Convert to Deal
          </DialogTitle>
          <DialogContent>
            <Conversion
              leadData={fields}
              closeModal={closeModal}
              successUpdate={conversionUpdate}
            />
          </DialogContent>
        </Dialog>
        {/* EMail communication */}
        <Dialog
          open={openEMail}
          onClose={closeEmail}
          aria-labelledby="responsive-dialog-title"
          maxWidth={"sm"}
          fullWidth={true}
          className="target-modal-wrap add-action-popup attachment-popup"
          disableScrollLock
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="popup-title py-2"
          >
            <div className="d-flex align-items-center justify-content-between">
              <span>Send Email</span>
              <span onClick={closeEmail} className="crm-cp">
                X
              </span>
            </div>
          </DialogTitle>
          <DialogContent>
            <div>
              <div className="popup-form-wrap">
                <div className="row">
                  <div className="col-md-12">
                    <label className="msg-history">
                      Message<span className="mandtory-class">*</span>
                    </label>
                    <textarea
                      className="form-control textarea"
                      rows={3}
                      value={fields.emailContent}
                      name={"emailContent"}
                      onChange={(e) => onChange(e)}
                      maxLength={2000}
                      placeholder="Enter your message here to send"
                    ></textarea>
                  </div>
                  <Box p={2} px={5} className="form-wrap">
                    <div className="info-title dynamic-form-title mt-3">
                      Attachment
                    </div>
                    <Box mt={3}>
                      <Divider />
                    </Box>

                    <Box mt={2} className="camp-edit meet-table">
                      <Row className="m-0">
                        <Col xs={6} className="body-th common-input-style">
                          <div className="fileUpload">
                            <label className="upload">
                              <input
                                type="file"
                                id="fileA"
                                name="offerLetter1"
                                accept=".pdf,.jpg,.png,.doc"
                                value=""
                                multiple={true}
                                onChange={(e) => onFileChange(e)}
                              />
                              Upload
                            </label>
                          </div>
                          <br />
                          {files?.map((i, k) => (
                            <p className="file-list ps-4" key={k}>
                              {i.name}
                              <img
                                src={DeleteIcon}
                                className="del-icon ms-2"
                                alt="Delete"
                                onClick={(e) => delImage(e, k)}
                              />
                            </p>
                          ))}
                        </Col>
                      </Row>
                    </Box>
                  </Box>
                  <div className="col-md-12 pt-2 d-flex justify-content-end">
                    <Button
                      className="save-note send-mail-btn"
                      type="button"
                      onClick={(e) => sendEmail(e)}
                      disabled={isEmailSubmit ? true : false}
                    >
                      {isEmailSubmit ? "Processing..." : "Send"}
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {emailHistory.length > 0 && (
                      <label className="msg-history">Message History</label>
                    )}
                    {emailHistory.map((msg, idx) => {
                      return (
                        <div className="msg-history-list" key={idx}>
                          <p className="msg-content">{msg.content}</p>
                          <p className="d-flex align-item-center justify-content-between mb-0">
                            <span className="msg-send-date">
                              {common.formatDate(
                                msg.createdAt,
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </span>
                            <span className="msg-deliver-status">
                              {msg.status == "sended"
                                ? "Delivered"
                                : "Not Delivered"}
                            </span>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </Paper>
      {status == "success" && (
        <div className="fixed-foot-wrap pt-3">
          <Box className="mr-5">
            <Button
              onClick={(e) => {
                setStatus("process");
                getEditData();
              }}
            >
              Refresh
              <img
                className="pl-2"
                src={common.loadImg("refresh-icon.svg")}
                alt="Refresh"
              />
            </Button>
          </Box>
          <Box>
            <Button
              className="save-note fixed-width-btn"
              type="submit"
              onClick={() => onSubmit()}
              disabled={isSubmit ? true : false}
            >
              {isSubmit
                ? "Processing..."
                : props.data.value1
                ? "Update Changes"
                : "Save Changes"}
            </Button>
          </Box>
          <Box className="revert-close">
            <Button
              className="save-note fixed-width-btn pe-0"
              onClick={(e) => closeForm()}
            >
              Revert and Close
              <img src={common.loadImg("closeKanban.svg")} alt="close" />
            </Button>
          </Box>
        </div>
      )}
    </Box>
  );
}

export default ETLeadForm;

import React, { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid } from "@mui/material";
import moment from "moment";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import StatusBar from "elements/StatusBar";
import CrmHeader from "elements/crmHeader";
import serviceList from "assets/files/serviceList.json";
import { common, tools, envConfig, api } from "helpers";
import { useOutletContext } from "react-router-dom";
// dialog
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as Services from "helpers/service";

const initialState = {
  page: 1,
  limit: 10,
};
export default function ListView(props) {
  const propsData = props.data ? props.data.split(",") : [];
  const [limit, setLimit] = React.useState(10);
  const [module, setModule] = React.useState(props.module || "campaign");
  const [clsmodule, setClsmodule] = React.useState(props.className || "");

  const isEdutechAcc =
    common.isEduTech() && ["lead", "deal"].includes(props.module)
      ? true
      : false;

  let service = serviceList[props.module];
  const [result, setResult] = useState({
    columns: [],
    data: [],
    totalResults: 0,
    totalRecords: 0,
  });
  const [status, setStatus] = useState("process");
  const [isInit, setInit] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [sort, setSort] = useState({
    field: props.module != "deal" ? "CreatedOn" : "CreationDate",
    order: "desc",
    default: "asc",
    label: "",
  });
  const [paging, setPaging] = useState(initialState);

  const userInfo = common.userInfo();
  const authInfo = common.authInfo();
  let userData = tools.deCrypt(localStorage.getItem("userData"));
  const [permission] = useOutletContext();
  const [hidedColumns, setHidedColumns] = useState([]);
  const [formFields, setFormFields] = useState({});
  const [selected, setSelected] = React.useState([]);
  const [filterQuery, setFilterQuery] = React.useState("");
  const moduleLabel = {
    campaign: "Campaign List",
    enquiry: "Enquiry List",
    lead: "Lead List",
    deal: "Deal List",
    enquiryMember: "Members",
    contacts: "Contacts",
    contactsLink: "Linked Contacts",
    meeting: "Meetings",
    callLogs: "Call Logs",
  };
  const edutechAttr = [
    "Clattr1",
    "Clattr2",
    "Clattr3",
    "Clattr4",
    "Clattr5",
    "Clattr6",
    "Clattr7",
    "Description",
  ];
  const [selectedData, setSelectedData] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [desc, setDesc] = useState(null);

  React.useEffect(() => {
    if (props.module || props.id) {
      // common.clearFilter(props.module);
      // common.clearPagination(props.module);
      setStatus("process");
      if (props.status == "success") {
        setResult({
          columns: [],
          data: [],
          totalResults: 0,
          totalRecords: 0,
        });
        setFormFields({});
        let pageFilter =
          props.query && ["lead", "campaign"].includes(props.module)
            ? null
            : common.getPagination(props.module);
        if (pageFilter !== null) {
          setPaging(pageFilter.paging);
          setLimit(pageFilter.paging.limit ? pageFilter.paging.limit : 10);
          setSort(pageFilter.sort);
        } else {
          setPaging(initialState);
          setLimit(10);
          setSort({
            field: props.module != "deal" ? "CreatedOn" : "CreationDate",
            order: "desc",
            default: "asc",
            label: "",
          });
        }
        setModule(props.module);
        setFilterQuery("");
        getColumnsData();
      }
    }
  }, [props.module, props.id, props.status]);

  useEffect(() => {
    getColumnsData();
  }, [props.query && props.query.ListId && props.query.ListId]);

  useEffect(() => {
    if (isReload) {
      loadData("", "new");
    }
  }, [isReload]);

  useEffect(() => {
    if (Object.keys(formFields).length > 0) {
      loadData("", "new");
    }
  }, [formFields]);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const changeLimit = (event) => {
    let pageFilter =
      props.query && ["lead", "campaign"].includes(props.module)
        ? null
        : common.getPagination(props.module);
    if (pageFilter) {
      pageFilter.paging = {
        page: 1,
        limit: event.target.value,
      };
    } else {
      let fil = {
        paging: {
          page: 1,
          limit: event.target.value,
        },
        sort: {},
        view: null,
      };
      pageFilter = Object.assign({}, fil);
    }
    common.setStoragePagination(props.module, pageFilter);

    handleLimitChange(event.target.value);
    setLimit(event.target.value);
  };

  const selectAllCheck = () => {
    let i = 0;
    result &&
      result.data &&
      result.data.length > 0 &&
      result.data.map((item) => {
        if (selected.includes(item[service.id])) {
          ++i;
        }
      });
    return i > 0 && result.data.length === i;
  };

  const selectAll = (e) => {
    const { checked } = e.target;
    setSelected((pData) => {
      let nData = reParse(pData);
      if (checked) {
        result.data.map((i) => {
          if (!nData.includes(i[service.id])) {
            nData.push(i[service.id]);
          }
        });
      } else {
        result.data.map((i) => {
          nData.splice(nData.indexOf(i[service.id]), 1);
        });
      }
      onSelected(nData);
      return nData;
    });

    // selected data
    setSelectedData((pData) => {
      let nData = reParse(pData);
      if (checked) {
        result.data.map((i) => {
          let res = {};
          propsData.map((j) => {
            res[j] = i[j];
          });
          nData[i[service.id]] = res;
        });
      } else {
        result.data.map((i) => {
          delete nData[i[service.id]];
        });
      }
      return nData;
    });
  };

  const selectChange = (e, item) => {
    const { checked, value } = e.target;
    setSelected((pData) => {
      let nData = reParse(pData);
      if (checked) {
        nData.push(+value);
      } else {
        nData.splice(nData.indexOf(+value), 1);
      }
      onSelected(nData);
      return nData;
    });

    // selected data
    setSelectedData((pData) => {
      let nData = reParse(pData);
      if (checked) {
        let res = {};
        propsData.map((i) => {
          res[i] = item[i];
        });
        nData[+value] = res;
      } else {
        delete nData[+value];
      }
      return nData;
    });
  };

  const reParse = (data) => {
    return JSON.parse(JSON.stringify(data));
  };

  /***   for subheader fixed */
  useEffect(() => {
    var isMobileVersion = document.getElementsByClassName("rotate-arrow");
    if (isMobileVersion.length > 0) {
      const fixedHeader = document.getElementById("fixed-header");
      if (fixedHeader) {
        fixedHeader.classList.add("menu-shrink");
        fixedHeader.classList.remove("menu-expand");
      }
      const crmListView = document.getElementById("list-view-wrap");
      if (crmListView) {
        crmListView.classList.add("sm-listview-wrap");
        crmListView.classList.remove("lg-listview-wrap");
      }
    } else {
      const fixedHeader = document.getElementById("fixed-header");
      if (fixedHeader) {
        fixedHeader.classList.add("menu-expand");
        fixedHeader.classList.remove("menu-shrink");
      }
      const crmListView = document.getElementById("list-view-wrap");
      if (crmListView) {
        crmListView.classList.remove("sm-listview-wrap");
        crmListView.classList.add("lg-listview-wrap");
      }
    }
  });
  /***   for subheader fixed */
  const getColumnsData = () => {
    setStatus("process");
    let filter = `?q=EserviceCode=${service.code}`;
    let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          let formFields = JSON.parse(response.data.items[0].FormFields);
          let filtered = formFields.form.fields;
          // if (props.module == module) {
          setFormFields(formFields);
          setResult((prev) => ({
            ...prev,
            columns: columnValidate(filtered),
          }));
          getHidedColumns();
          // }
          // setIsReload(true)
          // loadData("", "new");
        } else {
          setStatus("error");
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const clrSelected = () => {
    setSelected([]);
    setSelectedData({});
  };

  const loadData = (fQuery, type) => {
    if (type == "apply") {
      setPaging(initialState);
      setLimit(10);
    }
    setSelected([]);
    setSelectedData({});
    let userData = tools.deCrypt(localStorage.getItem("userData"));
    let loggedEmpId = userData.EmpId;

    if (isInit) {
      // setStatus("process");
      setIsReload(false);
    }

    if (type == "apply" || type == "clear" || type == "new") {
      setStatus("process");
    }

    let getService = service.api;

    let query = `?q=${propsQuery()}`;
    let localFilter = common.getFilter(props.module);

    if (fQuery) {
      setFilterQuery(fQuery);
      query += fQuery;
    } else if (localFilter) {
      query += localFilter.list;
    } else if (
      type &&
      type != "clear" &&
      filterQuery != "" &&
      filterQuery != undefined &&
      filterQuery != null
    ) {
      query += `${filterQuery} `;
    } else {
      setFilterQuery("");
    }
    if (["CRM_LEADS", "CRM_DEALS"].includes(service.api)) {
      //Permission 1,3,4,5 Lead
      if (
        permission?.crm?.[service.api === "CRM_LEADS" ? "lead" : "deal"]?.r ===
        "1"
      ) {
        query += `OrgId=${userInfo.pOrgId} AND TenantId=${userInfo.pTenantId}`;
      } else if (
        permission?.crm?.[service.api === "CRM_LEADS" ? "lead" : "deal"]?.r ===
        "3"
      ) {
        if (["lead"].includes(props.module)) {
          query += `SalespersonId=${authInfo?.EmpId} AND OrgId=${userInfo.pOrgId} AND TenantId=${userInfo.pTenantId}`;
        } else {
          query += `SalesPersonId=${authInfo?.EmpId} AND OrgId=${userInfo.pOrgId} AND TenantId=${userInfo.pTenantId}`;
        }
      } else if (
        permission?.crm?.[service.api === "CRM_LEADS" ? "lead" : "deal"]?.r ===
        "4"
      ) {
        query += `TerritoryId IN (${authInfo?.territory || 0}) AND OrgId=${
          userInfo.pOrgId
        } AND TenantId=${userInfo.pTenantId}`;
      } else if (
        permission?.crm?.[service.api === "CRM_LEADS" ? "lead" : "deal"]?.r ===
        "5"
      ) {
        let empIds = authInfo.employeeList.sub || [];
        if (empIds.length > 0) {
          query += `${
            service.api === "CRM_LEADS" ? "SalespersonId" : "SalesPersonId"
          } IN (${empIds.join(",")})`;
        } else {
          query += `OrgId=${userInfo.pOrgId} AND TenantId=${userInfo.pTenantId}`;
        }
      } else {
        query += `OrgId=${userInfo.pOrgId} AND TenantId=${userInfo.pTenantId}`;
      }
    } else {
      query += `OrgId=${userInfo.pOrgId} AND TenantId=${userInfo.pTenantId}`;
    }

    let pg = `&totalResults=true&limit=${paging.limit}&offset=${getPaging(
      "offset"
    )}`;

    let orderBy = `&orderBy=${
      props.module != "deal" ? "CreatedOn" : "CreationDate"
    }:desc`;
    if (sort.field) {
      orderBy = `&orderBy=${sort.field}:${sort.order}`;
    }

    //CRM_LEADS CRM_DEALS

    let url = `${envConfig.BASE_API}${Services[getService]}${query}${pg}${orderBy}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response) {
          //Prev&Next Handling
          if (
            ["campaign", "lead", "enquiry", "deal", "contacts"].includes(
              props.module
            ) &&
            props?.type == "menu"
          ) {
            const ids = response.data.items.map(function (data) {
              return data[service.id];
            });
            localStorage.setItem(
              "dpHdr",
              JSON.stringify({
                url: url,
                ids: ids,
                total: response.data.totalResults,
                page: paging.page,
                limit: paging.limit,
              })
            );
          }
          if (props?.module == "enquiryMember") {
            const ids = response.data.items.map(function (data) {
              return data[service.id];
            });
            localStorage.setItem(
              "pgRecords",
              JSON.stringify({
                url: url,
                ids: ids,
                total: response.data.totalResults,
                page: paging.page,
                limit: paging.limit,
              })
            );
          }
          setResult((prev) => ({
            ...prev,
            data: response.data.items,
            totalResults: response.data.totalResults,
            totalRecords: isInit
              ? prev.totalRecords
              : response.data.totalResults,
          }));
          setStatus("success");
          if (response.data.items && response.data.items.length == 0) {
            setStatus("empty");
          }
        } else {
          setStatus("error");
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {
        setInit(true);
      }
    );
  };

  // props
  const propsQuery = () => {
    let res = "";
    if (props.query) {
      Object.entries(props.query).map(([key, item]) => {
        res += `${key}='${item}' AND `;
      });
    }
    return res;
  };

  const getHidedColumns = () => {
    let filterUserData =
      userData["UserCustomFilter"] &&
      userData["UserCustomFilter"][service.filter]
        ? userData["UserCustomFilter"][service.filter].split(",")
        : [];
    setHidedColumns(filterUserData);
    // loadData("", "new");
  };

  const columnValidate = (fields) => {
    let res = fields.filter((i) => i.status === "use" && i.visibility);
    return res;
  };

  // support
  const getPaging = (type) => {
    if (type == "offset") {
      let page =
        props.query && ["lead", "campaign"].includes(props.module)
          ? null
          : common.getPagination(props.module);
      return page != null
        ? (page.paging.page - 1) * page.paging.limit
        : (paging.page - 1) * paging.limit;
    } else if (type == "serial") {
      return (paging.page - 1) * paging.limit;
    } else if (type == "new") {
      return 0;
    }
  };

  const handlePageChange = (pageNumber) => {
    // let fil = {
    //   paging: {
    //     page: pageNumber,
    //     limit: paging.limit
    //   },
    //   sort: {},
    //   view: "list"
    // };
    // common.setStoragePagination(props.module, fil);
    let pageFilter =
      props.query && ["lead", "campaign"].includes(props.module)
        ? null
        : common.getPagination(props.module);
    if (pageFilter) {
      pageFilter.paging = {
        page: pageNumber,
        limit: paging.limit,
      };
    } else {
      let fil = {
        paging: {
          page: pageNumber,
          limit: paging.limit,
        },
        sort: {},
        view: null,
      };
      pageFilter = Object.assign({}, fil);
    }
    common.setStoragePagination(props.module, pageFilter);

    if (paging.page != pageNumber) {
      setPaging({ ...paging, page: pageNumber });
      setIsReload(true);
      setStatus("process");
    }
  };

  const handleLimitChange = (limit) => {
    if (paging.limit != limit) {
      setPaging({ page: 1, limit });
      setIsReload(true);
    }
  };

  const handleSortChange = (field) => {
    setSort({
      field: field.key,
      order:
        sort.field === field.key
          ? sort.order === "asc"
            ? "desc"
            : "asc"
          : sort.default,
      default: sort.default,
      label: field.label,
    });
    let sorts = {
      field: field.key,
      order:
        sort.field === field.key
          ? sort.order === "asc"
            ? "desc"
            : "asc"
          : sort.default
          ? sort.default
          : "asc",
      default: sort.default ? sort.default : "asc",
      label: field.label,
    };
    let pageFilter =
      props.query && ["lead", "campaign"].includes(props.module)
        ? null
        : common.getPagination(props.module);
    if (pageFilter) {
      pageFilter.sort = sorts;
    } else {
      let fil = {
        paging: {
          page: 1,
          limit: 10,
        },
        sort: sorts,
        view: null,
      };
      pageFilter = Object.assign({}, fil);
    }
    common.setStoragePagination(props.module, pageFilter);
    setIsReload(true);
    // setStatus("process");
  };

  const onHandleDetails = (e, val, item) => {
    e.preventDefault();
    setFilterQuery("");
    if (
      module == "callLogs" ||
      module == "meeting" ||
      module == "enquiryMember"
    ) {
      props.changeForm(true, "edit", item);
    } else {
      props.onHandleDetails(e, val, item);
    }
  };

  const updateResultColumns = (res, col) => {
    setResult(res);
    setHidedColumns(col);
  };

  const onSelected = (data) => {
    setSelected(data);
  };

  const showDesc = (key, desc) => {
    setIsModal(true);
    setDesc({ key: key, desc: desc });
  };

  const closeDesc = () => {
    setIsModal(false);
    setDesc(null);
  };

  const checkDesc = (key, desc) => {
    if (key == "Description") return desc != null;
    else if (desc) {
      let jData = JSON.parse(desc);
      return jData.length > 0;
    } else return false;
  };

  const renderWorkInfo = (desc) => {
    let data = JSON.parse(desc);
    return (
      <div>
        {data.map((obj, i) => {
          return (
            <div key={i}>
              <p className="college-title">Work History {i + 1}</p>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="camp-sub-name">Company Name</td>
                    <td className="camp-sub-des">{obj.companyName}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Designation</td>
                    <td className="camp-sub-des">{obj.designation || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Join Date</td>
                    <td className="camp-sub-des">{obj.joinDate || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">End Date</td>
                    <td className="camp-sub-des">{obj.endDate || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Line Manager Name</td>
                    <td className="camp-sub-des">
                      {obj.lineManagerName || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Line Manager Work Email</td>
                    <td className="camp-sub-des">
                      {obj.lineManagerEmail || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Line Manager Designation</td>
                    <td className="camp-sub-des">
                      {obj.lineManagerDesignation || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Company Email</td>
                    <td className="camp-sub-des">{obj.companyEmail || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Company Address</td>
                    <td className="camp-sub-des">
                      {obj.companyAddress || "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    );
  };

  const renderColInfo = (desc) => {
    let data = JSON.parse(desc);
    return (
      <div>
        {data.map((college, i) => {
          return (
            <div key={i}>
              <p className="college-title">College {i + 1}</p>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="camp-sub-name">Name of Institution</td>
                    <td className="camp-sub-des">
                      {college.institutionName || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Country of Education</td>
                    <td className="camp-sub-des">
                      {college.institutionCountry || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Qualification</td>
                    <td className="camp-sub-des">
                      {college.qualification || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Degree</td>
                    <td className="camp-sub-des">{college.degree || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Score</td>
                    <td className="camp-sub-des">{college.score || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Grade System</td>
                    <td className="camp-sub-des">
                      {college.gradingSystem || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Graduation Date</td>
                    <td className="camp-sub-des">
                      {college.graduationDate
                        ? common.formatDate(
                            college.graduationDate,
                            "DD-MM-YYYY"
                          )
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Start Date</td>
                    <td className="camp-sub-des">
                      {college.startDate
                        ? common.formatDate(college.startDate, "DD-MM-YYYY")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">End Date</td>
                    <td className="camp-sub-des">
                      {college.endDate
                        ? common.formatDate(college.endDate, "DD-MM-YYYY")
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    );
  };

  const renderTestInfo = (desc) => {
    let data = JSON.parse(desc);
    return (
      <div>
        {data.map((obj, i) => {
          return (
            <div key={i}>
              <p className="college-title">Test score {i + 1}</p>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="camp-sub-name">Exam Type</td>
                    <td className="camp-sub-des">{obj.examType || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Listening</td>
                    <td className="camp-sub-des">{obj.listening || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Reading</td>
                    <td className="camp-sub-des">{obj.reading || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Writing</td>
                    <td className="camp-sub-des">{obj.writing || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Speaking</td>
                    <td className="camp-sub-des">{obj.speaking || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    );
  };

  const renderSugCourseInfo = (desc) => {
    let data = JSON.parse(desc);
    return (
      <div>
        {data.map((obj, i) => {
          return (
            <div key={i}>
              <p>Suggested Course - {i + 1}</p>
              <p>Course : {obj?.course?.label}</p>
              <p>University: {obj?.university?.label}</p>
            </div>
          );
        })}
      </div>
    );
  };

  const renderAppCourseInfo = (desc) => {
    let data = JSON.parse(desc);
    return (
      <div>
        {data.map((obj, i) => {
          return (
            <div key={i}>
              <p>Appied Course - {i + 1}</p>
              <p>Course : {obj?.course}</p>
              <p>University: {obj?.university}</p>
              <p>
                Intake: {obj?.month} - {obj?.year}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  const enableCheckBox = () => {
    let enable = true;
    if (["lead", "campaign"].includes(props.module) && props.query) {
      enable = false;
    }
    return enable;
  };

  //Field level access handling
  const checkFieldAccess = (field) => {
    if (authInfo.roleId == 0) return true; //ADMIN Access
    if (field?.pspecific == true && field?.permission?.length > 0) {
      let fAccess = field.permission.filter(
        (e) => e.groupId == authInfo.roleId
      );
      return fAccess.length > 0
        ? fAccess[0].access !== "h"
        : field.ptype !== "h";
    } else {
      return field?.ptype !== "h" || true;
    }
  };

  const refreshCall = () => {
    setIsReload(true);
  };

  return (
    <>
      <CrmHeader
        changeView={(val) => props.changeView(val)}
        changeForm={(val) => props.changeForm(val, "add")}
        pageName={moduleLabel[module]}
        buttonName={moduleLabel[module]}
        selectedColumns={selected}
        selectedData={selectedData}
        result={result}
        clrSelected={clrSelected}
        loadData={(val, type) => loadData(val, type)}
        formFields={formFields}
        updateResultColumns={(res, col) => updateResultColumns(res, col)}
        hidedColumns={hidedColumns}
        sort={sort}
        status={status}
        module={module}
        listId={props.query && props.query.ListId ? props.query.ListId : ""}
        query={props.query}
        type={props.type ? props.type : "menu"}
        add={props.add != undefined ? props.add : true}
        action={props.action != undefined ? props.action : true}
        isExpand={props.isExpand !== undefined ? props.isExpand : "tab"}
        tabChange={() => props.tabChange()}
        pModule={props?.pModule}
        view={"list"}
        accAdd={props?.accAdd !== undefined ? props?.accAdd : true}
        accExport={props?.accExport !== undefined ? props?.accExport : true}
        accDelete={props?.accDelete !== undefined ? props?.accDelete : true}
        accUpdate={props?.accUpdate !== undefined ? props?.accUpdate : true}
        accImport={props?.accImport !== undefined ? props?.accImport : true}
        refreshData={() => refreshCall()}
      />
      <StatusBar status={status} />
      {status == "success" && (
        <TableContainer className="relative-position table-height">
          <div className={props.isExpand === "detail" ? "" : clsmodule}>
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
              className="osmo-crm-table "
            >
              <TableHead>
                <TableRow>
                  {result &&
                    result.columns &&
                    result.columns.length > 0 &&
                    result.columns
                      .filter((i) => !hidedColumns.includes(i.key))
                      .map((item, i) => {
                        if (
                          //edutech fields handling
                          !isEdutechAcc ||
                          !(
                            isEdutechAcc &&
                            (["Cattr15", "Cattr40"].includes(item.key) ||
                              ["Eattr3", "Eattr4"].includes(item.key))
                          )
                        ) {
                          if (props.isExpand != "tab" && i < 2) {
                            return (
                              <Fragment key={"fr_" + i}>
                                {i == 0 && props.isExpand == "tab" && (
                                  <TableCell
                                    key={"ftc_" + i}
                                    align={"left"}
                                    className="crm-table-header table-cell-checkbox"
                                  >
                                    <Grid item>
                                      <input
                                        type="checkbox"
                                        className="checkbox-round-new"
                                        checked={selectAllCheck()}
                                        onChange={(e) => selectAll(e)}
                                        inputprops={{ "aria-label": "Select" }}
                                      />
                                    </Grid>
                                  </TableCell>
                                )}
                                {checkFieldAccess(item) && (
                                  <TableCell
                                    key={"stc_" + i}
                                    align={"left"}
                                    className="crm-table-header"
                                    onClick={() => handleSortChange(item)}
                                  >
                                    <Grid container spacing={2}>
                                      <Grid item>
                                        <Box className="table-head">
                                          <div className="sorting-cell">
                                            <div>{item.label}</div>
                                            <div
                                              className={`sorting-wrap ${
                                                item.label == sort.label
                                                  ? "show-sort-icon"
                                                  : ""
                                              }`}
                                            >
                                              {" "}
                                              {/* Add one more class 'show-sort-icon' */}
                                              {sort.order != "asc" ? (
                                                <img
                                                  src={common.loadImg(
                                                    "down-arrow-icon.svg"
                                                  )}
                                                  className="ms-1"
                                                />
                                              ) : (
                                                <img
                                                  src={common.loadImg(
                                                    "arrow-up.svg"
                                                  )}
                                                  className="ms-1"
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                )}
                              </Fragment>
                            );
                          } else if (props.isExpand == "tab") {
                            return (
                              <Fragment key={"fr_" + i}>
                                {i == 0 &&
                                  props.isExpand == "tab" &&
                                  enableCheckBox() && (
                                    <TableCell
                                      key={"ftc_" + i}
                                      align={"left"}
                                      className="crm-table-header table-cell-checkbox"
                                    >
                                      <Grid item>
                                        <input
                                          type="checkbox"
                                          className="checkbox-round-new"
                                          checked={selectAllCheck()}
                                          onChange={(e) => selectAll(e)}
                                          inputprops={{
                                            "aria-label": "Select",
                                          }}
                                        />
                                      </Grid>
                                    </TableCell>
                                  )}
                                {checkFieldAccess(item) && (
                                  <TableCell
                                    key={"stc_" + i}
                                    align={"left"}
                                    className="crm-table-header"
                                    onClick={() => handleSortChange(item)}
                                  >
                                    <Grid container spacing={2}>
                                      <Grid item>
                                        <Box className="table-head">
                                          <div className="sorting-cell">
                                            <div>{item.label}</div>
                                            <div
                                              className={`sorting-wrap ${
                                                item.label == sort.label
                                                  ? "show-sort-icon"
                                                  : ""
                                              }`}
                                            >
                                              {" "}
                                              {/* Add one more class 'show-sort-icon' */}
                                              {sort.order != "asc" ? (
                                                <img
                                                  src={common.loadImg(
                                                    "down-arrow-icon.svg"
                                                  )}
                                                  className="ms-1"
                                                />
                                              ) : (
                                                <img
                                                  src={common.loadImg(
                                                    "arrow-up.svg"
                                                  )}
                                                  className="ms-1"
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                )}
                              </Fragment>
                            );
                          }
                        }
                      })}
                </TableRow>
              </TableHead>

              <TableBody>
                {result &&
                  result.data &&
                  result.data.length > 0 &&
                  result.data.map((item, i) => {
                    const isItemSelected = isSelected(item.CampaignName);
                    const labelId = `enhanced-table-checkbox-${i}`;
                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, item.CampaignName)}
                        role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={"tr_" + i}
                        // selected={isItemSelected}
                        className=""
                      >
                        {props.isExpand == "tab" && enableCheckBox() && (
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            className="crm-table-name table-cell-checkbox"
                          >
                            <Grid item>
                              <input
                                type="checkbox"
                                className="checkbox-round-new ml-4"
                                value={item[service.id] ? item[service.id] : ""}
                                checked={
                                  selected.includes(item[service.id])
                                    ? true
                                    : false
                                }
                                inputprops={{ "aria-labelledby": "" }}
                                onChange={(e) => selectChange(e, item)}
                              />
                            </Grid>
                          </TableCell>
                        )}
                        {result &&
                          result.columns &&
                          result.columns.length > 0 &&
                          result.columns
                            .filter((i) => !hidedColumns.includes(i.key))
                            .map((cItem, cKey) => {
                              if (
                                checkFieldAccess(cItem) &&
                                (!isEdutechAcc ||
                                  !(
                                    isEdutechAcc &&
                                    (["Cattr15", "Cattr40"].includes(
                                      cItem.key
                                    ) ||
                                      ["Eattr3", "Eattr4"].includes(cItem.key))
                                  ))
                              ) {
                                if (props.isExpand != "tab" && cKey < 2) {
                                  return (
                                    <TableCell
                                      align="left"
                                      className="crm-table-id"
                                      key={"tc_" + cKey}
                                    >
                                      {edutechAttr.includes(cItem.key) ? (
                                        checkDesc(
                                          cItem.key,
                                          item[cItem.key]
                                        ) ? (
                                          <img
                                            src={common.loadImg("lightDoc.svg")}
                                            alt="documents"
                                            className="crm-cp"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              showDesc(
                                                cItem.key,
                                                item[cItem.key]
                                              );
                                            }}
                                          />
                                        ) : (
                                          "-"
                                        )
                                      ) : (
                                        <Link
                                          to="#"
                                          onClick={(e) =>
                                            onHandleDetails(e, true, item)
                                          }
                                        >
                                          {cItem.attr.lookup ===
                                          "transactionStatus" ? (
                                            item[cItem.key] ? (
                                              <Box
                                                component="span"
                                                px={2}
                                                className={`crm-table-sts-common ${
                                                  item[cItem.key] == "NEW"
                                                    ? "crm-table-sts-new"
                                                    : item[cItem.key] ==
                                                      "SUBMITTED"
                                                    ? "crm-table-sts-end"
                                                    : "crm-table-sts-pending"
                                                }`}
                                              >
                                                {item[cItem.key]}
                                              </Box>
                                            ) : (
                                              "-"
                                            )
                                          ) : cItem.type === "date" ||
                                            cItem.type === "dateTime" ? (
                                            item[cItem.key] ? (
                                              moment(item[cItem.key]).format(
                                                "YYYY-MM-DD"
                                              )
                                            ) : (
                                              "-"
                                            )
                                          ) : cItem.type === "checkbox" ? (
                                            <div
                                              component="span"
                                              className={
                                                item[cItem.key] === "Y"
                                                  ? "crm-table-sts-new"
                                                  : "crm-table-sts-no"
                                              }
                                            >
                                              {item[cItem.key] === "Y"
                                                ? "Yes"
                                                : "No"}
                                            </div>
                                          ) : cItem.type === "lookup" ? (
                                            item[`${cItem.key}Desc`] ??
                                            item[cItem.key]
                                          ) : cItem.type === "multiLookup" ? (
                                            item[cItem.display.name] ??
                                            item[cItem.display.name]
                                          ) : (
                                            item[cItem.key] ?? "-"
                                          )}
                                        </Link>
                                      )}
                                    </TableCell>
                                  );
                                } else if (props.isExpand == "tab") {
                                  return (
                                    <TableCell
                                      align="left"
                                      className="crm-table-id"
                                      key={"tc_" + cKey}
                                    >
                                      {edutechAttr.includes(cItem.key) ? (
                                        checkDesc(
                                          cItem.key,
                                          item[cItem.key]
                                        ) ? (
                                          <img
                                            src={common.loadImg("lightDoc.svg")}
                                            alt="documents"
                                            className="crm-cp"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              showDesc(
                                                cItem.key,
                                                item[cItem.key]
                                              );
                                            }}
                                          />
                                        ) : (
                                          "-"
                                        )
                                      ) : (
                                        <Link
                                          to="#"
                                          onClick={(e) =>
                                            onHandleDetails(e, true, item)
                                          }
                                        >
                                          {cItem.attr.lookup ===
                                          "transactionStatus" ? (
                                            item[cItem.key] ? (
                                              <Box
                                                component="span"
                                                px={2}
                                                className={`crm-table-sts-common ${
                                                  item[cItem.key] == "NEW"
                                                    ? "crm-table-sts-new"
                                                    : item[cItem.key] ==
                                                      "SUBMITTED"
                                                    ? "crm-table-sts-end"
                                                    : "crm-table-sts-pending"
                                                }`}
                                              >
                                                {item[cItem.key]}
                                              </Box>
                                            ) : (
                                              "-"
                                            )
                                          ) : cItem.type === "date" ||
                                            cItem.type === "dateTime" ? (
                                            item[cItem.key] ? (
                                              moment(item[cItem.key]).format(
                                                "YYYY-MM-DD"
                                              )
                                            ) : (
                                              "-"
                                            )
                                          ) : cItem.type === "checkbox" ? (
                                            <div
                                              component="span"
                                              className={
                                                item[cItem.key] === "Y"
                                                  ? "crm-table-sts-new"
                                                  : "crm-table-sts-no"
                                              }
                                            >
                                              {item[cItem.key] === "Y"
                                                ? "Yes"
                                                : "No"}
                                            </div>
                                          ) : cItem.type === "lookup" ? (
                                            item[`${cItem.key}Desc`] ??
                                            item[cItem.key]
                                          ) : cItem.type === "multiLookup" ? (
                                            item[cItem.display.name] ??
                                            item[cItem.display.name]
                                          ) : cItem.type === "email" ? (
                                            item[cItem.key] ? (
                                              <a
                                                href={
                                                  "mailto:" + item[cItem.key]
                                                }
                                                target="_blank"
                                              >
                                                {tools.hideEmail(
                                                  item[cItem.key]
                                                )}
                                              </a>
                                            ) : (
                                              "-"
                                            )
                                          ) : cItem.type === "phone" ? (
                                            item[cItem.key] ? (
                                              <a
                                                href={"tel:+" + item[cItem.key]}
                                              >
                                                {tools.hideMobile(
                                                  item[cItem.key]
                                                )}
                                              </a>
                                            ) : (
                                              "-"
                                            )
                                          ) : isEdutechAcc &&
                                            ((props.module == "lead" &&
                                              ["Cattr27"].includes(
                                                cItem.key
                                              )) ||
                                              (props.module == "deal" &&
                                                ["Cattr1"].includes(
                                                  cItem.key
                                                ))) &&
                                            item[cItem.key] ? (
                                            <a
                                              href={"mailto:" + item[cItem.key]}
                                              target="_blank"
                                            >
                                              {tools.hideEmail(item[cItem.key])}
                                            </a>
                                          ) : (
                                            item[cItem.key] ?? "-"
                                          )}
                                        </Link>
                                      )}
                                    </TableCell>
                                  );
                                }
                              }
                            })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      )}
      {status == "success" && result.totalResults > 0 && (
        <div className="table-footer-wrap">
          <div className="row-count">
            <p>
              Showing {(paging.page - 1) * paging.limit + 1} to{" "}
              {(paging.page - 1) * paging.limit +
                (result.data ? result.data.length : 0)}{" "}
              of {result.totalResults} entries
            </p>
          </div>
          {props.isExpand == "tab" && (
            <div>
              <div className="pagination-wrap table-pagination-wrap">
                <div>
                  <select
                    className="form-select row-per-page"
                    aria-label="Default select example"
                    onChange={(e) => changeLimit(e)}
                    value={limit}
                  >
                    <option value="10">10/Page</option>
                    <option value="25">25/Page</option>
                    <option value="50">50/Page</option>
                    <option value="100">100/Page</option>
                  </select>
                </div>
                <div>
                  {result?.totalResults && (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={paging.page}
                      itemsCountPerPage={parseInt(paging.limit)}
                      totalItemsCount={result.totalResults}
                      pageRangeDisplayed={5}
                      prevPageText="Prev"
                      nextPageText="Next"
                      itemClassPrev="custom-prev-btn"
                      itemClassNext="custom-next-btn"
                      onChange={(pageNumber) => handlePageChange(pageNumber)}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {isModal && (
        <Dialog
          open={true}
          onClose={() => closeDesc()}
          className="description-popup"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableScrollLock
        >
          <DialogTitle
            id="alert-dialog-title"
            className="notify-title border-bottom"
          >
            {desc?.key == "Description"
              ? "Description"
              : desc?.key == "Clattr1"
              ? "Appied Course"
              : desc?.key == "Clattr2"
              ? "College Information"
              : desc?.key == "Clattr3"
              ? "Work History"
              : desc?.key == "Clattr4"
              ? "Test score"
              : desc?.key == "Clattr5"
              ? "Suggested Course"
              : "-"}
          </DialogTitle>
          <DialogContent>
            {desc?.key == "Description" ? (
              <p>{desc?.desc}</p>
            ) : desc?.key == "Clattr1" ? (
              renderAppCourseInfo(desc.desc)
            ) : desc?.key == "Clattr2" ? (
              renderColInfo(desc.desc)
            ) : desc?.key == "Clattr3" ? (
              renderWorkInfo(desc.desc)
            ) : desc?.key == "Clattr4" ? (
              renderTestInfo(desc.desc)
            ) : desc?.key == "Clattr5" ? (
              renderSugCourseInfo(desc.desc)
            ) : (
              "-"
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import { common } from "helpers";
let isPickauniDomain = window.location.hostname == 'uams.pickauni.com';

function AuthLayout(props) {
  return (
    <Box className="login-bg login">
      <Box display="flex" justifyContent="space-between" px={4} py={2}>
        <Box className="detail-head">
          <Grid container alignItems="center" spacing={2} className="pointer detail-head">
            <Grid item>
              <img src={common.loadImg("gotowebsite.svg")} alt="goto-website" />
            </Grid>
            <Grid item>
              {isPickauniDomain ? <a className="goto-website" href="https://pickauni.com/">Go to website</a> : <Typography className="goto-website">Go to website</Typography>}
            </Grid>
          </Grid>
        </Box>
        <Box className="safari-style">
          <Grid container alignItems="center" spacing={2} className="pointer safari-style">
            <Grid item>
              <img src={common.loadImg("contact.svg")} alt="contact" />
            </Grid>
            <Grid item>
              <Typography className="goto-website">Get in contact</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className="login-bg-container">
        <Container maxWidth="sm">
          <Outlet {...props} />
        </Container>
      </Box>
      <Box display="flex" justifyContent="space-between" px={4} py={2}>
        <Box className="detail-head">
          {!isPickauniDomain && <Grid container alignItems="center" spacing={2} className="pointer detail-head">
            <Grid item>
              <img src={common.loadImg("logo_footer.svg")} alt="goto-website" />
            </Grid>
            <Grid item>
              <Typography className="footer-content">
                OSMO 2021 All Rights Reserved
              </Typography>
            </Grid>
          </Grid>}
        </Box>
        <Box py={1} className="safari-style">
          <Grid container alignItems="center" spacing={2} className="pointer safari-style">
            <Grid item>
              <Typography className="footer-content">
                Product by Novelwall (PVT) Ltd 2021
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default AuthLayout;

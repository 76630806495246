import React, { useState, useEffect, createRef, Fragment } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useOutletContext,
} from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FormControl from "@mui/material/FormControl";
import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  TextField,
} from "@mui/material";
import Select from "react-select";
import EmailEditor from "react-email-editor";
import AmlHashForm from "aml-hash-form";
import StatusBar from "elements/StatusBar";
import { api, common, envConfig } from "helpers";
import { CHOLA_EMAIL_TEMPLATE, CRM_ESERVICE_HDR } from "helpers/service";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import AccessDenied from "elements/AccessDenied";

const SAVE_SUCCESS = "Template saved successfully.";
const SAVE_FAILED = "Template save failed.";
const UPDATE_FAILED = "Template update failed.";
const BACK_URL = "/setting/configure/template";
const COPIED = "COPIED TO CLIPBOARD";

const emailEditorRef = createRef();
const modules = [
  {
    label: "campaign",
    value: "CholaCrmCampaigns",
    api: "CRM_CAMPAIGNS",
  },
  {
    label: "enquiry",
    api: "CRM_LISTS",
    value: "CholaCrmLists",
  },
  {
    label: "enquiryMember",
    api: "CRM_LISTS_MEMBER",
    value: "CholaCrmListDtls",
  },
  {
    label: "lead",
    api: "CRM_LEADS",
    value: "CholaCrmLeads",
  },
  {
    label: "deal",
    api: "CRM_OPPORTUNITY",
    value: "CholaCrmOpportunity",
  },
];

function EditTemplate() {
  const { tId, type } = useParams();
  const [permission] = useOutletContext();

  const navigate = useNavigate();
  const userData = common.userInfo();
  const formFields = {
    TemplateCode: { validate: [] },
    TemplateName: { validate: ["req#Template Title is required"] },
    Subject: { validate: ["req#Email Subject is required"] },
    HtmlFormat: { validate: [] },
    DesignFormat: { validate: [] },
    TextFormat: { validate: [] },
    Active: { validate: [] },
    Category: { validate: ["req#Module is required"] },
    ContentTable: { validate: [] },
    TenantId: { validate: [] },
    OrgId: { validate: [] },
    TemplateType: { validate: [] },
  };
  const [Module, setModule] = useState(null);
  const [MergeFieldList, setMergeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(tId ? "process" : "success");
  const [copied, setCopied] = useState(false);
  // Main Form
  const { fields, errors, handleChange, handleSubmit, setMultiValue } =
    AmlHashForm(formFields);

  useEffect(() => {
    if (tId) {
      getTemplateInfo(); //edit template
    } else {
      setModule(modules[0]);
      setMultiValue({
        ...fields,
        TemplateType: type == "text" ? "TEXT" : "EMAIL",
      });
    }
  }, []);

  useEffect(() => {
    if (Module) {
      getColumnsData(Module);
    }
  }, [Module]);

  const changeModule = (e) => {
    setModule(e);
    setMultiValue({
      ...fields,
      Category: e.label,
      ContentTable: e.value,
    });
  };

  const handleOnChange = (event) => {
    let updateFields = common.reParse(fields);
    const { name, value } = event.target;

    if (updateFields.hasOwnProperty(name)) {
      updateFields[name] = value;
      // if (name == "Subject") {
      //   updateFields.TemplateName = value;
      //   updateFields.TemplateCode = value;
      // }

      setMultiValue({
        TextFormat: value || "",
        //TemplateName: value || "",
        //TemplateCode: value || "",
      });
    }
  };

  const mapKey = (key) => {
    if (key) {
      const filters = MergeFieldList.filter((i) =>
        i.label.toUpperCase().includes(key.toUpperCase())
      );
      return filters ? filters : MergeFieldList;
    } else {
      return MergeFieldList;
    }
  };

  const onLoad = async () => {
    if (fields.DesignFormat.length > 0) {
      let designFormat = fields.DesignFormat;
      if (fields.Category !== "") {
        designFormat = await common.evaluateEmailTemplates(
          MergeFieldList,
          designFormat,
          "key",
          "label"
        );
        let templateData = JSON.parse(designFormat);
        const templateJson = templateData.design;
        setTimeout(() => {
          emailEditorRef.current.editor.loadDesign(templateJson);
        }, 1000);
      }
    }
    removeUnusedIframes();
  };
  const removeUnusedIframes = () => {
    const frames = document.getElementsByTagName("iframe");
    const unlayerFrames = Array.from(frames).filter((frame) =>
      frame.src.includes("unlayer")
    );
    if (unlayerFrames.length === 1) return;
    unlayerFrames.slice(1).forEach((frame) => frame.remove());
  };
  const getTemplateInfo = () => {
    let url = `${envConfig.BASE_API}${CHOLA_EMAIL_TEMPLATE}/${tId}`;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };

    api.call(
      data,
      (response) => {
        if (response.status === 200) {
          const result = response.data;
          if (result) {
            let selectedModule = modules.find(
              (o) => o.label === result.Category
            );

            setMultiValue({
              HtmlFormat: result.HtmlFormat || "",
              DesignFormat: result.DesignFormat || "",
              TextFormat: result.TextFormat || "",
              Subject: result.Subject || "",
              TemplateCode: result.TemplateCode || "",
              TemplateName: result.TemplateName || "",
              Category: result.Category || "",
              ContentTable: result.ContentTable || "",
              TemplateType:
                result.TemplateType || (result.TextFormat ? "TEXT" : "EMAIL"),
            });
            setModule(selectedModule);
          }
        }
        // setStatus("success");
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const getColumnsData = (service) => {
    let filter = `?q=EserviceCode=${service.api}`;
    let url = envConfig.BASE_API + CRM_ESERVICE_HDR + filter;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response) {
          let formFields = JSON.parse(response.data.items[0].FormFields);

          let list = formFields.form.fields.sort((a, b) =>
            a.label.toUpperCase() > b.label.toUpperCase()
              ? 1
              : b.label.toUpperCase() > a.label.toUpperCase()
              ? -1
              : 0
          );
          setMergeList(list);
          setStatus("success");
          if (fields.TextFormat) {
            let textFormat = common.evaluateEmailTemplates(
              list,
              fields.TextFormat,
              "key",
              "label"
            );
            let res;
            textFormat.then(function (result) {
              res = result;
            });
            setTimeout(() => {
              setMultiValue({
                TextFormat: res,
              });
            }, 1000);
          }
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let isValid = handleSubmit();
    if (isValid) {
      let updateFields = common.reParse(fields);

      if (fields.TemplateType == "EMAIL") {
        emailEditorRef.current.editor.exportHtml(async (data) => {
          const { design, html } = data;

          if (fields.Category) {
            updateFields.HtmlFormat = await common.evaluateEmailTemplates(
              MergeFieldList,
              html,
              "label",
              "key"
            );
            let designFormat = await common.evaluateEmailTemplates(
              MergeFieldList,
              JSON.stringify({ design: design }),
              "label",
              "key"
            );
            updateFields.DesignFormat = designFormat;
            updateFields.TextFormat = null;

            if (designFormat) {
              setMultiValue({
                updateFields,
              });

              save(updateFields);
            }
          }
        });
      } else {
        let updateFields = common.reParse(fields);
        updateFields.HtmlFormat = null;
        updateFields.DesignFormat = null;
        let textFormat = await common.evaluateEmailTemplates(
          MergeFieldList,
          fields.TextFormat,
          "label",
          "key"
        );
        if (textFormat) {
          updateFields.TextFormat = textFormat;
          setMultiValue({
            updateFields,
          });

          save(updateFields);
        }
      }
    }
  };

  const save = (fields) => {
    setIsLoading(true);
    let params = {
      TemplateCode: fields.TemplateName,
      TemplateName: fields.TemplateName,
      Subject: fields.Subject,
      HtmlFormat: fields.HtmlFormat,
      DesignFormat: fields.DesignFormat,
      TextFormat: fields.TextFormat,
      Active: "Y",
      Category: Module.label,
      ContentTable: Module.value,
      TenantId: userData.pTenantId,
      OrgId: userData.pOrgId,
      TemplateType: fields.TemplateType || (type == "text" ? "TEXT" : "EMAIL"),
    };

    let url = `${envConfig.BASE_API}${CHOLA_EMAIL_TEMPLATE}`;
    if (tId) {
      url = `${envConfig.BASE_API}${CHOLA_EMAIL_TEMPLATE}/${tId}`;
    }
    let data = {
      url: url,
      type: "dynamic",
      method: tId ? "PATCH" : "POST",
      body: JSON.stringify(params),
      auth: "token",
      cType: 4,
    };

    api.call(
      data,
      (res) => {
        if (res.data) {
          if (tId) {
            common.snack("S", SAVE_SUCCESS);
          } else {
            common.snack("S", SAVE_SUCCESS);
          }
          navigate(BACK_URL);
        } else if (
          res?.status === 500 ||
          res?.status === 400 ||
          res?.status === 404
        ) {
          const error = res?.data?.error;
          if (tId) {
            common.snack("E", error || UPDATE_FAILED);
          } else {
            common.snack("E", error || SAVE_FAILED);
          }
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        common.snack("E", SAVE_FAILED);
      },
      (final) => {
        setIsLoading(false);
      }
    );
  };

  const Item = ({ entity: { label, key } }) => <div>{`${label}`}</div>;
  const Loading = ({ data }) => <div>Loading</div>;

  return (
    <div className="default-bg">
      {common.givenPermission(
        permission,
        "configure:template",
        "basic",
        tId
      ) ? (
        <AccessDenied />
      ) : (
        <Box className="template-box-border p-4">
          <div className="row">
            <div className="col-12">
              <div className="card border-color-F2F9FD">
                <div className="card-body header-border-padding p-0">
                  <Box alignItems="center" justifyContent="center">
                    <Box py={3}>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item className="detail-head">
                          <Grid
                            container
                            spacing={3}
                            alignItems="center"
                            className="detail-head"
                          >
                            <Grid item className="detail-head">
                              <Grid
                                container
                                alignItems="center"
                                className="detail-head"
                              >
                                <Grid item py={1} px={1}>
                                  <Link to={BACK_URL}>
                                    <img
                                      alt={"back"}
                                      src={common.loadImg(
                                        "iconly-light-arrow-down-circle.svg"
                                      )}
                                    />
                                  </Link>
                                </Grid>
                                <Grid item className="df-main-head">
                                  <Typography className="detail-title">
                                    {`${tId ? "Edit" : "Add"} Template`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item className="safari-style">
                          <Grid container className="safari-style">
                            <Box className="revert-close1 me-3">
                              <Button
                                className="sign-in-btn"
                                disabled={isLoading}
                                onClick={(e) => onSubmit(e)}
                              >
                                {isLoading ? "Processing..." : "Save"}
                              </Button>
                            </Box>
                            <Box className="revert-close revert-close1 close-icn-border">
                              <Link to={BACK_URL} className="text-none">
                                {" "}
                                <Button className="save-note " type="button">
                                  Revert and Close{" "}
                                  <img
                                    src={common.loadImg("closeKanban.svg")}
                                    alt="close"
                                  />
                                </Button>
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      <Divider />
                    </Box>
                    {status !== "success" ? (
                      <StatusBar status={status} />
                    ) : (
                      <Fragment>
                        <Box mt={2}>
                          <Row className="m-3">
                            <Col xs={6}>
                              <Box>
                                <FormControl className="dropdown  w-100">
                                  <label className="template-label-font template-label-position">
                                    Select Module
                                  </label>
                                  <Select
                                    // isClearable="true"
                                    className="module-list w-100 px-3 py-2"
                                    defaultValue={Module}
                                    onChange={changeModule}
                                    options={modules}
                                    isDisabled={tId ? true : false}
                                  />
                                </FormControl>
                              </Box>
                            </Col>
                            <Col xs={6}>
                              <Box>
                                <FormControl className="template-name-border w-100">
                                  <div className="template-name-border mandatoryclass">
                                    <label className="template-label-font template-label-padding">
                                      Template Title
                                    </label>
                                    <input
                                      type="text"
                                      name="TemplateName"
                                      value={fields.TemplateName}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      className="template-name w-100"
                                    ></input>
                                  </div>
                                </FormControl>
                                {errors.TemplateName ? (
                                  <p className="error-txt">
                                    {errors.TemplateName}
                                  </p>
                                ) : null}
                              </Box>
                            </Col>
                          </Row>
                          <Row className="m-3">
                            <Col xs={6}>
                              <Box>
                                <FormControl className="template-name-border w-100">
                                  <div className="template-name-border mandatoryclass">
                                    <label className="template-label-font template-label-padding">
                                      {fields.TemplateType == "EMAIL"
                                        ? "Email"
                                        : ""}{" "}
                                      Subject
                                    </label>
                                    <input
                                      type="text"
                                      name="Subject"
                                      value={fields.Subject}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      className="template-name w-100"
                                    ></input>
                                  </div>
                                </FormControl>
                                {errors.Subject ? (
                                  <p className="error-txt">{errors.Subject}</p>
                                ) : null}
                              </Box>
                            </Col>
                          </Row>
                        </Box>

                        <Box>
                          <Divider />
                        </Box>
                        {fields.TemplateType == "EMAIL" && (
                          <>
                            <Grid item>
                              <Grid container spacing={3} alignItems="center">
                                <Grid item>
                                  <Typography className="page-title mx-4 px-1 mt-3">
                                    Design Your Template
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Typography className=" edit-desc mx-4 px-1">
                                To merge copy paste the required field from
                                below
                              </Typography>
                            </Grid>

                            <Box className="design-template-border ">
                              <Grid
                                container
                                // spacing={2}
                                justifyContent="space-between"
                              >
                                <EmailEditor
                                  ref={emailEditorRef}
                                  onLoad={onLoad}
                                />
                              </Grid>
                            </Box>
                          </>
                        )}
                        {fields.TemplateType == "TEXT" && (
                          <div className="row px-4 py-2">
                            <div className="col-lg-12 auto-sugg-wrap">
                              <Grid item>
                                <Grid container spacing={3} alignItems="center">
                                  <Grid item>
                                    <Typography className="page-title">
                                      Design Your Template
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Typography className=" edit-desc">
                                  To merge the required field type "#" in
                                  textbox
                                </Typography>
                              </Grid>
                              <ReactTextareaAutocomplete
                                placeholder="Enter text message"
                                disabled={MergeFieldList.length == 0}
                                className="form-control p-3"
                                loadingComponent={Loading}
                                name="TextFormat"
                                value={fields.TextFormat}
                                onChange={(e) => handleOnChange(e)}
                                style={{
                                  fontSize: "15px",
                                  lineHeight: "20px",
                                  height: "150px",
                                  padding: 5,
                                }}
                                containerStyle={{
                                  marginTop: 20,
                                  height: 150,
                                  margin: "20px auto",
                                }}
                                minChar={0}
                                trigger={{
                                  "#": {
                                    dataProvider: (token) => {
                                      // console.log("token", token);
                                      return mapKey(token)
                                        .slice(0, 10)
                                        .map(({ label, key }) => ({
                                          label,
                                          key,
                                        }));
                                    },
                                    component: Item,
                                    output: (item, trigger) =>
                                      "${" + item.label + "}$",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        )}

                        <Box>
                          <Divider />
                        </Box>

                        {fields.TemplateType == "EMAIL" &&
                          MergeFieldList.length > 0 && (
                            <Fragment>
                              <Grid item>
                                <Grid container spacing={3} alignItems="center">
                                  <Grid item>
                                    <Typography className="page-title mx-4 px-1 mt-3">
                                      Fields
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Typography className=" edit-desc px-4 py-2">
                                  {/* To merge copy paste the required field from
                                  below */}
                                  To merge,Click to copy the required fields
                                  from below and Use as shown in the example.
                                  <br />
                                  Example:{" "}
                                  <span className="template-formatted-txt text-bold">
                                    {" "}
                                    $&#123;CAMPAIGN NAME&#125;$
                                  </span>
                                </Typography>
                              </Grid>
                            </Fragment>
                          )}
                        {fields.TemplateType == "EMAIL" &&
                          MergeFieldList.length > 0 && (
                            <Box my={3}>
                              <Grid item className="px-4">
                                <Grid container spacing={2} alignItems="center">
                                  {MergeFieldList.map((obj, idx) => {
                                    return (
                                      <Grid item key={idx} xs={3}>
                                        <CopyToClipboard
                                          text={"${" + obj.label + "}$"}
                                          onCopy={() =>
                                            common.snack("I", COPIED)
                                          }
                                        >
                                          <div
                                            key={"cpy_" + idx}
                                            type="text"
                                            className=" fields-border text-center pointer"
                                          >
                                            {obj.label}
                                          </div>
                                        </CopyToClipboard>
                                        {copied == true ? (
                                          <span style={{ color: "red" }}>
                                            Copied.
                                          </span>
                                        ) : null}
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                      </Fragment>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
}

export default EditTemplate;

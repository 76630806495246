import React, { memo, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { common } from "helpers";
import ListView from "elements/ListView";
import { useParams } from "react-router-dom";
import DynamicForm from "./DynamicForm";

const CallLog = memo(({ handleExpanded, isExpand, id }) => {
  let { mod } = useParams();
  const [isForm, setIsForm] = useState(false);
  const [objectId, setObjectId] = useState(id);
  const [callLogId, setCallLogId] = useState(0);
  const [objectType, setObjectType] = useState(mod);

  useEffect(() => {
    if (id) {
      setObjectId(id);
      setObjectType(mod);
    }
  }, [callLogId]);

  useEffect(() => {
    if (id) {
      setObjectId(id);
      setObjectType(mod);
    }
  }, [id]);

  const changeFormType = (val, type, data, ids) => {
    if (data) {
      setCallLogId(data.CallLogId);
    } else {
      setCallLogId(0);
    }
    setIsForm(val);
  };

  return (
    <div className="relative pb-3">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className="crm-submenu-wrap">
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="subheader-elements"
          >
            <Grid item>
              {isExpand === "detail" ? (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("tab")}
                >
                  Expand{" "}
                  <img src={common.loadImg("expandkanban.svg")} alt="expand" />
                </Typography>
              ) : (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("detail")}
                >
                  Collapse{" "}
                  <img
                    src={common.loadImg("expandkanban.svg")}
                    alt="Collapse"
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {!isForm && (
        <ListView
          module={"callLogs"}
          changeForm={(val, type, item) => changeFormType(val, type, item)}
          query={{
            ObjectId: objectId,
            ObjectType: common.getObjectNameByPage(objectType),
          }}
          add={true}
          accAdd={true}
          accExport={true}
          accDelete={true}
          accUpdate={true}
          accImport={true}
          sort="CreatedOn:desc"
          type={"subMenu"}
          className={"table-scroll-position-collapse"}
          isExpand={isExpand}
          id={id}
        />
      )}
      {isForm && (
        <DynamicForm
          module={"callLogs"}
          data={{
            value1: callLogId > 0 ? callLogId : "",
            value2: objectId,
            value3: common.getObjectNameByPage(objectType),
          }}
          closeForm={(val, type, data, id) =>
            changeFormType(val, type, data, id)
          }
        />
      )}
    </div>
  );
});

export { CallLog };

import { Box, Divider, Grid, Paper, Typography } from "@mui/material";

import React, { memo, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from "@mui/material";
import StatusBar from "./StatusBar";
import { common, eduTechSerivces, tools } from "helpers";

const CrmGeneralView = memo((props) => {
  const [data, setData] = useState([]);
  const authInfo = common.authInfo();
  const [imgPreview, setImgPreview] = useState(false);
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [appInfo, setAppInfo] = useState({});
  const isEdutechAcc =
    common.isEduTech() && ["lead", "deal"].includes(props.module)
      ? true
      : false;
  const edutechAttr = [
    "Clattr1",
    "Clattr2",
    "Clattr3",
    "Clattr4",
    "Clattr5",
    "Clattr6",
    "Clattr7",
    "Cattr38",
    "Phone",
    "PostalCode",
    "Description",
    "Address1",
    "Cattr12",
    "Cattr8",
    "Cattr21",
    "Cattr31",
    "PhoneNumber",
    "PostalCode",
    "Cattr41",
  ];

  const eduLeadDocAttr = [
    "Cattr38",
    "Phone",
    "PostalCode",
    "Address1",
    "Cattr41",
    "Address2",
  ];
  const eduDealDocs = ["Cattr12", "Cattr8"];
  const eduDealDocAttr = [
    "Cattr12",
    "Cattr8",
    "Cattr21",
    "Cattr31",
    "PhoneNumber",
    "PostalCode",
    "Cattr41",
    "Address",
  ];

  useEffect(() => {
    setData(props.data);
    if (isEdutechAcc && props.data?.fields) {
      getAppInfo(props.data);
    }
  }, [props.data]);
  console.log("props.data", props.data);
  const getAppInfo = async (data) => {
    if (isEdutechAcc) {
      let AppKey = props.module == "lead" ? "Cattr1" : "Cattr2";
      let fData = data.fields.filter((obj) => obj.key == AppKey);
      if (fData.length > 0) {
        let appId = fData[0].value;
        if (appId) {
          let res = await eduTechSerivces.getApplicationInfo(appId);
          console.log("res", res);
          setAppInfo(res);
        }
      }
    }
  };

  const previewImage = (e, file) => {
    e.preventDefault();
    setImgPreview(true);
    setImgPreviewSrc(file);
  };

  const handleClose = () => {
    setImgPreview(false);
    setImgPreviewSrc("");
  };

  const renderWorkInfo = (desc) => {
    let data = JSON.parse(desc);
    return (
      <div>
        {data.map((obj, i) => {
          return (
            <div key={i}>
              <p className="college-title">{i + 1}. Work History</p>
              <table className="table table-bordered mb-4">
                <tbody>
                  <tr>
                    <td className="camp-sub-name">Company Name</td>
                    <td className="camp-sub-des">{obj.companyName}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Designation</td>
                    <td className="camp-sub-des">{obj.designation || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Join Date</td>
                    <td className="camp-sub-des">{obj.joinDate || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">End Date</td>
                    <td className="camp-sub-des">{obj.endDate || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Line Manager Name</td>
                    <td className="camp-sub-des">
                      {obj.lineManagerName || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Line Manager Work Email</td>
                    <td className="camp-sub-des">
                      {obj.lineManagerEmail || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Line Manager Designation</td>
                    <td className="camp-sub-des">
                      {obj.lineManagerDesignation || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Company Email</td>
                    <td className="camp-sub-des">{obj.companyEmail || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Company Address</td>
                    <td className="camp-sub-des">
                      {obj.companyAddress || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name" colSpan="2">
                      Documents
                      {obj?.docs ? renderAttachment(obj.docs) : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    );
  };

  const renderColInfo = (desc) => {
    let data = JSON.parse(desc);
    return (
      <>
        {data.map((college, i) => {
          return (
            <div key={i}>
              <p className="college-title">{i + 1}. College</p>
              <table className="table table-bordered mb-4">
                <tbody>
                  <tr>
                    <td className="camp-sub-name">Name of Institution</td>
                    <td className="camp-sub-des">
                      {college?.institutionName || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Country of Education</td>
                    <td className="camp-sub-des">
                      {college?.institutionCountry?.name || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Qualification</td>
                    <td className="camp-sub-des">
                      {college?.qualification || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Degree</td>
                    <td className="camp-sub-des">{college?.degree || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Score</td>
                    <td className="camp-sub-des">{college.score || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Grading System</td>
                    <td className="camp-sub-des">
                      {college?.gradingSystem || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Graduation Date</td>
                    <td className="camp-sub-des">
                      {college.graduationDate
                        ? common.formatDate(
                            college.graduationDate,
                            "DD-MM-YYYY"
                          )
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Start Date</td>
                    <td className="camp-sub-des">
                      {college.startDate
                        ? common.formatDate(college.startDate, "DD-MM-YYYY")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">End Date</td>
                    <td className="camp-sub-des">
                      {college.endDate
                        ? common.formatDate(college.endDate, "DD-MM-YYYY")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name" colSpan="2">
                      Documents
                      {college?.docs ? renderAttachment(college.docs) : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      </>
    );
  };

  const renderTestInfo = (desc) => {
    let data = JSON.parse(desc);
    return (
      <div>
        {data.map((obj, i) => {
          return (
            <div key={i}>
              <p className="college-title">{i + 1}. Test score</p>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="camp-sub-name">Exam Type</td>
                    <td className="camp-sub-des">{obj.examType || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Listening</td>
                    <td className="camp-sub-des">{obj.listening || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Reading</td>
                    <td className="camp-sub-des">{obj.reading || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Writing</td>
                    <td className="camp-sub-des">{obj.writing || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name">Speaking</td>
                    <td className="camp-sub-des">{obj.speaking || "-"}</td>
                  </tr>
                  <tr>
                    <td className="camp-sub-name" colSpan="2">
                      Documents
                      {obj.docs ? renderAttachment(obj.docs) : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    );
  };

  const renderOtherEduInfo = (desc) => {
    let obj = JSON.parse(desc);
    return obj.type == "work" ? (
      <div>
        <p className="college-title">Work Experience</p>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td className="camp-sub-name">Years of Work Experience</td>
              <td className="camp-sub-des">{obj.experience || "-"}</td>
            </tr>
            <tr>
              <td className="camp-sub-name">Are you currently working?</td>
              <td className="camp-sub-des">{obj.working || "-"}</td>
            </tr>
            <tr>
              <td className="camp-sub-name">Manager or HR email</td>
              <td className="camp-sub-des">
                {obj.email ? (
                  <a href={"mailto:" + obj.email} target="_blank">
                    {tools.hideEmail(obj.email)}
                  </a>
                ) : (
                  "-"
                )}
              </td>
            </tr>
            <tr>
              <td
                className="camp-sub-name"
                title="P60, P46, Work Referrence Letter"
                colSpan="2"
              >
                Proof of Evidence
                {obj.docs ? renderAttachment(obj.docs) : ""}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ) : (
      <div>
        <p className="college-title"> Other Qualification</p>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td className="camp-sub-name">Qualification</td>
              <td className="camp-sub-des">{obj.qualification || "-"}</td>
            </tr>
            <tr>
              <td className="camp-sub-name">Description</td>
              <td className="camp-sub-des">{obj.qualificationDesc || "-"}</td>
            </tr>
            <tr>
              <td className="camp-sub-name" colSpan="2">
                Documents
                {obj.docs ? renderAttachment(obj.docs) : ""}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderAttachment = (docs) => {
    const imgArr = docs.filter((e) =>
      ["jpg", "jpeg", "png"].includes(e.split(".").pop())
    );
    const pdf = docs.filter(
      (e) => !["jpg", "jpeg", "png"].includes(e.split(".").pop())
    );
    return (
      <div>
        <div className="attached-img-wrap">
          {imgArr.map((image, idx) => {
            return (
              <img
                key={idx}
                src={common.loadEduImage(image)}
                onClick={(e) => previewImage(e, image)}
              />
            );
          })}
        </div>
        {pdf.map((image, idx) => {
          return (
            <div className="attach-link" key={idx}>
              <a href={common.loadEduImage(image)} download target="_blank">
                <img
                  src={common.loadImg("external-link-icn.svg")}
                  className="me-2"
                />
                {image.split("/").pop()}
              </a>
            </div>
          );
        })}
      </div>
    );
  };

  const noData = () => {
    return (
      <Col xs={3} className="body-th">
        <div className="camp-sub-name">Documents</div>
        <div className="camp-sub-des my-2">-</div>
      </Col>
    );
  };

  const checkFieldAccess = (field) => {
    if (authInfo.roleId == 0) return true; //ADMIN Access
    if (field?.pspecific == true && field?.permission?.length > 0) {
      let fAccess = field.permission.filter(
        (e) => e.groupId == authInfo.roleId
      );
      return fAccess.length > 0
        ? fAccess[0].access !== "h"
        : field.ptype !== "h";
    } else {
      return field?.ptype !== "h" || true;
    }
  };

  return (
    <div>
      <StatusBar status={props.status} />
      <Paper variant="contained" square>
        {props.status == "success" &&
          data &&
          data.sections &&
          Object.keys(data.sections).length > 0 &&
          Object.keys(data.sections).map((sItem, sKey) => {
            let sData = data.sections[sItem];
            let fields = props.getFormFields(sKey);
            if (fields.length > 0) {
              return (
                <Box pb={1} key={sKey}>
                  <Box p={3}>
                    <Typography className="camp-sub-title">
                      {sData.label}
                    </Typography>
                  </Box>
                  <Box>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      {fields &&
                        fields.length > 0 &&
                        fields.map((fItem, fkey) => {
                          return (
                            checkFieldAccess(fItem) && (
                              <Col xs={6} className="body-th" key={fkey}>
                                <Grid container key={fkey}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    key={fkey}
                                  >
                                    <Box>
                                      <div className="camp-sub-name">
                                        {fItem && fItem.label != ""
                                          ? fItem.label
                                          : "-"}
                                      </div>
                                      <div className="camp-sub-des mt-2">
                                        {isEdutechAcc &&
                                        appInfo &&
                                        edutechAttr.includes(fItem.key) &&
                                        (props.module == "lead" ||
                                          props.module == "deal") &&
                                        fItem.value ? (
                                          <div>
                                            {" "}
                                            {(eduLeadDocAttr.includes(
                                              fItem.key
                                            ) &&
                                              props.module == "lead") ||
                                            (props.module == "deal" &&
                                              eduDealDocAttr.includes(
                                                fItem.key
                                              ) &&
                                              fItem.value)
                                              ? renderAttachment(
                                                  (props.module == "lead" &&
                                                    fItem.key == "Cattr38") ||
                                                    (props.module == "deal" &&
                                                      fItem.key == "Cattr21")
                                                    ? appInfo?.profile
                                                        ?.passportDocs || []
                                                    : [
                                                        "Address1",
                                                        "Address",
                                                      ].includes(fItem.key)
                                                    ? appInfo?.profile?.poeDocs
                                                    : ["Cattr41"].includes(
                                                        fItem.key
                                                      )
                                                    ? [
                                                        appInfo?.profile
                                                          ?.homeOfficeDocs,
                                                      ]
                                                    : [
                                                        "Phone",
                                                        "PhoneNumber",
                                                      ].includes(fItem.key)
                                                    ? [
                                                        appInfo?.profile
                                                          ?.brpDocs,
                                                      ]
                                                    : ["PostalCode"].includes(
                                                        fItem.key
                                                      )
                                                    ? [appInfo?.profile?.euDocs]
                                                    : //: fItem.key != "Address1"
                                                      [fItem?.value]
                                                  //: fItem?.value?.split(",")
                                                )
                                              : fItem.key == "Clattr2"
                                              ? renderColInfo(fItem.value)
                                              : fItem.key == "Clattr3"
                                              ? renderWorkInfo(fItem.value)
                                              : fItem.key == "Clattr4"
                                              ? renderTestInfo(fItem.value)
                                              : fItem.key == "Description"
                                              ? renderOtherEduInfo(fItem.value)
                                              : fItem.value || "-"}
                                          </div>
                                        ) : [
                                            "Mobile",
                                            "MobileNumber",
                                            "Email",
                                          ].includes(fItem.key) &&
                                          fItem.value ? (
                                          ["Mobile", "MobileNumber"].includes(
                                            fItem.key
                                          ) ? (
                                            <a href={"tel:" + fItem.value}>
                                              {tools.hideMobile(fItem.value)}
                                            </a>
                                          ) : (
                                            <a
                                              href={"mailto:" + fItem.value}
                                              target="_blank"
                                            >
                                              {tools.hideEmail(fItem.value)}
                                            </a>
                                          )
                                        ) : isEdutechAcc &&
                                          ((props.module == "lead" &&
                                            ["Cattr27"].includes(fItem.key)) ||
                                            (props.module == "deal" &&
                                              ["Cattr1"].includes(
                                                fItem.key
                                              ))) &&
                                          fItem.value ? (
                                          <a
                                            href={"mailto:" + fItem.value}
                                            target="_blank"
                                          >
                                            {tools.hideEmail(fItem.value)}
                                          </a>
                                        ) : (
                                          fItem.value || "-"
                                        )}
                                      </div>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Col>
                            )
                          );
                        })}
                    </Row>
                  </Box>
                </Box>
              );
            }
          })}

        {props.status == "success" && isEdutechAcc && appInfo?._id && (
          <Box p={2} px={5} className="form-wrap">
            <div className="info-title dynamic-form-title mt-3">
              Document Section
            </div>
            <Box mt={3}>
              <Divider />
            </Box>
            <Box mt={2} className="camp-edit meet-table">
              <Row className="m-0">
                {appInfo["passportDocs"] && (
                  <Col xs={12} className="body-th p-0">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className="college-title">
                          <div className="camp-sub-des">
                            Application Documents
                          </div>
                        </Box>
                        <Box mt={2} className="camp-edit meet-table">
                          <Row className="m-0">
                            <Col xs={6} className="body-th">
                              <div className="camp-sub-name">
                                Birth Certificate or Proof of ID Docs
                              </div>
                              <div className="camp-sub-des my-2">
                                {appInfo["passportDocs"] &&
                                appInfo["passportDocs"].length > 0
                                  ? renderAttachment(appInfo["passportDocs"])
                                  : "-"}
                              </div>
                            </Col>
                            <Col xs={6} className="body-th">
                              <div className="camp-sub-name">
                                Biometric Residence Permit (BRP)
                              </div>
                              <div className="camp-sub-des my-2">
                                {appInfo["brpDocs"]
                                  ? renderAttachment([appInfo["brpDocs"]])
                                  : "-"}
                              </div>
                            </Col>
                          </Row>
                          <Row className="m-0">
                            <Col xs={6} className="body-th">
                              <div className="camp-sub-name">EU Share Code</div>
                              <div className="camp-sub-des my-2">
                                {appInfo["euDocs"]
                                  ? renderAttachment([appInfo["euDocs"]])
                                  : "-"}
                              </div>
                            </Col>
                            <Col xs={6} className="body-th">
                              <div className="camp-sub-name">
                                Letter of Approval from SFE / Academic Progress
                              </div>
                              <div className="camp-sub-des my-2">
                                {appInfo["sfeAppDocs"]
                                  ? renderAttachment([appInfo["sfeAppDocs"]])
                                  : "-"}
                              </div>
                            </Col>
                            <Col xs={6} className="body-th">
                              <div className="camp-sub-name">
                                Home office letter
                              </div>
                              <div className="camp-sub-des my-2">
                                {appInfo["homeOfficeDocs"]
                                  ? renderAttachment([
                                      appInfo["homeOfficeDocs"],
                                    ])
                                  : "-"}
                              </div>
                            </Col>
                            <Col xs={6} className="body-th">
                              <div className="camp-sub-name">
                                Proof of evidence of Stay
                              </div>
                              <div className="camp-sub-des my-2">
                                {appInfo["poeDocs"] &&
                                appInfo["poeDocs"].length > 0
                                  ? renderAttachment(appInfo["poeDocs"])
                                  : "-"}
                              </div>
                            </Col>
                          </Row>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                )}
                {appInfo.profile.college && (
                  <Col xs={12} className="body-th p-0">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className="college-title">
                          <div className="camp-sub-des">
                            Education Qualification and History
                          </div>
                        </Box>
                        <Box mt={2} className="camp-edit meet-table">
                          {appInfo.profile.college.length == 0
                            ? noData()
                            : appInfo.profile.college.map((college, idx) => {
                                return (
                                  <Col xs={6} className="body-th" key={idx}>
                                    <div className="camp-sub-name">
                                      Documents
                                    </div>
                                    <div className="camp-sub-des my-2">
                                      {renderAttachment(college.docs)}
                                    </div>
                                  </Col>
                                );
                              })}
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                )}

                {appInfo.profile.workExp && (
                  <Col xs={12} className="body-th p-0">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className="college-title">
                          <div className="camp-sub-des">Career History</div>
                        </Box>
                        <Box mt={2} className="camp-edit meet-table">
                          {appInfo.profile.workExp.length == 0
                            ? noData()
                            : appInfo.profile.workExp.map((exp, idx) => {
                                return (
                                  <Col xs={6} className="body-th" key={idx}>
                                    <div className="camp-sub-name">
                                      Documents
                                    </div>
                                    <div className="camp-sub-des my-2">
                                      {renderAttachment(exp.docs)}
                                    </div>
                                  </Col>
                                );
                              })}
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                )}
                {appInfo.profile.extraCurricular && (
                  <Col xs={12} className="body-th p-0">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className="college-title">
                          <div className="camp-sub-des">
                            Extra Curricular Documents
                          </div>
                        </Box>
                        <Box mt={2} className="camp-edit meet-table">
                          {appInfo.profile.extraCurricular.length == 0
                            ? noData()
                            : appInfo.profile.extraCurricular.map(
                                (obj, idx) => {
                                  return (
                                    <Row className="m-0" key={idx}>
                                      <Col xs={3} className="body-th">
                                        <div className="camp-sub-name">
                                          Title
                                        </div>
                                        <div className="camp-sub-des my-2">
                                          {obj.title}
                                        </div>
                                      </Col>
                                      <Col xs={3} className="body-th">
                                        <div className="camp-sub-name">
                                          Description
                                        </div>
                                        <div className="camp-sub-des my-2">
                                          {obj.description}
                                        </div>
                                      </Col>
                                      <Col xs={6} className="body-th">
                                        <div className="camp-sub-name">
                                          Documents
                                        </div>
                                        <div className="camp-sub-des my-2">
                                          {renderAttachment(obj.docs)}
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                              )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                )}
                {appInfo.profile.otherActivity && (
                  <Col xs={12} className="body-th p-0">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className="college-title">
                          <div className="camp-sub-des">Other Documents</div>
                        </Box>
                        <Box mt={2} className="camp-edit meet-table">
                          {appInfo.profile.otherActivity.length == 0
                            ? noData()
                            : appInfo.profile.otherActivity.map((obj, idx) => {
                                return (
                                  <Row className="m-0" key={idx}>
                                    <Col xs={3} className="body-th">
                                      <div className="camp-sub-name">Title</div>
                                      <div className="camp-sub-des my-2">
                                        {obj.title}
                                      </div>
                                    </Col>
                                    <Col xs={3} className="body-th">
                                      <div className="camp-sub-name">
                                        Description
                                      </div>
                                      <div className="camp-sub-des my-2">
                                        {obj.description}
                                      </div>
                                    </Col>
                                    <Col xs={6} className="body-th">
                                      <div className="camp-sub-name">
                                        Documents
                                      </div>
                                      <div className="camp-sub-des my-2">
                                        {renderAttachment(obj.docs)}
                                      </div>
                                    </Col>
                                  </Row>
                                );
                              })}
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                )}
                {isEdutechAcc &&
                  props.module == "deal" &&
                  props.status == "success" &&
                  data &&
                  data.sections &&
                  Object.keys(data.sections).length > 0 &&
                  Object.keys(data.sections).map((sItem, sKey) => {
                    let fields = props.getFormFields(sKey);
                    if (fields.length > 0) {
                      return (
                        <Box pb={1} key={sKey}>
                          {props.module == "deal" &&
                            fields &&
                            fields.length > 0 &&
                            fields.map((fItem, fkey) => {
                              return (
                                eduDealDocs.includes(fItem.key) && (
                                  <Col xs={12} className="body-th p-0">
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                      >
                                        <Box className="college-title">
                                          <div className="camp-sub-des">
                                            {fItem && fItem.label != ""
                                              ? fItem.label
                                              : "-"}
                                          </div>
                                        </Box>
                                        <Box
                                          mt={2}
                                          className="camp-edit meet-table"
                                        >
                                          <Row className="m-0">
                                            <Col xs={6} className="body-th">
                                              <div className="camp-sub-name">
                                                Documents
                                              </div>
                                              <div className="camp-sub-des my-2">
                                                {eduDealDocs.includes(
                                                  fItem.key
                                                ) && fItem.value
                                                  ? renderAttachment([
                                                      fItem.value,
                                                    ])
                                                  : "-"}
                                              </div>
                                            </Col>
                                          </Row>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                )
                              );
                            })}
                        </Box>
                      );
                    }
                  })}

                {isEdutechAcc && (
                  <Col xs={12} className="body-th p-0">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className="college-title">
                          <div className="camp-sub-des">SFE Information</div>
                        </Box>
                        <Box mt={2} className="camp-edit meet-table">
                          <Row className="m-0">
                            <Col xs={2} className="body-th">
                              <div className="camp-sub-name">SFE Status</div>
                              <div className="camp-sub-des my-2">
                                {appInfo?.sfeField?.sfeStatus || "-"}
                              </div>
                            </Col>
                            <Col xs={5} className="body-th">
                              <div className="camp-sub-name">
                                SFE Missing Fields
                              </div>
                              <div className="camp-sub-des my-2">
                                {appInfo?.sfeField?.sfeStatus
                                  ? appInfo?.sfeField?.sfeMissing.join(",")
                                  : "-"}
                              </div>
                            </Col>
                            <Col xs={5} className="body-th">
                              <div className="camp-sub-name">
                                SFE Interview Slot
                              </div>
                              <div className="camp-sub-des my-2">
                                {appInfo?.sfeField?.interview &&
                                  appInfo?.sfeField?.interview?.slot.length >
                                    0 &&
                                  appInfo?.sfeField?.interview.slot.map(
                                    (obj, idx) => {
                                      let bookedDate = appInfo?.sfeField
                                        ?.interview?.bookedSlot
                                        ? common.formatDate(
                                            appInfo?.sfeField?.interview
                                              .bookedSlot,
                                            "YYYY-MM-DD"
                                          )
                                        : null;
                                      let bookedTime = appInfo?.sfeField
                                        ?.interview.bookedSlot
                                        ? common.formatDate(
                                            appInfo?.sfeField?.interview
                                              .bookedSlot,
                                            "hh:mm a"
                                          )
                                        : null;
                                      return (
                                        <div
                                          key={idx}
                                          className="cond-date-wrap mt-2"
                                        >
                                          {obj.date || ""}
                                          {obj.slot.map((time, idx) => {
                                            return (
                                              <div
                                                key={idx}
                                                className={
                                                  obj.date == bookedDate &&
                                                  time == bookedTime
                                                    ? "cond-slot-wrap booked-slot"
                                                    : "cond-slot-wrap"
                                                }
                                              >
                                                {time
                                                  ? common.formatDate(
                                                      time,
                                                      "hh:mm a"
                                                    )
                                                  : ""}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </Col>
                          </Row>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                )}
              </Row>
            </Box>
          </Box>
        )}

        {imgPreview && (
          <Dialog
            open={imgPreview}
            // onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"sm"}
            fullWidth={true}
            className="target-modal-wrap add-action-popup attachment-popup"
            disableScrollLock
          >
            <DialogTitle
              id="responsive-dialog-title"
              className="popup-title py-2"
            >
              <div className="attach-popup-title">
                <Typography>
                  <a
                    href={common.loadEduImage(imgPreviewSrc)}
                    download
                    target="_blank"
                    className="cust-link"
                  >
                    <img
                      src={common.loadImg("external-link-icn.svg")}
                      className="me-2"
                    />
                    View
                  </a>
                </Typography>
                <IconButton onClick={handleClose}>
                  <img src={common.loadImg("filterCloseIcon.svg")} />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="popup-form-wrap imgDlgContent">
                <div className="list-row text-center">
                  <img
                    src={
                      imgPreviewSrc ? common.loadEduImage(imgPreviewSrc) : null
                    }
                    alt="image"
                    className="w-100"
                  />
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </Paper>
    </div>
  );
});

export default CrmGeneralView;

import React, { useState, useEffect, forwardRef } from "react";
import { Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
// api
import * as Services from "helpers/service";
import { common, envConfig, api, eduTechSerivces } from "helpers";
// json
import lookupList from "assets/files/lookupList";

const AutomationSelect = forwardRef((props, ref) => {
  const { field, value } = props;
  const lookup = lookupList[field.attr.lookup];
  const [mount, setMount] = useState(false);
  const [isLoading, setIsLoading] = useState(props.isRender);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isRender, setIsRender] = useState(props.isRender);
  const [resultList, setResultList] = useState([]);
  const [search, setSearch] = useState("");
  const [userInfo] = useState(common.userInfo());
  const [hasMore, setHasMore] = useState(false);
  const [limit] = useState(100);
  const [offset, setOffset] = useState(0);
  var delayTimer;
  const isEdutechAcc =
    common.isEduTech() && ["lead", "deal"].includes(props?.module)
      ? true
      : false;

  useEffect(() => {
    setOffset(0);
    setSearch("");
    setResultList([]);
  }, [props.field]);

  // effect
  useEffect(() => {
    if (isRender) {
      loadData();
    }
  }, [isRender]);

  useEffect(() => {
    if (mount) {
      loadData();
    }
  }, [offset]);

  useEffect(() => {
    if (mount) {
      setIsLoading(true);
      setResultList([]);
      loadData();
    }
  }, [field]);

  // Select style
  // const asyncSelectStyles = {
  //   menuList: (base) => ({
  //     ...base,
  //     maxHeight: "230px !important"
  //   })
  // };

  // support
  const getUrl = (type) => {
    let url = `${envConfig.BASE_API}${Services[lookup.name]}`;
    let fields = `?fields=${lookup.get},${lookup.set}`;
    let trasaction = `&q=${
      lookup.name == "TRANSACTION_STATUS"
        ? `TransactionTypeId=${common.getTransactionTypeId(
            envConfig[field.attr.data1]
          )} AND Active='Y' AND OrgId=${userInfo.pOrgId} AND `
        : ""
    }`;

    let vTenant = `TenantId=${userInfo.pTenantId}`;
    let search = ` AND UPPER(${lookup.get}) LIKE '*{SEARCH}*'`;
    let orderBylimit = `&orderBy=${lookup.name == "TRANSACTION_STATUS" ? "OrderNo" : lookup.get}:asc&limit=${limit}&offset=${
      type === "search" ? 0 : offset * limit
    }&totalResults=true`;
    let query = "";
    return url + fields + trasaction + vTenant + search + query + orderBylimit;
  };

  // api
  const loadData = async () => {
    if (
      isEdutechAcc &&
      (["Cattr15", "Cattr40"].includes(field.key) ||
        ["Eattr3", "Eattr4"].includes(field.key))
    ) {
      if (["Cattr15", "Eattr3"].includes(field.key)) {
        let result = await eduTechSerivces.getUniversityCourses(
          "",
          search || "",
          limit,
          offset + 1
        );
        let udata = common.getOptionsData(result.data, "name", "_id");
        let existingRecords = resultList.length;
        setResultList((oldData) => {
          return [...new Set([...oldData, ...udata])];
        });
        setIsLoading(false);
        setMount(true);
        setIsLoadingMore(false);
        setHasMore(
          result.pageInfo.totalCount > existingRecords + result.pageInfo.limit
        );
        if (!props.isRender) {
          setIsMenuOpen(true);
          setIsFocus(true);
        }
      } else {
        let result = await eduTechSerivces.getAllUniversities(
          search || "",
          limit,
          offset + 1
        );
        let udata = common.getOptionsData(result.data, "name", "_id");
        let existingRecords = resultList.length;
        setResultList((oldData) => {
          return [...new Set([...oldData, ...udata])];
        });
        setIsLoading(false);
        setMount(true);
        setIsLoadingMore(false);
        setHasMore(
          result.pageInfo.totalCount > existingRecords + result.pageInfo.limit
        );
        if (!props.isRender) {
          setIsMenuOpen(true);
          setIsFocus(true);
        }
      }
    } else {
      let url = getUrl("load").replace("{SEARCH}", "");
      let data = {
        url: url,
        type: "dynamic",
        method: "GET",
        auth: "token",
        moreHead: { rfv: 2 },
        cType: 4,
      };

      api.call(
        data,
        (response) => {
          if (response) {
            let result = response.data;
            let data = common.getOptionsOnlyData(
              result.items,
              lookup.get,
              lookup.set,
              true
            );

            if (offset == 0) {
              setResultList(data);
            } else {
              setResultList((oldData) => {
                return [...new Set([...oldData, ...data])];
              });
            }
            setMount(true);
            setIsLoadingMore(false);
            setHasMore(result.hasMore);
            if (!props.isRender) {
              setIsMenuOpen(true);
              setIsFocus(true);
            }
          }
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
        },
        (final) => {
          setIsLoading(false);
        }
      );
    }
  };

  // load
  const loadOptions = async (inputValue) => {
    if (
      isEdutechAcc &&
      (["Cattr15", "Cattr40"].includes(field.key) ||
        ["Eattr3", "Eattr4"].includes(field.key))
    ) {
      if (["Cattr15", "Eattr3"].includes(field.key)) {
        let result = await eduTechSerivces.getUniversityCourses(
          "",
          inputValue || "",
          limit,
          0
        );
        let udata = common.getOptionsData(result.data, "name", "_id");
        return udata;
      } else {
        let result = await eduTechSerivces.getAllUniversities(
          inputValue || "",
          limit,
          0
        );
        let udata = common.getOptionsData(result.data, "name", "_id");
        return udata;
      }
    } else {
      let result = await new Promise((resolve, reject) => {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
          setSearch(inputValue);

          var url = getUrl("search").replace("{SEARCH}", inputValue);
          let data = {
            url: url,
            type: "dynamic",
            method: "GET",
            auth: "token",
            moreHead: { rfv: 2 },
            cType: 4,
          };

          api.call(
            data,
            (response) => {
              if (response) {
                let result = response.data;
                let data = common.getOptionsOnlyData(
                  result.items,
                  lookup.get,
                  lookup.set,
                  true
                );
                resolve(data);
              } else {
                resolve([]);
              }
            },
            (error) => {
              resolve([]);
            },
            (final) => {
              resolve([]);
            }
          );
        }, 1000);
      });
      return result;
    }
  };

  // handler
  const onChange = (value) => {
    props.onSelect(value);
  };

  const onInputChange = (value) => {
    if (value === "") {
      setSearch("");
    }
  };

  const onFocus = () => {
    if (!isRender) {
      setIsLoading(true);
      setIsRender(true);
    }
  };

  const loadMore = () => {
    if (hasMore && !search && !isLoadingMore) {
      setIsLoadingMore(true);
      setOffset((prev) => ++prev);
    }
  };

  return isLoading ? (
    <Form.Control
      type="text"
      defaultValue={"Loading..."}
      readOnly={true}
      className="loading-text"
    />
  ) : (
    <AsyncSelect
      ref={ref}
      isMulti={props.isMulti}
      isClearable={true}
      value={value}
      defaultOptions={resultList}
      loadOptions={loadOptions}
      onChange={onChange}
      onFocus={onFocus}
      onInputChange={onInputChange}
      menuIsOpen={isMenuOpen}
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={() => setIsMenuOpen(false)}
      autoFocus={isFocus}
      onMenuScrollToBottom={() => loadMore()}
      isLoading={isLoadingMore}
      isDisabled={props.disabled}
      maxMenuHeight={230}
    />
  );
});

export default AutomationSelect;

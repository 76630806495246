import React, { useEffect, useState, useRef } from "react";
import { Form, Col, Row } from "react-bootstrap";
import Select, { components } from "react-select";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { common, tools, envConfig, api } from "helpers";
import * as Services from "helpers/service";
import DynamicSelect from "./DynamicSelect";
import Conversion from "elements/Conversion";
import lookupList from "assets/files/lookupList.json";
import serviceList from "assets/files/serviceList.json";
import "react-phone-input-2/lib/style.css";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  InputLabel,
  FormControl,
  Hidden,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import InputIcon from "react-multi-date-picker/components/input_icon";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePicker from "react-multi-date-picker";
import PhoneInput from "react-phone-input-2";
import StatusBar from "elements/StatusBar";
import AccessDenied from "./AccessDenied";

function DynamicForm(props) {
  const myRefs = useRef([]);
  const [isConvertModal, setIsConvertModal] = useState(false);
  const [convertData, setConvertData] = useState({});
  const propsDefault = props.default || {};
  const propsDisabled = props.disabled || {};
  const service = serviceList[props.module];
  const authInfo = common.authInfo();
  const [isFormSubmit, setFormSubmit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [data, setData] = useState([]);
  const [fieldTypes] = useState({
    default: [
      "singleLine",
      "multiLine",
      "email",
      "phone",
      "number",
      "decimal",
      "currency",
      "url",
    ],
    bool: ["checkbox"],
    date: ["date", "dateTime"],
    singleSelect: ["pickList", "lookup"],
    multipleSelect: ["multiSelect", "multiLookup"],
    file: ["imageUpload", "fileUpload"],
  });
  const [fieldIndex, setFieldIndex] = useState(null);
  const [status, setStatus] = useState("process");
  const [ids, setIds] = useState(props.data.value1 || "");
  const userPermissions = props.userPermissions
    ? props.userPermissions
    : {
        r: "1",
        a: "1",
        u: "1",
        d: "1",
        i: "1",
        e: "1",
        a: "1",
      };
  const [access, setAccess] = useState(userPermissions);
  let fieldCount = 0;
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);

  // effect
  useEffect(() => {
    setIds(props.data.value1);
    // setData([])
    getColumnsData();
    setStatus("process");
    setAccess(props.userPermissions);
    if (props.data.value1 && props.module == "enquiryMember") {
      checkPaging();
    }
  }, [props]);

  // effect
  useEffect(() => {
    setStatus("process");
    getEditData();
  }, [ids]);

  //Prev&Next Handling
  const goto = (e, type) => {
    e.preventDefault();
    let detailRecords = localStorage.getItem("pgRecords")
      ? JSON.parse(localStorage.getItem("pgRecords"))
      : null;
    if (detailRecords) {
      let cIdx = detailRecords.ids.indexOf(parseInt(ids));

      if (type == "prev") {
        let prevId = detailRecords.ids[cIdx - 1];
        //navigate("/crm/" + props.module + "/details/" + prevId);
        setIds(prevId);
      } else {
        let nextId = detailRecords.ids[cIdx + 1];
        // navigate("/crm/" + props.module + "/details/" + nextId);
        setIds(nextId);
      }
    }
  };

  const checkPaging = () => {
    setDisableNext(false);
    setDisablePrev(false);

    let detailRecords = localStorage.getItem("pgRecords")
      ? JSON.parse(localStorage.getItem("pgRecords"))
      : null;
    if (detailRecords) {
      let cIdx = detailRecords.ids.indexOf(parseInt(ids));

      if (cIdx == 0) {
        setDisablePrev(true);
      } else if (cIdx == detailRecords.ids.length - 1) {
        setDisableNext(true);
      }
      if (cIdx == 0 && detailRecords.total == 1) {
        setDisablePrev(true);
        setDisableNext(true);
      } else if (
        cIdx == 0 &&
        detailRecords.page > 1 &&
        detailRecords.total > detailRecords.ids.length
      ) {
        console.log("df call1");
        callNextRecords(true, detailRecords.url);
      } else if (
        cIdx == detailRecords.limit - 1 &&
        detailRecords.total > detailRecords.ids.length
      ) {
        console.log("df call2");
        callNextRecords(false, detailRecords.url);
      }
    } else {
      setDisableNext(true);
      setDisablePrev(true);
    }
  };

  const callNextRecords = (isPrev, url) => {
    let detailRecords = localStorage.getItem("pgRecords")
      ? JSON.parse(localStorage.getItem("pgRecords"))
      : null;
    if (url) {
      let firstUrlStr = url.substring(0, url.indexOf("offset=") + 7);
      let lasUrlStr = url.substring(url.indexOf("&orderBy"), url.length);
      let offset =
        parseInt(detailRecords.page - 1) * parseInt(detailRecords.limit);

      if (isPrev) {
        offset =
          parseInt(detailRecords.page - 2) * parseInt(detailRecords.limit);
      } else {
        offset = parseInt(detailRecords.page) * parseInt(detailRecords.limit);
      }

      let fUrl = firstUrlStr + offset + lasUrlStr;
      fUrl = fUrl + "&fields=" + service.id;
      let data = {
        url: fUrl,
        type: "dynamic",
        method: "GET",
        auth: "token",
        moreHead: { rfv: 2 },
        cType: 4,
      };
      api.call(
        data,
        (response) => {
          if (response.status == 200) {
            const ids = response.data.items.map(function (data) {
              return data[service.id];
            });

            if (detailRecords) {
              let old = detailRecords.ids;
              const array3 = isPrev ? [...ids, ...old] : [...old, ...ids];
              if (props?.module == "enquiryMember") {
                localStorage.setItem(
                  "pgRecords",
                  JSON.stringify({
                    url: detailRecords.url,
                    ids: array3,
                    total: response.data.totalResults,
                    page: detailRecords.page,
                    limit: detailRecords.limit,
                  })
                );
              }
              setTimeout(() => {
                checkPaging();
              }, 3000);
            }
          }
        },
        (error) => {
          setStatus("error");
        },
        (final) => {}
      );
    }
  };

  const getColumnsData = () => {
    let filter = `?q=EserviceCode=${service.code}`;
    let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          let formFields = JSON.parse(response.data.items[0].FormFields);
          if (props.data.value1) {
            setData(formFields.form);
            getEditData();
          } else {
            setData(setFormData(formFields.form));
          }
          if (ids == "") {
            setStatus("success");
          }
        } else {
          setStatus("error");
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  // edit
  const getEditData = () => {
    let url = envConfig.BASE_API + Services[service.api] + "/" + ids;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          setConvertData(response.data);
          // set value
          setData((oldData) => {
            let newData = reParse(oldData);
            newData.fields &&
              newData.fields.length > 0 &&
              newData.fields.map((item) => {
                let value = response.data[item.key];
                value = value === null ? "" : value;
                if (item.type === "pickList") {
                  value = value ? { label: value, value: value } : value;
                } else if (item.type === "lookup") {
                  value = value
                    ? {
                        label: response.data[`${item.key}Desc`] || value,
                        value: String(value),
                      }
                    : value;
                } else if (
                  item.type === "multiSelect" ||
                  item.type === "multiLookup"
                ) {
                  //ToDo - Reminder. if you add more Multilookup fields, please check form fields json structure
                  if (item.display) {
                    let dField = item.display;
                    if (
                      dField.storeType == "multiple" &&
                      response.data[dField.name]
                    ) {
                      let nameList = response.data[dField.name].split(",");
                      value = value
                        ? value.split(",").map((i, idx) => ({
                            label: nameList[idx],
                            value: i,
                          }))
                        : "";
                    }
                  } else {
                    value = value
                      ? value.split(",").map((i) => ({ label: i, value: i }))
                      : "";
                  }
                } else if (item.type === "currency") {
                  value = tools
                    .onlyFloat(String(value))
                    .replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
                }
                // default
                if (!value && item.key in propsDefault) {
                  value = propsDefault[item.key];
                }
                item.value = value;
                // disabled
                if (item.key in propsDisabled) {
                  item.disabled = propsDisabled[item.key];
                }
              });
            return newData;
          });
          setTimeout(() => {
            setStatus("success");
          }, 500);
          if (props.module == "enquiryMember") {
            checkPaging();
          }
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const fillData = (field) => {
    if (field.value !== "" && field.attr.fill !== null) {
      if (field.attr.fill.type === "api") {
        let lookup = lookupList[field.attr.lookup];
        let url = `${envConfig.BASE_API}${Services[lookup.name]}?q=${
          lookup.set
        }=${field.value.value}`;
        let data = {
          url: url,
          type: "dynamic",
          method: "GET",
          auth: "token",
        };
        api.call(
          data,
          (response) => {
            if (response) {
              let result = response.data.items[0];
              setData((oldData) => {
                let newData = reParse(oldData);
                newData.fields.map((item, key) => {
                  if (item.key in field.attr.fill.params) {
                    let value = result[field.attr.fill.params[item.key]];
                    if (item.type === "pickList" && value) {
                      item.value = { label: value, value: value };
                    } else {
                      item.value = value;
                    }
                  }
                });
                return newData;
              });
            }
          },
          (error) => {},
          (final) => {}
        );
      }
    }
  };

  // forms
  const setFormData = (form) => {
    form &&
      form.fields &&
      form.fields.length > 0 &&
      form.fields.map((item) => {
        // default
        if (item.key in propsDefault) {
          item.value = propsDefault[item.key];
        }
        // disabled
        if (item.key in propsDisabled) {
          item.disabled = propsDisabled[item.key];
        }
      });
    return form;
  };

  const getFormFields = (sKey) => {
    let nData = reParse(data);
    let sId = data.sections[sKey].id;
    let quickList = [true, false];
    if (props.quick) {
      quickList = [true];
    }
    return nData.fields.filter(
      (item) =>
        item.layout === sId &&
        item.status === "use" &&
        item.visibility &&
        item.store &&
        quickList.includes(item.quick)
    );
  };

  // handler
  const onFieldChange = (e, index) => {
    let newData = reParse(data);

    let field = newData.fields[index];
    let value = "";
    if (fieldTypes.default.includes(field.type) && field.type == "phone") {
      value = e;
    } else if (fieldTypes.default.includes(field.type)) {
      value = e.target.value;
    } else if (fieldTypes.bool.includes(field.type)) {
      value = e.target.checked ? "Y" : "N";
    } else if (fieldTypes.date.includes(field.type)) {
      value = e?.toDate();
    } else if (fieldTypes.singleSelect.includes(field.type)) {
      value = e ? e : "";
    } else if (fieldTypes.multipleSelect.includes(field.type)) {
      value = e ? e : "";
    }
    value = validateType(index, value);
    field.error = validateField(index, value);
    field.value = value;
    field.touched = true;
    setData(newData);
    setFieldIndex(index);
    fillData(field);
  };

  const onImageChange = (e, index) => {
    let file = "";
    if (e.target.files) {
      file = e.target.files[0];
      if (!file) {
        file = "";
      }
      let newData = reParse(data);
      let field = newData.fields[index];
      field.value = file;
      field.error = validateField(index, file);
      field.touched = true;
      setData(newData);
    }
  };

  const onFileChange = (e, index) => {};

  const onCancel = () => {
    if (typeof props.onCancel !== "undefined") {
      props.onCancel();
    }
  };

  // submit handler
  const onSubmit = () => {
    setFormSubmit(true);
    let allow = validateForm();
    if (allow) {
      setIsSubmit(true);
      // fields
      let fields = {
        TenantId: authInfo.TenantId,
        OrgId: authInfo.DefaultOrgId,
      };
      data.fields
        .filter((i) => i.store === true)
        .map((item) => {
          let value = item.value;
          if (item.props !== "") {
            value = props.data[item.props];
          } else if (fieldTypes.singleSelect.includes(item.type)) {
            value = value ? value.value : "";
          } else if (fieldTypes.multipleSelect.includes(item.type)) {
            value = value ? value.map((i) => i.value).join(",") : "";
          } else if (fieldTypes.date.includes(item.type)) {
            value = value
              ? common.formatDate(value, "YYYY-MM-DDTHH:mm:ssZ")
              : null;
          } else if (item.type === "currency") {
            value = String(value).replace(/,/g, "");
          }
          fields[item.key] = value;
        });

      // create
      if (!props.data.value1) {
        let url = envConfig.BASE_API + Services[service.api];
        let data = {
          url: url,
          type: "dynamic",
          method: "POST",
          auth: "token",
          body: JSON.stringify(fields),
          cType: 4,
        };
        // return
        api.call(
          data,
          (response) => {
            if (response.status == 201) {
              common.snack("S", "Successfully Created");
              closeForm(response.data[service.id], response.data);
              setIsSubmit(false);
            } else {
              common.snack("W", "Campaign Name already exists!");
              setIsSubmit(false);
            }
          },
          (error) => {
            setIsSubmit(false);
          },
          (final) => {}
        );
      } else {
        let url = envConfig.BASE_API + Services[service.api] + "/" + ids;
        let data = {
          url: url,
          type: "dynamic",
          method: "PATCH",
          auth: "token",
          body: JSON.stringify(fields),
          cType: 4,
        };
        api.call(
          data,
          (response) => {
            if (response.status == 200) {
              common.snack("S", "Successfully Updated");
              closeForm(response.data[service.id], response.data);
              window.scrollTo(0, 0);
              setIsSubmit(false);
            }
          },
          (error) => {
            setIsSubmit(false);
          },
          (final) => {}
        );
      }
    }
  };

  // validate
  const validateForm = () => {
    let allow = true;
    let focus = true;
    let newData = reParse(data);
    newData.fields.map((item, index) => {
      let error = "";
      if (checkFieldAccess(item) == "rw") {
        let fvalue = "";
        if (fieldTypes.default.includes(item.type) && item.type == "phone") {
          fvalue = item.value;
        } else if (fieldTypes.default.includes(item.type)) {
          fvalue = item.value;
        } else if (fieldTypes.bool.includes(item.type)) {
          fvalue = item.value;
        } else if (fieldTypes.date.includes(item.type)) {
          fvalue = item.value;
        } else if (fieldTypes.singleSelect.includes(item.type)) {
          fvalue = item.value?.value || "";
        } else if (fieldTypes.multipleSelect.includes(item.type)) {
          fvalue = item.value?.value || "";
        }
        if (fvalue) {
          fvalue = validateType(index, fvalue);
        }

        if (item.required && !fvalue) {
          error = `${item.label} is required`;
          allow = false;
        } else if (
          item.required &&
          item.type === "email" &&
          fvalue &&
          !tools.ValidateEmail(fvalue)
        ) {
          error = `Please enter a valid email for ${item.label}`;
          allow = false;
        }
        if (error !== "" && focus) {
          try {
            if (fieldTypes.date.includes(item.type)) {
              myRefs.current[item.index].setFocus(true);
            } else {
              myRefs.current[item.index].focus();
            }
          } catch (e) {
            //alert(e);
          }
          focus = false;
        }
      }
      item.error = error;
    });

    if (!allow) {
      setData(newData);
    }
    return allow;
  };

  const validateField = (index, value) => {
    let error = "";
    if (isFormSubmit) {
      let newData = reParse(data);
      let field = newData.fields[index];
      if (field.required && (value === "" || value === null)) {
        error = `${field.label} is required`;
      } else if (
        field.type === "email" &&
        value !== "" &&
        !tools.ValidateEmail(value)
      ) {
        error = `Please enter a valid email`;
      }
    }
    return error;
  };

  const validateType = (index, value) => {
    let newData = reParse(data);
    let field = newData.fields[index];
    if (field.type === "number" || field.type === "phone") {
      return tools.onlyNumbers(value);
    } else if (field.type === "decimal") {
      return tools.onlyFloat(value);
    } else if (field.type == "currency") {
      return tools.onlyFloat(value).replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
    }
    return value;
  };

  const reParse = (data) => {
    return JSON.parse(JSON.stringify(data));
  };

  const labelValue = (data) => {
    if (typeof data == "object") {
      return data ? data.map((item) => ({ label: item, value: item })) : "";
    } else {
      return data ? { label: data, value: data } : "";
    }
  };

  const closeForm = (id, data) => {
    let ids = id ? id : props.data.value1;
    props.closeForm(false, "id", data, ids);
  };

  //Check conversion status and show popup if not converted
  const convert = () => {
    const isEduTech = common.isEdutechAcc();
    if (
      convertData.OpportunityConverted == null ||
      convertData.OpportunityConverted == "N"
    ) {
      if (isEduTech) {
        if (
          convertData.LeadStatus == "Potential Student" &&
          convertData.Cattr25 != "Y" &&
          convertData.Cattr35 == null
        ) {
          showDealInfo();
        } else {
          common.snack("E", "Potential Student only convertable to Deal");
        }
      } else {
        showDealInfo();
      }
    } else {
      common.snack("E", "Lead already converted as Deal");
    }
  };

  //Open conversion popup
  const showDealInfo = () => {
    setIsConvertModal(true);
  };

  //close conversion popup
  const closeModal = () => {
    setIsConvertModal(false);
  };

  //update conversion status
  const conversionUpdate = () => {
    let newFields = common.reParse(convertData);
    newFields.OpportunityConverted = "Y";
    setConvertData(newFields);
  };

  // Floating React select
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  // Select box style
  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    container: (provided, state) => ({
      ...provided,
      marginTop: 0,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: "visible",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue ? -15 : "10%",
      transition: "top 0.1s, font-size 0.1s",
      fontSize: state.hasValue || state.selectProps.inputValue ? 11 : 13,
      color: "#9daabb",
    }),
  };

  const permissionView = () => {
    if (["lead", "deal"].includes(props.module)) {
      if (ids > 0 && userPermissions.u != "2") {
        let empIds = [];
        if (userPermissions.u == "1") {
          return false;
        }
        if (userPermissions.u == "3") {
          empIds.push(authInfo.EmpId);
        } else if (userPermissions.u == "4") {
          empIds = authInfo.employeeList.active;
        } else if (userPermissions.u == "5") {
          empIds = authInfo.employeeList.sub;
        }

        if (
          props.module == "lead" &&
          empIds.includes(convertData.SalespersonId)
        ) {
          return false;
        } else if (
          props.module == "deal" &&
          empIds.includes(convertData.SalesPersonId)
        ) {
          return false;
        } else {
          return true;
        }
      }

      if (ids == "" && userPermissions.a != "2") return false;
      else return true;
    } else {
      //edit
      if (ids) {
        if (props?.accUpdate !== undefined) {
          return props?.accUpdate;
        } else {
          return false;
        }
      } else {
        if (props?.accAdd !== undefined) {
          return props?.accAdd;
        } else {
          return false;
        }
      }
    }
  };

  const checkFieldAccess = (field) => {
    if (authInfo.roleId == 0) return "rw"; //ADMIN Access
    if (field?.pspecific == true) {
      if (field?.permission?.length > 0) {
        let fAccess = field.permission.filter(
          (e) => e.groupId == authInfo.roleId
        );
        return fAccess.length > 0 ? fAccess[0].access : field?.ptype || "rw";
      } else {
        return field?.ptype || "rw";
      }
    } else {
      return field?.ptype || "rw";
    }
  };

  const renderReadOnlyField = (item) => {
    let value = item.value;
    if (item.type === "pickList") {
      value = item.value?.label || "";
    } else if (item.type === "lookup") {
      value = item.value?.label || "";
    } else if (item.type === "multiSelect" || item.type === "multiLookup") {
      // if (item.display) {
      //   let dField = item.display;
      //   if (
      //     dField.storeType == "multiple" &&
      //     response.data[dField.name]
      //   ) {
      //     value = item.value ? item.value.split(",").map((i, idx) => i) : "";
      //   }
      // } else {
      value = item.value ? item.value.split(",").map((i) => i) : "";
      // }
    } else if (item.type === "date") {
      value = item.value ? moment(item.value).format("YYYY-MM-DD") : "-";
    } else if (item.type == "dateTime") {
      value = item.value
        ? moment(item.value).format("MM/DD/YYYY hh:mm A")
        : "-";
    }
    return value;
  };

  // render
  return (
    <Box>
      <Paper
        variant={!props.quick ? "contained dynamic-form-wrap" : null}
        square={!props.quick ? true : false}
      >
        {!props.quick ? (
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className="dynamic-form-header"
          >
            <Grid item className="detail-head">
              <Grid
                container
                alignItems="center"
                spacing={2}
                className="detail-head"
              >
                <Grid item>
                  <Box py={1} px={1} className="icon-back icon-buton">
                    <IconButton onClick={(e) => closeForm()}>
                      <img
                        src={common.loadImg("backArrowKanban.svg")}
                        alt="back"
                      />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item className="df-main-head">
                  <Typography className="detail-title">
                    {props.data.value1 ? "Update Form" : "Add Form"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {status == "success" &&
              props?.module == "enquiryMember" &&
              props.data.value1 &&
              !permissionView() && (
                <Grid item>
                  <Box px={2}>
                    <Grid
                      container
                      alignItems="center"
                      spacing={2}
                      sx={{
                        justifyContent: { xs: "center", lg: "start" },
                      }}
                    >
                      <Grid item>
                        <Box
                          className={
                            disablePrev
                              ? "disable-campaign-box safari-style"
                              : "active-campaign-box safari-style"
                          }
                        >
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            className="pointer safari-style"
                            onClick={(e) => goto(e, "prev")}
                          >
                            <Grid item>
                              <img
                                src={common.loadImg("arrowBackKanban.svg")}
                                alt="back"
                              />
                            </Grid>
                            <Grid item>
                              <Typography className="pre-text">Prev</Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Hidden smDown>
                          <Box className="verical-divider">
                            <Divider orientation="vertical" />
                          </Box>
                        </Hidden>
                      </Grid>
                      <Grid item>
                        <Box
                          className={
                            disableNext
                              ? "disable-campaign-box safari-style"
                              : "active-campaign-box safari-style"
                          }
                        >
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            className="pointer safari-style"
                            onClick={(e) => goto(e, "next")}
                          >
                            <Grid item>
                              <Typography className="pre-text">Next</Typography>
                            </Grid>
                            <Grid item>
                              <img
                                src={common.loadImg("arrowForwardKanban.svg")}
                                alt="back"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}

            {status == "success" && !permissionView() && (
              <Grid item className="safari-style">
                <Box px={2} className="safari-style">
                  <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    className="safari-style"
                  >
                    <Grid item>
                      <Box className="verical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Grid>
                    {props.data.value1 &&
                      props.module == "lead" &&
                      convertData.OpportunityConverted != "Y" && (
                        <Grid item>
                          <Box>
                            <Button
                              className="save-note"
                              type="submit"
                              onClick={() => convert()}
                            >
                              {"Convert to Deal"}
                            </Button>
                          </Box>
                        </Grid>
                      )}
                    {props.data.value1 &&
                      props.module == "lead" &&
                      convertData.OpportunityConverted != "Y" && (
                        <Grid item>
                          <Box className="vertical-divider">
                            <Divider orientation="vertical" />
                          </Box>
                        </Grid>
                      )}
                    {/* <Grid item>
                      <Box>
                        <Button
                          className="save-note"
                          type="submit"
                          onClick={() => onSubmit()}
                          disabled={isSubmit ? true : false}
                        >
                          {isSubmit
                            ? "Processing..."
                            : props.data.value1
                            ? "Update Changes"
                            : "Save Changes"}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="vertical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="revert-close">
                        <Button
                          className="save-note pe-0"
                          onClick={(e) => closeForm()}
                        >
                          Revert and Close
                          <img
                            src={common.loadImg("closeKanban.svg")}
                            alt="close"
                          />
                        </Button>
                      </Box>
                    </Grid> */}
                  </Grid>
                </Box>
              </Grid>
            )}
          </Grid>
        ) : null}
        <Box>
          <Divider />
        </Box>
        <StatusBar status={status} />
        {status == "success" && permissionView() ? (
          <AccessDenied />
        ) : (
          status == "success" &&
          data &&
          data.sections &&
          Object.keys(data.sections).length > 0 &&
          Object.keys(data.sections).map((sItem, sKey) => {
            let sData = data.sections[sItem];
            let fields = getFormFields(sKey);
            if (fields.length > 0) {
              ++fieldCount;
              return (
                <Box p={2} px={5} className="form-wrap" key={sKey}>
                  <Typography className="info-title dynamic-form-title mt-3">
                    {sData.label}
                  </Typography>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      {fields.map((fItem, fkey) => {
                        let fAccess = checkFieldAccess(fItem);
                        return fAccess === "rw" ? (
                          <Col
                            xs={6}
                            className={
                              fItem.required
                                ? "body-th common-input-style mandatoryclass"
                                : "body-th common-input-style"
                            }
                            key={fkey}
                          >
                            {fItem.type == "singleLine" && (
                              <Box className="filled-box">
                                {/* {fItem.required && (
                                    <span className="mf-required">*</span>
                                  )} */}
                                <TextField
                                  id="filled-basic"
                                  label={fItem.label}
                                  variant="filled"
                                  // placeholder={`Enter ${fItem.label}`}
                                  inputprops={{
                                    maxLength: fItem.attr.maxChar,
                                  }}
                                  value={fItem.value}
                                  onChange={(e) =>
                                    onFieldChange(e, fItem.index)
                                  }
                                  ref={(el) =>
                                    (myRefs.current[fItem.index] = el)
                                  }
                                />
                              </Box>
                            )}
                            {fItem.type == "multiLine" && (
                              <Box>
                                <div className="form-floating">
                                  <textarea
                                    className="form-control textarea"
                                    id="floatingTextarea"
                                    maxLength={fItem.attr.maxChar}
                                    value={fItem.value}
                                    onChange={(e) =>
                                      onFieldChange(e, fItem.index)
                                    }
                                    ref={(el) =>
                                      (myRefs.current[fItem.index] = el)
                                    }
                                  ></textarea>
                                  <label
                                    htmlFor="floatingTextarea"
                                    className="floating-label"
                                  >
                                    {fItem.label}
                                  </label>
                                </div>
                              </Box>
                            )}
                            {/* {fItem.type == "email" && (
                              <Box className="filled-box">
                                <TextField
                                  id="filled-basic"
                                  label={fItem.label}
                                  variant="filled"
                                  maxLength={fItem.attr.maxChar}
                                  value={fItem.value}
                                  onChange={(e) =>
                                    onFieldChange(e, fItem.index)
                                  }
                                  ref={(el) =>
                                    (myRefs.current[fItem.index] = el)
                                  }
                                />
                              </Box>
                            )} */}
                            {fItem.type == "phone" && (
                              <Box className="text-box">
                                <label className="text-label ml-2 mt-1">
                                  {fItem.label}
                                </label>
                                <PhoneInput
                                  country={"lk"}
                                  className="ml-3"
                                  value={fItem.value}
                                  onChange={(e) =>
                                    onFieldChange(e, fItem.index)
                                  }
                                  ref={(el) =>
                                    (myRefs.current[fItem.index] = el)
                                  }
                                />
                              </Box>
                            )}
                            {fItem.type == "pickList" && (
                              <Box className="">
                                <FormControl
                                  fullWidth
                                  className="select-float-label common-padding-zero-select"
                                >
                                  {/* <InputLabel
                                    id="select"
                                    className="floating-label"
                                  >
                                    {fItem.label}
                                  </InputLabel> */}
                                  <Select
                                    className="text-box custom-react-select"
                                    labelId="select"
                                    id="select"
                                    label={fItem.label}
                                    menuPortalTarget={document.body}
                                    value={
                                      !fItem.touched && !fItem.value
                                        ? labelValue(fItem.attr.optionDefault)
                                        : fItem.value
                                    }
                                    isClearable={true}
                                    options={labelValue(fItem.attr.option)}
                                    onChange={(data) =>
                                      onFieldChange(data, fItem.index)
                                    }
                                    ref={(el) =>
                                      (myRefs.current[fItem.index] = el)
                                    }
                                    components={{
                                      ValueContainer: CustomValueContainer,
                                    }}
                                    placeholder={fItem.label}
                                    styles={selectStyles}
                                  ></Select>
                                </FormControl>
                              </Box>
                            )}
                            {fItem.type == "multiSelect" && (
                              <Box className="text-box custom-select">
                                <FormControl
                                  fullWidth
                                  className="select-float-label common-padding-zero-select"
                                >
                                  <InputLabel
                                    id="select"
                                    className="floating-label"
                                  >
                                    {fItem.label}
                                  </InputLabel>
                                  <Select
                                    labelId="select"
                                    id="select"
                                    label={fItem.label}
                                    menuPortalTarget={document.body}
                                    value={
                                      !fItem.touched && !fItem.value
                                        ? labelValue(fItem.attr.optionDefault)
                                        : fItem.value
                                    }
                                    isClearable={true}
                                    options={labelValue(fItem.attr.option)}
                                    onChange={(data) =>
                                      onFieldChange(data, fItem.index)
                                    }
                                    ref={(el) =>
                                      (myRefs.current[fItem.index] = el)
                                    }
                                    components={{
                                      ValueContainer: CustomValueContainer,
                                    }}
                                    placeholder={fItem.label}
                                    styles={selectStyles}
                                  ></Select>
                                </FormControl>
                              </Box>
                            )}
                            {fItem.type == "date" && (
                              <Box className="text-box" id="float-label1">
                                <label
                                  className={
                                    fItem.value
                                      ? "Active text-label ml-4"
                                      : "text-label ml-4 camp-time-label"
                                  }
                                >
                                  {fItem.label}
                                </label>

                                <Box className="ml-1 input-date-picker cust-br">
                                  <DatePicker
                                    format="MM/DD/YYYY"
                                    render={<InputIcon />}
                                    placeholder="Select Date"
                                    value={
                                      fItem.value
                                        ? moment(fItem.value).toDate()
                                        : ""
                                    }
                                    onChange={(date) =>
                                      onFieldChange(date, fItem.index)
                                    }
                                    ref={(el) =>
                                      (myRefs.current[fItem.index] = el)
                                    }
                                  />
                                </Box>
                              </Box>
                            )}
                            {fItem.type == "email" && (
                              <Box className="filled-box">
                                <TextField
                                  id="filled-basic"
                                  label={fItem.label}
                                  variant="filled"
                                  inputprops={{
                                    maxLength: fItem.attr.maxChar,
                                  }}
                                  value={fItem.value}
                                  onChange={(e) =>
                                    onFieldChange(e, fItem.index)
                                  }
                                  ref={(el) =>
                                    (myRefs.current[fItem.index] = el)
                                  }
                                />
                              </Box>
                            )}
                            {fItem.type == "dateTime" && (
                              <Box className="text-box" id="float-label">
                                <label
                                  // className={
                                  //   isActive1
                                  //     ? "Active text-label ml-4"
                                  //     : "text-label ml-4 camp-time-label"
                                  // }
                                  className={
                                    fItem.value
                                      ? "Active text-label camp-time-label ml-4"
                                      : "text-label ml-4"
                                  }
                                >
                                  {fItem.label}
                                </label>
                                <Box className="ml-1 input-date-picker cust-br">
                                  <DatePicker
                                    format="MM/DD/YYYY hh:mm A"
                                    plugins={[
                                      <TimePicker
                                        hideSeconds
                                        position="bottom"
                                        className="ml-3"
                                      />,
                                    ]}
                                    render={<InputIcon />}
                                    // placeholder="Select Date"
                                    value={
                                      fItem.value
                                        ? moment(fItem.value).toDate()
                                        : ""
                                    }
                                    onChange={(date) =>
                                      onFieldChange(date, fItem.index)
                                    }
                                    ref={(el) =>
                                      (myRefs.current[fItem.index] = el)
                                    }
                                  />
                                </Box>
                              </Box>
                            )}
                            {fItem.type == "number" && (
                              <Box className="filled-box">
                                <TextField
                                  id="filled-basic"
                                  label={fItem.label}
                                  variant="filled"
                                  // placeholder={`Enter ${fItem.label}`}
                                  inputprops={{
                                    maxLength: fItem.attr.maxChar,
                                  }}
                                  value={fItem.value}
                                  onChange={(e) =>
                                    onFieldChange(e, fItem.index)
                                  }
                                  ref={(el) =>
                                    (myRefs.current[fItem.index] = el)
                                  }
                                />
                              </Box>
                            )}
                            {fItem.type == "decimal" && (
                              <Box className="filled-box">
                                <TextField
                                  id="filled-basic"
                                  label={fItem.label}
                                  variant="filled"
                                  inputprops={{
                                    maxLength: fItem.attr.maxChar,
                                  }}
                                  value={fItem.value}
                                  onChange={(e) =>
                                    onFieldChange(e, fItem.index)
                                  }
                                  ref={(el) =>
                                    (myRefs.current[fItem.index] = el)
                                  }
                                />
                              </Box>
                            )}
                            {fItem.type == "currency" && (
                              <Box className="filled-box">
                                <TextField
                                  id="filled-basic"
                                  label={fItem.label}
                                  variant="filled"
                                  inputprops={{
                                    maxLength: fItem.attr.maxChar,
                                  }}
                                  value={fItem.value}
                                  onChange={(e) =>
                                    onFieldChange(e, fItem.index)
                                  }
                                  ref={(el) =>
                                    (myRefs.current[fItem.index] = el)
                                  }
                                />
                              </Box>
                            )}
                            {fItem.type == "checkbox" && (
                              <FormControlLabel
                                className="dynamic-checkbox ms-0 checkbox-space"
                                control={<Checkbox />}
                                label={fItem.label}
                                value={fItem.value}
                                labelPlacement="end"
                                checked={
                                  !fItem.value
                                    ? fItem.attr.defaultChecked
                                    : fItem.value === "Y"
                                    ? true
                                    : false
                                }
                                onChange={(e) => onFieldChange(e, fItem.index)}
                                ref={(el) => (myRefs.current[fItem.index] = el)}
                                disabled={fItem.disabled}
                              />
                            )}
                            {fItem.type == "url" && (
                              <Box className="filled-box">
                                <TextField
                                  id="filled-basic"
                                  label={fItem.label}
                                  variant="filled"
                                  placeholder={`Enter ${fItem.label}`}
                                  inputprops={{
                                    maxLength: fItem.attr.maxChar,
                                  }}
                                  value={fItem.value}
                                  onChange={(e) =>
                                    onFieldChange(e, fItem.index)
                                  }
                                  ref={(el) =>
                                    (myRefs.current[fItem.index] = el)
                                  }
                                />
                              </Box>
                            )}
                            {fItem.type == "lookup" && (
                              <DynamicSelect
                                isRender={true}
                                field={fItem}
                                fieldIndex={fieldIndex}
                                data={data}
                                params={props}
                                module={props.module}
                                userPermissions={access}
                                recordId={ids}
                                onSelect={(data) =>
                                  onFieldChange(data, fItem.index)
                                }
                                ref={(el) => (myRefs.current[fItem.index] = el)}
                                disabled={fItem.disabled}
                              />
                            )}
                            {fItem.type == "multiLookup" && (
                              <DynamicSelect
                                isMulti={true}
                                isRender={true}
                                field={fItem}
                                fieldIndex={fieldIndex}
                                data={data}
                                params={props}
                                module={props.module}
                                userPermissions={access}
                                onSelect={(data) =>
                                  onFieldChange(data, fItem.index)
                                }
                                ref={(el) => (myRefs.current[fItem.index] = el)}
                              />
                            )}
                            {fItem.type == "fileUpload" && (
                              <Form.Control
                                type="file"
                                onChange={(e) => onFileChange(e, fItem.index)}
                                ref={(el) => (myRefs.current[fItem.index] = el)}
                              />
                            )}
                            {fItem.type == "imageUpload" && (
                              <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={(e) => onImageChange(e, fItem.index)}
                                ref={(el) => (myRefs.current[fItem.index] = el)}
                              />
                            )}
                            {isFormSubmit && fItem.error !== "" ? (
                              <div style={{ height: "6px" }}></div>
                            ) : (
                              <div style={{ height: "0px" }}></div>
                            )}
                            {isFormSubmit && fItem.error !== "" ? (
                              <Form.Text
                                className={
                                  isFormSubmit && fItem.error !== ""
                                    ? "error-txt show"
                                    : ""
                                }
                              >
                                {fItem.error}
                              </Form.Text>
                            ) : (
                              <Form.Text className="error-txt"></Form.Text>
                            )}
                          </Col>
                        ) : (
                          fAccess === "r" && (
                            <Col
                              xs={6}
                              className={"body-th common-input-style"}
                              key={fkey}
                            >
                              <Box>
                                <div className="add-field-permis">
                                  {fItem.label || "-"}
                                </div>
                                <div className="add-field-crm-perm mt-2">
                                  {renderReadOnlyField(fItem) || "-"}
                                </div>
                              </Box>
                            </Col>
                          )
                        );
                      })}
                    </Row>
                  </Box>
                </Box>
              );
            }
          })
        )}

        {isConvertModal && (
          <Dialog
            open={isConvertModal}
            // onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"sm"}
            fullWidth={true}
            className="target-modal-wrap add-action-popup attachment-popup"
            disableScrollLock
          >
            <DialogTitle
              id="responsive-dialog-title"
              className="popup-title py-2"
            >
              Convert to Deal
            </DialogTitle>
            <DialogContent>
              <Conversion
                leadData={convertData}
                closeModal={closeModal}
                successUpdate={conversionUpdate}
              />
            </DialogContent>
          </Dialog>
        )}
        <Box>
          {props.quick && status === "success" ? (
            <Box p={2}>
              <Button
                className="save-note"
                type="submit"
                onClick={() => onSubmit()}
                disabled={isSubmit ? true : false}
              >
                {isSubmit
                  ? "Processing..."
                  : props.data.value1
                  ? "Update Changes"
                  : "Save Changes"}
              </Button>
              <Button
                className="automation-cancel-btn"
                onClick={onCancel}
                type="button"
              >
                Cancel
              </Button>
            </Box>
          ) : null}
        </Box>
      </Paper>
      {status == "success" && !permissionView() && (
        <div className="fixed-foot-wrap pt-3">
          <Box>
            <Button
              className="save-note fixed-width-btn"
              type="submit"
              onClick={() => onSubmit()}
              disabled={isSubmit ? true : false}
            >
              {isSubmit
                ? "Processing..."
                : props.data.value1
                ? "Update Changes"
                : "Save Changes"}
            </Button>
          </Box>
          <Box className="revert-close">
            <Button
              className="save-note fixed-width-btn pe-0"
              onClick={(e) => closeForm()}
            >
              Revert and Close
              <img src={common.loadImg("closeKanban.svg")} alt="close" />
            </Button>
          </Box>
        </div>
      )}
    </Box>
  );
}

export default DynamicForm;

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { common } from "helpers";
import AmlHashForm from "aml-hash-form";
import { api } from "helpers/api";
let isPickauniDomain = window.location.hostname == "uams.pickauni.com";
function Signin() {
  const navigate = useNavigate();
  const formFields = {
    email: {
      validate: [
        "req#Email is required",
        "email#Please enter a valid email address",
      ],
    },
    password: {
      validate: [
        "req#Password is required!",
        "min:6#Password should be minimum 6 Digits",
        "max:16#Password should be maximum 16 Digits",
      ],
    },
  };

  const { fields, errors, setMultiValue, handleChange, handleSubmit } =
    AmlHashForm(formFields);
  const [isLoader, setIsLoader] = useState(false);

  const [isRememberMe, setIsRememberMe] = useState(
    common.localGet("orm") ? common.localGet("orm") : false
  );

  const [isAutoLogin, setIsAutoLogin] = useState(false);

  //Submit login
  const onSubmit = (e) => {
    e.preventDefault();
    login();
  };

  const login = (_) => {
    let isValid = handleSubmit();
    if (isValid) {
      setIsLoader(true);
      let authString = `${fields.email.toLowerCase()}:${fields.password}`;
      let data = {
        url: "login",
        method: "POST",
        auth: "basic",
        body: {
          ExpandUserInfo: true,
        },
        cType: 1,
        credentials: btoa(authString),
      };

      let loginInfo = {
        userName: fields.email.toLowerCase(),
        password: fields.password,
      };

      api.call(
        data,
        (res) => {
          if (res.status === 200) {
            if (isRememberMe) {
              common.localSet("orm", isRememberMe);
              common.localSet("oui", common.crypt(loginInfo, true));
            }
            res.data.basicToken = btoa(authString);
            localStorage.setItem("userToken", res.data.token);
            common.localSet("userData", common.crypt(res.data, true));
            setTimeout(() => {
              // window.location.href = common.url("dashboard/index");
              window.location.href = common.url("crm/lead/list");
            }, 500);
          } else {
            common.snack("E", "Please enter the valid credentials");
          }
        },
        (error) => {
          common.snack("E", "Please enter the valid credentials");
          setIsLoader(false);
        },
        (final) => {
          setIsLoader(false);
        }
      );
    }
  };

  //Goto forgot password page
  const goto = (e) => {
    e.preventDefault();
    navigate("/forgot/password");
  };

  // Check if remember me checked or not
  const getRememberMe = (e) => {
    setIsRememberMe(e.target.checked);
  };

  useEffect(() => {
    if (isRememberMe) {
      let user = common.localGet("oui") ? common.localGet("oui") : "";
      let userInfo = JSON.parse(common.deCrypt(user));
      setMultiValue({
        email: userInfo.userName,
        password: userInfo.password,
      });
      setIsAutoLogin(true);
    }
  }, []);

  useEffect(() => {
    if (isAutoLogin) {
      login();
    }
  }, [isAutoLogin]);

  return (
    <Paper className="login-card" elevation={3}>
      <Container maxWidth="md">
        <Box p={2} px={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}>
          <img
            className={isPickauniDomain ? "login-logo" : ""}
            src={
              isPickauniDomain
                ? common.loadImg("pickauni-logo-white.png")
                : common.loadImg("login_logo.svg")
            }
            alt="logo"
          />
        </Box>
      </Container>
      <Divider />
      <form onSubmit={(e) => onSubmit(e)}>
        <Container maxWidth="md">
          <Box px={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 8 }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box mt={2}>
                  <Typography className="login-title">Welcome,</Typography>
                  <Typography className="login-title">
                    Login to Your Account
                  </Typography>
                </Box>
              </Grid>
              <Grid item className="sign-in-text">
                <TextField
                  name="email"
                  id="email"
                  type="text"
                  variant="outlined"
                  placeholder="Enter your email"
                  fullWidth
                  autoComplete="nope"
                  onChange={handleChange}
                  value={fields.email}
                  InputProps={{
                    endadornment: (
                      <InputAdornment position="end">
                        <img
                          src={common.loadImg("inputTick.svg")}
                          alt="verify_icon"
                        />
                      </InputAdornment>
                    ),
                  }}
                  endadornment={
                    <InputAdornment position="end"></InputAdornment>
                  }
                />
                {fields.email != "" &&
                  (errors.email == "" || errors.email == undefined) && (
                    <div className=" email-fieldcheckbox checkbox-design">
                      <div className="input-tick-active"></div>
                    </div>
                  )}
                <p className="error-txt show form-text">{errors.email}</p>
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={2}>
                  <Grid item className="sign-in-text">
                    <TextField
                      id="password"
                      name="password"
                      variant="outlined"
                      placeholder="Enter your password"
                      type="password"
                      onChange={handleChange}
                      value={fields.password}
                      fullWidth
                      InputProps={{
                        endadornment: (
                          <InputAdornment position="end">
                            <img
                              src={common.loadImg("inputTick.svg")}
                              alt="verify_icon"
                            />
                          </InputAdornment>
                        ),
                      }}
                      endadornment={
                        <InputAdornment position="end"></InputAdornment>
                      }
                    />
                    {fields.password != "" &&
                      (errors.password == "" ||
                        errors.password == undefined) && (
                        <div className=" email-fieldcheckbox checkbox-design">
                          <div className="input-tick-active"></div>
                        </div>
                      )}
                    <p className="error-txt show form-text">
                      {errors.password}
                    </p>
                  </Grid>
                  <Grid item>
                    <Typography className="forgot-text">
                      Forgot password?{" "}
                      <Typography
                        component="span"
                        className="reset-text"
                        onClick={(e) => goto(e)}
                      >
                        Reset your password
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="signed-in" xs={12}>
                <Grid container justify="space-between">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={7}
                    xl={7}
                    className="d-flex align-items-center"
                  >
                    <Grid container spacing={2}>
                      <Grid item>
                        <label>
                          <input
                            type="checkbox"
                            className="checkbox-round"
                            defaultChecked={isRememberMe}
                            value={isRememberMe}
                            onChange={(e) => getRememberMe(e)}
                          />
                          <span className="forgot-text ps-3">
                            Keep me logged in
                          </span>
                        </label>
                      </Grid>
                      {/* <Grid item>
                      <Typography className="forgot-text">
                        Keep me logged in
                      </Typography>
                    </Grid> */}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <input
                      type="submit"
                      value={isLoader ? "Processing" : "Sign In"}
                      className="sign-in-btn sign-button"
                      disabled={isLoader}
                    />
                    {/*  <Button
                    className="sign-in-btn"
                    fullWidth
                    disabled={isLoader}
                  //  onClick={(e) => onSubmit(e)}
                  >
                    {isLoader ? "Processing" : "Sign In"}
                  </Button>*/}
                  </Grid>
                </Grid>
                {/* <Box mt={3}>
                <Grid container className="login-divider" align="center">
                  <Grid item xs={4}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="login-divider">
                      Login with
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Divider />
                  </Grid>
                </Grid>
                  </Box> 
              <Box mt={4}>
                <Grid container direction="row">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    xl={4}
                    className="social-btn-grp"
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      className="top-bottom-left-radius"
                    >
                      <img
                        src={common.loadImg("facebook.svg")}
                        alt={"facebook"}
                      />
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    xl={4}
                    className="social-btn-grp "
                  >
                    <Button fullWidth variant="contained">
                      <img src={common.loadImg("google.svg")} alt={"google"} />
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    xl={4}
                    className="social-btn-grp"
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      className="top-bottom-right-radius"
                    >
                      <img
                        src={common.loadImg("twitter.svg")}
                        alt={"twitter"}
                        style={{ paddingBottom: "2px" }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Box>*/}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </form>
      <Box mt={3}>
        <Divider />
      </Box>
      {!isPickauniDomain && (
        <Container maxWidth="md">
          <Box
            p={2}
            px={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}
            className="sign-in-forgot"
          >
            <Typography className="forgot-text">
              Don't have an account?&nbsp;
              <Link to="/signup" className="new-acc-text" component="span">
                Get registered for our service{" "}
                <img
                  src={common.loadImg("Yellowarrow.png")}
                  alt={"arrow"}
                  className="arrow-icon"
                />
              </Link>
            </Typography>
          </Box>
        </Container>
      )}
    </Paper>
  );
}

export { Signin };

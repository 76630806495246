import React, { useEffect, useState } from "react";
import { common, tools, envConfig, api, eduTechSerivces } from "helpers";
import * as Services from "helpers/service";
import serviceList from "assets/files/serviceList.json";
import lookupList from "assets/files/lookupList.json";
import AutomationSelect from "elements/AutomationSelect";
import Select from "react-select";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Papa from "papaparse";
import { ProgressBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Col, Row } from "react-bootstrap";
import FieldSelect from "elements/FieldSelect";
import { Divider } from "@mui/material";

const PopupForm = function (props) {
  let userData = common.authInfo();
  const isEdutechAcc =
    common.isEduTech() && ["lead", "deal"].includes(props.props.module)
      ? true
      : false;
  const fieldTypes = {
    default: [
      "singleLine",
      "multiLine",
      "email",
      "phone",
      "number",
      "decimal",
      "currency",
      "url",
    ],
    bool: ["checkbox"],
    date: ["date", "dateTime"],
    singleSelect: ["pickList", "lookup"],
    multipleSelect: ["multiSelect", "multiLookup"],
    file: ["imageUpload", "fileUpload"],
  };
  let service = serviceList[props.props.module];
  const userInfo = common.userInfo();
  const [isReload, setIsReload] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [selected, setSelected] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [update, setUpdate] = useState([
    {
      column: "",
      value: "",
    },
  ]);
  const [result, setResult] = useState({
    columns: [],
    data: [],
    totalResults: 0,
    totalRecords: 0,
  });
  const [formFields, setFormFields] = useState({});
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProcessed, setUploadProcessed] = useState(false);
  const [excFields, setExcFields] = useState([
    "CreatedBy",
    "CreatedOn",
    "CreationDate",
    "ModifiedBy",
    "ModifiedOn",
    "ListDetailId",
    "TerritoryId",
    "SalespersonId",
    "SalesPersonId",
    "status",
    "valid",
  ]);
  const [filterLookupKey, setFilterLookupKey] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [failureData, setFailureData] = useState([]);
  const [sCount, setSCount] = useState(0);
  const [eCount, setECount] = useState(0);
  // const [listId, setListId] = useState("");
  const [convertData, setConvertData] = useState({
    OpportunityName: "LeadName",
    ClosingDate: "",
    ExpectedRevenue: "",
    CampaignId: "",
    AccountId: "",
  });
  let error = {
    // OpportunityName: {
    //   error: "",
    //   isReq: "Please select the Deal Name",
    // },
    AccountId: {
      error: "",
      isReq: "Please select the Customer Account",
    },
  };
  const [errors, setErrors] = useState(error);
  const [fields, setFields] = useState([]);
  const [formSubmit, setFormSubmit] = useState(false);
  const [conversionProcess, setConversionProcess] = useState(false);

  useEffect(() => {
    setResult(props.props.result);
    setSelected(props.selected);
    let fields = props.props.formFields.form.fields;
    fields.sort((a, b) => {
      return a.index - b.index;
    });
    setFormFields(fields);
    let options = [];
    fields.map((item) => {
      options.push({ value: item.key, label: item.label });
    });
    setFields(options);
    service = serviceList[props.props.module];
  }, [props]);

  // update
  const updateAdd = (e) => {
    e.preventDefault();
    setUpdate((pData) => {
      let nData = reParse(pData);
      nData.push({
        column: "",
        value: "",
      });
      return nData;
    });
  };

  const updateRemove = (e, key) => {
    e.preventDefault();
    setUpdate((pData) => {
      let nData = reParse(pData);
      nData.splice(key, 1);
      return nData;
    });
  };

  const updateChange = (e, key) => {
    const { name, value } = e.target;
    setUpdate((pData) => {
      let nData = reParse(pData);
      nData[key][name] = value;
      if (name === "column") {
        nData[key].value = "";
      }
      return nData;
    });
  };

  const updateValueChange = (key, value) => {
    setUpdate((pData) => {
      let nData = reParse(pData);
      nData[key].value = value;
      return nData;
    });
  };

  const updateSubmit = async () => {
    setIsUpdate(true);
    let err = [];
    let UpdateValue;
    let params = {
      name: "massAttributesUpdate",
      parameters: [
        { AttributeName: "" },
        { ObjectName: Services[service.api] },
        { Key: service.id },
        { KeyValue: selected.join(",") },
        { NewValue: "" },
        { AttributeName1: "" },
        { NewValue1: "" },
        { AttributeName2: "" },
        { NewValue2: "" },
        { AttributeName3: "" },
        { NewValue3: "" },
        { AttributeName4: "" },
        { NewValue4: "" },
        { AttributeName5: "" },
        { NewValue5: "" },
        { TenantId: +userInfo.pTenantId },
        { OrgId: +userInfo.pOrgId },
      ],
    };
    let m = 5;
    let n = 0;
    update.map((i, k) => {
      ++k;
      let item = columnInfo(i.column);
      if (i.column === "") {
        err.push(`Please select the column in row ${k}`);
      } else if (i.value === "" || i.value === null) {
        err.push(`Please fill the value in row ${k}`);
      } else {
        let value = i.value;
        if (fieldTypes.singleSelect.includes(item.type)) {
          value = value ? value.value : "";
        } else if (fieldTypes.multipleSelect.includes(item.type)) {
          value = value ? value.map((i) => i.value).join(",") : "";
        } else if (fieldTypes.date.includes(item.type)) {
          value = value
            ? common.formatDate(value, "YYYY-MM-DDTHH:mm:ssZ")
            : null;
        } else if (item.type === "currency") {
          value = String(value).replace(/,/g, "");
        }
        if (n === 0) {
          params.parameters[0][`AttributeName`] = item.key;
          params.parameters[4][`NewValue`] = value;
          UpdateValue = value; //single field(Responsible Person) update for edutech accounts
        } else {
          params.parameters[m][`AttributeName${n}`] = item.key;
          ++m;
          params.parameters[m][`NewValue${n}`] = value;
          ++m;
        }
        ++n;
      }
    });
    if (err.length) {
      common.snack("E", err[0]);
    } else {
      // api call
      let url = envConfig.BASE_API + Services.CUSTOM_ATTR;
      let data = {
        url: url,
        type: "dynamic",
        method: "POST",
        auth: "token",
        body: JSON.stringify(params),
        cType: 3,
      };
      //   return
      api.call(
        data,
        async (response) => {
          if (response.status == 200) {
            if (isEdutechAcc) {
              let updateParams = {
                updateFiled: [selected.join(",")],
                fields: props?.props?.module == "lead" ? "leadId" : "dealId",
                salesPersonId: UpdateValue,
              };

              await eduTechSerivces.massUpdateApp(updateParams);
            }
            common.snack("S", "Item has been updated successfully");
            setSelected([]);
            setSelectedData({});
            setUpdate([
              {
                column: "",
                value: "",
              },
            ]);
            setIsReload(true);
            setIsUpdate(false);
            props.massupdateClose("clear");
          }
        },
        (error) => {
          common.snack("E", error);
        },
        (final) => {}
      );
    }
  };

  // columns
  const columnInfo = (key) => {
    let res = result.columns.filter((i) => i.key === key);
    return res[0];
  };

  const reParse = (data) => {
    return JSON.parse(JSON.stringify(data));
  };

  const labelValue = (data) => {
    if (isObject(data)) {
      return data ? data.map((item) => ({ label: item, value: item })) : "";
    } else {
      return data ? { label: data, value: data } : "";
    }
  };

  const isObject = (val) => {
    return typeof val === "object" && val !== null;
  };

  const closeForm = () => {
    props.massupdateClose("close");
    // setSelected([]);
  };

  // delete
  const deleteYes = () => {
    setIsLoader(true);
    let params = {
      name: "massDelete",
      parameters: [
        {
          ObjectName: Services[service.api],
        },
        {
          Key: service.id,
        },
        {
          KeyValue: selected.join(","),
        },
        {
          TenantId: +userInfo.pTenantId,
        },
        {
          OrgId: +userInfo.pOrgId,
        },
      ],
    };
    // api call
    let url = envConfig.BASE_API + Services.CUSTOM_ATTR;
    let data = {
      url: url,
      type: "dynamic",
      method: "POST",
      auth: "token",
      body: JSON.stringify(params),
      cType: 3,
    };
    //   return
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          common.snack("S", "Item has been deleted successfully");
          setIsLoader(false);
          setSelected([]);
          setSelectedData({});
          props.massupdateClose("clear");
        }
      },
      (error) => {
        common.snack("E", error);
        setIsLoader(false);
      },
      (final) => {}
    );
  };

  // import
  /**
   * Formatting csv file to download
   */
  const downloadFormat = (e) => {
    e.preventDefault();
    let name = props.props.module; // == "lead" ? "lead" : "enquiry_member";
    let csvTitle = {};

    formFields.map((obj) => {
      if (
        checkFieldAccess(obj) &&
        !excFields.includes(obj.key) &&
        obj.status == "use" &&
        obj.store == true &&
        obj.disabled == false &&
        obj.visibility == true
      ) {
        csvTitle[obj.label] = "";
      }
    });
    let csvTitleArray = [];
    csvTitleArray.push(csvTitle);
    let fileName = name + "_import_format.csv";
    downloadCSV(fileName, csvTitleArray);
  };

  /**
   * Download csv file
   */
  const downloadCSV = (fileName, data) => {
    var csv = Papa.unparse(data);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, fileName);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", fileName);
    tempLink.click();
  };

  /**
   * File upload
   */
  const handleFileInputChange = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setIsUploading(true);
      setUploadProcessed(false);
      setFailureData([]);
      setCsvData([]);
      setFilterLookupKey([]);
      Papa.parse(file, {
        complete: (result) => {
          let data = result.data;
          setCsvData(data);
          initUpload(data);
        },
        header: true,
      });
    }
  };

  const initUpload = async (data) => {
    // setCsvData(data);
    //remove last empty row if we have more data in csv
    if (data.length > 0) {
      let len = data.length;
      let lastRow = Object.values(data[len - 1]);
      if (allEqual(lastRow)) {
        data.pop();
      }
    }

    let valid = validateCsvData(data);
    if (valid) {
      //get lookup data
      let LookupResult = await getLookupAllKeys(data);
      setFilterLookupKey(LookupResult);
      let mappedData = await KeyLabelMapping(data);
      let mappedData2 = await AllRecordValueMapping(mappedData);
      let mappedData3 = await LookupValueMapping(mappedData2, LookupResult);
      bulkInsert(mappedData3, data);
    } else {
      setIsUploading(false);
    }
  };

  const allEqual = (arr) => {
    return new Set(arr).size == 1;
  };

  /**
   * Valid the csv data
   */
  const validateCsvData = (data) => {
    let valid = false;
    // Step 1: valid fields if file have data
    if (data.length > 0) {
      let csvKeys = Object.keys(data[0]);
      let csvValues = Object.values(data[0]);

      let dbKeys = [];

      formFields.map((x) => {
        if (
          checkFieldAccess(x) &&
          !excFields.includes(x.key) &&
          x.status == "use" &&
          x.store == true &&
          x.disabled == false &&
          x.visibility == true
        ) {
          dbKeys.push(x.label);
        }
      });
      //validate db and csv fields
      if (JSON.stringify(csvKeys) === JSON.stringify(dbKeys)) {
        const allEquals = allEqual(csvValues);
        if (allEquals) {
          common.snack("E", "No data found.Please upload file with data");
          return false;
        } else {
          valid = true;
          data.map(async (row) => {
            let obj = Object.values(row);

            //If any empty row found, ignore the import
            if (obj.join("").replace(/\s/gi, "").length == 0) {
              valid = false;
              common.snack("E", "Empty row found. Please avoid empty row");
              return false;
            } else {
              if (props.props.module == "lead") {
                //Lead name is mandatory for lead module
                if (
                  (!row["Lead Name"] || row["Lead Name"].trim() == "") &&
                  (!row["Mobile"] || row["Mobile"].trim() == "")
                ) {
                  valid = false;
                  common.snack(
                    "E",
                    "Lead name and MobileNumber is Mandatory.Please provide value"
                  );
                  return false;
                }
              } else if (props.props.module == "deal") {
                //Deal name is mandatory for lead module
                if (!row["Deal Name"] || row["Deal Name"].trim() == "") {
                  valid = false;
                  common.snack(
                    "E",
                    "Deal name is Mandatory.Please provide value"
                  );
                  return false;
                }
              } else if (
                ["enquiryMember", "contacts"].includes(props.props.module)
              ) {
                //First name is mandatory for enquiry member, contact module
                if (!row["First Name"] || row["First Name"].trim() == "") {
                  valid = false;
                  common.snack(
                    "E",
                    "First name is Mandatory.Please provide value"
                  );
                  return false;
                }
              }
            }
          });
        }
      } else {
        common.snack("E", "Please upload valid file with correct fields");
      }
    }
    // Step 2: No data in file
    else if (data.length == 0) {
      common.snack(
        "E",
        "No records found. Please upload file with valid format"
      );
    }
    return valid;
  };

  const getLookupAllKeys = async (data) => {
    return new Promise((resolve, reject) => {
      let filterLookupKey = [];
      let loop = 0;
      //lookup
      let filteredLookupFields = formFields.filter((data) => {
        return data.type == "lookup" && !excFields.includes(data.key);
      });

      filteredLookupFields.map(async (field) => {
        let columnUniqueValues = [];
        data.map(async (obj) => {
          if (
            obj[field.label] &&
            obj[field.label] != "" &&
            !columnUniqueValues.includes(obj[field.label].toLowerCase().trim())
          ) {
            columnUniqueValues.push(obj[field.label].toLowerCase().trim());
          }
          return obj;
        });

        if (columnUniqueValues.length > 0) {
          filterLookupKey.push({
            key: field.key,
            label: field.label,
            columnUniqueValues: columnUniqueValues,
            lookup: field.attr.lookup,
            items: [],
          });
        } else {
          filterLookupKey.push({
            key: field.key,
            label: field.label,
            columnUniqueValues: [],
            lookup: field.attr.lookup,
            items: [],
          });
        }
        return field;
      });

      if (filterLookupKey.length > 0) {
        filterLookupKey.map((key, index) => {
          if (key.columnUniqueValues.length > 0) {
            let userData = common.authInfo();
            let lookup = lookupList[key.lookup];
            let result = [];

            let url = `${envConfig.BASE_API}${Services[lookup.name]}?q=UPPER(${
              lookup.get
            }) IN${
              "('" + key.columnUniqueValues.join("','") + "')"
            } AND TenantId=${userData.TenantId}`;
            url = encodeURI(url);
            url = url.replace(/&/g, "%26");
            url = url + "&limit=10000&offset=0";

            let data = {
              url: url,
              type: "dynamic",
              method: "GET",
              auth: "token",
              moreHead: { rfv: 2 },
              cType: 4,
            };
            api.call(
              data,
              (response) => {
                if (response) {
                  result = response.data.items ? response.data.items : [];
                  key.items = result;
                  loop++;
                  if (loop == filterLookupKey.length) {
                    resolve(filterLookupKey);
                  }
                }
              },
              (error) => {
                loop++;
              },
              (final) => {}
            );
          } else {
            key.items = [];
            loop++;
          }
          if (loop == filterLookupKey.length) {
            resolve(filterLookupKey);
          }
        });
      } else {
        resolve(filterLookupKey);
      }
    });
  };

  const KeyLabelMapping = (data) => {
    return new Promise((resolve, reject) => {
      let keyMappedData = [];
      data.map(async (rData, index) => {
        let nData = {};
        Object.keys(rData).map(async (cData, index) => {
          let mapField = formFields.filter((item) => item.label == cData);
          nData[mapField[0].key] = rData[cData];
        });
        keyMappedData.push(nData);

        return rData;
      });
      resolve(keyMappedData);
    });
  };

  const LookupValueMapping = async (data, filterLookupKey) => {
    return new Promise((resolve, reject) => {
      filterLookupKey.map((fData, index) => {
        let fKey = fData.key;
        let fItems = fData.items;
        if (fData.columnUniqueValues.length > 0) {
          let formField = formFields.filter((field) => {
            return field["key"] == fKey && !excFields.includes(field["key"]);
          });

          if (formField && formField.length > 0) {
            let lookup = lookupList[formField[0].attr.lookup];

            data.map((obj) => {
              if (obj[fKey] != undefined && obj[fKey] != null) {
                let match = fItems.filter(
                  (item) =>
                    item[lookup.get].toLowerCase().trim() ==
                    obj[fKey].toLowerCase().trim()
                );
                if (match.length > 0) {
                  obj[fKey] = match[0][lookup.set];
                } else {
                  obj["status"] =
                    obj["status"] + "Invalid - " + fData.label + ".";
                  obj["valid"] = false;
                }
              }
            });
          }
        }
        //return fData;
      });
      resolve(data);
    });
  };

  const AllRecordValueMapping = async (data) => {
    return new Promise((resolve, reject) => {
      data.map(async (rData, index) => {
        rData["valid"] = true;
        rData["status"] = "";

        Object.keys(rData).map(async (cData, index) => {
          let filteredFields = await formFields.filter((data) => {
            return data.key == cData;
          });
          if (filteredFields.length > 0) {
            let filterField = filteredFields[0];
            if (rData[cData] == "") {
              rData[cData] = null;
            } else {
              if (filterField.type == "pickList") {
                let options = filterField.attr.option;
                if (
                  options.length > 0 &&
                  rData[cData] != "" &&
                  rData[cData] != null
                ) {
                  const index = await options.findIndex((element) => {
                    return (
                      element.toLowerCase().trim() ==
                      rData[cData].toLowerCase().trim()
                    );
                  });
                  if (index > -1) {
                    rData[cData] =
                      filterField["type"] == "number"
                        ? parseInt(options[index])
                        : options[index];
                  } else {
                    rData["valid"] = false;
                    rData["status"] =
                      rData["status"] + "Invalid -" + filterField.label + ".";
                  }
                }
              } else if (filterField["type"] == "email") {
                if (tools.ValidateEmail(rData[cData])) {
                  rData[cData] = rData[cData];
                } else {
                  rData["valid"] = false;
                  rData["status"] =
                    rData["status"] +
                    "Invalid email -" +
                    filterField.label +
                    ".";
                }
              } else if (filterField["type"] == "number") {
                if (rData[cData] != "") {
                  if (isNaN(rData[cData])) {
                    rData["valid"] = false;
                    rData["status"] =
                      rData["status"] +
                      "Invalid number " +
                      filterField.label +
                      ".";
                  } else {
                    let value = parseInt(rData[cData]);
                    rData[cData] = value;
                  }
                }
              } else if (filterField["type"] == "decimal") {
                if (rData[cData] != "") {
                  if (isNaN(rData[cData])) {
                    rData["valid"] = false;
                    rData["status"] =
                      rData["status"] +
                      "Invalid decimal " +
                      filterField.label +
                      ".";
                  } else {
                    let value = parseFloat(rData[cData]);
                    rData[cData] = value;
                  }
                }
              } else if (filterField["type"] == "currency") {
                if (rData[cData] && rData[cData] != "") {
                  let cAmount = Number(rData[cData].replace(/,/g, ""));
                  if (isNaN(cAmount)) {
                    rData["valid"] = false;
                    rData["status"] =
                      rData["status"] +
                      "Invalid currency " +
                      filterField.label +
                      ".";
                  } else {
                    rData[cData] = cAmount;
                  }
                }
              } else if (filterField["type"] == "phone") {
                let phoneInput = rData[cData];
                if (phoneInput.length > 0) {
                  if (phoneInput.charAt(0) != "+") {
                    rData["valid"] = false;
                    rData["status"] =
                      rData["status"] +
                      "Invalid - " +
                      filterField.label +
                      ".Must number start with + countrycode" +
                      ".";
                  }
                }
              } else if (
                filterField["type"] == "date" ||
                filterField["type"] == "dateTime"
              ) {
                let date = rData[cData] ? new Date(rData[cData]) : null;
                let dVal = date
                  ? common.formatDate(date, "YYYY-MM-DDTHH:mm:ssZ")
                  : null;

                if (dVal == "Invalid date") {
                  rData["valid"] = false;
                  rData["status"] =
                    rData["status"] + "Invalid -" + filterField.label + ".";
                } else {
                  rData[cData] = dVal;
                }
              } else if (filterField["type"] == "checkbox") {
                let valid = ["y", "yes", "n", "no"];
                if (valid.includes(rData[cData].toLowerCase().trim())) {
                  let value =
                    rData[cData].toLowerCase().trim() == "y" ||
                    rData[cData].toLowerCase().trim() == "yes"
                      ? "Y"
                      : "N";
                  rData[cData] = value;
                } else {
                  rData["valid"] = false;
                  rData["status"] =
                    rData["status"] +
                    "Invalid " +
                    filterField.label +
                    "- value must be Y or N.";
                }
              }
            }
          }
          return cData;
        });

        return rData;
      });

      resolve(data);
    });
  };

  const bulkInsert = (data, csvdata) => {
    let success = data.filter((item) => item.valid == true);
    createLeads(success, data, csvdata);
  };

  const createLeads = async (success, failure, csvdata) => {
    let EserviceCode = Services[serviceList[props.props.module].api];
    let payload = {};
    let failureData = [];
    if (failure.length > 0) {
      failure.map((fData, idx) => {
        if (fData.valid == false) {
          csvdata[idx]["status"] = fData.status;
          failureData.push(csvdata[idx]);
        }
      });
    }

    success.map((obj, index) => {
      let gIdx = Math.floor(index / 500);
      let arr = [];
      if (payload.hasOwnProperty(gIdx)) {
        arr = payload[gIdx];
      } else {
        payload[gIdx] = [];
      }
      let dataObj = Object.assign({}, obj);

      if (props.props.module == "lead" || props.props.module == "deal") {
        if (props.props.module == "lead") {
          dataObj.SalespersonId = userData.EmpId;
        } else {
          dataObj.SalesPersonId = userData.EmpId;
        }
        dataObj.TerritoryId = userData.territory ? userData.territory[0] : null;
      } else if (props.props.module == "enquiryMember") {
        dataObj.ListId = props.props.listId;
        dataObj.Active = dataObj.Active || "Y";
      }

      dataObj.TenantId = parseInt(userData.TenantId);
      dataObj.OrgId = parseInt(userData.DefaultOrgId);

      delete dataObj.status;
      delete dataObj.valid;

      arr.push({
        id: "part" + (index + 1),
        path: "/" + EserviceCode,
        operation: "create",
        payload: dataObj,
      });
      payload[gIdx] = arr;
    });
    if (Object.keys(payload).length > 0) {
      let uploadResult = await importData(payload);
      if (uploadResult.length == Object.keys(payload).length) {
        common.snack(
          "S",
          "Records are imported successfully in " + props.props.module
        );
        setIsUploading(false);
        setUploadProcessed(true);
        setSCount(success.length);
        setECount(failureData.length);
        setFailureData(failureData);

        if (failureData.length > 0) {
          downloadCSV(fileName + "_error_records.csv", failureData);
        }
      }
    } else if (failureData.length > 0) {
      setIsUploading(false);
      setUploadProcessed(true);
      setSCount(success.length);
      setECount(failureData.length);
      setFailureData(failureData);
      downloadCSV(fileName + "_error_records.csv", failureData);
    }
  };

  const importData = async (payload) => {
    let status = [];

    return await new Promise((resolve, reject) => {
      Object.keys(payload).map(function async(pData, idx) {
        var formAttr = { parts: payload[pData] };
        let url = encodeURI(envConfig.BASE_API);

        let data = {
          url: url,
          type: "dynamic",
          method: "POST",
          auth: "token",
          body: JSON.stringify(formAttr),
          cType: 5,
        };
        api.call(
          data,
          (response) => {
            if (response != null && response.status == 200) {
              status.push({ status: true });
              resolve(status);
            } else {
              status.push({ status: false });
              setFailureData({ ...failureData, ...payload[pData] });
              if (response.data.indexOf("for field")) {
                let errMessage = response.data.substring(
                  response.data.indexOf("for field") + 10,
                  response.data.length
                );
                common.snack("E", errMessage);
              } else {
                common.snack("E", response.data.toString());
              }
              resolve(status);
            }
          },
          (error) => {
            status.push({ status: false });
            resolve(status);
          },
          (final) => {}
        );
      });
      //resolve(status);
    });
  };

  const downloadInvalid = () => {
    downloadCSV(fileName + "_error_records.csv", failureData);
  };

  //convert
  const convertToDeal = (e) => {
    e.preventDefault();
    let isValid = validateAll();
    let userData = tools.deCrypt(localStorage.getItem("userData"));
    setFormSubmit(true);
    if (isValid) {
      setConversionProcess(true);
      var createParams = {
        name: "convertLeadToOpportunity",
        parameters: [
          { P_LEAD_ID: selected.join(",") },
          { P_OPPORTUNITY_NAME: "${" + convertData.OpportunityName + "}$" },
          { P_OPPORTUNITY_TYPE: "" },
          { P_PRIORITY: "" },
          { P_SALES_PERSON_ID: userData.EmpId },
          { P_PHONE_NUMBER: "" },
          { P_MOBILE_NUMBER: "" },
          { P_OPPORTUNITY_DESC: "" },
          { P_EMAIL: "" },
          { P_APPR_STATUS: "NEW" },
          { P_TERRITORY_ID: userData.territory[0] },
          {
            P_CAMPAIGN_ID: convertData.CampaignId
              ? convertData.CampaignId.value > 0
                ? parseInt(convertData.CampaignId.value)
                : ""
              : "",
          },
          {
            P_EXPECTED_REVENUE: convertData.ExpectedRevenue
              ? convertData.ExpectedRevenue
              : "",
          },
          {
            P_CUSTOMER_ID: convertData.AccountId
              ? parseInt(convertData.AccountId.value)
              : "",
          },
        ],
      };

      if (convertData.ClosingDate) {
        createParams.parameters.push({
          P_CLOSING_DATE: common.formatDate(
            convertData.ClosingDate,
            "YYYY-MM-DDTHH:mm:ssZ"
          ),
        });
      }

      let url = encodeURI(
        envConfig.BASE_API + Services.CHOLA_CUSTOM_CONVERSION
      );
      let data = {
        url: url,
        type: "dynamic",
        method: "POST",
        auth: "token",
        body: JSON.stringify(createParams),
        cType: 3,
      };
      //   return
      api.call(
        data,
        (response) => {
          if (response.status >= 200 && response.status <= 300) {
            common.snack("S", "Successfully Converted as Deal");
            let convertData = {
              OpportunityName: "",
              ClosingDate: "",
              ExpectedRevenue: "",
              CampaignId: "",
            };
            setConvertData(convertData);
            setFormSubmit(false);
            setConversionProcess(false);
            props.massupdateClose("clear");
          }
        },
        (error) => {
          common.snack("E", error);
        },
        (final) => {}
      );
    }
  };

  const validateAll = () => {
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (convertData[key] == "" || convertData[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else {
        errors[key].error = "";
      }
    }
    setErrors(errors);
    return isValid;
  };

  const onHandleSelect = async (data, name) => {
    setConvertData({ ...convertData, [name]: data });
    if (data && errors[name]) {
      errors[name].error = "";
      setErrors(errors);
    }
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    if (convertData.hasOwnProperty(name)) {
      setConvertData({ ...convertData, [name]: value });
    }
  };

  // const selectHandleOnChange = async (event, name) => {
  //   const { value } = event;
  //   if (convertData.hasOwnProperty(name)) {
  //     setConvertData({ ...convertData, [name]: value });
  //   }
  //   if (value) {
  //     errors[name].error = "";
  //     setErrors(errors);
  //   }
  // };

  const onDateChange = (date, name) => {
    setConvertData({ ...convertData, [name]: date });
  };

  //Field level access handling
  const checkFieldAccess = (field) => {
    if (userData.roleId == 0) return true; //ADMIN Access
    if (field?.pspecific == true && field?.permission?.length > 0) {
      let fAccess = field.permission.filter(
        (e) => e.groupId == userData.roleId
      );
      return fAccess.length > 0
        ? fAccess[0].access === "rw"
        : field.ptype === "rw";
    } else {
      return field?.ptype === "rw" || true;
    }
  };

  let tot = csvData.length;
  let sucess = Math.round((sCount / tot) * 100);
  let failure = 100 - sucess;

  return (
    <div>
      {props.action == "update" && (
        <div className="amlShow">
          <p className="amlFilterSelected">
            {props.selectedColumns > 0 ? props.selectedColumns : 0} item(s)
            selected
          </p>
          <ul className="conditions-list">
            {update.map((item, key) => {
              let clCount = key;
              let field = item.column ? columnInfo(item.column) : "";
              let updateColumns = update.map((i) =>
                i.column ? i.column : null
              );
              return (
                <li className="mb-3" key={clCount}>
                  <div className="row">
                    {/* <div className="col-md-2"></div> */}
                    <div className="col-1 align-self-center text-center">
                      <span className="bulk-action-steps step-count">
                        {++clCount}
                      </span>
                    </div>
                    <div className="col">
                      <select
                        className="form-control"
                        name="column"
                        value={item.column}
                        onChange={(e) => updateChange(e, key)}
                      >
                        <option value="">None</option>
                        {result &&
                          result.columns.length &&
                          result.columns
                            .filter(
                              (i) =>
                                (isEdutechAcc &&
                                  ["SalespersonId", "SalesPersonId"].includes(
                                    i.key
                                  )) ||
                                (!isEdutechAcc && i.fieldUpdate)
                            )
                            .map((item, key) => (
                              <option
                                value={item.key}
                                key={key}
                                disabled={updateColumns.includes(item.key)}
                              >
                                {item.label}
                              </option>
                            ))}
                      </select>
                    </div>
                    {field !== "" &&
                      (field.type == "pickList" ? (
                        <div className="col filter-select">
                          <Select
                            value={item.value}
                            isClearable={true}
                            options={labelValue(field.attr.option)}
                            onChange={(data) => updateValueChange(key, data)}
                          />
                        </div>
                      ) : field.type == "date" ? (
                        <div className="col filter-datepicker">
                          <DatePicker
                            isClearable={true}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="MMMM d, yyyy"
                            placeholderText="Select Date"
                            selected={
                              item.value ? moment(item.value).toDate() : null
                            }
                            onChange={(date) => updateValueChange(key, date)}
                          />
                        </div>
                      ) : field.type == "dateTime" ? (
                        <div className="col filter-datepicker">
                          <DatePicker
                            isClearable={true}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            // showTimeSelect
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy"
                            placeholderText="Select Date and Time"
                            selected={
                              item.value ? moment(item.value).toDate() : null
                            }
                            onChange={(date) => updateValueChange(key, date)}
                          />
                        </div>
                      ) : field.type == "checkbox" ? (
                        <div className="col">
                          <select
                            className="form-control"
                            value={item.value}
                            onChange={(e) =>
                              updateValueChange(key, e.target.value)
                            }
                          >
                            <option value="">None</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                        </div>
                      ) : field.type == "lookup" ? (
                        <div className="col filter-select">
                          <AutomationSelect
                            isRender={true}
                            field={field}
                            value={item.value}
                            onSelect={(data) => updateValueChange(key, data)}
                          />
                        </div>
                      ) : (
                        <div className="col">
                          <input
                            type={field.type === "number" ? "number" : "text"}
                            className="form-control"
                            placeholder={`Enter ${field.label}`}
                            maxLength={field.attr.maxChar}
                            value={item.value}
                            onChange={(e) =>
                              updateValueChange(key, e.target.value)
                            }
                          />
                        </div>
                      ))}

                    <div className="col align-self-center">
                      <div className="col align-items-center">
                        {update.length === 1 && key === 0 ? (
                          <Link
                            to="#"
                            className="amlAddRuleLink amlAddRule"
                            onClick={(e) => updateAdd(e)}
                          >
                            {" "}
                            +{" "}
                          </Link>
                        ) : (
                          key === 0 && (
                            <Link
                              to="#"
                              className="amlAddRuleLink amlRemoveRule"
                              onClick={(e) => updateRemove(e, key)}
                            >
                              {" "}
                              -{" "}
                            </Link>
                          )
                        )}
                        {key > 0 && (
                          <React.Fragment>
                            <Link
                              to="#"
                              className="amlAddRuleLink amlRemoveRule"
                              onClick={(e) => updateRemove(e, key)}
                            >
                              {" "}
                              -{" "}
                            </Link>
                            {update.length == clCount &&
                              update.length !== 5 && (
                                <Link
                                  to="#"
                                  className="amlAddRuleLink amlAddRule"
                                  onClick={(e) => updateAdd(e)}
                                >
                                  {" "}
                                  +{" "}
                                </Link>
                              )}
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="amlFilterButtons">
            <Button
              className="automation-footer-btn"
              onClick={(e) => updateSubmit(e)}
              disabled={isUpdate ? true : false}
            >
              {isUpdate ? "Process" : "Update"}
            </Button>
            <Link className="automation-cancel-btn" onClick={() => closeForm()}>
              Cancel
            </Link>
          </div>
        </div>
      )}
      {props.action == "delete" && (
        <div className="amlShow">
          <p className="amlFilterTitle">Delete Confirmation</p>
          <p id="alert-dialog-description">
            Are you sure you want to delete this record(s)?
          </p>
          <div className="amlFilterButtons">
            <Button
              variant="outlined"
              size="small"
              className="automation-footer-btn"
              onClick={(e) => deleteYes(e)}
              disabled={isLoader ? true : false}
            >
              {isLoader ? "Process" : "Yes"}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              className="automation-cancel-btn"
              onClick={() => closeForm()}
            >
              No
            </Button>
          </div>
        </div>
      )}
      {props.action == "import" && (
        <div className="lead-wraper">
          {/* <h5>
            {mod == "lead" ? "Lead Bulk Import" : "Enquiry Member Bulk Import"}
          </h5> */}
          {!isUploading && (
            <div className="row justify-content-center  mt-4 mb-4">
              <div className="col-2"></div>
              <div className="col-4 d-flex ">
                <div className="download-format-btn ">
                  <Button
                    type="button"
                    className="btn-import "
                    onClick={(e) => downloadFormat(e)}
                    disabled={isLoading}
                  >
                    Download Format
                  </Button>
                </div>
              </div>
              <div className="col-4">
                <label htmlFor="upload_new" className="lbl-import">
                  <input
                    accept=".csv"
                    className="hideColumn "
                    id="upload_new"
                    onChange={(e) => handleFileInputChange(e)}
                    multiple={false}
                    type="file"
                    disabled={isLoading || isUploading}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className="btn-upload "
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
              <div className="col-2"></div>
            </div>
          )}
          {isUploading && (
            <div className="row justify-content-between mt-5">
              <div className="col-12 progress-sec">
                <div className="col-9 m-auto">
                  <ProgressBar>
                    <ProgressBar striped variant="info" animated now={100} />
                  </ProgressBar>
                </div>
                <p className="progress-txt mt-3">Uploading...Please wait</p>
              </div>
            </div>
          )}
          {uploadProcessed && (
            <div className="row justify-content-between mt-5">
              <div className="col-12 progress-sec">
                <div className="col-9 m-auto">
                  <ProgressBar>
                    <ProgressBar
                      striped
                      variant="success"
                      now={sucess}
                      key={1}
                      label="success"
                    />
                    <ProgressBar
                      striped
                      variant="danger"
                      now={failure}
                      key={2}
                      label="failure"
                    />
                  </ProgressBar>
                </div>
                <p className="progress-info-text mt-3">Uploaded Information</p>
                <div className="d-flex justify-content-center">
                  <div className="me-3">
                    {" "}
                    <p className="progress-indicator-container">
                      <label className="success success-indicator">
                        <b className="indicator-label-success"> Success</b>{" "}
                        <b>{sucess} %</b>
                      </label>
                    </p>
                  </div>
                  <div>
                    <p>
                      {" "}
                      <label className="danger danger-indicator ">
                        <b className="indicator-label-fail">Failure</b>{" "}
                        <b>{failure} %</b>
                      </label>
                    </p>
                  </div>
                </div>
                {eCount > 0 && (
                  <button
                    type="button"
                    className="btn btn-primary text-center"
                    onClick={() => downloadInvalid()}
                  >
                    Download Failure Records
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {props.action == "convert" && (
        <div>
          <div className="popup-input-wrap">
            <div className="list-row">
              <Row>
                <Col sm={6} className="body-th common-input-style">
                  <Form.Group id="float-label">
                    <Form.Label
                      className={
                        convertData.ExpectedRevenue
                          ? "Active text-label ml-3"
                          : "text-label ml-3 camp-time-label"
                      }
                    >
                      Amount
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min={0}
                      name="ExpectedRevenue"
                      value={convertData.ExpectedRevenue}
                      onChange={(e) => handleOnChange(e)}
                      className="convert-border"
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col
                  sm={6}
                  className="body-th common-input-style  common-select-border"
                >
                  <Form.Group>
                    {/* <div>
                      <Select
                        value={fields.find(
                          (o) => o.value === convertData.OpportunityName
                        )}
                        onChange={(e) =>
                          selectHandleOnChange(e, "OpportunityName")
                        }
                        options={fields}
                        placeholder="Deal Name *"
                        isClearable={false}
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={{
                          container: (provided, state) => ({
                            ...provided,
                            marginTop: 0,
                          }),
                          valueContainer: (provided, state) => ({
                            ...provided,
                            overflow: "visible",
                          }),
                          placeholder: (provided, state) => ({
                            ...provided,
                            position: "absolute",
                            top:
                              state.hasValue || state.selectProps.inputValue
                                ? -15
                                : "10%",
                            transition: "top 0.1s, font-size 0.1s",
                            fontSize:
                              state.hasValue || state.selectProps.inputValue
                                ? 11
                                : 13,
                            color: "#9daabb",
                          }),
                          
                        }}
                        disabled={true}
                      />
                    </div>
                    {errors.OpportunityName.error !== "" &&
                      formSubmit === true && (
                        <span className="mf-required">
                          {errors.OpportunityName.error}
                        </span>
                      )} */}
                    <FieldSelect
                      api="CRM_CUSTOMER"
                      get="CustomerName"
                      set="CustomerId"
                      value={convertData.AccountId}
                      isMulti={false}
                      isRender={true}
                      onSelect={(data) => onHandleSelect(data, "AccountId")}
                      placeholder="Customer Account"
                    />
                    {errors.AccountId.error !== "" && formSubmit === true && (
                      <span className="mf-required">
                        {errors.AccountId.error}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col
                  sm={6}
                  className="body-th common-input-style popup-date-picker"
                >
                  <Form.Group className="" id="float-label1">
                    <Form.Label
                      className={
                        convertData.ClosingDate
                          ? "Active text-label ml-3"
                          : "text-label ml-3 camp-time-label"
                      }
                    >
                      Closing Date
                    </Form.Label>

                    <DatePicker
                      name="ClosingDate"
                      value={
                        convertData.ClosingDate
                          ? moment(convertData.ClosingDate).toDate()
                          : null
                      }
                      minDate={moment().toDate()}
                      onChange={(date) => onDateChange(date, "ClosingDate")}
                      format="DD/MM/YYYY"
                      render={<InputIcon />}
                    />
                  </Form.Group>
                </Col>
                <Col
                  sm={6}
                  className="body-th common-input-style common-select-border"
                >
                  <Form.Group>
                    <FieldSelect
                      api="CRM_CAMPAIGNS"
                      get="CampaignName"
                      set="CampaignId"
                      value={convertData.CampaignId}
                      isMulti={false}
                      isRender={true}
                      onSelect={(data) => onHandleSelect(data, "CampaignId")}
                      placeholder="Campaign"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {/* <Col
                  sm={6}
                  className="body-th common-input-style common-select-border"
                >
                  <Form.Group>
                    <FieldSelect
                      api="CRM_CUSTOMER"
                      get="CustomerName"
                      set="CustomerId"
                      value={convertData.AccountId}
                      isMulti={false}
                      isRender={true}
                      onSelect={(data) => onHandleSelect(data, "AccountId")}
                      placeholder="Customer Account"
                    />
                    {errors.AccountId.error !== "" && formSubmit === true && (
                      <span className="mf-required">
                        {errors.AccountId.error}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={6}></Col> */}
              </Row>
            </div>
          </div>
          <Divider />
          <Row className="botBtn mt-3">
            <Col sm={12}>
              <Button
                variant="primary"
                className="saveBtn automation-footer-btn me-2"
                onClick={(e) => convertToDeal(e)}
                disabled={conversionProcess}
              >
                {/* Convert */}
                {conversionProcess ? "Processing..." : "Convert"}
              </Button>
              <Button
                variant="primary"
                onClick={() => closeForm()}
                className="cancelBtn btn btn-primary automation-cancel-btn"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};
export default PopupForm;

import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import AmlHashForm from "aml-hash-form";
import { api, common, envConfig } from "helpers";
import { CRM_CREATE_USER } from "helpers/service";

function ResetPassword() {
  const formFields = {
    password: {
      validate: [
        "req#Password is required!",
        "min:6#Password should be minimum 6 Digits",
        "max:15#Password should be maximum 15 Digits",
      ],
    },
    confirmPassword: {
      validate: [
        "req#Confirm Password is required!",
        "min:6#Password should be minimum 6 Digits",
        "max:15#Password should be maximum 15 Digits",
        "sameAsField:password#Your password is not match",
      ],
    },
  };
  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);
  const [isLoader, setIsLoader] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    let isValid = handleSubmit();
    if (isValid) {
      setIsLoader(true);
      let params = JSON.stringify({
        name: "updatePassword",
        parameters: [
          {
            username: common.localGet("email"),
          },
          {
            Password: fields.password,
          },
        ],
      });
      let url = `${envConfig.AUTH_REST_URL}${CRM_CREATE_USER}`;
      let data = {
        url: url,
        type: "dynamic",
        method: "POST",
        auth: "none",
        cType: 3,
        body: params,
      };

      api.call(
        data,
        (res) => {
          console.log("res", res);
          if (res.status === 200 && res.data.result === "S") {
            common.snack("S", "Password has been successfully changed");
            window.location.href = common.url("/signin");
          } else {
            common.snack("E", "Password not updated please check");
          }
        },
        (error) => {
          common.snack("E", "Password not updated please check");
          setIsLoader(false);
        },
        (final) => {
          setIsLoader(false);
        }
      );
    }
  };

  return (
    <Paper className="login-card" elevation={3}>
      <Container maxWidth="md">
        <Box p={2} px={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}>
          <img src={common.loadImg("login_logo.svg")} alt="logo" />
        </Box>
      </Container>
      <Divider />
      <Container maxWidth="md">
        <Box px={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 8 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box mt={2}>
                <Typography className="login-title">Reset Password</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={2}
          className="otp-wrap"
          px={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 8 }}
        >
          <Row className="m-0">
            <Col xs={12} className="body-th common-input-style mb-3 ps-0">
              <Box className="filled-box">
                <TextField
                  label="New Password"
                  variant="filled"
                  id="password"
                  name="password"
                  type="password"
                  onChange={handleChange}
                  value={fields.password}
                />
              </Box>
              <p className="error-txt show form-text">{errors.password}</p>
            </Col>
            <Col xs={12} className="body-th common-input-style mb-2 ps-0">
              <Box className="filled-box">
                <TextField
                  label="Confirm Password"
                  variant="filled"
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  onChange={handleChange}
                  value={fields.confirmPassword}
                />
              </Box>
              <p className="error-txt show form-text">
                {errors.confirmPassword}
              </p>
            </Col>
          </Row>
        </Box>
        <Box mt={2} px={{ xs: 1, sm: 2, md: 3, lg: 8, xl: 8 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Button
                className="sign-in-btn mb-2"
                disabled={isLoader}
                onClick={(e) => onSubmit(e)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box mt={1}>
        <Divider />
      </Box>
      <Container maxWidth="md">
        <Box p={2} px={{ xs: 1, sm: 2, md: 3, lg: 7, xl: 7 }}>
          <Typography className="forgot-text">
            <a href="/signin" className="new-acc-text" component="span">
              Click here to Login{" "}
              <img
                src={common.loadImg("arrowBlack.svg")}
                alt={"arrow"}
                className="arrow-icon"
              />
            </a>
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
}

export { ResetPassword };

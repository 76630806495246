import React, { memo, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@material-ui/core/Grid";
import { Box, Typography } from "@mui/material";
import SubMenuStaticTable from "elements/subMenuStaticTable";
import leadProduct from "assets/files/static/leadProduct.json";
import dealProduct from "assets/files/static/dealProduct.json";
import { useParams } from "react-router-dom";
import { common } from "helpers";
import { ProductServiceForm } from "./productServiceForm";

const ProductServices = memo(({ handleExpanded, isExpand, id }) => {
    let { mod } = useParams();
    const [isForm, setIsForm ] = useState(false);
    const [productId, setProductId ] = useState(0);

    const closeForm = () => {
        setIsForm(false);
    }

    const openForm = (item) => {
        if(item){
            switch (mod) {
                case "lead":
                    setProductId(item.LeadProId);
                  break;
                case "deal":
                    setProductId(item.OppProId);
                  break;
                default:
                    setProductId(0)
              }
        } else {
            setProductId(0);
        }
        setIsForm(true);
    }

  return (
    <div className="default-bgnd">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className="crm-submenu-wrap">
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="subheader-elements"
          >
            <Grid item>
              {isExpand === "detail" ? (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("tab")}
                >
                  Expand{" "}
                  <img src={common.loadImg("expandkanban.svg")} alt="expand" />
                </Typography>
              ) : (
                <Typography
                  className="expand pointer"
                  onClick={() => handleExpanded("detail")}
                >
                  Collapse{" "}
                  <img
                    src={common.loadImg("expandkanban.svg")}
                    alt="Collapse"
                  />
                </Typography>
              )}
            </Grid>
           </Grid>
        </Box>
      </Box>
        {isForm == false && 
      <div>
        <Paper
          sx={{ width: "100%", mb: 2 }}
          className={`container responsive-table p-0`}
        >
          <SubMenuStaticTable 
            module={mod == "lead" ? leadProduct : dealProduct} 
            objectId={id} 
            objectType={mod}
            menuType={"subMenu"}
            openForm={(item) => openForm(item)}
            isExpand={isExpand}
            id={id}
          />
        </Paper>
      </div>
}
{isForm == true && 
      <ProductServiceForm
        query={{
            "objectId": id,
            "productId": productId,
            "objectType": mod
        }}
        closeForm = {() => closeForm()}
      />
    }
    </div>
  );
});

export { ProductServices };

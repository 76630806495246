import React, { memo, useEffect, useRef, useState, Fragment } from "react";
import { Button, IconButton } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import { Link, useOutletContext, useNavigate } from "react-router-dom";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
//import Popover from "@mui/material/Popover";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Typography from "@mui/material/Typography";
import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import AccessDenied from "elements/AccessDenied";
import { api, common, envConfig } from "helpers";
import StatusBar from "elements/StatusBar";
import { CRM_CUSTOMER } from "helpers/service";
import { useDebounce } from "helpers/debounce";
import { formatNumber, formatInitialText } from "helpers/tools";
import StaticTale from "elements/staticTable";
import CustomerSites from "../../../../assets/files/static/customerSites.json";

const DATE_FORMAT = "DD-MM-YYYY";
const LIST_LIMIT = 10;
const TIMER_INTERVAL = 1000;
const ADD_URL = "/setting/vendor/customer/add";
const BACK_URL = "/setting/view/vendor";

const menuList = [
  { id: "1", name: "Sites" },
  //{ id: "2", name: "Tax Identification" },
  { id: "2", name: "Contacts" },
  { id: "3", name: "Attachements" },
];

const Customer = memo(() => {
  const fileUploadRef = useRef(null);
  const [permission] = useOutletContext();
  const navigate = useNavigate();
  let editRef = useRef();

  const [page, setPage] = React.useState(0);
  const [resultList, setResultList] = React.useState([]);
  const [total, setTotal] = React.useState(null);
  const [hasMore, setHasMore] = React.useState(null);
  const [fullPageLoading, setFullPageLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [searchWord, setSearchWord] = React.useState("");

  const [selectedMenu, setSelectedMenu] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rightAnchorEl, setRightAnchorEl] = React.useState(null);

  useEffect(() => {
    setFullPageLoading(true);
  }, []);

  const debouncedSearchTerm = useDebounce(searchWord, TIMER_INTERVAL);
  useEffect(() => {
    setIsLoading(true);
    loadCustomersList(0, debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const loadCustomersList = (pageNo, searchText) => {
    setPage(pageNo);
    let userData = common.authInfo();
    let url = `${envConfig.BASE_API}${CRM_CUSTOMER}?q=TenantId=${userData.TenantId}`;

    if (searchText) {
      url = `${url} AND UPPER(CustomerName) LIKE '*${searchText}*'`;
    }
    url = `${url}&totalResults=true&orderBy=CreatedOn:desc&offset=${
      pageNo * LIST_LIMIT
    }&limit=${pageNo * LIST_LIMIT + LIST_LIMIT}`;

    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };

    api.call(
      data,
      (response) => {
        if (response) {
          let result = response.data;
          setResultList((oldData) => {
            if (pageNo === 0) {
              return result.items;
            } else {
              return [...new Set([...oldData, ...result.items])];
            }
          });

          if (result.items?.length > 0) {
            if (pageNo === 0) {
              setSelectedItem(result.items[0]);
            }
          } else {
            setSelectedItem(null);
          }
          setTotal(result.totalResults);
          setHasMore(result.hasMore);
        }

        setFullPageLoading(false);
        setIsLoading(false);
      },
      (error) => {
        setFullPageLoading(false);
        setIsLoading(false);
      },
      (final) => {
        setFullPageLoading(false);
        setIsLoading(false);
      }
    );
  };

  const handleSearchChange = (event) => {
    setSearchWord(event.target.value);
    setPage(1);
  };

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuChange = (idx) => {
    setSelectedMenu(idx);
  };

  const handleFileUpload = () => {
    if (fileUploadRef?.current) {
      fileUploadRef?.current?.click();
    }
  };

  const onFileChange = (event) => {
    const file = event.targe.files[0];
    console.log("file", file);
  };

  const handleSelectUser = (item, index) => {
    setSelectedItem(item);
    setSelectedIndex(index);
    setSelectedMenu(0);
  };
  const [isOpen, setIsOpen] = useState(true);
  const clickAwayHandler = () => {
    setIsOpen(false);
    const taskListView1 = document.getElementById("customer-user-list");
    if (taskListView1) {
      taskListView1.classList.remove("sm-customer-user-list");
    }
  };

  const detailsMoreClick = (event) => {
    setIsOpen(true);
    setRightAnchorEl(event.currentTarget);
    const taskListView1 = document.getElementById("customer-user-list");
    if (taskListView1) {
      taskListView1.classList.add("sm-customer-user-list");
    }
  };
  const detailsCloseClick = () => {
    setRightAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const rightMoreOpen = Boolean(rightAnchorEl);


  console.log({selectedItem})
  return (
    <React.Fragment>
      {common.givenPermission(permission, "vendor:customers", "read") ? (
        <AccessDenied />
      ) : (
        <Box className="template-box-border p-4">
          <div className="row">
            {true && (
              <div className="col-md-4">
                <div className="card border-color-F2F9FD">
                  <div className="card-body p-0">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        className="p-2"
                      >
                        <Grid item>
                          <h5 className="d-flex align-items-center title">
                            <Link to={BACK_URL}>
                              <img
                                alt={"back"}
                                className="mx-2"
                                src={common.loadImg(
                                  "iconly-light-arrow-down-circle.svg"
                                )}
                                width={30}
                                height={30}
                              />
                            </Link>
                            <label className="d-flex align-items-center page-title ms-0">
                              Customers{" "}
                              {!isLoading && total ? (
                                <span className="location-count-label">
                                  {formatNumber(total || 0)}
                                </span>
                              ) : null}
                            </label>
                          </h5>
                        </Grid>
                        <Grid item>
                          {!common.givenPermission(
                            permission,
                            "vendor:customers",
                            "add"
                          ) ? (
                            <Box className="flex" alignItems="center">
                              <Link to={ADD_URL} className="text-none pointer">
                                <span className="mx-2 add-new">Add New</span>
                              </Link>

                              <div className="add-new-button d-flex align-items-center justify-content-center">
                                <Link
                                  to={ADD_URL}
                                  className="text-decoration-none"
                                >
                                  <span className="d-flex align-items-center plus-icon">
                                    +
                                  </span>
                                </Link>
                              </div>
                            </Box>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Box>
                    {fullPageLoading ? (
                      <StatusBar status="process" />
                    ) : (
                      <Fragment>
                        <div className="d-flex bg-F2F9FD">
                          <Box className="filter-search" width="100%">
                            <FormControl sx={{ width: "100%" }}>
                              <OutlinedInput
                                id="search"
                                value={searchWord}
                                onChange={(event) => handleSearchChange(event)}
                                placeholder={"Search"}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <img
                                      src={common.loadImg("common-search.svg")}
                                      alt="search"
                                    />
                                  </InputAdornment>
                                }
                                aria-describedby="outlined-weight-helper-text"
                              />
                            </FormControl>
                          </Box>
                        </div>

                        <div
                          className="bg-white customer-user-list"
                          id="customer-user-list"
                        >
                          {/* Profile */}
                          <Grid container direction="column">
                            <InfiniteScroll
                              scrollThreshold={0.6}
                              next={() =>
                                loadCustomersList(page + 1, searchWord)
                              }
                              hasMore={hasMore}
                              loader={
                                isLoading && page > 0 ? (
                                  <StatusBar status="process" />
                                ) : null
                              }
                              dataLength={resultList?.length || 0}
                            >
                              {isLoading ? (
                                <StatusBar status="process" />
                              ) : resultList?.length ? (
                                resultList.map((item, i) => {
                                  const initial = formatInitialText(
                                    item.CustomerName
                                  );

                                  return (
                                    <Grid key={i} item>
                                      <Box>
                                        <Grid
                                          container
                                          alignItems="center"
                                          className="pointer color-divider"
                                          onClick={() =>
                                            handleSelectUser(item, i)
                                          }
                                        >
                                          <Grid item xs={2} mt={0.5}>
                                            <Box px={2} py={1}>
                                              <Box className="img-box">
                                                {(initial || "").toUpperCase()}
                                              </Box>
                                            </Box>
                                            {i === selectedIndex ? (
                                              <div className="active active-location"></div>
                                            ) : null}
                                          </Grid>

                                          {/* <Grid item xs={1}>
                                      <Box
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        <Box >
                                          <Divider orientation="vertical" height="76px"/>
                                        </Box>
                                      </Box>
                                    </Grid> */}
                                          <Grid
                                            item
                                            xs={9}
                                            className="divider-color"
                                          >
                                            <Box px={2}>
                                              <Grid
                                                container
                                                justifyContent="space-between"
                                                alignItems="center"
                                              >
                                                <Grid item>
                                                  <div className="mt-2 font-weight-bold">
                                                    <label
                                                      className={
                                                        i === selectedIndex
                                                          ? "location-names-active"
                                                          : "location-names"
                                                      }
                                                    >
                                                      {item.CustomerName || ""}
                                                    </label>
                                                    <p className="setting-card-content ">
                                                      {item.CustomerType || ""}
                                                    </p>
                                                  </div>
                                                </Grid>
                                                <Grid item>
                                                  <div className="my-auto pe-4 ">
                                                    <MoreHorizOutlinedIcon
                                                      className="pointer moreicon-color"
                                                      aria-describedby={
                                                        item.CustomerId
                                                      }
                                                      // aria-describedby={
                                                      //   "right-more-popover"
                                                      // }
                                                      onClick={(
                                                        event,
                                                        item
                                                      ) => {
                                                        //event.stopPropagation();
                                                        detailsMoreClick(
                                                          event,
                                                          selectedItem.CustomerId
                                                        );
                                                      }}
                                                    />
                                                    {isOpen && (
                                                      <ClickAwayListener
                                                        onClickAway={
                                                          clickAwayHandler
                                                        }
                                                      >
                                                        <Popper
                                                          // id={"right-more-popover"}
                                                          id={item.CustomerId}
                                                          open={
                                                            selectedItem.CustomerId ==
                                                            item.CustomerId
                                                              ? rightMoreOpen
                                                              : false
                                                          }
                                                          anchorEl={
                                                            rightAnchorEl
                                                          }
                                                          onClose={
                                                            detailsCloseClick
                                                          }
                                                        >
                                                          {/* {["Edit", "Delete"].map(
                                                        (x) => (
                                                          <Typography
                                                            key={x}
                                                            sx={{ p: 2 }}
                                                            className="text-capitalize menu-item crm-cp"
                                                          >
                                                            {x}

                                                          </Typography>
                                                        )
                                                      )} */}
                                                          <Box
                                                            sx={{
                                                              border: 0,
                                                              p: 1,
                                                              bgcolor:
                                                                "background.paper",
                                                            }}
                                                          >
                                                            <Typography
                                                              id={
                                                                item.CustomerId
                                                              }
                                                              sx={{ p: 2 }}
                                                              className="text-capitalize pointer menu-item crm-cp bg-style-wrap"
                                                              onClick={(
                                                                e,
                                                                item,
                                                                i
                                                              ) => {
                                                                if (
                                                                  selectedItem
                                                                ) {
                                                                  navigate(
                                                                    `/setting/vendor/customer/edit/${selectedItem?.CustomerId}?page=${page}&search=${searchWord}`
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              Edit
                                                            </Typography>
                                                          </Box>
                                                          {/* <Typography
                                                        sx={{ p: 1 }}
                                                        className="menu-item crm-cp"
                                                        // onClick={() =>
                                                        //   deleteSelectedItem()
                                                        //}
                                                      >
                                                        Delete
                                                      </Typography> */}
                                                        </Popper>
                                                      </ClickAwayListener>
                                                    )}
                                                  </div>
                                                </Grid>
                                              </Grid>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                        {/* <Box>
                                    <Divider />
                                  </Box> */}
                                      </Box>
                                    </Grid>
                                  );
                                })
                              ) : (
                                <StatusBar status="empty" />
                              )}
                            </InfiniteScroll>
                          </Grid>
                        </div>
                      </Fragment>
                    )}
                    {!fullPageLoading && (
                      <Box
                        sx={{ width: 1 }}
                        px={1}
                        py={1}
                        className="location-footer"
                      >
                        {"Showing" +
                          " " +
                          `${resultList?.length ? 1 : 0} to ${
                            resultList?.length
                          } of ${total}`}
                      </Box>
                    )}
                  </div>
                </div>
              </div>
            )}
            {true && (
              <div className="col-md-8">
                {!isLoading && selectedItem ? (
                  <>
                    <div className="row card-custom">
                      <div className=" p-0">
                        <div className="card-body p-0">
                          <div className="d-flex justify-content-between bottom-border-divider">
                            <h5 className="right-section-title my-3">
                              Details{" "}
                            </h5>

                            <div className="my-auto pe-4">
                              {/* <MoreHorizOutlinedIcon
                                className="pointer moreicon-color"
                                aria-describedby={"right-more-popover"}
                                onClick={detailsMoreClick}
                              /> */}

                              {/* <Popover
                                disableScrollLock
                                id={"right-more-popover"}
                                open={anchorEl}
                                anchorEl={rightAnchorEl}
                                onClose={detailsCloseClick}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                {["menu 1", "menu 2"].map((x) => (
                                  <Typography
                                    key={x}
                                    sx={{ p: 2 }}
                                    className="text-capitalize"
                                  >
                                    {x}
                                  </Typography>
                                ))}
                              </Popover> */}
                            </div>
                          </div>

                          {/* <div className="customer-tab-list">
                            <ul className="flex submenu-ul ps-0">
                              {menuList?.map((menu, index) => {
                                return (
                                  <li
                                    key={menu?.id}
                                    className={`pointer ${
                                      index === selectedMenu
                                        ? "customer-active-li"
                                        : "customer-tab-li"
                                    }`}
                                    onClick={() => handleMenuChange(index)}
                                  >
                                    {menu?.name}
                                  </li>
                                );
                              })}
                            </ul>
                          </div> */}
                        </div>
                      </div>
                      <div className="card-custom">
                        <StaticTale
                          module={CustomerSites}
                          query={`CustomerId=${selectedItem?.CustomerId}`}
                          add={
                            !common.givenPermission(
                              permission,
                              "vendor:customers",
                              "add"
                            )
                          }
                          update={
                            !common.givenPermission(
                              permission,
                              "vendor:customers",
                              "update"
                            )
                          }
                          delete={
                            !common.givenPermission(
                              permission,
                              "vendor:customers",
                              "delete"
                            )
                          }
                          isCustom={true}
                          routePrams={`${selectedItem?.CustomerId}`}
                          safariclassName={"safari-style"}
                        />
                      </div>
                      {/* <div className="settings-secondary" /> */}

                      {/* <div className="">
                        <Box
                          className="row"
                          style={{
                            backgroundColor: "white",
                            height: "calc(100vh - 320px)",
                            overflow: "auto",
                          }}
                        >
                          <div className="upload-section-box my-4">
                            <div
                              className="upload-section"
                              onClick={() => handleFileUpload()}
                            >
                              <div className="d-flex justify-content-between">
                                <div>
                                  <div className="upload-or-attach-file">
                                    Upload or Attach a File
                                  </div>
                                  <div className="mt-1 drag-nd-drop-file">
                                    Drag and drop a file you wish to upload
                                  </div>
                                </div>

                                <img
                                  alt={"back"}
                                  className="mx-1"
                                  src={common.loadImg("attachement-upload.svg")}
                                  width={30}
                                  height={30}
                                />
                              </div>
                            </div>

                            <input
                              ref={fileUploadRef}
                              type="file"
                              className="d-none"
                              onChange={(e) => onFileChange(e)}
                            />
                          </div>

                          <div className="d-flex align-items-center justify-content-center bottom-border-divider pb-3">
                            <label className="customer-file-header Customer-heading-files">
                              <b>Files</b>{" "}
                              <span className="location-count-label">02</span>
                            </label>
                          </div>

                          {[0, 1, 2, 3, 4].map((item) => (
                            <div
                              key={item}
                              className="d-flex align-items-center justify-content-center file-list bottom-border-divider mt-4 pb-4"
                            >
                              <div className="customer-file-header">
                                <Grid container direction="row" spacing={1}>
                                  <Grid item xs={6}>
                                    <Box display="flex" flexDirection="column">
                                      <u className="file-name-border">
                                        <span className="file-name">
                                          ScreenShot01.png
                                        </span>
                                      </u>
                                      <span className="file-timeline">
                                        Jan 20 9:20am
                                      </span>
                                    </Box>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={3}
                                    className="d-flex align-items-center justify-content-around"
                                  >
                                    <div className="view-file-text">
                                      View File
                                    </div>
                                    <img
                                      alt={"view"}
                                      className="mx-1 pointer"
                                      src={common.loadImg("view-file-img.svg")}
                                      width={23}
                                      height={20}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    className="d-flex align-items-center justify-content-around"
                                  >
                                    <div className="view-file-text">
                                      Download
                                    </div>
                                    <img
                                      alt={"download"}
                                      className="mx-1 pointer"
                                      src={common.loadImg(
                                        "attachment-download-img.svg"
                                      )}
                                      width={23}
                                      height={20}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          ))}
                        </Box>
                      </div> */}
                    </div>
                  </>
                ) : (
                  <StatusBar status={isLoading ? "process" : "empty"} />
                )}
              </div>
            )}
          </div>
        </Box>
      )}
    </React.Fragment>
  );
});

export { Customer };

const AuthURL = "https://homenu.novelwall.org/cholaauthservices/";
const BaseURL = "https://homenu.novelwall.org/cholacrmrestservices/api/v1/";
const AuthRestURL = "https://homenu.novelwall.org/cholaauthservices/rest/v1/";
const ServletURL = "https://homenu.novelwall.org/cholacrmrestservices/cholapic";
const ImgServletURL =
  "https://homenu.novelwall.org/cholacrmrestservices/cholaimgservlet";
const FileURL = "https://homenu.novelwall.org/cholacrmrestservices/";

const DownloadURL =
  "https://homenu.novelwall.org/cholacrmrestservices/cholapic?imagePath=";
const uploadURL =
  "https://homenu.novelwall.org/cholacrmrestservices/cholauploadservlet";
const LIV_EduTechURL = "https://ws.homeneu.pickauni.com/api/service/crm";
const LIV_EduTechComURL = "https://ws.homeneu.pickauni.com/api/";

const DEV_EduTechURL = "https://ws-dev.homeneu.pickauni.com/api/service/crm";
const DEV_EduTechComURL = "https://ws-dev.homeneu.pickauni.com/api/";

const EduTechS3URL = "https://edutech22.s3.amazonaws.com/";
//const EdCrmAuthToken =
//"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzNWZjYzUzMDY5Yjc5MjhiMmI1ZWFmYyIsInJvbGUiOiJhZ2VudCIsInR5cGUiOiJ1c2VyIiwiaWF0IjoxNjczNTE3ODExLCJleHAiOjE3MDUwNTM4MTF9.nft8uh2cRpjUMk1wkriM66zA-d6ePqQ7Ds1lNAXKXx0";
const EdCrmAuthToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzMTA3MjJiNzg2MzdjZTUxZmZjOTRiZCIsInJvbGUiOiJhZG1pbiIsInR5cGUiOiJhZG1pbiIsImlhdCI6MTY2NzI3NTk4MSwiZXhwIjoxNzI5NDgzOTgxfQ.A0jJkkcx6P3JYEfZz53fTU2QQLEmyeU6cQ6G7kRpxrE";

export const config = {
  base_url: process.env.REACT_APP_BASE_URL,
  // api
  api: {
    isExpired: 403, // set empty for unvalidate
    // url
    auth_api: `${AuthURL}`,
    base_api: `${BaseURL}`,
    auth_rest_api: `${AuthRestURL}`,
  },
  // others
  tenantId: 33,
  userId: 5514, // 6325
  cryptKey: "#cErLyPpKt#",
};

export const envConfig = {
  // Edutech Related
  EDUTECH_TENANT: [75, 83],
  EDUTECH_S3_URL: `${EduTechS3URL}`,
  DEV_EDUTECH_URL: `${DEV_EduTechURL}`,
  DEV_ETECH_COMM_URL: `${DEV_EduTechComURL}`,

  LIV_EDUTECH_URL: `${LIV_EduTechURL}`,
  LIV_ETECH_COMM_URL: `${LIV_EduTechComURL}`,

  ED_CRM_TOKEN: `${EdCrmAuthToken}`,

  UPLOAD_URL: `${uploadURL}`,
  DOWNLOAD_URL: `${DownloadURL}`,
  FILE_URL: `${FileURL}`,
  AUTH_API: `${AuthURL}`,
  BASE_API: `${BaseURL}`,
  AUTH_REST_URL: `${AuthRestURL}`,
  IMG_SERVLET_URL: `${ImgServletURL}`,
  SERVLET_URL: `${ServletURL}`,
  PARENT_TENANT_ID: 33,
  LIST_TRANS_TYPE_ID: "CRM_LISTS",
  LEAD_TRANS_TYPE_ID: "CRM_LEADS",
  DEAL_TRANS_TYPE_ID: "CRM_OPPORTUNITY",
  CAMPAIGN_TRANS_TYPE_ID: "CRM_CAMPAIGNS",
  QUOTE_TRANS_TYPE_ID: "CUSTOMER_QUOTATION",
  SALESORDER_TRANS_TYPE_ID: "CUSTOMER_ORDER",
  INVOICE_TRANS_TYPE_ID: "SALES_INVOICE",
  ESERVICE_LIST_CODE: "CRM_LISTS",
  ESERVICE_ENQUIRY_CODE: "CRM_LISTS",
  ESERVICE_LEAD_CODE: "CRM_LEADS",
  ESERVICE_DEAL_CODE: "CRM_OPPORTUNITY",
  ESERVICE_CAMPAIGN_CODE: "CRM_CAMPAIGNS",
  ESERVICE_QUOTE_CODE: "CUSTOMER_QUOTATION",
  ESERVICE_SALESORDER_CODE: "CUSTOMER_ORDER",
  ESERVICE_INVOICE_CODE: "SALES_INVOICE",
  ESERVICE_ENQUIRY_MEMBER_CODE: "CRM_LISTS_MEMBER",
  ESERVICE_CONTACTS_CODE: "CRM_CONTACTS",
  ESERVICE_CALLOGS_CODE: "CRM_CALLLOG",
  ESERVICE_MEETING_CODE: "CRM_MEETING",
  DEFAULT_ROLE_GROUP: "Osmo Default",
  SENDER_EMAIL: "vimaladevi@atommedialabs.com",
  allPermissions: [
    { label: "All", value: "1" },
    { label: "Access Denied", value: "2" },
    { label: "Personal", value: "3" },
    { label: "Personal & department", value: "4" },
    { label: "Personal, department & subdepartment", value: "5" },
  ],
  permissions: [
    { label: "All", value: "1" },
    { label: "Access Denied", value: "2" },
  ],
  MergeFieldModule: [
    { label: "Leads", value: "CholaCrmLeads" },
    { label: "Deals", value: "CholaCrmOpportunity" },
  ],
  WorkingStatus: "ACTIVE",
  ModNameChange: true,
  UMAS_URL: "https://uams.pickauni.com",
  SENDER_EMAIL: "Pickauni <info@pickauni.com>",
};

export const eduTechConfig = {
  LeadStatus: [
    {
      label: "Unassigned Application",
      value: "Unassigned Application",
      disabled: true,
    },
    { label: "New Application", value: "New Application", disabled: true },
    { label: "Potential Student", value: "Potential Student", disabled: true },
  ],
  DealStatus: [
    { label: "G2G", value: "G2G", disabled: true },
    { label: "Submissions", value: "Submissions", disabled: true },
    {
      label: "Conditionals",
      value: "Conditionals",
      disabled: true,
    },
    {
      label: "Unconditionals",
      value: "Unconditionals",
      disabled: true,
    },
    // {
    //   label: "SFE",
    //   value: "SFE",
    //   disabled: true,
    // },
    {
      label: "Enrolled",
      value: "Enrolled",
      disabled: true,
    },
    { label: "Fully Enrolled", value: "Fully Enrolled", disabled: true },
  ],
  missingFields: [
    { label: "Personal - FirstName", value: "personal_firstName" },
    { label: "Personal - LastName", value: "personal_lastName" },
    { label: "Personal - Dob", value: "personal_dob" },
    { label: "Personal - Gender", value: "personal_gender" },
    { label: "Personal - Nationality", value: "personal_nationality" },
    { label: "Personal - FirstLanguage", value: "personal_firstLanguage" },
    { label: "Personal - MaritalStatus", value: "personal_maritalStatus" },
    { label: "Personal - Country of Birth", value: "personal_birth" },
    {
      label: "Address - AddressWithRegion",
      value: "address_addressWithRegion",
    },
    { label: "Address - City", value: "address_city" },
    { label: "Address - Country", value: "address_country" },
    { label: "Address - Province", value: "address_province" },
    { label: "Address - PostalCode", value: "address_postalCode" },
    { label: "Address - Email", value: "address_email" },
    { label: "Address - Mobile", value: "address_mobile" },
    // { label: "Address - MobileCode", value: "address_mobileCode" },
    {
      label: "Education - CountryOfEducation",
      value: "education_countryOfEducation",
    },
    {
      label: "Education - HighLevelOfEducation",
      value: "education_highLevelOfEducation",
    },
    { label: "Education - Credits", value: "education_grade" },
    {
      label: "Other - Qualification",
      value: "education_otherQualification",
    },
    {
      label: "Other - Qualification Description",
      value: "education_otherQualificationDesc",
    },
    {
      label: "Other Qualification Document",
      value: "modal_otherQualificationDocs",
    },
    {
      label: "Work Experience - Are you currently working",
      value: "education_ruWorking",
    },
    {
      label: "Work Experience - Year of Working",
      value: "education_yearOfExp",
    },
    { label: "Work Experience - Proof Of Evidence", value: "modal_expDocs" },
    {
      label: "College - InstitutionCountry",
      value: "college_institutionCountry",
    },
    { label: "College - InstitutionName", value: "college_institutionName" },
    { label: "College - Qualification", value: "college_qualification" },
    { label: "College - Degree", value: "college_degree" },
    { label: "College - StartDate", value: "college_startDate" },
    { label: "College - EndDate", value: "college_endDate" },
    { label: "College - GraduationDate", value: "college_graduationDate" },
    { label: "College - GradingSystem", value: "college_gradingSystem" },
    { label: "College - College score", value: "college_score" },
    { label: "College - College Documents", value: "college_docs" },
    // { label: "Exam - Type", value: "exam_examType" },
    // { label: "Exam - DateOfIssue", value: "exam_dateOfIssue" },
    // { label: "Exam - TestScore", value: "exam_testScore" },
    // { label: "Exam - Listening", value: "exam_listening" },
    // { label: "Exam - Reading", value: "exam_reading" },
    // { label: "Exam - Writing", value: "exam_writing" },
    // { label: "Exam - Speaking", value: "exam_speaking" },
    // { label: "Exam - Exam Documents", value: "exam_docs" },
    { label: "Passport or Birth Certificate", value: "modal_passportDocs" },
    {
      label: "Background - PassportNumber",
      value: "background_passportNumber",
    },
    {
      label: "Background - Have You Applied for Student Finance before?",
      value: "background_isFinanceTakenBefore",
    },
    {
      label: "Background - Are you a Refugee/ Asylum Seeker?",
      value: "background_refugee",
    },
    {
      label:
        "Background - Have you spent any time in public care up to the age of 18?",
      value: "background_publicCare",
    },
    {
      label: "Background - Disability/special needs?",
      value: "background_disability",
    },
    {
      label: "Background - How many years of funding have you taken before?",
      value: "background_financeYearTakenBefore",
    },
    {
      label: "Background - What is your Visa Status?",
      value: "background_visaStatus",
    },
    {
      label: "Background - How long have you been living in UK?",
      value: "background_livingPeriod",
    },
    { label: "Work Experience - Company Name", value: "workExp_companyName" },
    { label: "Work Experience - Designation", value: "workExp_designation" },
    { label: "Work Experience - JoinDate", value: "workExp_joinDate" },
    { label: "Work Experience - EndDate", value: "workExp_endDate" },
    {
      label: "Work Experience - Line Manager Name",
      value: "workExp_lineManagerName",
    },
    {
      label: "Work Experience - Line Manager Work Email",
      value: "workExp_lineManagerEmail",
    },
    {
      label: "Work Experience - Line Manager Designation",
      value: "workExp_lineManagerDesignation",
    },
    { label: "Work Experience - Company Email", value: "workExp_companyEmail" },
    {
      label: "Work Experience - Company Address",
      value: "workExp_companyAddress",
    },
    { label: "Work Experience - Documents", value: "workExp_docs" },
    {
      label: "Other - Documents",
      value: "modal_otherActivity",
    },
    {
      label: "Extra - Curricular Documents",
      value: "modal_extraCurricular",
    },
  ],
  docMissingFields: [
    { label: "College - College Documents", value: "college_docs" },
    // { label: "Exam - Exam Documents", value: "exam_docs" },
    { label: "Work Experience - Documents", value: "workExp_docs" },
    { label: "Transcript", value: "extra_transcript" },
    { label: "Portfolio", value: "extra_portfolio" },
  ],
  sfeDocuments: [
    { label: "Passport / Birth Certificate", value: "modal_passportDocs" },
    { label: "Biometric Residence Permit (BRP)", value: "modal_brpDocs" },
    { label: "EU Share Code", value: "modal_euDocs" },
    {
      label: "Letter of Approval from SFE / Academic Progress",
      value: "modal_sfeAppDocs",
    },
    { label: "Home office letter", value: "modal_homeOfficeDocs" },
    { label: "Proof of evidence of Stay", value: "modal_poeDocs" },
    { label: "CRN", value: "background_crn" },
    { label: "SRN", value: "background_srn" },
    { label: "Funding Type", value: "background_fundingType" },
    { label: "Year of funding taken", value: "background_fundingTaken" },
  ],
};

import React, { memo, useEffect, useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  FormControl,
  TextField,
} from "@mui/material";
// AutoComplete
import AutoComplete from "@mui/material/Autocomplete";

import { common, envConfig, api } from "helpers";
import FieldLookup from "elements/FieldLookup";
import StatusBar from "elements/StatusBar";
import * as Services from "helpers/service";
import CircularProgress from "@material-ui/core/CircularProgress";

// Defined Errors
const defErrs = {
  ProductType: {
    isReq: "Line type is required!",
  },
  ProductId: {
    isReq: "Item number is required!",
  },
};

const ProductServiceForm = memo((props) => {
  const userData = common.userInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [autoLoading, setAutoLoading] = useState(false);
  const [status, setStatus] = useState(
    props.query.productId > 0 ? "process" : "success"
  );
  const [fields, setFields] = useState({
    ProductId: 0,
    ProductType: "",
    ExpRevenue: "",
    Uom: "",
    Currency: "",
    Qty: "",
    Description: "",
    TenantId: userData.pTenantId,
    OrgId: userData.pOrgId,
  });

  const [formSubmit, setFormSubmit] = useState(false);
  const [autoFields, setAutoFields] = useState({
    ProductIdSearchTerm: "",
  });
  const [commonResource, setCommonResource] = useState({
    ItemName: "",
    ItemNumber: "",
    objectType: "",
    objectId: 0,
    redirect: "",
  });
  const [masterData, SetMasterData] = useState({
    productTypes: [],
    itemData: [],
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    defineInitErrorMsg();
    if (props.query.objectId != undefined) {
      commonResource.objectId = props.query.objectId;
      commonResource.productId = props.query.productId
        ? props.query.productId
        : 0;
      commonResource.redirect = props.query.objectType;
      if (props.query.productId > 0) {
        getDetailById(props.query.productId);
      }
      commonResource.objectType = common.getObjectNameByPage(
        props.query.objectType
      );
      setCommonResource(commonResource);
    }
  }, [props]);

  const defineInitErrorMsg = () => {
    for (var key of Object.keys(defErrs)) {
      errors[key] = defErrs[key].isReq;
    }
    setErrors(errors);
  };

  const getDetailById = (id) => {
    let url = encodeURI(getBaseURLByType() + "/" + id);
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      async (response) => {
        if (response) {
          for (var key of Object.keys(response.data)) {
            if (fields.hasOwnProperty(key)) {
              if (response.data[key]) {
                fields[key] = response.data[key];
                // Remove Default Errors
                if (errors.hasOwnProperty(key)) {
                  errors[key] = "";
                }
              }

              if (key == "ProductType") {
                let val = {
                  label: response.data.ProductType,
                  value: response.data.ProductType,
                };
                fields.ProductType = val;
              }
              if (key == "Uom") {
                let val = {
                  label: response.data.Uom,
                  value: response.data.Uom,
                };
                fields.Uom = val;
              }
              if (key == "Currency") {
                let val = {
                  label: response.data.Currency,
                  value: response.data.Currency,
                };
                fields.Currency = val;
              }

              if (key === "LeadProId" || key === "OppProId") {
                fields.ProductId = response.data[key];
              }
            }
            if (key === "ItemName") {
              commonResource.ItemName = response.data[key];
            }
          }
          setFields((prev) => ({ ...prev, fields }));
          setCommonResource(commonResource);
          setErrors(errors);
          getItemDetailsById();
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const getItemDetailsById = () => {
    let url = encodeURI(
      envConfig.BASE_API + Services.ITEM_MATSER + "/" + fields.ProductId
    );
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response) {
          autoFields["ProductIdSearchTerm"] = response.data.ItemNumber;
          setAutoFields(autoFields);
          setStatus("success");
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const getBaseURLByType = () => {
    switch (commonResource.redirect) {
      case "deal":
        return envConfig.BASE_API + Services.CRM_DEALS_PRODUCTS;
        break;
      case "lead":
        return envConfig.BASE_API + Services.CRM_LEADS_PRODUCTS;
        break;
      default:
        break;
    }
  };

  const selectHandleOnChange = (data, name) => {
    if (fields.hasOwnProperty(name)) {
      setFields((prev) => ({ ...prev, [name]: data.value }));
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (fields.ProductType == "") {
      errors.ProductType = "Line type is required!";
      isValid = false;
    } else {
      errors.ProductType = "";
    }

    if (fields.ProductId == "") {
      errors.ProductId = "Item number is required!";
      isValid = false;
    } else {
      errors.ProductId = "";
    }

    setErrors(errors);
    return isValid;
  };

  /**
   * Handle to change input values
   */
   const handleAutoChange = (event, selectedValue) => {
    const { id } = event.target;
    var splitId = id.split("-");

    //get selected item details and assign to fields, commonResource
    fields[splitId[0]] = selectedValue.ItemId;
    commonResource.ItemNumber = selectedValue.ItemNumber;
    commonResource.ItemName = selectedValue.ItemName;
    fields.Uom = {
      label: selectedValue.PrimaryUom,
      value: selectedValue.PrimaryUom,
    };
    fields.Description = selectedValue.Description;
    //fields.Currency = {label : selectedValue.Currency, value: selectedValue.Currency};
    setFields((prev) => ({ ...prev, fields }));
    setCommonResource(commonResource);
  };

  const handleOnChange = (event) => {
    const { name, value, type, checked } = event.target;
    var inputValue = value;

    if (type == "checkbox") {
      inputValue = checked ? "Y" : "N";
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }
    setFields((prev) => ({ ...prev, fields }));
  };

  /**
   * Handle to get changed search terms and get list based on terms
   */
  const handleInputChange = (event, terms = "") => {
    masterData.itemData = [];

    const { id } = event.target;
    var splitId = id.split("-");

    autoFields[splitId[0] + "SearchTerm"] = terms;
    if (terms.length === 0) {
      fields[splitId[0]] = "";
      validateForm(splitId[0], fields[splitId[0]]);
    }

    setAutoLoading(true);
    SetMasterData(masterData);
    setAutoFields(autoFields);
    setFields((prev) => ({ ...prev, fields }));

    let filter = `?q=TenantId=${userData.pTenantId} AND UPPER(ItemName) LIKE '*${terms}*' &limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.ITEM_MATSER + filter;

    let data = {
      url: encodeURI(url),
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response) {
          masterData.itemData =
            response.data.items && response.data.items.length
              ? response.data.items
              : [];
          setAutoLoading(false);
          SetMasterData(masterData);
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const save = (event) => {
    event.preventDefault();
    var isValid = validateForm();
    setFormSubmit(true);
    if (isValid) {
      setIsLoading(true);
      onSubmit();
    }
  };

  const onSubmit = () => {
    let url = encodeURI(getBaseURLByType());
    if (commonResource.productId > 0) {
      url = encodeURI(getBaseURLByType()) + "/" + commonResource.productId;
    }

    let fieldsVal = {
      Description: fields.Description,
      ExpRevenue: fields.ExpRevenue,
      OrgId: fields.OrgId,
      ProductId: fields.ProductId,
      ProductType: fields.ProductType,
      Qty: fields.Qty,
      TenantId: fields.TenantId,
      Uom: fields.Uom,
      Currency: fields.Currency,
    };

    switch (props.query.objectType) {
      case "deal":
        fieldsVal["OpportunityId"] = props.query.objectId;
        break;
      case "lead":
        fieldsVal["LeadId"] = props.query.objectId;
        break;
      default:
        break;
    }

    let data = {
      url: url,
      type: "dynamic",
      method: commonResource.productId > 0 ? "PATCH" : "POST",
      body: JSON.stringify(fieldsVal),
      auth: "token",
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response) {
          setIsLoading(false);
          props.closeForm();
        }
      },
      (error) => {
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  return (
    <div>
      <Paper
        sx={{ width: "100%", mb: 2 }}
        className={`container responsive-table p-0`}
      >
        <Box>
          <Paper variant="contained dynamic-form-wrap" square>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Box py={1} px={1} className="icon-back icon-buton">
                      <IconButton onClick={() => props.closeForm()}>
                        <img
                          src={common.loadImg("backArrowKanban.svg")}
                          alt="back"
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item className="df-main-head">
                    <Typography className="detail-title">
                      {`${props.query.productId > 0 ? "Edit" : "Add"}`} Product
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Box px={2}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <Box className="verical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box>
                        {status == "success" && (
                          <Button
                            className="save-note"
                            fullWidth
                            disabled={isLoading}
                            onClick={(e) => save(e)}
                          >
                            {isLoading
                              ? "Processing..."
                              : props.query.productId > 0
                              ? "Update Changes"
                              : "Save Changes"}
                          </Button>
                        )}
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="vertical-divider">
                        <Divider orientation="vertical" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className="revert-close">
                        <Button
                          className="save-note pe-0"
                          onClick={() => props.closeForm()}
                        >
                          Revert and Close
                          <img
                            src={common.loadImg("closeKanban.svg")}
                            alt="close"
                          />
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Divider />
            </Box>
            {status !== "success" ? (
              <StatusBar status={status} />
            ) : (
              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col
                    xs={6}
                    className="body-th common-input-style mandatoryclass"
                  >
                    <Box className="filled-box">
                      <FormControl
                        fullWidth
                        className="select-float-label common-select-border"
                      >
                        <FieldLookup
                          isRender={true}
                          name="ProductType"
                          type="CHOLA_LINE_TYPE"
                          lookup={true}
                          value={fields.productType}
                          onSelect={(data) =>
                            selectHandleOnChange(data, "ProductType")
                          }
                          placeholder="Product Type"
                        />
                      </FormControl>
                      {errors.ProductType !== "" && formSubmit === true ? (
                        <p className="error-txt">{errors.ProductType}</p>
                      ) : null}
                    </Box>
                  </Col>
                  <Col
                    xs={6}
                    className="body-th common-input-style mandatoryclass"
                  >
                    <Box className="filled-box">
                      <AutoComplete
                        freeSolo
                        id="ProductId"
                        className="autocomplete-input"
                        disableClearable
                        options={masterData.itemData}
                        loading={autoLoading}
                        getOptionLabel={(option) => option.ItemName}
                        onChange={handleAutoChange}
                        inputValue={autoFields.ProductIdSearchTerm}
                        onInputChange={handleInputChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Search Item Name"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                              endAdornment: (
                                <React.Fragment>
                                  {autoLoading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                      {errors.ProductId !== "" && formSubmit === true && (
                        <Form.Text className="error-txt">
                          {errors.ProductId}
                        </Form.Text>
                      )}
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="filled-box">
                      <TextField
                        id="filled-basic"
                        label="Item Name"
                        variant="filled"
                        name="ItemName"
                        value={commonResource.ItemName}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                        disabled
                      />
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="filled-box">
                      <TextField
                        id="filled-basic"
                        label="Description"
                        variant="filled"
                        name="Description"
                        value={fields.Description}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                        disabled
                      />
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="filled-box">
                      <FormControl
                        fullWidth
                        className="select-float-label common-select-border"
                      >
                        <FieldLookup
                          isRender={true}
                          name="Uom"
                          type="UOM"
                          lookup={true}
                          value={fields.Uom}
                          onSelect={(data) => selectHandleOnChange(data, "Uom")}
                          placeholder="UOM"
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="filled-box">
                      <TextField
                        id="filled-basic"
                        label="Qty"
                        variant="filled"
                        name="Qty"
                        value={fields.Qty}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </Box>
                  </Col>

                  <Col xs={6} className="body-th common-input-style">
                    <Box className="filled-box">
                      <FormControl
                        fullWidth
                        className="select-float-label common-select-border"
                      >
                        <FieldLookup
                          isRender={true}
                          name="Currency"
                          type="CURRENCIES"
                          lookup={true}
                          value={fields.Currency}
                          onSelect={(data) =>
                            selectHandleOnChange(data, "Currency")
                          }
                          placeholder="Currency"
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="filled-box">
                      <TextField
                        id="filled-basic"
                        label="Expected Revenue"
                        variant="filled"
                        name="ExpRevenue"
                        value={fields.ExpRevenue}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </Box>
                  </Col>
                </Row>
              </Box>
            )}
          </Paper>
        </Box>
      </Paper>
    </div>
  );
});

export { ProductServiceForm };

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogActions } from "@material-ui/core";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import FieldSelect from "elements/FieldSelect";
import StatusBar from "elements/StatusBar";
import {
  api,
  common,
  eduTechConfig,
  eduTechSerivces,
  envConfig,
  tools,
} from "helpers";
import * as Services from "helpers/service";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import RCTimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import DeleteIcon from "../assets/img/actionDelete.svg";
import { CRM_PAY_EMPLOYEE } from "helpers/service";

const reviewStatus = [
  { value: "missing", label: "Missing" },
  { value: "under_review", label: "Under Review" },
  { value: "reviewed", label: "Reviewed" },
];
const canApprStatus = [
  { value: "accepted", label: "Accepted" },
  { value: "rejected", label: "Rejected" },
];
const interviewMode = [
  { value: "Online", label: "Online" },
  { value: "Offline", label: "Offline" },
];
const interviewType = [
  { value: "ELPT", label: "ELPT" },
  { value: "Exam", label: "Exam" },
  { value: "Interview", label: "Interview" },
];
const minterviewType = [
  { value: "Mock Interview", label: "Mock Interview" },
  { value: "Workshop", label: "Workshop" },
];
const interviewStatus = [
  { value: "Scheduled", label: "Scheduled", disabled: false },
  { value: "Attended", label: "Attended", disabled: false },
  { value: "Not Attended", label: "Not Attended", disabled: false },
  { value: "Cancelled", label: "Cancelled", disabled: false },
  { value: "Rescheduled", label: "Rescheduled", disabled: false },
  { value: "Confirmed", label: "Confirmed", disabled: true },
  {
    value: "Reschedule Unavailable",
    label: "Reschedule Unavailable",
    disabled: false,
  },
];

const minterviewStatus = [
  { value: "Pending", label: "Pending", disabled: false },
  { value: "Booked", label: "Booked", disabled: true },
  { value: "Attended", label: "Attended", disabled: false },
  { value: "Not Attended", label: "Not Attended", disabled: false },
];

const sfeStatus = [
  { value: "Pending", label: "Pending", disabled: false },
  { value: "Progress", label: "Progress", disabled: false },
  { value: "Booked", label: "Booked", disabled: false },
  { value: "Completed", label: "Completed", disabled: false },
];

function ETDealForm(props) {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [status, setStatus] = useState("process");
  const [imgPreview, setImgPreview] = useState(false);
  const [cancelDA, setCancelDA] = useState(false);
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const withdrawOption = [
    { value: "Not withdrawn", label: "Not withdrawn" },
    { value: "Withdrawn by user", label: "Withdrawn by user" },
    { value: "Withdrawn by admin", label: "Withdrawn by admin" },
  ];
  const [fields, setFields] = useState({}); //crm deal response
  const [fileA, setFileA] = useState([]);
  const [fileB, setFileB] = useState([]);
  const [fileC, setFileC] = useState([]);
  const [dealStatus, setDealStatus] = useState(eduTechConfig.DealStatus);
  const [mSel, setMSel] = useState([]);
  const [sfeSel, setSFESel] = useState([]);
  const initialMFields = {
    field: "",
    desc: "",
    status: "missing",
  };
  const authInfo = common.authInfo();

  const initialInterviewFields = {
    id: common.randomString(),
    mode: "Online",
    type: "",
    desc: "",
    date: "",
    venue: "",
    status: "Scheduled",
    cancelReason: "",
    rescheduleDate: [],
  };

  const initialMockInterviewFields = {
    id: common.randomString(),
    mode: "Online",
    type: "",
    desc: "",
    slot: [],
    bookedSlot: "",
    venue: "",
    status: "Pending",
    cancelReason: "",
    responsiblePerson: authInfo.EmpId,
  };

  const [isNewDateAdd, setIsNewDateAdd] = useState(false);
  const [newDate, setNewDate] = useState({ date: "", slot: [] });
  const [sfeFields, setSFEFields] = useState([initialMFields]);
  const [mFields, setMFields] = useState([initialMFields]);
  const [iFields, setIFields] = useState([initialInterviewFields]);
  const [miFields, setMIFields] = useState([]);
  const [interviewModel, setInterviewModel] = useState(false);
  const [mInterviewField, setMInterviewField] = useState({});
  const [slotTime, setSlotTime] = useState(moment());

  const [sfeSlots, setSFESlots] = useState({ slot: [], bookedSlot: "" });
  const [condOrSfeSlot, setCondOrSfeSlot] = useState(true);
  const [openEMail, setOpenEMail] = useState(false);
  const [isEmailSubmit, setIsEmailSubmit] = useState(false);
  const [emailHistory, setEmailHistory] = useState([]);
  const [appInfo, setAppInfo] = useState(null);
  const intakeFields = {
    intakeDiffer: false,
    intakeMonthYear: null,
    intakeReason: "",
  };
  const [intake, setIntake] = useState(intakeFields);
  const [courseIntMonthYear, setCourseIntMonthYear] = useState([]);
  const [files, setFiles] = useState([]);
  const [salesPerson, setSalesPerson] = useState({});

  //effect
  useEffect(() => {
    if (props.data.value1 == "") {
      navigate("/crm/deal/list");
    } else {
      getEditData();
    }
  }, []);

  // Select box style
  const selectStyles = {
    container: (provided, state) => ({
      ...provided,
      marginTop: 0,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: "visible",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue ? -15 : "10%",
      transition: "top 0.1s, font-size 0.1s",
      fontSize: state.hasValue || state.selectProps.inputValue ? 11 : 13,
      color: "#9daabb",
    }),
  };

  // edit
  const getEditData = () => {
    let url = envConfig.BASE_API + Services.CRM_DEALS + "/" + props.data.value1;
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
    };
    api.call(
      data,
      async (response) => {
        if (response.status == 200) {
          // set value
          setCRMDealData(response.data);
          //get email history
          let result = await eduTechSerivces.getAppEmailHistory(
            response.data.Cattr2
          );
          setEmailHistory(result.data || []);

          let res = await eduTechSerivces.getApplicationInfo(
            response.data.Cattr2
          );
          setAppInfo(res);
          let courseIntake = [];
          res?.course?.intake?.map((yObj) => {
            yObj.month?.map((mObj) => {
              courseIntake.push({
                label: yObj.year + " " + common.getMonthList()[mObj - 1].label,
                value: yObj.year + " " + mObj,
              });
            });
          });
          setCourseIntMonthYear(courseIntake);
          if (res.sfeField) {
            setSFEFields(res.sfeField.missing);
            setSFESel(res.sfeField.fields.split(","));

            if (res.sfeField.interview) {
              let sfeSlot = res.sfeField.interview;
              sfeSlot?.slot.map((sObj) => {
                sObj?.slot.map((slot, idx) => {
                  sObj.slot[idx] = common.formatDate(slot, "hh:mm a");
                });
              });
              setSFESlots(sfeSlot);
            } else {
              setSFESlots({ slot: [], bookedSlot: "" });
            }
          }
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const setCRMDealData = (data) => {
    let newFields = common.reParse(data);

    // get sales person details
    getEmpByEmpId(data.SalesPersonId);

    newFields.SalesPersonId = {
      label: data.SalesPersonIdDesc,
      value: data.SalesPersonId,
    }; //sales person
    setDealStatus(common.enableDealStatus(data.ApprStatus));
    newFields.Cattr5 = data.Cattr5 || "N"; //mock interview
    newFields.Cattr39 = data.Cattr39 || "N"; //interview field
    newFields.Cattr40 = data.Cattr40 || "N"; //missing field
    newFields.Cattr28 = { label: data.Cattr28, value: data.Cattr28 };
    newFields.ApprStatus = { label: data.ApprStatus, value: data.ApprStatus }; //deal status
    newFields.Cattr14 = data.Cattr14
      ? { label: data.Cattr14, value: data.Cattr14 }
      : null;
    newFields.Cattr20 = { label: data.Cattr20, value: data.Cattr20 }; //canRequest acceptance status
    if (data.Clattr6 && data.Cattr6) {
      setMFields(JSON.parse(data.Clattr6));
      setMSel(data.Cattr6.split(","));
    }

    if (data.Clattr1) {
      let miFields = JSON.parse(data.Clattr1);
      miFields.map((mobj) => {
        mobj.slot.map((sObj) => {
          sObj.slot.map((slot, idx) => {
            sObj.slot[idx] = common.formatDate(slot, "hh:mm a");
          });
        });
      });
      setMIFields(miFields);
    }

    if (data.Clattr2) {
      setIFields(JSON.parse(data.Clattr2));
    }
    newFields.emailContent = "";
    setFields(newFields);
    if (fields?.Cattr20?.value === "accepted") {
      setCancelDA(true);
    }

    if (data.ApprStatus === "Conditionals") {
      let mStatus = mFields.filter((a) => a.status != "reviewed");
      if (data?.Cattr40 == "Y") {
        if (mStatus.length == 0) {
          enableStatus("Unconditionals");
        }
      } else {
        enableStatus("Unconditionals");
      }
    }

    if (data.Cattr12 != null && data.ApprStatus === "Unconditionals") {
      // let mStatus = mFields.filter((a) => a.status != "reviewed");
      // if(mStatus.length == 0){
      //enableStatus("SFE");
      enableStatus("Enrolled");
      // }
    }

    if (data.ApprStatus === "SFE") {
      enableStatus("Enrolled");
    }

    if (data.ApprStatus === "Enrolled") {
      enableStatus("Fully Enrolled");
    }

    if (data.ApprStatus === "Fully Enrolled") {
      enableStatus("");
    }
    setStatus("success");
  };

  const enableStatus = (status) => {
    if (status) {
      let dStatus = eduTechConfig.DealStatus;
      dStatus.map((obj) => {
        if (obj.value == status) {
          obj.disabled = false;
        } else {
          obj.disabled = true;
        }
      });
      setDealStatus(dStatus);
    } else {
      let dStatus = eduTechConfig.DealStatus;
      setDealStatus(dStatus);
    }
  };

  // Missing Select handler
  const onMissingSelect = (data, name, idx, sec) => {
    if (sec == 1) {
      //Missing Field
      setMFields((oldData) => {
        let newData = common.reParse(oldData);
        newData[idx][name] = name == "desc" ? data : data.value;
        return newData;
      });
      if (name == "field" && data.value && !mSel.includes(data.label)) {
        let selected = mFields.map((a) => a.field);
        selected.push(data.value);
        setMSel(selected);
      }
    } else if (sec == 2) {
      //Interview Field
      setIFields((oldData) => {
        let newData = common.reParse(oldData);
        if (name == "date") {
          newData[idx][name] = data?.toDate() || "";
        } else {
          newData[idx][name] = ["desc", "venue"].includes(name)
            ? data
            : data.value;
        }
        return newData;
      });
    } else if (sec == 3) {
      //Mock Interview field
      setMIFields((oldData) => {
        let newData = common.reParse(oldData);
        if (name == "slot") {
          //newData[idx][name].push(data?.toDate());
          newData[idx][name] = [data?.toDate()];
        } else {
          newData[idx][name] = ["desc", "venue"].includes(name)
            ? data
            : data.value;
        }
        return newData;
      });
    } else if (sec == 4) {
      //SFE Document Field
      setSFEFields((oldData) => {
        let newData = common.reParse(oldData);
        newData[idx][name] = name == "desc" ? data : data.value;
        return newData;
      });
      if (name == "field" && data.value && !sfeSel.includes(data.label)) {
        let selected = sfeFields.map((a) => a.field);
        selected.push(data.value);
        setSFESel(selected);
      }
    }
  };

  const saveNewDate = (e) => {
    e.preventDefault();
    let newFields = common.reParse(mInterviewField);
    if (newDate && newDate.date !== "") {
      let isEx = newFields.slot.filter((e) => e.date == newDate.date);
      if (isEx.length == 0) {
        newFields.slot.push(newDate);
        setMInterviewField(newFields);
        setIsNewDateAdd(false);
        setNewDate({ date: "", slot: [] });
      } else {
        common.snack("E", "Selected date already exists");
      }
    } else {
      common.snack("E", "Please select date");
    }
  };

  const showNewDate = (e) => {
    e.preventDefault();
    setIsNewDateAdd(true);
    setNewDate({ date: "", slot: [] }); //resetting value
  };

  const setNewDateChange = (date) => {
    let newFields = common.reParse(newDate);
    newFields.date = date ? common.formatDate(date.toDate(), "YYYY-MM-DD") : "";
    setNewDate(newFields);
  };

  const addDelDate = (e, idx, type, data) => {
    e.preventDefault();
    let newFields = common.reParse(mInterviewField);
    if (type == "add") {
      newFields.slot.push({ date: data, slot: [] });
    } else {
      if (idx > -1) {
        newFields.slot.splice(idx, 1);
      }
    }
    setMInterviewField(newFields);
  };

  const addDelSlot = (e, idx, tdx, type, data) => {
    e.preventDefault();
    let newFields = common.reParse(mInterviewField);
    if (type == "add") {
      newFields.slot[idx].slot.push({ date: data, slot: [] });
    } else {
      if (tdx > -1) {
        newFields.slot[idx].slot.splice(tdx, 1);
      }
    }
    setMInterviewField(newFields);
  };

  const showTimePicker = (e, idx) => {
    e.preventDefault();
    setSlotTime(moment());
    let newFields = common.reParse(mInterviewField);
    newFields.slot[idx].add = true;
    setMInterviewField(newFields);
  };

  const saveSlot = (e, idx) => {
    e.preventDefault();
    if (slotTime) {
      let newFields = common.reParse(mInterviewField);
      let isEx = newFields.slot[idx].slot.filter(
        (e) => e == common.formatDate(slotTime, "hh:mm a")
      );
      if (isEx.length == 0) {
        newFields.slot[idx].slot.push(common.formatDate(slotTime, "hh:mm a"));
        newFields.slot[idx].add = false;
        setSlotTime(moment());
        setMInterviewField(newFields);
      } else {
        common.snack("E", "Selected slot time already exists");
      }
    } else {
      common.snack("E", "Please select slot time");
    }
  };

  const addMRow = (e, sec) => {
    e.preventDefault();
    if (sec == 1) {
      //missing field
      setMFields((oldData) => {
        let newData = common.reParse(oldData);
        newData.push(initialMFields);
        return newData;
      });
    } else if (sec == 2) {
      //interview field
      setIFields((oldData) => {
        let newData = common.reParse(oldData);
        let nRow = common.reParse(initialInterviewFields);
        nRow.id = common.randomString();
        newData.push(nRow);
        return newData;
      });
    } else if (sec == 3) {
      //mock interview field
      setMIFields((oldData) => {
        let newData = common.reParse(oldData);
        let nRow = common.reParse(initialMockInterviewFields);
        nRow.id = common.randomString();
        newData.push(nRow);
        return newData;
      });
    } else if (sec == 4) {
      //missing field
      setSFEFields((oldData) => {
        let newData = common.reParse(oldData);
        newData.push(initialMFields);
        return newData;
      });
    }
  };

  const removeMRow = (e, idx, sec) => {
    e.preventDefault();
    if (sec == 1) {
      setMFields((oldData) => {
        let newData = common.reParse(oldData);
        newData.splice(idx, 1);
        return newData;
      });
    } else if (sec == 2) {
      setIFields((oldData) => {
        let newData = common.reParse(oldData);
        newData.splice(idx, 1);
        return newData;
      });
    } else if (sec == 3) {
      setMIFields((oldData) => {
        let newData = common.reParse(oldData);
        newData.splice(idx, 1);
        return newData;
      });
    } else if (sec == 4) {
      setSFEFields((oldData) => {
        let newData = common.reParse(oldData);
        newData.splice(idx, 1);
        return newData;
      });
    }
  };

  const intakeChange = (e, name) => {
    let obj = common.reParse(intake);
    if (name == "intakeDiffer") {
      obj[name] = e.target.checked ? "Y" : "N";
      setIntake(obj);
    } else if (["intakeMonthYear"].includes(name)) {
      obj[name] = e;
      setIntake(obj);
    } else {
      obj[name] = e.target.value;
      setIntake(obj);
    }
  };

  const onFieldChange = (e, name) => {
    let newFields = common.reParse(fields);
    newFields[name] = e.target.checked ? "Y" : "N";
    setFields(newFields);
    if (name == "Cattr5" && e.target.checked) {
      setMIFields([initialMockInterviewFields]);
    }
  };

  // Select handler
  const onSelectHandler = (data, name) => {
    let newFields = common.reParse(fields);
    newFields[name] = data;
    setFields(newFields);
    if (name == "ApprStatus" && data.value == "Enrolled") {
      if (sfeFields.length > 0) {
        sfeFields.map((sField) => {
          if (sField.status != "reviewed") {
            common.snack(
              "E",
              "Can't change Deal Status with Missing Fields Progress"
            );
          }
        });
      }
    }
  };

  //textarea, text, , datepicker handler
  const onChange = (e) => {
    let newFields = common.reParse(fields);
    if (
      e &&
      e.target &&
      (e.target.type == "text" || e.target.type == "textarea")
    ) {
      const { name, value } = e.target;
      newFields[name] = value;
    } else {
      newFields["Dattr1"] = e;
    }
    newFields.isUpdate = true;
    setFields(newFields);
  };

  const saveMockInterviewSlot = (e) => {
    e.preventDefault();
    let mockIntFields = common.reParse(miFields);
    let isEmpty = "";
    mInterviewField &&
      mInterviewField.slot.length > 0 &&
      mInterviewField.slot.map((item) => {
        if (item.slot.length == 0) {
          isEmpty = "empty";
        }
      });
    if (isEmpty != "empty") {
      if (condOrSfeSlot) {
        mockIntFields.map((obj) => {
          if (obj.id == mInterviewField.id) {
            obj.slot = mInterviewField.slot;
          }
        });
        setMIFields(mockIntFields);
      } else {
        let newFields = mInterviewField;
        setSFESlots(newFields);
      }
      setMInterviewField(null);
      setInterviewModel(false);
    } else {
      common.snack("E", "Please select slot time");
    }
  };

  // file handler
  const onFileChangeA = (e) => {
    const files = e.target.files;
    setFileA((prev) => {
      let nData = [...files];
      let result = [];
      if (nData.length > 3) {
        result = prev;
        common.notify("E", "Allowed only 3 files");
      } else {
        result = nData;
      }
      return result;
    });
  };

  const onFileChangeB = (e) => {
    const files = e.target.files;
    setFileB((prev) => {
      let nData = [...files];
      let result = [];
      if (nData.length > 3) {
        result = prev;
        common.notify("E", "Allowed only 3 files");
      } else {
        result = nData;
      }

      return result;
    });
  };

  // const onFileChangeC = (e) => {
  //   const files = e.target.files;
  //   setFileC((prev) => {
  //     let nData = [...files];
  //     let result = [];
  //     if (nData.length > 3) {
  //       result = prev;
  //       common.notify("E", "Allowed only 3 files");
  //     } else {
  //       result = nData;
  //     }

  //     return result;
  //   });
  // };

  const delImage = (e, src) => {
    e.preventDefault();
    let newFields = common.reParse(fields);
    if (src == "fileA") {
      setFileA([]);
      newFields.Cattr8 = "";
    } else if (src == "fileB") {
      setFileB([]);
      newFields.Cattr12 = "";
    }

    setFields(newFields);
  };

  const uploadFile = async (idx) => {
    var FormDataObj = new FormData();
    if (idx == "fileA") {
      fileA.forEach((i, k) => {
        FormDataObj.append("file", i);
      });
    } else if (idx == "fileB") {
      fileB.forEach((i, k) => {
        FormDataObj.append("file", i);
      });
    } else if (idx == "fileC") {
      fileC.forEach((i, k) => {
        FormDataObj.append("file", i);
      });
    }

    let data = {
      url: "common_upload_direct",
      method: "POST",
      auth: "config",
      body: FormDataObj,
      ssr: true,
      cType: 6,
    };
    let resultData = await eduTechSerivces.call(data);
    return resultData;
  };

  const previewImage = (e, file) => {
    e.preventDefault();
    setImgPreview(true);
    setImgPreviewSrc(file);
  };

  const openModel = (e, obj) => {
    e.preventDefault();
    setInterviewModel(true);
    if (obj) {
      setMInterviewField(obj);
      setCondOrSfeSlot(true);
    } else {
      setCondOrSfeSlot(false);
      setMInterviewField({
        slot: sfeSlots.slot,
        bookedSlot: sfeSlots.bookedSlot,
      });
    }
  };

  const handleModel = () => {
    setInterviewModel(false);
  };

  const handleClose = () => {
    setImgPreview(false);
    setImgPreviewSrc("");
  };

  const onSubmit = async () => {
    setIsSubmit(true);
    let aResult = {};
    let bResult = {};
    let cResult = {};
    let error = "";
    if (fields.ApprStatus.value == "Conditionals") {
      // upload 1
      if (fileA?.length) {
        aResult = await uploadFile("fileA");
        if (aResult.status !== 200) {
          error = aResult.data.error;
        }
      }
    } else if (fields.ApprStatus.value == "Unconditionals") {
      // upload 2
      if (!error && fileB?.length) {
        bResult = await uploadFile("fileB");
        if (bResult.status !== 200) {
          error = bResult.data.error;
        }
      }
    } else if (fields.ApprStatus.value == "Acceptance") {
      // upload 3
      if (!error && fileC?.length) {
        cResult = await uploadFile("fileC");
        if (cResult.status !== 200) {
          error = cResult.data.error;
        }
      }
    }
    if (error) {
      common.snack("E", error);
      setIsSubmit(false);
    } else {
      let inputs = await formInputs(aResult, bResult, cResult);
      if (inputs.valid == true && inputs.dealData && inputs.appData) {
        let url =
          envConfig.BASE_API +
          Services.CRM_DEAL_OPPORTUNITY +
          "/" +
          props.data.value1;

        let data = {
          url: url,
          type: "dynamic",
          method: "PATCH",
          body: JSON.stringify(inputs.dealData),
          auth: "token",
          cType: 4,
        };
        api.call(
          data,
          async (response) => {
            window.scrollTo(0, 0);
            setIsSubmit(false);
            if (Object.keys(inputs.appData).length > 0) {
              await eduTechSerivces.updateApplicationInfo(
                fields.Cattr2,
                inputs.appData
              );
            }
            // if (typeof props.onSave !== "undefined") {
            //   props.onSave(response);
            setDealStatus(common.enableDealStatus(fields.ApprStatus.value));
            setFileA([]);
            setFileB([]);
            setFileC([]);
            setCRMDealData(response.data);
            setIntake(intakeFields);
            getEditData();
            common.snack("S", "Successfully Updated");
          },
          (error) => {
            setIsSubmit(false);
          },
          (final) => {}
        );
      } else {
        setIsSubmit(false);
      }
    }
  };

  const formInputs = async (aResult, bResult, cResult) => {
    let appData = {}; //Edutech app fields
    let uFields = {}; //CRM Deal update fields
    let valid = true;

    if (
      intake.intakeDiffer === "Y" &&
      (!intake.intakeMonthYear.value || !intake.intakeReason)
    ) {
      valid = false;
      common.snack(
        "E",
        "You have selected intake differ, please fill all intake fields"
      );
    }

    if (
      intake.intakeDiffer === "Y" &&
      intake.intakeMonthYear.value &&
      intake.intakeReason
    ) {
      let intakes = intake.intakeMonthYear.value.split(" ");
      appData.intake = {
        intakeYear: intakes[0],
        intakeMonth: intakes[1],
        intakeReason: intake.intakeReason,
      };
      appData.type = "review";
      appData.category = "intake_diff";
    }

    if (
      fields.Cattr9 == "Y" ||
      (fields.Cattr28 && fields.Cattr20 == "accepted")
    ) {
      if (fields.ApprStatus.value != fields.ApprStatus) {
        valid = false;
        common.snack(
          "E",
          "Deal status can't change after application rejected or withdrawn"
        );
      }
    } else {
      //Deal status
      if (fields.ApprStatus.value) uFields.ApprStatus = fields.ApprStatus.value;

      if (
        fields.Cattr9 != "Y" &&
        fields.Cattr28.value == "Not withdrawn" &&
        fields.Cattr20.value != "accepted"
      ) {
        if (fields.ApprStatus.value == "Submissions") {
          appData.status = "submitted_to_university";
        }

        if (fields.ApprStatus.value == "Conditionals") {
          uFields.Cattr5 = fields.Cattr5;
          uFields.Cattr39 = fields.Cattr39;
          uFields.Cattr40 = fields.Cattr40;
          let validCndOffer = true;
          if (aResult.data?.link?.length || fields.Cattr8) {
            let url = aResult.data?.link[0] || fields.Cattr8;
            appData.status = "conditional_offer";
            appData.offerLetter = url; //offerletter url
            uFields.Cattr8 = url; //offerletter url
            appData.missingInfo = []; //clear missing info
          } else {
            validCndOffer = false;
            valid = false;
            common.snack("E", "Please upload conditional offer letter");
            setIsSubmit(false);
          }
          if (validCndOffer) {
            if (
              fields.Cattr39 == "Y" ||
              fields.Cattr40 == "Y" ||
              fields.Cattr5 == "Y"
            ) {
              if (fields.Cattr39 == "Y") {
                //Interview
                if (iFields.length > 0) {
                  let mData = [];
                  iFields.map((obj) => {
                    if (
                      obj.mode &&
                      obj.type &&
                      (obj.desc || obj.description) &&
                      obj.date &&
                      obj.venue &&
                      obj.status
                    ) {
                      mData.push({
                        id: obj.id,
                        mode: obj.mode,
                        type: obj.type,
                        date: common.formatDate(
                          obj.date || new Date(),
                          "YYYY-MM-DDTHH:mm:ssZ"
                        ),
                        venue: obj.venue,
                        description: obj.desc || obj.description,
                        status: obj.status,
                        userReqStatus: obj.userReqStatus,
                        cancelReason: obj.cancelReason,
                      });
                    } else {
                      valid = false;
                    }
                  });
                  if (valid == false) {
                    valid = false;
                    common.snack(
                      "E",
                      "Please fill all the interview information"
                    );
                    setIsSubmit(false);
                  } else {
                    appData.multiStatus = [
                      {
                        status: "conditional_offer_interview",
                        process: mData,
                      },
                    ];
                    uFields.Clattr2 = JSON.stringify(iFields);
                  }
                }
              }

              if (valid == true && fields.Cattr5 == "Y") {
                //Mock Interview
                if (miFields.length > 0) {
                  let mData = [];
                  miFields.map((obj) => {
                    if (
                      obj.mode &&
                      obj.type &&
                      (obj.desc || obj.description) &&
                      obj.slot.length > 0 &&
                      obj.venue &&
                      obj.status
                    ) {
                      let convertSlot = [];
                      obj.slot.map((s) => {
                        let slotArray = [];
                        s.slot.map((sobj) => {
                          slotArray.push(new Date(s.date + " " + sobj));
                        });
                        convertSlot.push({ date: s.date, slot: slotArray });
                      });
                      mData.push({
                        id: obj.id,
                        mode: obj.mode,
                        type: obj.type,
                        slot: convertSlot,
                        bookedSlot: obj.bookedSlot,
                        venue: obj.venue,
                        description: obj.desc || obj.description,
                        status: obj.status,
                      });
                    } else {
                      valid = false;
                    }
                  });
                  if (valid == false) {
                    valid = false;
                    common.snack(
                      "E",
                      "Please fill all the mock interview information"
                    );
                    setIsSubmit(false);
                  } else {
                    if (appData.multiStatus && appData.multiStatus.length > 0) {
                      appData.multiStatus.push({
                        status: "conditional_offer_mock",
                        process: mData,
                      });
                    } else {
                      appData.multiStatus = [
                        {
                          status: "conditional_offer_mock",
                          process: mData,
                        },
                      ];
                    }
                    uFields.Clattr1 = JSON.stringify(mData);
                  }
                }
              }

              if (valid == true && fields.Cattr40 == "Y") {
                //Missing Fields
                if (mFields.length > 0) {
                  let mData = [];
                  mFields.map((obj) => {
                    if (obj.field && obj.desc && obj.status) {
                      mData.push({
                        field: obj.field,
                        description: obj.desc,
                        status: obj.status,
                      });
                    } else {
                      valid = false;
                    }
                  });
                  if (valid == false) {
                    valid = false;
                    common.snack("E", "Please fill the missing information");
                    setIsSubmit(false);
                  } else {
                    appData.missingInfo = mData;

                    var filtered = mSel.filter(Boolean);
                    uFields.Cattr6 = filtered.join(",");
                    uFields.Clattr6 = JSON.stringify(mFields);
                  }
                }
              }
            } else {
              valid = false;
              common.snack("E", "Please select at least one conditional offer");
              setIsSubmit(false);
            }
          }
        } else if (fields.ApprStatus.value == "Unconditionals") {
          if (bResult.data?.link?.length) {
            let url = bResult.data?.link[0];
            appData.status = "unconditional_offer";
            appData.offerLetter = url; //offerletter url

            uFields.Cattr12 = url;
            //clear missing fields
            uFields.Cattr6 = "";
            uFields.Clattr6 = null;
            // if (fields?.Cattr32 === "Y") {
            //   uFields.Cattr32 = fields?.Cattr32;
            //appData.status = "course_payment";
            // }
          } else if (fields.Cattr12 == null) {
            valid = false;
            common.snack("E", "Please upload unconditional offer letter");
            setIsSubmit(false);
          }
        } else if (fields.ApprStatus.value == "Enrolled") {
          appData.status = "enrolled";
          appData.action = "pending";
          appData.description = "Course Enrolled Partially";
        } else if (fields.ApprStatus.value == "Fully Enrolled") {
          appData.status = "enrolled";
          appData.action = "completed";
          appData.description = "Course Enrolled Successfully";
        }
        //sfe 02-aug-2024
        if (fields.Cattr14?.value) {
          let mData = [];
          let sfe_MissingFields = [];
          if (sfeFields.length > 0) {
            sfeFields.map((obj) => {
              let sField = eduTechConfig.sfeDocuments.filter((val) => {
                return val.value.includes(obj.field);
              });

              if (sField.length > 0) {
                sfe_MissingFields.push(sField[0].label);
              }

              if (obj.field && obj.desc && obj.status) {
                mData.push({
                  field: obj.field,
                  description: obj.desc,
                  status: obj.status,
                });
              } else if (sfeFields.length > 1) {
                valid = false;
              }
            });
          }
          if (valid == false) {
            common.snack("E", "Please fill the SFE documents");
            setIsSubmit(false);
          } else {
            let sfeData = {};
            sfeData.sfeStatus = fields.Cattr14?.value;
            sfeData.sfeMissing = sfe_MissingFields;
            if (appData.status) {
              sfeData.type = appData.status;
            } else {
              sfeData.type = appInfo?.currentStatus;
            }
            sfeData.status = "sfe_process";
            sfeData.description =
              "Please stay in touch as we working on your SFE loan";
            sfeData.action = fields.Cattr14?.value;
            uFields.Cattr14 = fields.Cattr14?.value;

            if (mData.length > 0) {
              sfeData.missingInfo = mData;
            }
            if (sfeSlots?.slot.length > 0) {
              let convertSlot = [];

              sfeSlots.slot.map((obj) => {
                let slotArray = [];
                obj.slot.map((s) => {
                  slotArray.push(new Date(obj.date + " " + s));
                });
                convertSlot.push({ date: obj.date, slot: slotArray });
              });
              sfeData.slot = convertSlot;
              sfeData.bookedSlot = sfeSlots.bookedSlot;
              let udata = {
                slot: convertSlot,
                bookedSlot: sfeSlots.bookedSlot,
              };
              appData.sfeField = {
                interview: udata,
              };
            }

            //uFields.ApprStatus = "SFE";
            var filtered = sfeSel.filter(Boolean);
            appData.sfe = sfeData;

            appData.sfeField = {
              sfeStatus: sfeData.sfeStatus,
              sfeMissing: sfeData.sfeMissing,
              fields: filtered ? filtered.join(",") : null,
              missing: filtered ? sfeFields : null,
              interview: appData.sfeField?.interview || null,
            };
          }
        }
      }
    }
    //cancelled request
    if (fields?.Cattr28?.value !== "Not withdrawn") {
      if (fields?.Cattr28?.value !== "Withdrawn by admin") {
        if (fields.Cattr20.value == "accepted") {
          appData.status = "cancelled";
          appData.action = "accepted";
          uFields.Cattr24 = fields.Cattr24;
          uFields.Cattr20 = fields.Cattr20.value;
        } else {
          appData.status = "cancelled";
          appData.action = "rejected";
          appData.description = fields.Cattr24;
          uFields.Cattr24 = fields.Cattr24;
          uFields.Cattr20 = fields.Cattr20?.value;
        }
      } else {
        if (fields.Cattr20.value == "accepted") {
          appData.status = "cancelled";
          appData.action = "accepted";
          appData.description = fields.Cattr24;
          uFields.Cattr20 = fields.Cattr20?.value;
          uFields.Cattr24 = fields.Cattr24;
        } else {
          appData.status = "cancelled";
          appData.action = "rejected";
          appData.description = fields.Cattr24;
          uFields.Cattr24 = fields.Cattr24;
          uFields.Cattr20 = fields.Cattr20?.value;
        }
      }
    }

    if (fields.Cattr9 == "Y") {
      if (fields.Cattr17) {
        appData.status = "rejected";
        appData.description = fields.Cattr17;
        uFields.Cattr17 = fields.Cattr17;
        uFields.Cattr9 = fields.Cattr9;
      } else {
        valid = false;
        common.snack("E", "Please enter application rejected reason");
      }
    }

    appData.dealId = props.data.value1;
    uFields.Cattr28 = fields?.Cattr28?.value;
    //Responsible person
    if (fields.SalesPersonId.value) {
      uFields.SalesPersonId = fields.SalesPersonId.value;
      appData.salesPersonId = fields.SalesPersonId.value;
      // appData.responsiblePersonEmail = fields.responsiblePersonEmail;
    }
    if (appData.intake?.intakeMonth) {
      if (appData.status) {
        appData.type = appData.status;
      } else {
        appData.type = appInfo?.currentStatus;
      }
    }
    return {
      valid: valid,
      dealData: uFields,
      appData: appData,
    };
  };

  const renderAttachment = (docs) => {
    if (docs !== undefined || docs !== null) {
      const imgArr = docs.filter((e) => {
        if (e !== null) {
          return ["jpg", "jpeg", "png"].includes(e.split(".").pop());
        }
      });
      const pdf = docs.filter((e) => {
        if (e !== null) {
          return !["jpg", "jpeg", "png"].includes(e.split(".").pop());
        }
      });
      return (
        <div>
          <div className="attached-img-wrap">
            {imgArr.map((image, idx) => {
              return (
                <img
                  key={idx}
                  src={common.loadEduImage(image)}
                  onClick={(e) => previewImage(e, image)}
                />
              );
            })}
          </div>
          {pdf.map((image, idx) => {
            return (
              <div className="attach-link" key={idx}>
                <a href={common.loadEduImage(image)} download target="_blank">
                  <img
                    src={common.loadImg("external-link-icn.svg")}
                    className="me-2"
                  />
                  {image.split("/").pop()}
                </a>
              </div>
            );
          })}
        </div>
      );
    } else {
      return "--";
    }
  };

  const closeForm = (id, data) => {
    let ids = id ? id : props.data.value1;
    props.closeForm(false, "id", data, ids);
  };

  // Floating React select
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const openEmail = (e) => {
    e.preventDefault();
    setOpenEMail(true);
  };

  const closeEmail = (e) => {
    e.preventDefault();
    setOpenEMail(false);
  };

  //get Employee
  const getEmpByEmpId = (EmpId) => {
    let url = `${envConfig.BASE_API}${CRM_PAY_EMPLOYEE}/${EmpId}`;
    api.call(
      { url: url, type: "dynamic", method: "GET", auth: "token" },
      (response) => {
        console.log("==================response.data", response.data);
        if (response.status === 200) {
          setSalesPerson(response.data);
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  const uploadAttachFile = async () => {
    var FormDataObj = new FormData();
    files.forEach((i, k) => {
      FormDataObj.append("file", i);
    });
    let data = {
      url: "common_upload_direct",
      method: "POST",
      auth: "config",
      body: FormDataObj,
      ssr: true,
      cType: 6,
    };
    let resultData = await eduTechSerivces.call(data);
    return resultData;
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    const authInfo = common.authInfo();
    let uploadFiles = [];
    let isTrue = true;
    let formData = new FormData();
    if (fields.emailContent) {
      setIsEmailSubmit(true);
      if (files?.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append("attachment", files[i]);
        }
        isTrue = false;
        uploadFiles = await uploadAttachFile();
        if (uploadFiles) {
          isTrue = true;
        }
      }
      //post request & refresh
      if (isTrue) {
        formData.append("salesPersonEmail", salesPerson.Email);
        formData.append("content", fields.emailContent);
        // formData.append("salesPerson", authInfo.UserName);
        formData.append("salesPerson", salesPerson.EmployeeName);
        formData.append("documents", JSON.stringify(uploadFiles?.data?.url));

        // let resultData = await eduTechSerivces.sendApplicationEmail(fields.Cattr1, formData);
        let data = {
          url: "send_email",
          method: "POST",
          auth: "config",
          query: `/${fields.Cattr2}`,
          body: formData,
          ssr: true,
          cType: 6,
        };
        let resultData = await eduTechSerivces.call(data);
        if (resultData.status == 200) {
          //add new msg to history
          let logs = common.reParse(emailHistory);
          logs.push({
            content: fields.emailContent,
            status: "sended",
            createdAt: new Date(),
          });
          setEmailHistory(logs);

          //reset text area
          let newFields = common.reParse(fields);
          newFields.emailContent = "";
          setFields(newFields);
          setFiles([]);
          common.snack("S", "Email sended successfully");
        } else {
          common.snack("E", "Sending message failed");
        }
        setIsEmailSubmit(false);
      }
    } else {
      common.snack("W", "Please enter email content");
    }
  };

  // file handler
  const onFileChange = (e) => {
    const files = e.target.files;
    setFiles((prev) => {
      let nData = [...prev, ...files];
      return nData;
    });
  };

  const delImages = (e, i) => {
    e.preventDefault();
    setFiles((oldData) => {
      let newData = [...oldData];
      newData.splice(i, 1);
      return newData;
    });
  };

  // render
  return (
    <Box>
      <Paper variant="contained dynamic-form-wrap" square>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Box py={1} px={1} className="icon-back icon-buton">
                  <IconButton onClick={(e) => closeForm()}>
                    <img
                      src={common.loadImg("backArrowKanban.svg")}
                      alt="back"
                    />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item className="df-main-head">
                <div className="detail-title">
                  {props.data.value1 ? "Update Form" : "Add Form"}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Box px={2}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <IconButton
                    onClick={(e) => {
                      setStatus("process");
                      getEditData();
                    }}
                  >
                    <img
                      src={common.loadImg("refresh-icon.svg")}
                      alt="document"
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Box className="verical-divider">
                    <Divider orientation="vertical" />
                  </Box>
                </Grid>
                {/* <Grid item>
                  <Box>
                    <Button
                      className="save-note"
                      type="submit"
                      onClick={() => onSubmit()}
                      disabled={isSubmit ? true : false}
                    >
                      {isSubmit
                        ? "Processing..."
                        : props.data.value1
                        ? "Update Changes"
                        : "Save Changes"}
                    </Button>
                  </Box>
                </Grid>
                <Grid item>
                  <Box className="vertical-divider">
                    <Divider orientation="vertical" />
                  </Box>
                </Grid>
                <Grid item>
                  <Box className="revert-close">
                    <Button
                      className="save-note pe-0"
                      onClick={(e) => closeForm()}
                    >
                      Revert and Close
                      <img
                        src={common.loadImg("closeKanban.svg")}
                        alt="close"
                      />
                    </Button>
                  </Box>
                </Grid> */}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Divider />
        </Box>
        <StatusBar status={status} />
        {status == "success" && (
          <>
            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title send-mail-sec mt-3">
                <span>Deal Information</span>
                <Link
                  to="#"
                  onClick={(e) => {
                    openEmail(e);
                  }}
                >
                  Send Email
                </Link>
              </div>
              <Box mt={3}>
                <Divider />
              </Box>

              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">First Name</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr10 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Last Name</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr11 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Email</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Email ? (
                              <a
                                href={"mailto:" + fields.Email}
                                target="_blank"
                              >
                                {tools.hideEmail(fields.Email)}
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Mobile</div>
                          <div className="camp-sub-des mt-2">
                            {fields.MobileNumber ? (
                              <a href={"tel:" + fields.MobileNumber}>
                                {tools.hideMobile(fields.MobileNumber)}
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">Application ID</div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr2 || "-"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Agent Email & Name
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr1 && fields.Cattr4 ? (
                              <span>
                                <a href={"tel:" + fields.Cattr1}>
                                  {tools.hideMobile(fields.Cattr1)}
                                </a>{" "}
                                {" & " + fields.Cattr4}
                              </span>
                            ) : (
                              "-"
                            )}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  {fields.Eattr2 && (
                    <Col xs={6} className="body-th common-input-style">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">Applied Course</div>
                            <div className="camp-sub-des mt-2">
                              {fields.Eattr2 || "-"}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {fields.Eattr1 && (
                    <Col xs={6} className="body-th common-input-style">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">University</div>
                            <div className="camp-sub-des mt-2">
                              {fields.Eattr1 || "-"}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {fields.Eattr5 && (
                    <Col xs={6} className="body-th common-input-style">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">Intake</div>
                            <div className="camp-sub-des mt-2">
                              {fields.Eattr5} {fields.Eattr6 || "-"}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                </Row>
              </Box>
            </Box>

            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title mt-3">
                Student Finance Information
              </div>
              <Box mt={3}>
                <Divider />
              </Box>
              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Have You Applied for Student Finance before?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr32 == "Y" ? "Yes" : "No"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            How many years of funding have you taken before?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr15 || ""}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>

                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            What is your Visa Status?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.PrimaryContactName || ""}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            How long have you been living in UK?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr13 || ""}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Are you a Refugee/ Asylum Seeker?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr7 == "Y" ? "Yes" : "No"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Have you spent any time in public care up to the age
                            of 18?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr16 == "Y" ? "Yes" : "No"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xs={6} className="body-th">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box>
                          <div className="camp-sub-name">
                            Disability/special needs?
                          </div>
                          <div className="camp-sub-des mt-2">
                            {fields.Cattr27 == "Y" ? "Yes" : "No"}
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Col>
                  {appInfo?.profile && appInfo?.profile["passportDocs"] && (
                    <Col xs={6} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">
                              Passport / Birth Certificate
                            </div>
                            <div className="camp-sub-des mt-2">
                              {renderAttachment(
                                appInfo?.profile["passportDocs"]
                              )}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {appInfo?.profile && appInfo?.profile["brpDocs"] && (
                    <Col xs={3} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">
                              Biometric Residence Permit (BRP)
                            </div>
                            <div className="camp-sub-des mt-2">
                              {renderAttachment([appInfo?.profile["brpDocs"]])}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {appInfo?.profile && appInfo?.profile["euDocs"] && (
                    <Col xs={3} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">EU Share Code</div>
                            <div className="camp-sub-des mt-2">
                              {renderAttachment([appInfo?.profile["euDocs"]])}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {appInfo?.profile && appInfo?.profile["homeOfficeDocs"] && (
                    <Col xs={3} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">
                              Home Office Letter
                            </div>
                            <div className="camp-sub-des mt-2">
                              {renderAttachment([
                                appInfo?.profile["homeOfficeDocs"],
                              ])}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                  {appInfo?.profile && appInfo?.profile["poeDocs"] && (
                    <Col xs={3} className="body-th">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Box>
                            <div className="camp-sub-name">
                              Proof of evidence of Stay
                            </div>
                            <div className="camp-sub-des mt-2">
                              {renderAttachment(appInfo?.profile["poeDocs"])}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  )}
                </Row>
              </Box>
            </Box>

            <Box p={2} px={5} className="form-wrap">
              <div className="info-title dynamic-form-title mt-3">
                Application Status
              </div>
              <Box mt={3}>
                <Divider />
              </Box>

              <Box mt={2} className="camp-edit meet-table">
                <Row className="m-0">
                  <Col
                    xs={6}
                    className="body-th common-input-style common-select-border"
                  >
                    <Box className="">
                      <FormControl fullWidth className="select-float-label">
                        <FieldSelect
                          api="CRM_EMPLOYEES"
                          get="EmployeeName"
                          set="EmployeeId"
                          value={fields.SalesPersonId}
                          isMulti={false}
                          isRender={true}
                          onSelect={(data) =>
                            onSelectHandler(data, "SalesPersonId")
                          }
                          placeholder="Responsible Person*"
                        />
                      </FormControl>
                    </Box>
                  </Col>
                  <Col xs={6} className="body-th common-input-style">
                    <Box className="">
                      <FormControl
                        fullWidth
                        className="select-float-label common-padding-zero-select"
                      >
                        <Select
                          className="text-box custom-react-select"
                          labelId="select"
                          id="select"
                          label="Deal Status"
                          menuPortalTarget={document.body}
                          menuPlacement="auto"
                          onChange={(data) =>
                            onSelectHandler(data, "ApprStatus")
                          }
                          options={dealStatus}
                          isOptionDisabled={(option) => option.disabled}
                          isDisabled={
                            fields.Cattr9 === "Y" ||
                            fields?.Cattr20?.value === "accepted"
                          }
                          defaultValue={fields["ApprStatus"]}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          placeholder="Deal Status"
                          styles={selectStyles}
                        ></Select>
                      </FormControl>
                    </Box>
                  </Col>
                  {fields.ApprStatus.value == "Conditionals" && (
                    <Col xs={12} className="body-th common-input-style">
                      <FormControlLabel
                        className="dynamic-checkbox ms-0 checkbox-space"
                        control={<Checkbox />}
                        value={fields.Cattr39}
                        label={"Interview Scheduled?"}
                        labelPlacement="end"
                        checked={fields.Cattr39 === "Y" ? true : false}
                        onChange={(e) => onFieldChange(e, "Cattr39")}
                      />
                      <FormControlLabel
                        className="dynamic-checkbox ms-0 checkbox-space"
                        control={<Checkbox />}
                        value={fields.Cattr5}
                        label={"Mock Interview Scheduled?"}
                        labelPlacement="end"
                        checked={fields.Cattr5 === "Y" ? true : false}
                        onChange={(e) => onFieldChange(e, "Cattr5")}
                      />
                      <FormControlLabel
                        className="dynamic-checkbox ms-0 checkbox-space"
                        control={<Checkbox />}
                        value={fields.Cattr40}
                        label={"Is Missing Information?"}
                        labelPlacement="end"
                        checked={fields.Cattr40 === "Y" ? true : false}
                        onChange={(e) => onFieldChange(e, "Cattr40")}
                      />
                    </Col>
                  )}
                </Row>
              </Box>
            </Box>

            {fields.ApprStatus.value == "Conditionals" &&
              fields.Cattr39 == "Y" && (
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Interview
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>
                  <Box mt={2} className="camp-edit meet-table">
                    {iFields.map((iObj, idx) => {
                      let clCount = idx;
                      return (
                        <Row className="m-0" key={clCount}>
                          <Col
                            xs={3}
                            className="body-th common-input-style mandatoryclass"
                          >
                            <Box className="filled-box">
                              <Select
                                className="text-box custom-react-select"
                                labelId="select"
                                label="Interview Type"
                                menuPortalTarget={document.body}
                                id={"it_" + clCount}
                                onChange={(data) =>
                                  onMissingSelect(data, "type", clCount, 2)
                                }
                                options={interviewType}
                                value={interviewType.find(
                                  (o) => o.value == iObj.type
                                )}
                                isMulti={false}
                                components={{
                                  ValueContainer: CustomValueContainer,
                                }}
                                placeholder="Interview Type"
                                styles={{
                                  container: (provided, state) => ({
                                    ...provided,
                                    marginTop: 0,
                                  }),
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    overflow: "visible",
                                  }),
                                  placeholder: (provided, state) => ({
                                    ...provided,
                                    position: "absolute",
                                    top:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? -15
                                        : "10%",
                                    transition: "top 0.1s, font-size 0.1s",
                                    fontSize:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? 11
                                        : 13,
                                    color: "#9daabb",
                                  }),
                                }}
                              ></Select>
                            </Box>
                          </Col>
                          <Col
                            xs={3}
                            className="body-th common-input-style mandatoryclass"
                          >
                            <Box className="filled-box">
                              <Select
                                className="text-box custom-react-select"
                                labelId="select"
                                label="Interview Mode"
                                menuPortalTarget={document.body}
                                id={"im_" + clCount}
                                onChange={(data) =>
                                  onMissingSelect(data, "mode", clCount, 2)
                                }
                                options={interviewMode}
                                components={{
                                  ValueContainer: CustomValueContainer,
                                }}
                                value={interviewMode.find(
                                  (o) => o.value == iObj.mode
                                )}
                                isMulti={false}
                                placeholder="Interview Mode"
                                styles={{
                                  container: (provided, state) => ({
                                    ...provided,
                                    marginTop: 0,
                                  }),
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    overflow: "visible",
                                  }),
                                  placeholder: (provided, state) => ({
                                    ...provided,
                                    position: "absolute",
                                    top:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? -15
                                        : "10%",
                                    transition: "top 0.1s, font-size 0.1s",
                                    fontSize:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? 11
                                        : 13,
                                    color: "#9daabb",
                                  }),
                                }}
                              ></Select>
                            </Box>
                          </Col>

                          <Col
                            xs={3}
                            className="body-th common-input-style mandatoryclass"
                          >
                            <Box className="text-box" id="float-label">
                              <label
                                className={
                                  iObj.date
                                    ? "Active text-label camp-time-label ml-4"
                                    : "text-label ml-4"
                                }
                              >
                                Select Date and Time
                              </label>
                              <Box className="ml-1 input-date-picker cust-br">
                                <DatePicker
                                  format="MM/DD/YYYY hh:mm A"
                                  hideMonth={true}
                                  hideWeekDays={true}
                                  hideYear={true}
                                  plugins={[
                                    <TimePicker
                                      hideSeconds
                                      position="bottom"
                                      className="ml-3"
                                    />,
                                  ]}
                                  render={<InputIcon />}
                                  value={
                                    iObj.date
                                      ? moment(iObj.date).toDate()
                                      : null
                                  }
                                  selected={
                                    iObj.date
                                      ? moment(iObj.date).toDate()
                                      : null
                                  }
                                  name="date"
                                  onChange={(date) =>
                                    onMissingSelect(date, "date", clCount, 2)
                                  }
                                />
                              </Box>
                            </Box>
                          </Col>
                          <Col
                            xs={3}
                            className="body-th common-input-style mandatoryclass"
                          >
                            <Box className="filled-box">
                              <TextField
                                id={"il_" + clCount}
                                value={iObj.venue}
                                label={
                                  iObj.mode == "Offline"
                                    ? "Interview Location"
                                    : "Meeting Link"
                                }
                                variant="filled"
                                inputprops={{ maxLength: 150 }}
                                onChange={(e) =>
                                  onMissingSelect(
                                    e.target.value,
                                    "venue",
                                    clCount,
                                    2
                                  )
                                }
                              />
                            </Box>
                          </Col>
                          <Col
                            xs={3}
                            className="body-th common-input-style mandatoryclass"
                          >
                            <Box className="filled-box">
                              <div className="form-floating">
                                <textarea
                                  className="form-control textarea"
                                  id={"ids" + clCount}
                                  value={iObj.desc || iObj?.description || ""}
                                  rows={2}
                                  maxLength={150}
                                  onChange={(e) =>
                                    onMissingSelect(
                                      e.target.value,
                                      "desc",
                                      clCount,
                                      2
                                    )
                                  }
                                ></textarea>
                                <label
                                  htmlFor="floatingTextarea"
                                  className="floating-label"
                                >
                                  Description
                                </label>
                              </div>
                            </Box>
                          </Col>
                          <Col
                            xs={3}
                            className="body-th common-input-style mandatoryclass"
                          >
                            <Box className="filled-box">
                              <Select
                                className="text-box custom-react-select"
                                labelId="select"
                                label="Interview Status"
                                menuPortalTarget={document.body}
                                value={interviewStatus.find(
                                  (o) => o.value == iObj.status
                                )}
                                isMulti={false}
                                id={"is_" + clCount}
                                onChange={(data) =>
                                  onMissingSelect(data, "status", clCount, 2)
                                }
                                isOptionDisabled={(option) => option.disabled}
                                options={interviewStatus}
                                components={{
                                  ValueContainer: CustomValueContainer,
                                }}
                                placeholder="Interview Status"
                                styles={{
                                  container: (provided, state) => ({
                                    ...provided,
                                    marginTop: 0,
                                  }),
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    overflow: "visible",
                                  }),
                                  placeholder: (provided, state) => ({
                                    ...provided,
                                    position: "absolute",
                                    top:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? -15
                                        : "10%",
                                    transition: "top 0.1s, font-size 0.1s",
                                    fontSize:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? 11
                                        : 13,
                                    color: "#9daabb",
                                  }),
                                }}
                              ></Select>
                            </Box>
                          </Col>
                          {((iObj.userReqStatus &&
                            ["Cancelled", "Rescheduled"].includes(
                              iObj.userReqStatus
                            )) ||
                            ["Cancelled", "Rescheduled"].includes(
                              iObj.status
                            )) && (
                            <Col xs={4} className="body-th common-input-style">
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                >
                                  <Box>
                                    <div className="camp-sub-name">
                                      {iObj.userReqStatus == "Cancelled"
                                        ? "Cancel"
                                        : "Reschedule"}{" "}
                                      Reason
                                    </div>
                                    <div className="camp-sub-des mt-2">
                                      {iObj.cancelReason || ""}
                                    </div>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Col>
                          )}
                          <Col
                            xs={2}
                            className="body-th common-input-style v-align-center"
                          >
                            <div className="form-group ">
                              {iFields.length === 1 && idx === 0 ? (
                                <Link
                                  to="#"
                                  className="add-rule-link add-rule"
                                  onClick={(e) => {
                                    addMRow(e, 2);
                                  }}
                                >
                                  +
                                </Link>
                              ) : (
                                idx === 0 && (
                                  <Link
                                    to="#"
                                    className="add-rule-link remove-rule"
                                    onClick={(e) => {
                                      removeMRow(e, clCount, 2);
                                    }}
                                  >
                                    -
                                  </Link>
                                )
                              )}
                              {idx > 0 && (
                                <React.Fragment>
                                  <Link
                                    to="#"
                                    className="add-rule-link remove-rule"
                                    onClick={(e) => removeMRow(e, clCount, 2)}
                                  >
                                    -
                                  </Link>
                                  {iFields.length - 1 == idx && (
                                    <Link
                                      to="#"
                                      className="add-rule-link add-rule"
                                      onClick={(e) => addMRow(e, 2)}
                                    >
                                      +
                                    </Link>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </Box>
                </Box>
              )}

            {fields.ApprStatus.value == "Conditionals" &&
              fields.Cattr5 == "Y" && (
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Mock Interview
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>
                  <Box mt={2} className="camp-edit meet-table">
                    {miFields.map((iObj, idx) => {
                      let bookedDate = iObj.bookedSlot
                        ? common.formatDate(iObj.bookedSlot, "YYYY-MM-DD")
                        : null;
                      let bookedTime = iObj.bookedSlot
                        ? common.formatDate(iObj.bookedSlot, "hh:mm a")
                        : null;

                      let clCount = idx;
                      return (
                        <Row className="m-0" key={clCount}>
                          <Col
                            xs={3}
                            className="body-th common-input-style mandatoryclass"
                          >
                            <Box className="filled-box">
                              <Select
                                className="text-box custom-react-select"
                                labelId="select"
                                label="Interview Type"
                                menuPortalTarget={document.body}
                                id={"imt_" + clCount}
                                onChange={(data) =>
                                  onMissingSelect(data, "type", clCount, 3)
                                }
                                options={minterviewType}
                                value={minterviewType.find(
                                  (o) => o.value == iObj.type
                                )}
                                isMulti={false}
                                components={{
                                  ValueContainer: CustomValueContainer,
                                }}
                                placeholder="Interview Type"
                                styles={{
                                  container: (provided, state) => ({
                                    ...provided,
                                    marginTop: 0,
                                  }),
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    overflow: "visible",
                                  }),
                                  placeholder: (provided, state) => ({
                                    ...provided,
                                    position: "absolute",
                                    top:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? -15
                                        : "10%",
                                    transition: "top 0.1s, font-size 0.1s",
                                    fontSize:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? 11
                                        : 13,
                                    color: "#9daabb",
                                  }),
                                }}
                              ></Select>
                            </Box>
                          </Col>
                          <Col
                            xs={3}
                            className="body-th common-input-style mandatoryclass"
                          >
                            <Box className="filled-box">
                              <Select
                                className="text-box custom-react-select"
                                labelId="select"
                                label="Interview Mode"
                                menuPortalTarget={document.body}
                                id={"imm_" + clCount}
                                onChange={(data) =>
                                  onMissingSelect(data, "mode", clCount, 3)
                                }
                                options={interviewMode}
                                value={interviewMode.find(
                                  (o) => o.value == iObj.mode
                                )}
                                isMulti={false}
                                components={{
                                  ValueContainer: CustomValueContainer,
                                }}
                                placeholder="Interview Mode"
                                styles={{
                                  container: (provided, state) => ({
                                    ...provided,
                                    marginTop: 0,
                                  }),
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    overflow: "visible",
                                  }),
                                  placeholder: (provided, state) => ({
                                    ...provided,
                                    position: "absolute",
                                    top:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? -15
                                        : "10%",
                                    transition: "top 0.1s, font-size 0.1s",
                                    fontSize:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? 11
                                        : 13,
                                    color: "#9daabb",
                                  }),
                                }}
                              ></Select>
                            </Box>
                          </Col>
                          <Col
                            xs={3}
                            className="body-th common-input-style mandatoryclass"
                          >
                            <Box className="filled-box">
                              <TextField
                                id={"imv_" + clCount}
                                value={iObj.venue}
                                label={
                                  iObj.mode == "Offline"
                                    ? "Interview Location"
                                    : "Meeting Link"
                                }
                                variant="filled"
                                inputprops={{ maxLength: 150 }}
                                onChange={(e) =>
                                  onMissingSelect(
                                    e.target.value,
                                    "venue",
                                    clCount,
                                    3
                                  )
                                }
                              />
                            </Box>
                          </Col>
                          <Col
                            xs={3}
                            className="body-th common-input-style mandatoryclass"
                          >
                            <Box className="filled-box">
                              <div className="form-floating">
                                <textarea
                                  className="form-control textarea"
                                  id={"mc" + clCount}
                                  value={iObj.desc || iObj?.description || ""}
                                  rows={2}
                                  maxLength={150}
                                  onChange={(e) =>
                                    onMissingSelect(
                                      e.target.value,
                                      "desc",
                                      clCount,
                                      3
                                    )
                                  }
                                ></textarea>
                                <label
                                  htmlFor="floatingTextarea"
                                  className="floating-label"
                                >
                                  Description
                                </label>
                              </div>
                            </Box>
                          </Col>
                          <Col
                            xs={3}
                            className="body-th common-input-style mandatoryclass"
                          >
                            <label className="camp-sub-name">
                              Interview Date with Slots
                            </label>
                            {iObj.slot &&
                              iObj.slot.length > 0 &&
                              iObj.slot.map((obj, idx) => {
                                return (
                                  <div
                                    key={idx}
                                    className="cond-date-wrap mt-2"
                                  >
                                    {obj.date || ""}
                                    {obj.slot.map((time, idx) => {
                                      return (
                                        <div
                                          key={idx}
                                          className={
                                            obj.date == bookedDate &&
                                            time == bookedTime
                                              ? "cond-slot-wrap mt-1 booked-slot"
                                              : "cond-slot-wrap mt-1"
                                          }
                                        >
                                          {time ? time : ""}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            <div className="mt-2">
                              <Link
                                to="#"
                                onClick={(e) => openModel(e, iObj)}
                                className="simple-btn"
                              >
                                Add/Edit
                              </Link>
                            </div>
                          </Col>

                          <Col
                            xs={3}
                            className="body-th common-input-style mandatoryclass"
                          >
                            <Box className="filled-box">
                              <Select
                                className="text-box custom-react-select"
                                labelId="select"
                                label="Interview Status"
                                menuPortalTarget={document.body}
                                value={minterviewStatus.find(
                                  (o) => o.value == iObj.status
                                )}
                                isMulti={false}
                                id={"is_" + clCount}
                                onChange={(data) =>
                                  onMissingSelect(data, "status", clCount, 3)
                                }
                                isOptionDisabled={(option) => option.disabled}
                                options={minterviewStatus}
                                components={{
                                  ValueContainer: CustomValueContainer,
                                }}
                                placeholder="Interview Status"
                                styles={{
                                  container: (provided, state) => ({
                                    ...provided,
                                    marginTop: 0,
                                  }),
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    overflow: "visible",
                                  }),
                                  placeholder: (provided, state) => ({
                                    ...provided,
                                    position: "absolute",
                                    top:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? -15
                                        : "10%",
                                    transition: "top 0.1s, font-size 0.1s",
                                    fontSize:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? 11
                                        : 13,
                                    color: "#9daabb",
                                  }),
                                }}
                              ></Select>
                            </Box>
                          </Col>

                          <Col
                            xs={2}
                            className="body-th common-input-style v-align-center"
                          >
                            <div className="form-group ">
                              {miFields.length === 1 && idx === 0 ? (
                                <Link
                                  to="#"
                                  className="add-rule-link add-rule"
                                  onClick={(e) => {
                                    addMRow(e, 3);
                                  }}
                                >
                                  +
                                </Link>
                              ) : (
                                idx === 0 && (
                                  <Link
                                    to="#"
                                    className="add-rule-link remove-rule"
                                    onClick={(e) => {
                                      removeMRow(e, clCount, 3);
                                    }}
                                  >
                                    -
                                  </Link>
                                )
                              )}
                              {idx > 0 && (
                                <React.Fragment>
                                  <Link
                                    to="#"
                                    className="add-rule-link remove-rule"
                                    onClick={(e) => removeMRow(e, clCount, 3)}
                                  >
                                    -
                                  </Link>
                                  {miFields.length - 1 == idx && (
                                    <Link
                                      to="#"
                                      className="add-rule-link add-rule"
                                      onClick={(e) => addMRow(e, 3)}
                                    >
                                      +
                                    </Link>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </Box>
                </Box>
              )}

            {fields.ApprStatus.value == "Conditionals" &&
              fields.Cattr40 == "Y" && (
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Missing Information
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    {mFields.map((mObj, idx) => {
                      let clCount = idx;
                      return (
                        <Row className="m-0" key={clCount}>
                          <Col xs={3} className="body-th common-input-style">
                            <Box className="">
                              <FormControl
                                fullWidth
                                className="select-float-label common-padding-zero-select"
                              >
                                <Select
                                  className="text-box custom-react-select"
                                  labelId="select"
                                  label="Missing Field"
                                  menuPortalTarget={document.body}
                                  id={"mlf_" + clCount}
                                  value={eduTechConfig.docMissingFields.find(
                                    (o) => o.value == mObj.field
                                  )}
                                  isMulti={false}
                                  onChange={(data) =>
                                    onMissingSelect(data, "field", clCount, 1)
                                  }
                                  options={eduTechConfig.docMissingFields.filter(
                                    (obj) => !mSel.includes(obj.value)
                                  )}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="Missing Field"
                                  styles={{
                                    container: (provided, state) => ({
                                      ...provided,
                                      marginTop: 0,
                                    }),
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflow: "visible",
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      position: "absolute",
                                      top:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? -15
                                          : "10%",
                                      transition: "top 0.1s, font-size 0.1s",
                                      fontSize:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? 11
                                          : 13,
                                      color: "#9daabb",
                                    }),
                                  }}
                                ></Select>
                              </FormControl>
                            </Box>
                          </Col>
                          <Col xs={3} className="body-th common-input-style">
                            <Box>
                              <div className="form-floating">
                                <textarea
                                  className="form-control textarea"
                                  id={"mfn_" + clCount}
                                  value={mObj.desc}
                                  onChange={(e) =>
                                    onMissingSelect(
                                      e.target.value,
                                      "desc",
                                      clCount,
                                      1
                                    )
                                  }
                                  maxLength={150}
                                ></textarea>
                                <label
                                  htmlFor="floatingTextarea"
                                  className="floating-label"
                                >
                                  Missing Field Note
                                </label>
                              </div>
                            </Box>
                          </Col>
                          <Col xs={3} className="body-th common-input-style">
                            <Box className="">
                              <FormControl
                                fullWidth
                                className="select-float-label common-padding-zero-select"
                              >
                                <Select
                                  className="text-box custom-react-select"
                                  labelId="select"
                                  label="Review Status"
                                  menuPortalTarget={document.body}
                                  id={clCount}
                                  value={reviewStatus.find(
                                    (o) => o.value == mObj.status
                                  )}
                                  isMulti={false}
                                  onChange={(data) =>
                                    onMissingSelect(data, "status", idx, 1)
                                  }
                                  options={reviewStatus}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="Review Status"
                                  styles={{
                                    container: (provided, state) => ({
                                      ...provided,
                                      marginTop: 0,
                                    }),
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflow: "visible",
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      position: "absolute",
                                      top:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? -15
                                          : "10%",
                                      transition: "top 0.1s, font-size 0.1s",
                                      fontSize:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? 11
                                          : 13,
                                      color: "#9daabb",
                                    }),
                                  }}
                                ></Select>
                              </FormControl>
                            </Box>
                          </Col>
                          <Col
                            xs={3}
                            className="body-th common-input-style v-align-center"
                          >
                            <div className="form-group ">
                              {mFields.length === 1 && idx === 0 ? (
                                <Link
                                  to="#"
                                  className="add-rule-link add-rule"
                                  onClick={(e) => {
                                    addMRow(e, 1);
                                  }}
                                >
                                  +
                                </Link>
                              ) : (
                                idx === 0 && (
                                  <Link
                                    to="#"
                                    className="add-rule-link remove-rule"
                                    onClick={(e) => {
                                      removeMRow(e, clCount, 1);
                                    }}
                                  >
                                    -
                                  </Link>
                                )
                              )}
                              {idx > 0 && (
                                <React.Fragment>
                                  <Link
                                    to="#"
                                    className="add-rule-link remove-rule"
                                    onClick={(e) => removeMRow(e, clCount, 1)}
                                  >
                                    -
                                  </Link>
                                  {mFields.length - 1 == idx && (
                                    <Link
                                      to="#"
                                      className="add-rule-link add-rule"
                                      onClick={(e) => addMRow(e, 1)}
                                    >
                                      +
                                    </Link>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </Box>
                </Box>
              )}

            {fields.ApprStatus.value == "Conditionals" && (
              <Box p={2} px={5} className="form-wrap">
                <div className="info-title dynamic-form-title mt-3">
                  Conditional Offer Letter
                </div>
                <Box mt={3}>
                  <Divider />
                </Box>

                <Box mt={2} className="camp-edit meet-table">
                  <Row className="m-0">
                    <Col xs={6} className="body-th common-input-style">
                      {fileA.length == 0 && !fields.Cattr8 && (
                        <div className="fileUpload">
                          <label className="upload">
                            <input
                              type="file"
                              id="fileA"
                              name="offerLetter1"
                              accept=".pdf,.jpg,.png,.doc"
                              value=""
                              multiple={false}
                              onChange={(e) => onFileChangeA(e)}
                            />
                            Upload
                          </label>
                        </div>
                      )}
                      <br />
                      {fileA.map((i, k) => (
                        <p className="file-list ps-4" key={k}>
                          {i.name}
                          <img
                            src={DeleteIcon}
                            className="del-icon ms-2"
                            alt="Delete"
                            onClick={(e) => delImage(e, "fileA")}
                          />
                        </p>
                      ))}
                      {fields.Cattr8 &&
                        renderAttachment([fields.Cattr8], "fileA")}
                    </Col>
                  </Row>
                </Box>
              </Box>
            )}

            {fields.ApprStatus.value == "Unconditionals" && (
              <Box p={2} px={5} className="form-wrap">
                <div className="info-title dynamic-form-title mt-3">
                  Unconditional Offer Letter
                </div>
                <Box mt={3}>
                  <Divider />
                </Box>

                <Box mt={2} className="camp-edit meet-table">
                  <Row className="m-0">
                    <Col xs={6} className="body-th common-input-style">
                      {fileB.length == 0 && !fields.Cattr12 && (
                        <div className="fileUpload">
                          <label className="upload">
                            <input
                              type="file"
                              id="fileB"
                              name="offerLetter2"
                              placeholder="Upload"
                              accept=".pdf,.jpg,.png,.doc"
                              value=""
                              multiple={false}
                              onChange={(e) => onFileChangeB(e)}
                            />
                            Upload
                          </label>
                        </div>
                      )}

                      <br />
                      {fileB.map((i, k) => (
                        <p key={k} className="ps-4">
                          {i.name}
                          <img
                            src={DeleteIcon}
                            className="del-icon ms-2"
                            alt="Delete"
                            onClick={(e) => delImage(e, "fileB")}
                          />
                        </p>
                      ))}
                      {fields.Cattr12 &&
                        renderAttachment([fields.Cattr12], "fileB")}
                    </Col>
                  </Row>
                </Box>
              </Box>
            )}

            {(fields.Cattr8 || fields.Cattr12) &&
              !["Conditionals", "Unconditionals"].includes(
                fields.ApprStatus.value
              ) && (
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Offer Documents
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      {fields.Cattr8 && (
                        <Col xs={6} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                              <Box>
                                <div className="camp-sub-name">
                                  Conditional Offer Letter
                                </div>
                                <div className="camp-sub-des mt-2">
                                  {fields.Cattr8 &&
                                    renderAttachment([fields.Cattr8])}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                      )}
                      {fields.Cattr12 && (
                        <Col xs={6} className="body-th">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                              <Box>
                                <div className="camp-sub-name">
                                  Unconditional Offer Letter
                                </div>
                                <div className="camp-sub-des mt-2">
                                  {fields.Cattr12 &&
                                    renderAttachment([fields.Cattr12])}
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Col>
                      )}
                    </Row>
                  </Box>
                </Box>
              )}

            {fields.ApprStatus.value == "SFE" && (
              <Box p={2} px={5} className="form-wrap">
                <div className="info-title dynamic-form-title mt-3">
                  SFE Documents
                </div>
                <Box mt={3}>
                  <Divider />
                </Box>

                <Box mt={2} className="camp-edit meet-table">
                  <Row className="m-0">
                    <Col xs={6} className="body-th common-input-style">
                      <Box className="">
                        <FormControl
                          fullWidth
                          className="select-float-label common-padding-zero-select"
                        >
                          <Select
                            className="text-box custom-react-select"
                            labelId="select"
                            id="select_sfe"
                            label="SFE Status"
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            onChange={(data) =>
                              onSelectHandler(data, "Cattr14")
                            }
                            options={sfeStatus}
                            isOptionDisabled={(option) => option.disabled}
                            defaultValue={fields["Cattr14"]}
                            components={{
                              ValueContainer: CustomValueContainer,
                            }}
                            placeholder="SFE Status"
                            styles={selectStyles}
                          ></Select>
                        </FormControl>
                      </Box>
                    </Col>
                    <Col xs={6} className="body-th common-input-style">
                      <label className="camp-sub-name">SFE Booking slots</label>
                      {sfeSlots?.slot &&
                        sfeSlots?.slot.length > 0 &&
                        sfeSlots.slot.map((obj, idx) => {
                          let bookedDate = sfeSlots.bookedSlot
                            ? common.formatDate(
                                sfeSlots.bookedSlot,
                                "YYYY-MM-DD"
                              )
                            : null;
                          let bookedTime = sfeSlots.bookedSlot
                            ? common.formatDate(sfeSlots.bookedSlot, "hh:mm a")
                            : null;
                          return (
                            <div key={idx} className="cond-date-wrap mt-2">
                              {obj.date || ""}
                              {obj.slot.map((time, idx) => {
                                return (
                                  <div
                                    key={idx}
                                    className={
                                      obj.date == bookedDate &&
                                      time == bookedTime
                                        ? "cond-slot-wrap booked-slot"
                                        : "cond-slot-wrap"
                                    }
                                  >
                                    {time ? time : ""}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      <div className="mt-2">
                        <Link
                          to="#"
                          onClick={(e) => openModel(e, null)}
                          className="simple-btn"
                        >
                          Add/Edit
                        </Link>
                      </div>
                    </Col>
                  </Row>

                  {sfeFields.map((mObj, idx) => {
                    let clCount = idx;
                    return (
                      <Row className="m-0" key={clCount}>
                        <Col xs={3} className="body-th common-input-style">
                          <Box className="">
                            <FormControl
                              fullWidth
                              className="select-float-label common-padding-zero-select"
                            >
                              <Select
                                className="text-box custom-react-select"
                                labelId="select"
                                label="SFE Document Request"
                                menuPortalTarget={document.body}
                                id={"sfe_" + clCount}
                                value={eduTechConfig.sfeDocuments.find(
                                  (o) => o.value == mObj.field
                                )}
                                isMulti={false}
                                onChange={(data) =>
                                  onMissingSelect(data, "field", clCount, 4)
                                }
                                options={eduTechConfig.sfeDocuments.filter(
                                  (obj) => !sfeSel.includes(obj.value)
                                )}
                                components={{
                                  ValueContainer: CustomValueContainer,
                                }}
                                placeholder="SFE Document Request"
                                styles={{
                                  container: (provided, state) => ({
                                    ...provided,
                                    marginTop: 0,
                                  }),
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    overflow: "visible",
                                  }),
                                  placeholder: (provided, state) => ({
                                    ...provided,
                                    position: "absolute",
                                    top:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? -15
                                        : "10%",
                                    transition: "top 0.1s, font-size 0.1s",
                                    fontSize:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? 11
                                        : 13,
                                    color: "#9daabb",
                                  }),
                                }}
                              ></Select>
                            </FormControl>
                          </Box>
                        </Col>
                        <Col xs={3} className="body-th common-input-style">
                          <Box>
                            <div className="form-floating">
                              <textarea
                                className="form-control textarea"
                                id={"sfet_" + clCount}
                                value={mObj.desc}
                                onChange={(e) =>
                                  onMissingSelect(
                                    e.target.value,
                                    "desc",
                                    clCount,
                                    4
                                  )
                                }
                                maxLength={150}
                              ></textarea>
                              <label
                                htmlFor="floatingTextarea"
                                className="floating-label"
                              >
                                SFE Request Description
                              </label>
                            </div>
                          </Box>
                        </Col>
                        <Col xs={3} className="body-th common-input-style">
                          <Box className="">
                            <FormControl
                              fullWidth
                              className="select-float-label common-padding-zero-select"
                            >
                              <Select
                                className="text-box custom-react-select"
                                labelId="select"
                                label="Review Status"
                                menuPortalTarget={document.body}
                                id={clCount}
                                value={reviewStatus.find(
                                  (o) => o.value == mObj.status
                                )}
                                isMulti={false}
                                onChange={(data) =>
                                  onMissingSelect(data, "status", idx, 4)
                                }
                                options={reviewStatus}
                                components={{
                                  ValueContainer: CustomValueContainer,
                                }}
                                placeholder="Review Status"
                                styles={{
                                  container: (provided, state) => ({
                                    ...provided,
                                    marginTop: 0,
                                  }),
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    overflow: "visible",
                                  }),
                                  placeholder: (provided, state) => ({
                                    ...provided,
                                    position: "absolute",
                                    top:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? -15
                                        : "10%",
                                    transition: "top 0.1s, font-size 0.1s",
                                    fontSize:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? 11
                                        : 13,
                                    color: "#9daabb",
                                  }),
                                }}
                              ></Select>
                            </FormControl>
                          </Box>
                        </Col>
                        <Col
                          xs={3}
                          className="body-th common-input-style v-align-center"
                        >
                          <div className="form-group ">
                            {sfeFields.length === 1 && idx === 0 ? (
                              <Link
                                to="#"
                                className="add-rule-link add-rule"
                                onClick={(e) => {
                                  addMRow(e, 4);
                                }}
                              >
                                +
                              </Link>
                            ) : (
                              idx === 0 && (
                                <Link
                                  to="#"
                                  className="add-rule-link remove-rule"
                                  onClick={(e) => {
                                    removeMRow(e, clCount, 4);
                                  }}
                                >
                                  -
                                </Link>
                              )
                            )}
                            {idx > 0 && (
                              <React.Fragment>
                                <Link
                                  to="#"
                                  className="add-rule-link remove-rule"
                                  onClick={(e) => removeMRow(e, clCount, 4)}
                                >
                                  -
                                </Link>
                                {sfeFields.length - 1 == idx && (
                                  <Link
                                    to="#"
                                    className="add-rule-link add-rule"
                                    onClick={(e) => addMRow(e, 4)}
                                  >
                                    +
                                  </Link>
                                )}
                              </React.Fragment>
                            )}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </Box>
              </Box>
            )}
            {fields.ApprStatus.value !== "Fully Enrolled" && (
              <>
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Application Rejection
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>
                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col xs={6} className="body-th common-input-style">
                        <FormControlLabel
                          className="dynamic-checkbox ms-0 checkbox-space"
                          control={<Checkbox />}
                          label={"Application Rejected?"}
                          value={fields.Cattr9}
                          labelPlacement="end"
                          disabled={fields.Cattr28?.value !== "Not withdrawn"}
                          checked={fields.Cattr9 === "Y" ? true : false}
                          onChange={(e) => onFieldChange(e, "Cattr9")}
                        />
                      </Col>
                      {fields.Cattr9 === "Y" && (
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            <div className="form-floating">
                              <textarea
                                className="form-control textarea"
                                id="floatingTextarea"
                                maxLength={150}
                                rows={2}
                                name="Cattr17"
                                value={fields.Cattr17}
                                onChange={(e) => onChange(e)}
                              ></textarea>
                              <label
                                htmlFor="floatingTextarea"
                                className="floating-label"
                              >
                                Rejected Reason
                              </label>
                            </div>
                          </Box>
                        </Col>
                      )}
                    </Row>
                  </Box>
                </Box>

                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Application Withdrawn Request
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col xs={6} className="body-th common-input-style">
                        <Select
                          className="text-box custom-react-select"
                          labelId="select"
                          id="select"
                          label="Application Withdraw"
                          menuPortalTarget={document.body}
                          value={fields.Cattr28}
                          isMulti={false}
                          onChange={(data) => onSelectHandler(data, "Cattr28")}
                          options={withdrawOption}
                          isDisabled={fields.Cattr9 === "Y" || cancelDA}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          isOptionDisabled={(option) => option.disabled}
                          placeholder="Application withdrawn category"
                          styles={{
                            container: (provided, state) => ({
                              ...provided,
                              marginTop: 0,
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              overflow: "visible",
                            }),
                            placeholder: (provided, state) => ({
                              ...provided,
                              position: "absolute",
                              top:
                                state.hasValue || state.selectProps.inputValue
                                  ? -15
                                  : "10%",
                              transition: "top 0.1s, font-size 0.1s",
                              fontSize:
                                state.hasValue || state.selectProps.inputValue
                                  ? 11
                                  : 13,
                              color: "#9daabb",
                            }),
                          }}
                        ></Select>
                      </Col>

                      {fields?.Cattr28?.value !== "Not withdrawn" &&
                        fields?.Cattr28?.value !== "Withdrawn by admin" && (
                          <Col xs={6} className="body-th common-input-style">
                            <Box className="filled-box">
                              <div className="camp-sub-name">
                                Withdrawn Request Message
                              </div>
                              <div className="camp-sub-des mt-2">
                                {fields.Cattr19 || "-"}
                              </div>
                            </Box>
                          </Col>
                        )}

                      {fields.Cattr19 ||
                        (fields?.Cattr28?.value == "Withdrawn by admin" && (
                          <Col xs={6} className="body-th common-input-style">
                            <Box className="">
                              <FormControl
                                fullWidth
                                className="select-float-label common-padding-zero-select"
                              >
                                <Select
                                  className="text-box custom-react-select"
                                  labelId="select"
                                  id="select"
                                  label="Application Rejected category"
                                  menuPortalTarget={document.body}
                                  value={fields.Cattr20}
                                  isMulti={false}
                                  onChange={(data) =>
                                    onSelectHandler(data, "Cattr20")
                                  }
                                  isDisabled={cancelDA}
                                  options={canApprStatus}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="Application Rejected category"
                                  styles={{
                                    container: (provided, state) => ({
                                      ...provided,
                                      marginTop: 0,
                                    }),
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflow: "visible",
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      position: "absolute",
                                      top:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? -15
                                          : "10%",
                                      transition: "top 0.1s, font-size 0.1s",
                                      fontSize:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? 11
                                          : 13,
                                      color: "#9daabb",
                                    }),
                                  }}
                                ></Select>
                              </FormControl>
                            </Box>
                          </Col>
                        ))}
                      {fields?.Cattr28?.value == "Withdrawn by admin" ||
                      (fields.Cattr19 && fields.Cattr20.value == "rejected") ? (
                        <Col xs={6} className="body-th">
                          <Box>
                            <div className="form-floating">
                              <textarea
                                className="form-control textarea"
                                name="Cattr24"
                                rows={2}
                                value={fields.Cattr24}
                                onChange={(e) => onChange(e)}
                                maxLength={150}
                                disabled={cancelDA}
                              ></textarea>
                              <label
                                htmlFor="floatingTextarea"
                                className="floating-label"
                              >
                                Request Rejected Reason
                              </label>
                            </div>
                          </Box>
                        </Col>
                      ) : null}
                    </Row>
                  </Box>
                </Box>

                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    Intake Differ
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>
                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col xs={6} className="body-th common-input-style">
                        <FormControlLabel
                          className="dynamic-checkbox ms-0 checkbox-space"
                          control={<Checkbox />}
                          label={"Intake Differ?"}
                          value={intake.intakeDiffer}
                          labelPlacement="end"
                          disabled={
                            fields.Cattr9 === "Y" ||
                            fields?.Cattr28?.value !== "Not withdrawn"
                          }
                          checked={intake.intakeDiffer == "Y" ? true : false}
                          onChange={(e) => intakeChange(e, "intakeDiffer")}
                        />
                      </Col>
                      {intake.intakeDiffer === "Y" && (
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="">
                            <FormControl
                              fullWidth
                              className="select-float-label common-padding-zero-select"
                            >
                              <Select
                                className="text-box custom-react-select"
                                labelId="select"
                                id="select"
                                label="Intake Month Year"
                                menuPortalTarget={document.body}
                                value={intake.intakeMonthYear}
                                isMulti={false}
                                onChange={(data) =>
                                  intakeChange(data, "intakeMonthYear")
                                }
                                options={courseIntMonthYear}
                                components={{
                                  ValueContainer: CustomValueContainer,
                                }}
                                placeholder="Intake Month Year"
                                styles={{
                                  container: (provided, state) => ({
                                    ...provided,
                                    marginTop: 0,
                                  }),
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    overflow: "visible",
                                  }),
                                  placeholder: (provided, state) => ({
                                    ...provided,
                                    position: "absolute",
                                    top:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? -15
                                        : "10%",
                                    transition: "top 0.1s, font-size 0.1s",
                                    fontSize:
                                      state.hasValue ||
                                      state.selectProps.inputValue
                                        ? 11
                                        : 13,
                                    color: "#9daabb",
                                  }),
                                }}
                              ></Select>
                            </FormControl>
                          </Box>
                        </Col>
                      )}

                      {intake.intakeDiffer === "Y" && (
                        <Col xs={6} className="body-th common-input-style">
                          <Box className="filled-box">
                            <div className="form-floating">
                              <textarea
                                className="form-control textarea"
                                id="floatingTextarea"
                                maxLength={150}
                                rows={2}
                                name="intakeReason"
                                value={intake.intakeReason}
                                onChange={(e) =>
                                  intakeChange(e, "intakeReason")
                                }
                              ></textarea>
                              <label
                                htmlFor="floatingTextarea"
                                className="floating-label"
                              >
                                Intake Differ Reason
                              </label>
                            </div>
                          </Box>
                        </Col>
                      )}
                    </Row>
                  </Box>
                  {appInfo?.intakeDiffer?.length > 0 && (
                    <Box mt={2} className="camp-edit meet-table">
                      <Row className="m-0">
                        <Col xs={3} className="body-th">
                          <div className="camp-sub-name">Intake Month</div>
                        </Col>
                        <Col xs={3} className="body-th">
                          <div className="camp-sub-name">Intake Year</div>
                        </Col>
                        <Col xs={4} className="body-th">
                          <div className="camp-sub-name">Intake Reason</div>
                        </Col>
                        <Col xs={2} className="body-th">
                          <div className="camp-sub-name">Intake Date</div>
                        </Col>
                      </Row>
                      {appInfo?.intakeDiffer?.map((obj, idx) => {
                        return (
                          <Row className="m-0" key={idx}>
                            <Col xs={3} className="body-th">
                              {obj?.intakeMonth}
                            </Col>
                            <Col xs={3} className="body-th">
                              {obj?.intakeYear}
                            </Col>
                            <Col xs={4} className="body-th">
                              {obj?.intakeReason}
                            </Col>
                            <Col xs={2} className="body-th">
                              {common.formatDate(
                                obj?.createdAt,
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </Col>
                          </Row>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              </>
            )}
            {status == "success" && fields && (
              <Box p={2} px={5} className="form-wrap">
                <div className="info-title dynamic-form-title mt-3">
                  Document Section
                </div>
                <Box mt={3}>
                  <Divider />
                </Box>
                <Box mt={2} className="camp-edit meet-table">
                  <Row className="m-0">
                    <Col xs={12} className="body-th p-0">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box className="college-title">
                            <div className="camp-sub-des">Documents</div>
                          </Box>
                          <Box mt={2} className="camp-edit meet-table">
                            <Row className="m-0">
                              <Col xs={6} className="body-th">
                                <div className="camp-sub-name">
                                  Conditional Offer Letter
                                </div>
                                <div className="camp-sub-des my-2">
                                  {fields.Cattr8
                                    ? renderAttachment([fields.Cattr8])
                                    : "-"}
                                </div>
                              </Col>
                              <Col xs={6} className="body-th">
                                <div className="camp-sub-name">
                                  Unconditional Offer Letter
                                </div>
                                <div className="camp-sub-des my-2">
                                  {fields.Cattr12
                                    ? renderAttachment([fields.Cattr12])
                                    : "-"}
                                </div>
                              </Col>
                              {appInfo?.profile &&
                                appInfo?.profile["passportDocs"] && (
                                  <Col xs={6} className="body-th">
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                      >
                                        <Box>
                                          <div className="camp-sub-name">
                                            Passport / Birth Certificate
                                          </div>
                                          <div className="camp-sub-des mt-2">
                                            {renderAttachment(
                                              appInfo?.profile["passportDocs"]
                                            )}
                                          </div>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Col>
                                )}
                              {appInfo?.profile && (
                                <Col xs={6} className="body-th">
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={6}
                                      xl={6}
                                    >
                                      <Box>
                                        <div className="camp-sub-name">
                                          Biometric Residence Permit (BRP)
                                        </div>
                                        <div className="camp-sub-des mt-2">
                                          {appInfo?.profile["brpDocs"]
                                            ? renderAttachment([
                                                appInfo?.profile["brpDocs"],
                                              ])
                                            : "-"}
                                        </div>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Col>
                              )}
                              {appInfo?.profile && (
                                <Col xs={6} className="body-th">
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={6}
                                      xl={6}
                                    >
                                      <Box>
                                        <div className="camp-sub-name">
                                          EU Share Code
                                        </div>
                                        <div className="camp-sub-des mt-2">
                                          {appInfo?.profile["euDocs"]
                                            ? renderAttachment([
                                                appInfo?.profile["euDocs"],
                                              ])
                                            : "-"}
                                        </div>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Col>
                              )}
                              {appInfo?.profile && (
                                <Col xs={6} className="body-th">
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={6}
                                      xl={6}
                                    >
                                      <Box>
                                        <div className="camp-sub-name">
                                          Home Office Letter
                                        </div>
                                        <div className="camp-sub-des mt-2">
                                          {appInfo?.profile["homeOfficeDocs"]
                                            ? renderAttachment([
                                                appInfo?.profile[
                                                  "homeOfficeDocs"
                                                ],
                                              ])
                                            : "-"}
                                        </div>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Col>
                              )}
                              {appInfo?.profile && (
                                <Col xs={6} className="body-th">
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={6}
                                      xl={6}
                                    >
                                      <Box>
                                        <div className="camp-sub-name">
                                          Proof of evidence of Stay
                                        </div>
                                        <div className="camp-sub-des mt-2">
                                          {appInfo?.profile["poeDocs"].length >
                                          0
                                            ? renderAttachment(
                                                appInfo?.profile["poeDocs"]
                                              )
                                            : "-"}
                                        </div>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Col>
                              )}
                            </Row>
                          </Box>
                        </Grid>
                      </Grid>
                    </Col>
                  </Row>
                </Box>
              </Box>
            )}
            {console.log("leadRes", fields.Cattr9, fields?.Cattr28)}

            {fields.Cattr9 != "Y" &&
              fields?.Cattr28?.value == "Not withdrawn" && (
                <Box p={2} px={5} className="form-wrap">
                  <div className="info-title dynamic-form-title mt-3">
                    SFE Information
                  </div>
                  <Box mt={3}>
                    <Divider />
                  </Box>

                  <Box mt={2} className="camp-edit meet-table">
                    <Row className="m-0">
                      <Col xs={6} className="body-th common-input-style">
                        <Box className="">
                          <FormControl
                            fullWidth
                            className="select-float-label common-padding-zero-select"
                          >
                            <Select
                              className="text-box custom-react-select"
                              labelId="select"
                              id="select_sfe"
                              label="SFE Status"
                              menuPortalTarget={document.body}
                              menuPlacement="auto"
                              onChange={(data) =>
                                onSelectHandler(data, "Cattr14")
                              }
                              options={sfeStatus}
                              isOptionDisabled={(option) => option.disabled}
                              defaultValue={fields["Cattr14"]}
                              components={{
                                ValueContainer: CustomValueContainer,
                              }}
                              placeholder="SFE Status"
                              styles={selectStyles}
                            ></Select>
                          </FormControl>
                        </Box>
                      </Col>
                      <Col xs={6} className="body-th common-input-style">
                        <label className="camp-sub-name">
                          SFE Booking slots
                        </label>
                        {sfeSlots?.slot &&
                          sfeSlots?.slot.length > 0 &&
                          sfeSlots.slot.map((obj, idx) => {
                            let bookedDate = sfeSlots.bookedSlot
                              ? common.formatDate(
                                  sfeSlots.bookedSlot,
                                  "YYYY-MM-DD"
                                )
                              : null;
                            let bookedTime = sfeSlots.bookedSlot
                              ? common.formatDate(
                                  sfeSlots.bookedSlot,
                                  "hh:mm a"
                                )
                              : null;
                            return (
                              <div key={idx} className="cond-date-wrap mt-2">
                                {obj.date || ""}
                                {obj.slot.map((time, idx) => {
                                  return (
                                    <div
                                      key={idx}
                                      className={
                                        obj.date == bookedDate &&
                                        time == bookedTime
                                          ? "cond-slot-wrap booked-slot"
                                          : "cond-slot-wrap"
                                      }
                                    >
                                      {time ? time : ""}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })}
                        <div className="mt-2">
                          <Link
                            to="#"
                            onClick={(e) => openModel(e, null)}
                            className="simple-btn"
                          >
                            Add/Edit
                          </Link>
                        </div>
                      </Col>
                    </Row>

                    {sfeFields.map((mObj, idx) => {
                      let clCount = idx;
                      return (
                        <Row className="m-0" key={clCount}>
                          <Col xs={3} className="body-th common-input-style">
                            <Box className="">
                              <FormControl
                                fullWidth
                                className="select-float-label common-padding-zero-select"
                              >
                                <Select
                                  className="text-box custom-react-select"
                                  labelId="select"
                                  label="SFE Document Request"
                                  menuPortalTarget={document.body}
                                  id={"sfe_" + clCount}
                                  value={eduTechConfig.sfeDocuments.find(
                                    (o) => o.value == mObj.field
                                  )}
                                  isMulti={false}
                                  onChange={(data) =>
                                    onMissingSelect(data, "field", clCount, 4)
                                  }
                                  options={eduTechConfig.sfeDocuments.filter(
                                    (obj) => !sfeSel.includes(obj.value)
                                  )}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="SFE Document Request"
                                  styles={{
                                    container: (provided, state) => ({
                                      ...provided,
                                      marginTop: 0,
                                    }),
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflow: "visible",
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      position: "absolute",
                                      top:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? -15
                                          : "10%",
                                      transition: "top 0.1s, font-size 0.1s",
                                      fontSize:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? 11
                                          : 13,
                                      color: "#9daabb",
                                    }),
                                  }}
                                ></Select>
                              </FormControl>
                            </Box>
                          </Col>
                          <Col xs={3} className="body-th common-input-style">
                            <Box>
                              <div className="form-floating">
                                <textarea
                                  className="form-control textarea"
                                  id={"sfet_" + clCount}
                                  value={mObj.desc}
                                  onChange={(e) =>
                                    onMissingSelect(
                                      e.target.value,
                                      "desc",
                                      clCount,
                                      4
                                    )
                                  }
                                  maxLength={150}
                                ></textarea>
                                <label
                                  htmlFor="floatingTextarea"
                                  className="floating-label"
                                >
                                  SFE Request Description
                                </label>
                              </div>
                            </Box>
                          </Col>
                          <Col xs={3} className="body-th common-input-style">
                            <Box className="">
                              <FormControl
                                fullWidth
                                className="select-float-label common-padding-zero-select"
                              >
                                <Select
                                  className="text-box custom-react-select"
                                  labelId="select"
                                  label="Review Status"
                                  menuPortalTarget={document.body}
                                  id={clCount}
                                  value={reviewStatus.find(
                                    (o) => o.value == mObj.status
                                  )}
                                  isMulti={false}
                                  onChange={(data) =>
                                    onMissingSelect(data, "status", idx, 4)
                                  }
                                  options={reviewStatus}
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  placeholder="Review Status"
                                  styles={{
                                    container: (provided, state) => ({
                                      ...provided,
                                      marginTop: 0,
                                    }),
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflow: "visible",
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      position: "absolute",
                                      top:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? -15
                                          : "10%",
                                      transition: "top 0.1s, font-size 0.1s",
                                      fontSize:
                                        state.hasValue ||
                                        state.selectProps.inputValue
                                          ? 11
                                          : 13,
                                      color: "#9daabb",
                                    }),
                                  }}
                                ></Select>
                              </FormControl>
                            </Box>
                          </Col>
                          <Col
                            xs={3}
                            className="body-th common-input-style v-align-center"
                          >
                            <div className="form-group ">
                              {sfeFields.length === 1 && idx === 0 ? (
                                <Link
                                  to="#"
                                  className="add-rule-link add-rule"
                                  onClick={(e) => {
                                    addMRow(e, 4);
                                  }}
                                >
                                  +
                                </Link>
                              ) : (
                                idx === 0 && (
                                  <Link
                                    to="#"
                                    className="add-rule-link remove-rule"
                                    onClick={(e) => {
                                      removeMRow(e, clCount, 4);
                                    }}
                                  >
                                    -
                                  </Link>
                                )
                              )}
                              {idx > 0 && (
                                <React.Fragment>
                                  <Link
                                    to="#"
                                    className="add-rule-link remove-rule"
                                    onClick={(e) => removeMRow(e, clCount, 4)}
                                  >
                                    -
                                  </Link>
                                  {sfeFields.length - 1 == idx && (
                                    <Link
                                      to="#"
                                      className="add-rule-link add-rule"
                                      onClick={(e) => addMRow(e, 4)}
                                    >
                                      +
                                    </Link>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </Box>
                </Box>
              )}
          </>
        )}

        {imgPreview && (
          <Dialog
            open={imgPreview}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"sm"}
            fullWidth={true}
            className="target-modal-wrap add-action-popup attachment-popup"
            disableScrollLock
          >
            <DialogTitle
              id="responsive-dialog-title"
              className="popup-title py-2"
            >
              <div className="attach-popup-title">
                <div>
                  <a
                    href={common.loadEduImage(imgPreviewSrc)}
                    download
                    target="_blank"
                    className="cust-link"
                  >
                    <img
                      src={common.loadImg("external-link-icn.svg")}
                      className="me-2"
                    />
                    View
                  </a>
                </div>
                <IconButton onClick={handleClose}>
                  <img src={common.loadImg("filterCloseIcon.svg")} />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="popup-form-wrap imgDlgContent">
                <div className="list-row text-center">
                  <img
                    src={common.loadEduImage(imgPreviewSrc) || null}
                    alt="image"
                    className="w-100"
                  />
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}
        {interviewModel && (
          <Dialog
            open={interviewModel}
            onClose={handleModel}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"sm"}
            fullWidth={true}
            className="target-modal-wrap add-action-popup attachment-popup"
            // disableScrollLock
          >
            <DialogTitle
              id="responsive-dialog-title"
              className="popup-title date-selection py-2"
            >
              <div className="attach-popup-title">
                <div>
                  {condOrSfeSlot
                    ? " Mock Interview Date"
                    : "SFE Interview Slot"}
                </div>

                <IconButton onClick={handleModel}>
                  <img src={common.loadImg("filterCloseIcon.svg")} />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="popup-form-wrap imgDlgContent dialogue-height">
                <div className="list-row text-center py-3">
                  {mInterviewField.slot &&
                    mInterviewField.slot.length > 0 &&
                    mInterviewField.slot.map((obj, idx) => {
                      let bookedDate = mInterviewField.bookedSlot
                        ? common.formatDate(
                            mInterviewField.bookedSlot,
                            "YYYY-MM-DD"
                          )
                        : null;
                      let bookedTime = mInterviewField.bookedSlot
                        ? common.formatDate(
                            mInterviewField.bookedSlot,
                            "hh:mm a"
                          )
                        : null;
                      return (
                        <div
                          key={idx}
                          className="cond-date-wrap mb-2 d-flex align-items-center"
                        >
                          {" "}
                          <div className="d-flex align-items-center">
                            {" "}
                            <div className=" d-flex align-items-center">
                              {" "}
                              <div style={{ width: "40px" }}>
                                {" "}
                                {obj.date != bookedDate && (
                                  <Link
                                    to="#"
                                    className="cond-date-close me-2"
                                    onClick={(e) =>
                                      addDelDate(e, idx, "del", null)
                                    }
                                  >
                                    X
                                  </Link>
                                )}
                              </div>
                              <div style={{ width: "76px" }}>
                                {obj.date || ""}
                              </div>
                            </div>
                            <div className=" d-flex align-items-center">
                              {" "}
                              <div className=" add-slot-date-display">
                                {" "}
                                {obj.slot.map((time, tdx) => {
                                  return (
                                    <div key={tdx} className=" me-2 mt-1">
                                      {" "}
                                      <div
                                        className={
                                          obj.date == bookedDate &&
                                          time == bookedTime
                                            ? "cond-slot-wrap booked-slot"
                                            : "cond-slot-wrap"
                                        }
                                      >
                                        {" "}
                                        {time ? time : ""}
                                        {(obj.date != bookedDate ||
                                          obj.date == bookedDate) &&
                                          time != bookedTime && (
                                            <Link
                                              to="#"
                                              className="cond-slot-close"
                                              onClick={(e) =>
                                                addDelSlot(
                                                  e,
                                                  idx,
                                                  tdx,
                                                  "del",
                                                  null
                                                )
                                              }
                                            >
                                              X
                                            </Link>
                                          )}
                                      </div>
                                    </div>
                                  );
                                })}
                                {obj.add == true ? (
                                  <div className="mt-1 rc-picker-custom rc-time-position">
                                    <RCTimePicker
                                      showSecond={false}
                                      use12Hours
                                      format="hh:mm a"
                                      style={{
                                        width: 96,
                                      }}
                                      defaultValue={slotTime}
                                      onChange={(date) => setSlotTime(date)}
                                      inputReadOnly
                                    />

                                    <Link
                                      to="#"
                                      className="simple-btn px-4 py-2"
                                      onClick={(e) => saveSlot(e, idx)}
                                    >
                                      Add
                                    </Link>
                                  </div>
                                ) : (
                                  <div className="mt-2">
                                    {" "}
                                    <Link
                                      to="#"
                                      className="simple-btn "
                                      onClick={(e) => showTimePicker(e, idx)}
                                    >
                                      Add slot
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {!isNewDateAdd ? (
                    <div
                      className={
                        mInterviewField?.slot?.length != 0 ? "left" : "center"
                      }
                    >
                      {" "}
                      <Link
                        to="#"
                        className="simple-btn py-2 px-4 mt-3 "
                        onClick={(e) => showNewDate(e)}
                      >
                        Add date
                      </Link>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <Box
                        className="text-box meeting-slot-input mb-3"
                        id="label-meeting-slot"
                      >
                        <label
                          className={
                            newDate.date
                              ? "Active text-label ml-4"
                              : "text-label ml-4 camp-time-label"
                          }
                        >
                          Select Date
                        </label>

                        <Box className="ml-1 input-date-picker cust-br">
                          <div className="custom-date-pic">
                            <DatePicker
                              format="YYYY-MM-DD"
                              render={<InputIcon />}
                              placeholder="Select Date"
                              value={newDate.date}
                              onChange={(date) => setNewDateChange(date)}
                            />
                          </div>
                        </Box>
                      </Box>
                      <div className="mb-3">
                        {" "}
                        <Link
                          to="#"
                          className="simple-btn px-4 py-2"
                          onClick={(e) => saveNewDate(e)}
                        >
                          Add
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </DialogContent>
            <DialogActions className="mx-auto ">
              {" "}
              <Button
                className="save-note"
                onClick={(e) => saveMockInterviewSlot(e)}
              >
                Save and Close
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* EMail communication */}
        <Dialog
          open={openEMail}
          onClose={closeEmail}
          aria-labelledby="responsive-dialog-title"
          maxWidth={"sm"}
          fullWidth={true}
          className="target-modal-wrap add-action-popup attachment-popup"
          disableScrollLock
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="popup-title py-2"
          >
            <div className="d-flex align-items-center justify-content-between">
              <span>Send Email</span>
              <span onClick={closeEmail} className="crm-cp">
                X
              </span>
            </div>
          </DialogTitle>
          <DialogContent>
            <div>
              <div className="popup-form-wrap">
                <div className="row">
                  <div className="col-md-12">
                    <label className="msg-history">
                      Message<span className="mandtory-class">*</span>
                    </label>
                    <textarea
                      className="form-control textarea"
                      rows={3}
                      value={fields.emailContent}
                      name={"emailContent"}
                      onChange={(e) => onChange(e)}
                      maxLength={2000}
                      placeholder="Enter your message here to send"
                    ></textarea>
                  </div>
                  <Box p={2} px={5} className="form-wrap">
                    <div className="info-title dynamic-form-title mt-3">
                      Attachment
                    </div>
                    <Box mt={3}>
                      <Divider />
                    </Box>

                    <Box mt={2} className="camp-edit meet-table">
                      <Row className="m-0">
                        <Col xs={6} className="body-th common-input-style">
                          <div className="fileUpload">
                            <label className="upload">
                              <input
                                type="file"
                                id="fileA"
                                name="offerLetter1"
                                accept=".pdf,.jpg,.png,.doc"
                                value=""
                                multiple={true}
                                onChange={(e) => onFileChange(e)}
                              />
                              Upload
                            </label>
                          </div>
                          <br />
                          {files?.map((i, k) => (
                            <p className="file-list ps-4" key={k}>
                              {i.name}
                              <img
                                src={DeleteIcon}
                                className="del-icon ms-2"
                                alt="Delete"
                                onClick={(e) => delImage(e, k)}
                              />
                            </p>
                          ))}
                        </Col>
                      </Row>
                    </Box>
                  </Box>
                  <div className="col-md-12 pt-2 d-flex justify-content-end">
                    <Button
                      className="save-note send-mail-btn"
                      type="button"
                      onClick={(e) => sendEmail(e)}
                      disabled={isEmailSubmit ? true : false}
                    >
                      {isEmailSubmit ? "Processing..." : "Send"}
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {emailHistory.length > 0 && (
                      <label className="msg-history">Message History</label>
                    )}
                    {emailHistory.map((msg, idx) => {
                      return (
                        <div className="msg-history-list" key={idx}>
                          <p className="msg-content">{msg.content}</p>
                          <p className="d-flex align-item-center justify-content-between mb-0">
                            <span className="msg-send-date">
                              {common.formatDate(
                                msg.createdAt,
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </span>
                            <span className="msg-deliver-status">
                              {msg.status == "sended"
                                ? "Delivered"
                                : "Not Delivered"}
                            </span>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </Paper>
      {status == "success" && (
        <div className="fixed-foot-wrap pt-3">
          <Box className="mr-5">
            <Button
              onClick={(e) => {
                setStatus("process");
                getEditData();
              }}
            >
              Refresh
              <img
                className="pl-2"
                src={common.loadImg("refresh-icon.svg")}
                alt="Refresh"
              />
            </Button>
          </Box>
          <Box>
            <Button
              className="save-note fixed-width-btn"
              type="submit"
              onClick={() => onSubmit()}
              disabled={isSubmit ? true : false}
            >
              {isSubmit
                ? "Processing..."
                : props.data.value1
                ? "Update Changes"
                : "Save Changes"}
            </Button>
          </Box>
          <Box className="revert-close">
            <Button
              className="save-note fixed-width-btn pe-0"
              onClick={(e) => closeForm()}
            >
              Revert and Close
              <img src={common.loadImg("closeKanban.svg")} alt="close" />
            </Button>
          </Box>
        </div>
      )}
    </Box>
  );
}

export default ETDealForm;

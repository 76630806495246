import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Col, Row } from "react-bootstrap";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  Dialog,
  Checkbox,
  Menu,
  MenuItem,
  Popover,
  // Select,
  TextField,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import * as Services from "helpers/service";
import { common, tools, envConfig, api, eduTechSerivces } from "helpers";
import PopupForm from "./PopupForm";
import { Link, useParams } from "react-router-dom";
import serviceList from "assets/files/serviceList.json";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CSVLink } from "react-csv";
import moment from "moment";
import StatusBar from "./StatusBar";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import AutomationSelect from "elements/AutomationSelect";
import formFields from "assets/files/formFields.json";
// confirm
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#2A3543",
    color: "rgba(157, 170, 187, 0.5)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    padding: 10,
  },
}));

export default function CrmHeader(props) {
  const isEdutechAcc =
    common.isEduTech() && ["lead", "deal"].includes(props?.module)
      ? true
      : false;
  const [anchorEl, setAnchorEl] = useState({
    action: null,
    columns: null,
    export: null,
  });
  useEffect(() => {
    if (Boolean(anchorEl.columns || anchorEl.action || anchorEl.export)) {
      document.body.onwheel = handleClose;
      document.body.addEventListener("touchstart", handleClose, false);
    }
    return () => {
      document.body.onwheel = undefined;
      document.body.removeEventListener("touchstart", handleClose, false);
    };
  }, [Boolean(anchorEl.columns || anchorEl.action || anchorEl.export)]);

  // Filter Popup
  const filterOperator = {
    string: {
      "=": "Equal to",
      "!=": "Not Equal to",
      "*": "Like",
    },
    number: {
      "=": "Equal to",
      "!=": "Not Equal to",
      ">": "Greater than",
      "<": "Less than",
      ">=": "Greater than or equal to",
      "<=": "Less than or equal to",
    },
    date: {
      "=": "Equal to",
      "!=": "Not Equal to",
      ">": "Greater than",
      "<": "Less than",
      ">=": "Greater than or equal to",
      "<=": "Less than or equal to",
    },
    api: {
      "=": "Equal to",
      "!=": "Not Equal to",
    },
    pickList: {
      "=": "Equal to",
      "!=": "Not Equal to",
    },
  };

  let { mod, type, id } = useParams();
  const csvRef = useRef(null);
  const listInnerRef = useRef();
  const userInfo = common.userInfo();
  const authInfo = common.authInfo();
  let userData = tools.deCrypt(localStorage.getItem("userData"));
  const exportType = [
    {
      label: "Export Visible Items",
      value: "visible",
    },
    {
      label: "Export Filtered Items",
      value: "filter",
    },
    {
      label: "Export All Items",
      value: "all",
    },
  ];
  let service = serviceList[props.module];
  const [viewType, setViewType] = React.useState(type || "list");
  const [actions, setActions] = React.useState([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [massUpdate, setMassUpdate] = React.useState(false);
  const [hidedColumns, setHidedColumns] = useState([]);
  const [columnAction, setColumnAction] = useState({
    column: false,
  });
  const [csvData, setCsvData] = useState([]);
  const [sort, setSort] = useState({});
  const [openFilter, setOpenFilter] = React.useState(false);
  const [isSaveFilter, setIsSaveFilter] = React.useState(false);
  const [filterList, setFilterList] = useState([]);
  const [status, setStatus] = useState("");
  const [showReports, setShowReports] = useState(false);
  let filterData = common.getFilter(props.module);
  const [filter, setFilter] = useState(
    (filterData &&
      filterData.direct &&
      filterData.direct.length > 0 &&
      filterData.direct) || [
      {
        logic: "AND",
        column: "",
        operator: "",
        value: "",
      },
    ]
  );
  const [reportFilterName, setReportFilterName] = useState("");
  const [filterId, setFilterId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [savedOption, setSavedOption] = React.useState(null);
  const openSavedFilter = Boolean(savedOption);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [filterIndex, setFilterIndex] = useState();
  const [hasMore, setHasMore] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [listLoader, setListLoader] = React.useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [selected, setSelected] = useState(props.selectedColumns);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailContent, setEmailContent] = useState("");

  const savedOptionClick = (event, item, i) => {
    setSavedOption(event.currentTarget);
    setSelectedFilter(item);
    setFilterIndex(i);
  };

  const savedOptionClose = () => {
    setSavedOption(null);
  };

  const isEduTech =
    common.isEduTech() && ["lead", "deal"].includes(props.module)
      ? true
      : false;

  useEffect(() => {
    if (filterData == null) {
      setFilter([
        {
          logic: "AND",
          column: "",
          operator: "",
          value: "",
        },
      ]);
      setIsFilter(false);
    } else {
      setFilter(filterData.direct);
      setIsFilter(true);
    }
  }, [props.module]);

  useEffect(() => {
    service = serviceList[props.module];
    let action = [];
    if (props.view == "list") {
      if (props.result.data && props.result.data.length > 0) {
        if (
          (props.module == "contactsLink" &&
            props.query &&
            props?.add == false) ||
          (!props.query && !["campaign", "lead"].includes([props.module])) ||
          (["meeting", "enquiryMember"].includes(props.module) && props.query)
        ) {
          if (props?.accDelete) {
            action.push({
              label: "Mass Delete",
              value: "delete",
            });
          }
        }
      }
      if (props.result.data && props.result.data.length > 0) {
        if (
          (props.module == "contacts" && props?.add == false) ||
          (["campaign", "lead"].includes([props.module]) && !props.query) ||
          (["meeting", "enquiryMember"].includes(props.module) &&
            props.query) ||
          props.type == "menu"
        ) {
          if (props?.accUpdate) {
            action.push({
              label: "Mass Update",
              value: "update",
            });
          }
        }
        if (isEduTech) {
          action = [];
          if (props?.accUpdate) {
            action.push({
              label: "Mass Update",
              value: "update",
            });
          }
          if (userData.roleId == 0) {
            action.push({
              label: "Mass Delete",
              value: "delete",
            });
          }
        }
      }
      if (
        props.module == "lead" &&
        props.result.data &&
        props.result.data.length > 0 &&
        !props.query
      ) {
        action.push({
          label: "Mass Convert",
          value: "convert",
        });
      }
      if (
        !isEduTech &&
        ((["lead", "deal", "enquiryMember", "contacts"].includes(
          props.module
        ) &&
          !props.query) ||
          ["enquiryMember"].includes(props.module))
      ) {
        if (props?.accImport) {
          action.push({
            label: "Mass Import",
            value: "import",
          });
        }
      }
    }
    if (["lead", "deal", "enquiryMember", "contacts"].includes(props.module)) {
      action.push({
        label: "Mass Email",
        value: "email",
      });
    }

    setActions(action);
    setHidedColumns(props.hidedColumns);
    setSort(props.sort);
    setSelected(props.selectedColumns);
  }, [props]);

  const handleClickExport = (e, type) => {
    e.preventDefault();
    setAnchorEl((prev) => ({ ...prev, [type]: e.currentTarget }));
  };

  const handleClose = (type) => {
    setAnchorEl((prev) => ({ ...prev, [type]: null }));
  };

  const filterOpen = (e) => {
    e.preventDefault();
    setOpenFilter(true);
    setShowReports(false);
    // resetFilter();
  };

  const filterClose = () => {
    setOpenFilter(false);
    setShowReports(false);
    // resetFilter();
    // props.loadData("", "no");
  };

  const saveFilterOpen = () => {
    setIsSaveFilter(true);
  };

  const saveFilterClose = () => {
    setIsSaveFilter(false);
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight && hasMore) {
        loadFilterData("", "no", page + 1);
      }
    }
  };

  const loadFilterData = (searchTerm, action, pageNo) => {
    // if (!showReports) {
    if (action == "back") {
      setShowReports(false);
    } else {
      setShowReports(true);
    }
    if (pageNo == 0) {
      setStatus("process");
    } else {
      setListLoader(true);
    }
    setPage(pageNo);
    setIsLoading(true);
    let filter = `?q=Module='${props.module}' AND UserId='${authInfo.UserId}'`;
    if (searchTerm) {
      filter += ` AND UPPER(ReportName) LIKE '*${searchTerm}%*' `;
    }

    filter += `&OrgId=${userInfo.pOrgId}&TenantId=${
      userInfo.pTenantId
    }&totalResults=true&limit=${limit}&offset=${
      pageNo * limit
    }&orderBy=CreatedOn:desc`;

    let url = encodeURI(
      envConfig.BASE_API + Services.CHOLA_USER_REPORTS + filter
    );
    let data = {
      url: url,
      type: "dynamic",
      method: "GET",
      auth: "token",
      moreHead: { rfv: 2 },
      cType: 4,
    };
    api.call(
      data,
      (response) => {
        if (response.status == 200) {
          savedOptionClose();
          if (pageNo == 0) {
            setFilterList(response.data.items);
          } else {
            setFilterList((oldData) => {
              return [...new Set([...oldData, ...response.data.items])];
            });
          }
          // setFilterList((oldData) => {
          //   if (pageNo === 0) {
          //     return response.data.items;
          //   } else {
          //     return [...new Set([...oldData, ...response.data.items])];
          //   }
          // });
          if (response.data.items.length == 0) {
            setStatus("empty");
          } else {
            // if(response.data.hasMore){
            setStatus("success");
            // }
          }
          setHasMore(response.data.hasMore);
          setListLoader(false);
        }
      },
      (error) => {
        setStatus("error");
      },
      (final) => {}
    );
  };

  const changeType = (val) => {
    props.changeView(val);
    setViewType(val);
  };

  const changeForm = (val) => {
    props.changeForm(val);
  };

  // const openNotification = Boolean(anchorEl);
  // const id = openNotification ? "simple-popover" : undefined;

  const massupdateOpen = (e, val) => {
    document.body.style.overflow = "visible";
    if (val != "import" && props.selectedColumns.length == 0) {
      common.snack("E", "Please select atleast one item");
    } else if (val == "convert") {
      handleClose("action");
      //Verify member is converted or not
      let cRecords = Object.keys(props.selectedData).filter((obj) => {
        return props.selectedData[obj].OpportunityConverted == "Y";
      });
      let ncRecords = Object.keys(props.selectedData).filter((obj) => {
        return (
          props.selectedData[obj].OpportunityConverted == "N" ||
          props.selectedData[obj].OpportunityConverted == null
        );
      });
      if (cRecords.length == 0) {
        convert(val, props.selectedColumns);
      } else {
        let cMembers = "";
        cRecords.map((obj) => {
          cMembers = cMembers + " " + props.selectedData[obj].LeadName + ",";
        });
        let ncMembers = "";
        let ncMembersId = [];
        ncRecords.map((obj) => {
          ncMembers = ncMembers + " " + props.selectedData[obj].LeadName + ",";
          ncMembersId.push(obj);
        });
        if (ncRecords.length > 0) {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">
                  <h1 className="notify-title border-bottom px-4 py-2">
                    Conversion Confirmation
                  </h1>
                  <div className="border-bottom px-4 pt-2 pb-4">
                    <p>The following Members are already converted as Deal</p>
                    <p>
                      <b>{cMembers}</b>
                    </p>
                    <p>
                      Are you sure you want to convert the remaining selected
                      Members?
                    </p>
                    <p>
                      <b>{ncMembers}</b>
                    </p>
                  </div>
                  <div className="px-4 py-3">
                    <button className="automation-cancel-btn" onClick={onClose}>
                      No
                    </button>
                    <button
                      onClick={() => {
                        convert(val, ncMembersId);
                        onClose();
                      }}
                      className="automation-footer-btn"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              );
            },
          });
        } else {
          common.snack("E", "Already selected Members are converted as Lead");
        }
      }
    } else {
      setSelectedAction(val);
      setMassUpdate(true);
    }
  };

  const convert = (val, data) => {
    setSelected(data);
    setSelectedAction(val);
    setMassUpdate(true);
  };

  const massupdateClose = (type) => {
    setMassUpdate(false);
    handleClose("action");
    // resetFilter();
    if (type != "close") {
      props.loadData("", type);
    }
  };

  const toggleColumn = (type) => {
    let columns = [];
    if (type === "hide") {
      columns = props?.result.columns.map((i) => i.key);
      common.snack("W", "You can't hide all the columns");
      columns.shift();
    }
    setHidedColumns(columns);
    setColumnAction((prev) => ({ ...prev, column: true }));
    props.updateResultColumns(props?.result, columns);
  };

  const saveFilter = (item) => {
    let uCustomFilter = userData["UserCustomFilter"];
    let params = {
      [service.filter]:
        hidedColumns.length > 0 ? hidedColumns.toString() : null,
      UserId: userData.UserId,
      TenantId: userData.TenantId,
      OrgId: userData.DefaultOrgId,
    };
    // return
    if (uCustomFilter && Object.keys(uCustomFilter).length > 0) {
      // api call to update
      let url =
        envConfig.BASE_API +
        Services.CHOLA_CUSTOM_FILTER +
        "/" +
        uCustomFilter.UserFilterId;
      let data = {
        url: url,
        type: "dynamic",
        method: "PATCH",
        auth: "token",
        body: JSON.stringify(params),
        cType: 4,
      };
      // return
      api.call(
        data,
        (response) => {
          if (response.status == 200) {
            common.snack("S", "Filter updated successfully");
            userData["UserCustomFilter"] = response.data;
            localStorage.setItem("userData", tools.crypt(userData));
            handleClose("columns");
          }
        },
        (error) => {
          common.snack("E", error);
        },
        (final) => {}
      );
    } else {
      // api call to create
      let url = envConfig.BASE_API + Services.CHOLA_CUSTOM_FILTER;
      let data = {
        url: url,
        type: "dynamic",
        method: "POST",
        auth: "token",
        body: JSON.stringify(params),
        cType: 4,
      };
      // return
      api.call(
        data,
        (response) => {
          if (response.status == 201) {
            common.snack("S", "Filter saved successfully");
            userData["UserCustomFilter"] = response.data;
            localStorage.setItem("userData", tools.crypt(userData));
            handleClose("columns");
          }
        },
        (error) => {
          common.snack("E", error);
        },
        (final) => {}
      );
    }
  };

  const handleColumnChange = (e) => {
    const { checked, value } = e.target;
    if (
      props?.result.columns.length - 1 == hidedColumns.length &&
      checked == false
    ) {
      common.snack("W", "You can't hide all the columns");
    } else {
      setHidedColumns((pData) => {
        let nData = reParse(pData);
        if (checked && nData.includes(value)) {
          nData.splice(nData.indexOf(value), 1);
        } else if (!checked) {
          nData.push(value);
        }
        props.updateResultColumns(props?.result, nData);
        return nData;
      });
      setColumnAction((prev) => ({ ...prev, column: true }));
    }
  };

  const reParse = (data) => {
    return JSON.parse(JSON.stringify(data));
  };

  // export
  const exportCsv = (key) => {
    handleClose("export");
    let exportData = [];
    let filterColumns = props?.result.columns.filter(
      (i) => !props.hidedColumns.includes(i.key) && checkFieldAccess(i)
    );
    if (key === 0) {
      props.result.data.map((item) => {
        let resultData = {};
        filterColumns.map((cItem) => {
          if (checkFieldAccess(cItem)) {
            resultData[cItem.label] =
              item[`${cItem.key}Desc`] ?? item[cItem.key];
          }
        });
        exportData.push(resultData);
      });
      exportFile(exportData);
    } else {
      if (key === 1 && filter[0].column === "") {
        common.snack("W", "You haven't applied any filter yet");
      } else {
        let subModule = false;
        let subModuleQuery = "";
        if (
          ["meeting", "callLogs", "contactsLink", "enquiryMember"].includes(
            props.module
          )
        ) {
          subModule = true;
          if (props.query) {
            Object.entries(props.query).map(([key, item]) => {
              subModuleQuery += `${key}='${item}' AND `;
            });
          }
        }

        let query = `?q=${subModule ? subModuleQuery : ""} ${
          key === 1 ? filterQuery() : ""
        }OrgId=${userInfo.pOrgId} AND TenantId=${
          userInfo.pTenantId
        }&totalResults=true&limit=10000&offset=0`; //ToDO : total number records to fetch from DB
        let orderBy = ``;
        if (sort.field) {
          orderBy = `&orderBy=${sort.field}:${sort.order}`;
        }

        let url = `${envConfig.BASE_API}${
          Services[service.api]
        }${query}${orderBy}`;
        let data = {
          url: url,
          type: "dynamic",
          method: "GET",
          auth: "token",
          moreHead: { rfv: 2 },
          cType: 4,
        };
        // return
        api.call(
          data,
          (response) => {
            if (response) {
              response.data.items.map((item) => {
                let resultData = {};
                filterColumns.map((cItem) => {
                  resultData[cItem.label] =
                    item[`${cItem.key}Desc`] ?? item[cItem.key];
                });
                exportData.push(resultData);
              });
              exportFile(exportData);
            } else {
              common.snack("E", "Sorry no records found");
            }
          },
          (error) => {
            common.snack("E", "CSV Download error");
          },
          (final) => {}
        );
      }
    }
  };

  const exportFile = (eData) => {
    setCsvData(eData);
    setTimeout(() => {
      csvRef.current.link.click();
      common.snack("S", "Export has been completed");
    }, 1000);
  };

  const [filterEl, setfilterEl] = React.useState(null);

  const handleClickFilter = (event, item, i) => {
    setfilterEl(event.currentTarget);
    setSelectedFilter(item);
    setFilterIndex(i);
  };

  const handleClosebtn = () => {
    setfilterEl(null);
  };

  // columns
  const columnInfo = (key) => {
    let res =
      props?.result &&
      props?.result.columns &&
      props?.result.columns.length > 0 &&
      props?.result.columns.filter((i) => i.key === key);
    return res[0];
  };

  const labelValue = (data) => {
    if (isObject(data)) {
      return data ? data.map((item) => ({ label: item, value: item })) : "";
    } else {
      return data ? { label: data, value: data } : "";
    }
  };

  const isObject = (val) => {
    return typeof val === "object" && val !== null;
  };

  // filter
  const filterAdd = (e) => {
    e.preventDefault();
    setFilter((pData) => {
      let nData = reParse(pData);
      nData.push({
        logic: nData[0].logic,
        column: "",
        operator: "",
        value: "",
      });
      return nData;
    });
  };

  const filterRemove = (e, key) => {
    e.preventDefault();
    setFilter((pData) => {
      let nData = reParse(pData);
      nData.splice(key, 1);
      return nData;
    });
    //clear local stroage filter
    common.clearFilter(props.module, filter);
  };

  const filterLogical = (e) => {
    e.preventDefault();
    setFilter((pData) => {
      let nData = reParse(pData);
      let logic = nData[0].logic === "AND" ? "OR" : "AND";
      nData = nData.map((i) => {
        i.logic = logic;
        return i;
      });
      return nData;
    });
  };

  const filterChange = (e, key) => {
    const { name, value } = e.target;
    setFilter((pData) => {
      let nData = reParse(pData);
      nData[key][name] = value;
      if (name === "column") {
        nData[key].operator = "";
        nData[key].value = "";
      }
      return nData;
    });
  };

  const filterValueChange = (key, value) => {
    setFilter((pData) => {
      let nData = reParse(pData);
      nData[key].value = value;
      return nData;
    });
  };

  const filterSubmit = (type) => {
    setIsLoading(true);
    if (reportFilterName == undefined || reportFilterName == "") {
      common.snack("E", "Filter name required to save");
    } else {
      setIsSaveFilter(true);
      setStatus("process");
      if (filterId == 0) {
        let params = {
          UserId: userData.UserId,
          TenantId: userData.TenantId,
          OrgId: userData.DefaultOrgId,
          Module: props.module,
          ReportName: reportFilterName,
          Criteria: JSON.stringify(filter),
        };
        let url = envConfig.BASE_API + Services.CHOLA_USER_REPORTS;
        let data = {
          url: url,
          type: "dynamic",
          method: "POST",
          auth: "token",
          body: JSON.stringify(params),
          cType: 4,
        };
        api.call(
          data,
          (response) => {
            if (response.status == 201) {
              loadFilterData("", "no", 0);
              common.snack("S", "Filter saved successfully");
              setReportFilterName("");
              setFilter([
                {
                  logic: "AND",
                  column: "",
                  operator: "",
                  value: "",
                },
              ]);
              setShowReports(true);
              setStatus("success");
              setIsLoading(false);
              setIsSaveFilter(false);
            }
          },
          (error) => {
            common.snack("E", error);
            setStatus("error");
          },
          (final) => {
            savedOptionClose();
          }
        );
      } else if (filterId > 0) {
        let params = {
          UserId: userData.UserId,
          TenantId: userData.TenantId,
          OrgId: userData.DefaultOrgId,
          Module: props.module,
          ReportName: reportFilterName,
          Criteria: JSON.stringify(filter),
        };
        let url =
          envConfig.BASE_API + Services.CHOLA_USER_REPORTS + "/" + filterId;
        let data = {
          url: url,
          type: "dynamic",
          method: "PATCH",
          auth: "token",
          body: JSON.stringify(params),
          cType: 4,
        };
        api.call(
          data,
          (response) => {
            if (response.status == 200) {
              loadFilterData("", "no", 0);
              common.snack("S", "Filter updated successfully");
              setReportFilterName("");
              setFilter([
                {
                  logic: "AND",
                  column: "",
                  operator: "",
                  value: "",
                },
              ]);
              setShowReports(true);
              setIsLoading(false);
              setStatus("success");
              setIsSaveFilter(false);
              setFilterId(0);
            }
          },
          (error) => {
            common.snack("E", error);
            setStatus("error");
          },
          (final) => {
            setIsLoading(false);
            savedOptionClose();
          }
        );
      }
    }
  };

  const deleteFilter = (e, id) => {
    let url = envConfig.BASE_API + Services.CHOLA_USER_REPORTS + "/" + id;
    let data = {
      url: url,
      type: "dynamic",
      method: "DELETE",
      auth: "token",
    };
    api.call(
      data,
      (response) => {
        if (response.status == 204) {
          common.snack("S", "Filter Deleted successfully");
          loadFilterData("", "no", 0);
          setStatus("success");
          setShowReports(true);
          savedOptionClose();
        }
      },
      (error) => {
        common.snack("E", error);
        setStatus("error");
      },
      (final) => {}
    );
  };

  const filterQuery = () => {
    let res = "";
    let valid = true;

    filter.map((i, k) => {
      if (i.column !== "" && i.operator !== "" && i.value !== "") {
        let column = columnInfo(i.column);
        let value = i.value;
        if (i.operator === "*") {
          res += `UPPER(${column.key}) LIKE '*${value}*'`;
        } else {
          if (column.type === "multiLookup") {
            value = value
              .map(function (elem) {
                return elem.value;
              })
              .join(",");
          }
          if (column.type === "pickList" || column.type === "lookup") {
            if (value) {
              value = value ? value.value : "";
            }
            // } else {
            //   common.snack("W", "Please fill the values in filter");
            // }
          } else if (column.type === "date" || column.type === "dateTime") {
            value = moment(value).format("YYYY-MM-DD");
          }
          res += `UPPER(${column.key})${i.operator}'${value}'`;
        }
        res += ` ${i.logic} `;
      } else {
        valid = false;
      }
    });

    return valid ? res : "";
  };

  const addNewRepFilter = (filterId, obj, e) => {
    if (e) {
      e.preventDefault();
    }
    setShowReports(false);
    if (filterId == 0) {
      setReportFilterName("");
      setFilterId(0);
      setFilter([
        {
          logic: "AND",
          column: "",
          operator: "",
          value: "",
        },
      ]);
    } else if (obj != null) {
      setReportFilterName(obj.ReportName);
      obj.Criteria == null
        ? setFilter([
            {
              logic: "AND",
              column: "",
              operator: "",
              value: "",
            },
          ])
        : setFilter(JSON.parse(obj.Criteria));

      setFilterId(filterId);
    }
  };

  const showFilterName = (e) => {
    let err = [];
    filter.map((i, k) => {
      if (
        i.column === "" ||
        i.operator === "" ||
        i.value === "" ||
        i.value === null
      ) {
        err.push(`Please fill all the values in row ${++k}`);
      }
    });
    if (err.length) {
      common.snack("E", err[0]);
    } else {
      setIsSaveFilter(true);
      setIsLoading(false);
    }
  };

  const searchFilter = (e) => {
    setTimeout(() => {
      loadFilterData(e.target.value, "no", 0);
      setShowReports(true);
      setPage(1);
    }, 2000);
  };

  const applyFilter = (filterId, sfilter, e) => {
    let fil = {
      paging: {
        page: 1,
        limit: 10,
      },
      sort: {},
      view: null,
    };
    common.setStoragePagination(props.module, fil);
    let filters = "";
    if (filterId && sfilter) {
      setReportFilterName(sfilter.ReportName);
      sfilter.Criteria == null
        ? setFilter([
            {
              logic: "AND",
              column: "",
              operator: "",
              value: "",
            },
          ])
        : setFilter(JSON.parse(sfilter.Criteria));

      // setFilterId(filterId);

      let query = JSON.parse(sfilter.Criteria);
      query.length > 0 &&
        query.map((item, i) => {
          // filters += `${query[i].column}${query[i].operator}'${
          //   typeof query[i].value == "string"
          //     ? query[i].value
          //     : query[i].value.value
          // }'${query[i].logic} `;

          if (item.column !== "") {
            let column = columnInfo(item.column);
            let value = item.value;
            if (item.operator === "*") {
              filters += `UPPER(${column.key}) LIKE '*${value}*'`;
            } else {
              if (column.type === "multiLookup") {
                value = value
                  .map(function (elem) {
                    return elem.value;
                  })
                  .join(",");
              }
              if (column.type === "pickList" || column.type === "lookup") {
                value = value.value;
              } else if (column.type === "date" || column.type === "dateTime") {
                value = moment(value).format("YYYY-MM-DD");
              }
              filters += `UPPER(${column.key})${item.operator}'${value}'`;
            }
            filters += ` ${item.logic} `;
          }
        });
    } else {
      filters = filterQuery();
    }

    if (filters) {
      setIsFilter(true);
      let fil = {
        direct: filter,
        list: filters,
      };
      common.setStorageFilter(props.module, fil);
      props.loadData(filters, "apply");
      setOpenFilter(false);
    } else {
      common.snack("E", "Please fill the filter values");
    }
    // resetFilter();
  };

  const resetFilter = () => {
    setReportFilterName("");
    setFilterId(0);
    setFilter([
      {
        logic: "AND",
        column: "",
        operator: "",
        value: "",
      },
    ]);
    setShowReports(false);
    setPage(1);
    setIsLoading(false);
    setIsFilter(false);
    //clear local stroage filter
    common.clearFilter(props.module, filter);
    props.loadData("", "clear");
  };

  const addNewFilter = () => {
    setReportFilterName("");
    setFilterId(0);
    setFilter([
      {
        logic: "AND",
        column: "",
        operator: "",
        value: "",
      },
    ]);
    setShowReports(false);
    setPage(1);
    setIsLoading(false);
    setIsFilter(false);
  };

  const convertLeadAction = (e) => {
    document.body.style.overflow = "visible";
    if (props.selectedColumns.length == 0) {
      common.snack("E", "Please select atleast one item");
    } else {
      //Verify member is converted or not
      let cRecords = Object.keys(props.selectedData).filter((obj) => {
        return props.selectedData[obj].LeadConverted == "Y";
      });
      let ncRecords = Object.keys(props.selectedData).filter((obj) => {
        return (
          props.selectedData[obj].LeadConverted == "N" ||
          props.selectedData[obj].LeadConverted == null
        );
      });

      if (cRecords.length == 0) {
        convertLead(props.selectedColumns);
      } else {
        let cMembers = "";
        cRecords.map((obj) => {
          cMembers = cMembers + " " + props.selectedData[obj].FirstName + ",";
        });
        let ncMembers = "";
        let ncMembersId = [];
        ncRecords.map((obj) => {
          ncMembers = ncMembers + " " + props.selectedData[obj].FirstName + ",";
          ncMembersId.push(obj);
        });
        if (ncRecords.length > 0) {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui pb-4">
                  <h1 className="notify-title border-bottom px-4 py-2">
                    Conversion Confirmation
                  </h1>
                  <p className="px-4 py-2">
                    The following Members are already converted as Lead
                  </p>
                  <p className="px-4">
                    <b>{cMembers}</b>
                  </p>
                  <p className="px-4 py-2">
                    Are you sure you want to convert the remaining selected
                    Members?
                  </p>
                  <p className="px-4">
                    <b>{ncMembers}</b>
                  </p>
                  <button className="automation-cancel-btn" onClick={onClose}>
                    No
                  </button>
                  <button
                    onClick={() => {
                      convertLead(ncMembersId);
                      onClose();
                    }}
                    className="automation-footer-btn"
                  >
                    Yes
                  </button>
                </div>
              );
            },
          });
        } else {
          common.snack("E", "Already selected Members are converted as Lead");
        }
      }
    }
  };

  const convertLead = (selected) => {
    var formAttr = {
      name: "convertListDtlToLead",
      parameters: [
        { P_LIST_DTL_ID: selected.join(",") },
        { P_LIST_ID: parseInt(props.listId) },
        { P_LEAD_NAME: "" },
        { P_LEAD_TYPE: "" },
        { P_CUSTOMER_ID: "" },
        { P_TERRITORY_ID: userData.territory[0] },
        { P_CAMPAIGN_ID: id ? parseInt(id) : null },
        { P_SALES_PERSON_ID: userData.EmpId },
      ],
    };

    let url = encodeURI(envConfig.BASE_API + Services.CHOLA_CUSTOM_CONVERSION);
    let data = {
      url: url,
      type: "dynamic",
      method: "POST",
      auth: "token",
      body: JSON.stringify(formAttr),
      cType: 3,
    };
    //   return
    api.call(
      data,
      (response) => {
        if (response.status >= 200 && response.status <= 300) {
          common.snack(
            "S",
            "Selected Members are converted as lead successfully."
          );
          massupdateClose("clear");
        }
      },
      (error) => {
        common.snack("E", error);
      },
      (final) => {}
    );
  };

  const onChangeReport = () => {
    setShowReports(false);
    setFilterId(0);
    setReportFilterName("");
  };

  const saveContact = () => {
    if (props.selectedColumns && props.selectedColumns.length == 0) {
      common.snack("E", "Please select atleast one item");
    } else {
      let inputData = [];

      props.selectedColumns &&
        props.selectedColumns.length > 0 &&
        props.selectedColumns.map((cId, index) => {
          let params = {
            ContactId: cId,
            OrgId: userInfo.pOrgId,
            TenantId: userInfo.pTenantId,
            ObjectId: id.toString(),
            ObjectType: serviceList[mod].code,
          };

          inputData.push({
            id: "part" + (index + 1),
            path: "/" + Services.CONTACTS_LINKS,
            operation: "create",
            payload: params,
          });
        });

      var formAttr = { parts: inputData };
      let url = encodeURI(envConfig.BASE_API);

      let data = {
        url: url,
        type: "dynamic",
        method: "POST",
        auth: "token",
        body: JSON.stringify(formAttr),
        cType: 5,
      };
      api.call(
        data,
        (response) => {
          if (response != null) {
            common.snack("S", "Contact linked successfully");
            props.tabChange();
          }
        },
        (error) => {
          common.snack("E", "Error occurred");
        },
        (final) => {}
      );
    }
  };

  const cancelEmail = (e) => {
    e.preventDefault();
    massupdateClose(type);
    setMassUpdate(false);
    setEmailSubject("");
    setEmailContent("");
    props.clrSelected();
  };

  const sendEmailAction = (e) => {
    e.preventDefault();
    if (!emailSubject || !emailContent) {
      common.snack("E", "please enter email subject & content");
    } else {
      let users = [];
      console.log("props.selectedData", props.selectedData);
      Object.values(props.selectedData).map((obj) => {
        if (obj.Email || obj.EmailAddress) {
          users.push(obj.Email || obj.EmailAddress);
        }
      });
      if (users.length > 0) {
        let sendData = {};
        sendData = {
          from: envConfig.SENDER_EMAIL,
          to: users.join(","),
          subject: emailSubject,
          content: emailContent,
        };
        if (sendData && sendData.to) {
          eduTechSerivces.sendUserTagMail(sendData);
          common.snack("S", "Email send succuessfully");
          setEmailSubject("");
          setEmailContent("");
          massupdateClose(type);
        }
      }
    }
  };

  //Field level access handling
  const checkFieldAccess = (field) => {
    if (authInfo.roleId == 0) return true; //ADMIN Access
    if (field?.pspecific == true && field?.permission?.length > 0) {
      let fAccess = field.permission.filter(
        (e) => e.groupId == authInfo.roleId
      );
      return fAccess.length > 0
        ? fAccess[0].access !== "h"
        : field.ptype !== "h";
    } else {
      return field?.ptype !== "h" || true;
    }
  };

  const openNotificationFilter = Boolean(filterEl);
  const idfilter = openNotificationFilter ? "simple-popover" : undefined;

  return (
    <Box className="crm-header-wrap cust-thead-wrap">
      {(props.type == "menu" || props.isExpand == "tab") && (
        <Grid
          container
          alignItems="center"
          justify="space-between"
          sx={{
            flexDirection: { xs: "column", lg: "row" },
            spacing: { xs: 3, lg: 0 },
          }}
        >
          <Grid item xs={2}>
            <Typography className="camp-title">
              {props.pageName ? props.pageName : ""}
            </Typography>
          </Grid>
          <Grid item justifyContent="center" xs={10}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="end"
              spacing={2}
            >
              {/* filter and other buttons */}
              {/* {props.isExpand == "tab" && ( */}
              <Grid item className="safari-style">
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  className="safari-style"
                >
                  <Grid item>
                    <HtmlTooltip
                      title={
                        <Typography
                          component={"span"}
                          color="inherit"
                          className="pop-title"
                        >
                          Refresh
                        </Typography>
                      }
                    >
                      <IconButton onClick={(e) => props.refreshData()}>
                        <img
                          src={common.loadImg("refresh-icon.svg")}
                          alt="document"
                          className="pt-1"
                        />
                      </IconButton>
                    </HtmlTooltip>
                  </Grid>
                  {/* Columns Popper */}
                  {(props?.pModule != "campaign" ||
                    props.module != "enquiryMember") &&
                    props?.result &&
                    props.result.data &&
                    props.result.data.length > 0 && (
                      <Grid item>
                        <Box className="export">
                          <Button
                            variant="outlined"
                            className="flex align-items-center"
                            onClick={(e) => handleClickExport(e, "columns")}
                          >
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item>Columns </Grid>
                            </Grid>
                            <Grid item>
                              <Box className="more-icon ml-5">
                                <MoreHorizIcon />
                              </Box>
                            </Grid>
                          </Button>
                          {/* Column checkbox */}
                          <Popover
                            id="columnPopover"
                            open={Boolean(anchorEl.columns)}
                            anchorEl={anchorEl.columns}
                            onClose={(e) => handleClose("columns")}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            className="notify-pop"
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            disableScrollLock
                          >
                            <Box>
                              <div className="amlColEnd p-1">
                                <Button
                                  color="primary"
                                  className="show-hide-btn"
                                  onClick={() => toggleColumn("show")}
                                >
                                  Show All
                                </Button>
                                <Button
                                  color="primary"
                                  className="show-hide-btn"
                                  onClick={() => toggleColumn("hide")}
                                >
                                  Hide All
                                </Button>
                              </div>
                            </Box>
                            <Box>
                              <Divider />
                            </Box>
                            <Box>
                              <div className="amlColSaveChanges p-1">
                                <Button
                                  color="primary"
                                  disabled={!columnAction.column}
                                  onClick={() =>
                                    saveFilter(columnAction.column)
                                  }
                                >
                                  Save your changes
                                </Button>
                              </div>
                            </Box>
                            <Box>
                              <Divider />
                            </Box>
                            <Box>
                              <Grid
                                container
                                direction="column"
                                className="pointer column-filter-list-wrap"
                              >
                                <Grid item>
                                  <Box>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="space-between"
                                      spacing={1}
                                    >
                                      <Grid item>
                                        {props?.result &&
                                          props?.result.columns.map(
                                            (item, key) =>
                                              checkFieldAccess(item) && (
                                                <FormControlLabel
                                                  className="dynamic-checkbox ms-0 checkbox-space px-2 py-1"
                                                  control={<Checkbox />}
                                                  label={item.label}
                                                  key={key}
                                                  value={item.key}
                                                  labelPlacement="end"
                                                  checked={
                                                    hidedColumns.includes(
                                                      item.key
                                                    )
                                                      ? false
                                                      : true
                                                  }
                                                  onChange={(e) =>
                                                    handleColumnChange(e)
                                                  }
                                                />
                                              )
                                          )}
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Popover>
                        </Box>
                      </Grid>
                    )}
                  {/* table view */}
                  <Grid item>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                      className={
                        isFilter ? "filter-common-flick" : "common-flick"
                      }
                    >
                      {!isEduTech &&
                        props.type &&
                        props.type == "menu" &&
                        props.module != "contacts" && (
                          <>
                            {viewType == "kanban" ? (
                              <Grid item>
                                <HtmlTooltip
                                  title={
                                    <Typography
                                      component={"span"}
                                      color="inherit"
                                      className="pop-title"
                                    >
                                      Switch to List View
                                    </Typography>
                                  }
                                >
                                  <IconButton
                                    onClick={() => changeType("list")}
                                  >
                                    <img
                                      src={common.loadImg("documentkanban.svg")}
                                      alt="document"
                                      className="pt-1 px-1"
                                    />
                                  </IconButton>
                                </HtmlTooltip>
                              </Grid>
                            ) : (
                              <Grid item>
                                <HtmlTooltip
                                  title={
                                    <Typography
                                      component={"span"}
                                      color="inherit"
                                      className="pop-title"
                                    >
                                      Switch to Kanban View
                                    </Typography>
                                  }
                                >
                                  <IconButton
                                    onClick={() => changeType("kanban")}
                                  >
                                    <img
                                      src={common.loadImg("chartKanban.svg")}
                                      alt="document"
                                      className="pt-1 px-1"
                                    />
                                  </IconButton>
                                </HtmlTooltip>
                              </Grid>
                            )}
                          </>
                        )}
                      {(props?.pModule != "campaign" ||
                        props.module != "enquiryMember") &&
                        !isFilter &&
                        props?.result &&
                        props.result.data &&
                        props.result.data.length > 0 && (
                          <Grid item>
                            <HtmlTooltip
                              title={
                                <Typography
                                  component={"span"}
                                  color="inherit"
                                  className="pop-title"
                                >
                                  Filter
                                </Typography>
                              }
                            >
                              <IconButton onClick={(e) => filterOpen(e)}>
                                <img
                                  src={common.loadImg("filter-default.svg")}
                                  alt="document"
                                />
                              </IconButton>
                            </HtmlTooltip>
                          </Grid>
                        )}
                      {isFilter && common.getFilter(props.module) !== null && (
                        <Grid item>
                          <HtmlTooltip
                            title={
                              <Typography
                                component={"span"}
                                color="inherit"
                                className="pop-title"
                              >
                                Filter
                              </Typography>
                            }
                          >
                            <IconButton onClick={(e) => filterOpen(e)}>
                              <img
                                src={common.loadImg("filter-active.svg")}
                                alt="document"
                              />
                            </IconButton>
                          </HtmlTooltip>
                        </Grid>
                      )}
                      {isFilter && common.getFilter(props.module) !== null && (
                        <Grid item>
                          <HtmlTooltip
                            title={
                              <Typography
                                component={"span"}
                                color="inherit"
                                className="pop-title"
                              >
                                Clear Filter
                              </Typography>
                            }
                          >
                            <IconButton onClick={() => resetFilter()}>
                              <img
                                src={common.loadImg("filter-clear.svg")}
                                alt="document"
                              />
                            </IconButton>
                          </HtmlTooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {/* Action */}
                  {actions && actions.length > 0 && (
                    // props?.add != false && props?.result &&
                    //   props.result.data &&
                    //   props.result.data.length > 0 &&
                    <Grid item>
                      <Box className="export">
                        <Button
                          variant="outlined"
                          className="flex align-items-center cust-btn"
                          onClick={(e) => handleClickExport(e, "action")}
                        >
                          <Grid
                            container
                            alignItems="center"
                            spacing={1}
                            className="cust-btn-txt"
                          >
                            <Grid item>Action </Grid>
                            <Grid item>
                              <div className="crm-table-action">
                                {selected ? selected.length : 0}
                              </div>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Box className="more-icon ml-5">
                              <MoreHorizIcon />
                            </Box>
                          </Grid>
                        </Button>
                        <Popover
                          id="actionPopover"
                          open={Boolean(anchorEl.action)}
                          anchorEl={anchorEl.action}
                          onClose={(e) => handleClose("action")}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          className="notify-pop"
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          disableScrollLock
                        >
                          <Box className="notify-box" p={1}>
                            <Grid
                              container
                              justify="space-between"
                              alignItems="center"
                            >
                              <Grid item xs={10}>
                                <Typography
                                  component="div"
                                  className="flex align-items-center notify-title pl-2"
                                >
                                  Action &nbsp;{" "}
                                  <div className="crm-table-action">
                                    {props.selectedColumns
                                      ? props.selectedColumns.length
                                      : 0}
                                  </div>
                                </Typography>
                              </Grid>
                              <Grid item>
                                <IconButton
                                  onClick={(e) => handleClose("action")}
                                >
                                  <img
                                    src={common.loadImg("dateClosekanban.svg")}
                                    alt="close"
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box>
                            <Divider />
                          </Box>
                          <Box>
                            <Grid
                              container
                              direction="column"
                              className="pointer"
                            >
                              {actions &&
                                actions.length > 0 &&
                                actions.map((item, idx) => {
                                  return (
                                    <Grid item key={idx}>
                                      <Box p={2} py={2}>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          spacing={1}
                                          onClick={(e) =>
                                            massupdateOpen(e, item.value)
                                          }
                                        >
                                          <Grid item>
                                            <Typography className="task-assign ">
                                              {item.label}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                      <Box>
                                        <Divider />
                                      </Box>
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          </Box>
                        </Popover>
                      </Box>
                    </Grid>
                  )}
                  {/* Export */}
                  {(props?.pModule != "campaign" ||
                    props.module != "enquiryMember") &&
                    props?.result &&
                    props.result.data &&
                    props.result.data.length > 0 &&
                    props?.accExport && (
                      <Grid item>
                        <Box className="export-imp">
                          <Button
                            variant="outlined"
                            onClick={(e) => handleClickExport(e, "export")}
                          >
                            Export{" "}
                            <img
                              src={common.loadImg("exportkanban.svg")}
                              alt="document"
                              className="ml-5"
                            />
                          </Button>
                        </Box>
                        {/* Export Popper */}
                        <Popover
                          id="exportPopover"
                          open={Boolean(anchorEl.export)}
                          anchorEl={anchorEl.export}
                          onClose={() => handleClose("export")}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          disableScrollLock
                        >
                          <Box className="notify-box" p={1}>
                            <Grid
                              container
                              justify="space-between"
                              alignItems="center"
                            >
                              <Grid item xs={10}>
                                <Typography
                                  component="div"
                                  className="flex align-items-center notify-title ps-3"
                                >
                                  Export
                                </Typography>
                              </Grid>
                              <Grid item>
                                <IconButton
                                  onClick={(e) => handleClose("export")}
                                >
                                  <img
                                    src={common.loadImg("dateClosekanban.svg")}
                                    alt="close"
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box>
                            <Divider />
                          </Box>
                          <Box>
                            <Grid
                              container
                              direction="column"
                              className="pointer"
                            >
                              {exportType.map((item, key) => (
                                <Grid item key={key}>
                                  <Box p={1}>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="space-between"
                                      spacing={1}
                                    >
                                      <Grid item>
                                        <MenuItem
                                          onClick={() => exportCsv(key)}
                                          key={key}
                                          className="export-list"
                                        >
                                          {item.label}
                                        </MenuItem>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Box>
                                    <Divider />
                                  </Box>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </Popover>
                      </Grid>
                    )}
                  {!common.isEduTech() &&
                    props.module == "enquiryMember" &&
                    props?.result &&
                    props.result.data &&
                    props.result.data.length > 0 && (
                      <Grid item>
                        <Box className="add-camp-btn">
                          <Button
                            variant="outlined"
                            onClick={() => convertLeadAction(true)}
                          >
                            Convert
                          </Button>
                        </Box>
                      </Grid>
                    )}
                  {props.module == "contacts" &&
                    props.type == "subMenu" &&
                    props?.result &&
                    props.result.data &&
                    props.result.data.length > 0 && (
                      <Grid item>
                        <Box className="add-camp-btn">
                          <Button
                            variant="outlined"
                            onClick={() => saveContact()}
                          >
                            LINK TO CONTACTS
                          </Button>
                        </Box>
                      </Grid>
                    )}
                </Grid>
              </Grid>
              {/* )} */}

              {/* Add button */}
              {!isEduTech && props?.add != false && props?.accAdd && (
                <Grid item>
                  <Box className="add-camp-btn cust-add-btn">
                    <Button variant="outlined" onClick={() => changeForm(true)}>
                      Add new{" "}
                      <img
                        src={common.loadImg("light-plus.svg")}
                        alt="document"
                        className="ml-5"
                      />
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Dialog
        // onClose={massupdateClose}
        open={massUpdate}
        className="massupdate-popup"
        disableScrollLock
      >
        <Box p={1}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={11}>
              <Typography
                component="div"
                className="flex align-items-center notify-title pl-2"
              >
                {/* Bulk{" "} */}
                {/* {props.module == "lead" ? "Lead Bulk " : "Enquiry Member Bulk "} */}
                {selectedAction ? common.textCapitalize(selectedAction) : ""}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => massupdateClose("close")}>
                <img src={common.loadImg("dateClosekanban.svg")} alt="close" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Divider />
        </Box>
        <Box p={2}>
          {selectedAction != "email" && (
            <PopupForm
              props={props}
              selected={selected}
              selectedColumns={
                props.selectedColumns ? props.selectedColumns.length : 0
              }
              selectedData={props.selectedData}
              action={selectedAction}
              massupdateClose={(type) => massupdateClose(type)}
            />
          )}
          {selectedAction == "email" && (
            <div>
              <Row className="m-0">
                <Col xs={12}>
                  <Box className="filled-box">
                    <TextField
                      id="filled-basic"
                      label={"Enter email subject"}
                      variant="filled"
                      inputprops={{
                        maxLength: 30,
                      }}
                      value={emailSubject}
                      onChange={(e) => setEmailSubject(e.target.value)}
                    />
                  </Box>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12}>
                  <Box className="filled-box px-2">
                    <textarea
                      className="form-control textarea"
                      value={emailContent}
                      onChange={(e) => setEmailContent(e.target.value)}
                    ></textarea>
                  </Box>
                </Col>
              </Row>
              <Row className="m-2">
                <Col>
                  <Button
                    className="save-note px-5"
                    onClick={(e) => sendEmailAction(e)}
                  >
                    Send
                  </Button>
                  <Button
                    className="automation-cancel-btn"
                    onClick={(e) => cancelEmail(e)}
                    type="button"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Box>
      </Dialog>
      {/* csv */}
      <CSVLink
        filename={`${props.module}_list.csv`}
        data={csvData}
        ref={csvRef}
      />

      {/* Filter Popup */}
      <Dialog
        onClose={filterClose}
        open={openFilter}
        className="filter-dialog"
        disableScrollLock
      >
        <Grid container>
          <Grid item xs={1} lg={1}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item py={2}>
                <HtmlTooltip
                  title={
                    <Typography
                      component={"span"}
                      color="inherit"
                      className="pop-title"
                    >
                      Filter
                    </Typography>
                  }
                >
                  <IconButton onClick={() => onChangeReport()}>
                    {!showReports ? (
                      <img
                        src={common.loadImg("tab-filter-active.svg")}
                        alt="close"
                      />
                    ) : (
                      <img
                        src={common.loadImg("tab-filter-icn.svg")}
                        alt="close"
                      />
                    )}
                  </IconButton>
                </HtmlTooltip>
              </Grid>
              <Grid item py={2}>
                <HtmlTooltip
                  title={
                    <Typography
                      component={"span"}
                      color="inherit"
                      className="pop-title"
                    >
                      Saved Filter
                    </Typography>
                  }
                >
                  <IconButton onClick={() => loadFilterData("", "no", 0)}>
                    {!showReports ? (
                      <img
                        src={common.loadImg("tab-savedfilter-icn.svg")}
                        alt="close"
                      />
                    ) : (
                      <img
                        src={common.loadImg("tab-savedfilter-active.svg")}
                        alt="close"
                      />
                    )}
                  </IconButton>
                </HtmlTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={11} lg={11}>
            <Box className="filter-left-border">
              <Box>
                <Dialog
                  onClose={saveFilterClose}
                  open={isSaveFilter}
                  className="filter-dialog-inner"
                  disableScrollLock
                >
                  <Box p={2}>
                    <Typography className="filter-inner-title">
                      {filterId == 0 ? "Save New Filter" : "Update Filter"}
                    </Typography>
                    <Box className="filled-box" my={2}>
                      <TextField
                        id="filled-basic"
                        label="Type in filter title"
                        variant="filled"
                        inputprops={{ maxLength: 50 }}
                        value={reportFilterName}
                        onChange={(e) => setReportFilterName(e.target.value)}
                      />
                    </Box>
                    <Box
                      className=""
                      p={2}
                      display="flex"
                      justifyContent="center"
                    >
                      <Button
                        className="filter-save-btn"
                        variant="contained"
                        onClick={() => filterSubmit()}
                        disabled={isLoading}
                      >
                        {isLoading
                          ? "Processing"
                          : filterId == 0
                          ? "Save"
                          : "Update"}
                      </Button>
                    </Box>
                  </Box>
                </Dialog>
              </Box>
              <Box className="filter-box" py={1} px={2}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item className="filter-right-wrap">
                    <Grid container className="filter-header">
                      <Grid item className="ps-0">
                        <Typography className="notify-title">
                          {showReports
                            ? "Saved Filter"
                            : `Filter ${
                                reportFilterName != "" && filterId > 0
                                  ? `: ${reportFilterName}`
                                  : ""
                              }`}
                        </Typography>
                      </Grid>
                      <Grid item className="ps-1">
                        <IconButton onClick={filterClose}>
                          <img
                            src={common.loadImg("filterCloseIcon.svg")}
                            alt="close"
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item>
                    {showReports && (
                      <Typography
                        className="saved-filters pointer"
                        onClick={() => setShowReports(false)}
                      >
                        {"Back to filter"}
                      </Typography>
                    )}
                    {!showReports && (
                      <Typography
                        className="saved-filters pointer"
                        onClick={() => loadFilterData("", "no", 0)}
                      >
                        {"Saved Filters"}
                      </Typography>
                    )}
                  </Grid> */}
                </Grid>
              </Box>
              {showReports && (
                <Box className="filter-search filters-padd-left-right">
                  <FormControl sx={{ width: "100%" }}>
                    <OutlinedInput
                      id="filters-padd-left-right"
                      placeholder={"Search"}
                      endAdornment={
                        <InputAdornment position="end">
                          <img
                            src={common.loadImg("common-search.svg")}
                            alt="search"
                          />
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      onChange={(e) => searchFilter(e)}
                    />
                  </FormControl>
                </Box>
              )}
              <Box className="filter-box-scroll">
                <div
                  className="filter-sts-scroll"
                  onScroll={onScroll}
                  ref={listInnerRef}
                >
                  {/* {TODO: filter edit purpose} */}
                  {!showReports && (
                    <ul className="condition-list">
                      {filter &&
                        filter.length > 0 &&
                        filter.map((item, key) => {
                          let clCount = key;
                          let field = item.column
                            ? columnInfo(item.column)
                              ? columnInfo(item.column)
                              : ""
                            : "";
                          return (
                            <li className="my-3" key={key}>
                              <div className="row">
                                <div className="col-1 align-self-center text-center">
                                  <span className="step-count">
                                    {++clCount}
                                  </span>
                                  {filter.length != clCount && (
                                    <Link
                                      to="#"
                                      className="step-logical"
                                      onClick={(e) => filterLogical(e, key)}
                                    >
                                      {item.logic}
                                    </Link>
                                  )}
                                </div>
                                <div className="col">
                                  <select
                                    className="form-control"
                                    name="column"
                                    value={item.column}
                                    onChange={(e) => filterChange(e, key)}
                                  >
                                    <option value="">Select Column</option>
                                    {props?.result &&
                                      props?.result.columns.map(
                                        (item, key) =>
                                          checkFieldAccess(item) &&
                                          !(
                                            isEdutechAcc &&
                                            ["Eattr1", "Eattr2"].includes(
                                              item.key
                                            )
                                          ) && (
                                            <option value={item.key} key={key}>
                                              {item.label}
                                            </option>
                                          )
                                      )}
                                  </select>
                                </div>
                                {field != "" && (
                                  <div className="col">
                                    <select
                                      className="form-control"
                                      name="operator"
                                      value={item.operator}
                                      onChange={(e) => filterChange(e, key)}
                                    >
                                      <option value="">Select Operator</option>
                                      {field.type &&
                                        formFields[field.type] &&
                                        formFields[field.type].type &&
                                        filterOperator[
                                          field.type == "pickList"
                                            ? "pickList"
                                            : formFields[field.type].type
                                        ] &&
                                        Object.entries(
                                          filterOperator[
                                            field.type == "pickList"
                                              ? "pickList"
                                              : formFields[field.type].type
                                          ]
                                        ).length > 0 &&
                                        Object.entries(
                                          filterOperator[
                                            field.type == "pickList"
                                              ? "pickList"
                                              : formFields[field.type].type
                                          ]
                                        ).map(([key, item]) => (
                                          <option value={key} key={key}>
                                            {item}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                )}
                                {field !== "" &&
                                  (field.type == "pickList" ? (
                                    <div className="col filter-popup-select">
                                      <Select
                                        value={item.value}
                                        isClearable={true}
                                        options={labelValue(field.attr.option)}
                                        onChange={(data) =>
                                          filterValueChange(key, data)
                                        }
                                      />
                                    </div>
                                  ) : field.type == "date" ? (
                                    <div className="col">
                                      <DatePicker
                                        isClearable={true}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        dateFormat="MMMM d, yyyy"
                                        placeholderText="Select Date"
                                        value={
                                          item.value
                                            ? moment(item.value).toDate()
                                            : null
                                        }
                                        onChange={(date) =>
                                          filterValueChange(key, date.toDate())
                                        }
                                      />
                                    </div>
                                  ) : field.type == "dateTime" ? (
                                    <div className="col action-popup-datepicker">
                                      <DatePicker
                                        isClearable={true}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        // showTimeSelect
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="MMMM d, yyyy"
                                        placeholderText="Select Date and Time"
                                        value={
                                          item.value
                                            ? moment(item.value).toDate()
                                            : null
                                        }
                                        onChange={(date) =>
                                          filterValueChange(key, date.toDate())
                                        }
                                      />
                                    </div>
                                  ) : field.type == "checkbox" ? (
                                    <div className="col">
                                      <select
                                        className="form-control"
                                        value={item.value}
                                        onChange={(e) =>
                                          filterValueChange(key, e.target.value)
                                        }
                                      >
                                        <option value="">None</option>
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                      </select>
                                    </div>
                                  ) : field.type == "lookup" ? (
                                    <div className="col filter-popup-select">
                                      <AutomationSelect
                                        isRender={true}
                                        field={field}
                                        value={item.value}
                                        menuIsOpen={true}
                                        module={props.module}
                                        onSelect={(data) =>
                                          filterValueChange(key, data)
                                        }
                                      />
                                    </div>
                                  ) : field.type == "multiLookup" ? (
                                    <div className="col">
                                      <AutomationSelect
                                        isMulti={true}
                                        isRender={true}
                                        field={field}
                                        value={item.value}
                                        onSelect={(data) =>
                                          filterValueChange(key, data)
                                        }
                                      />
                                    </div>
                                  ) : field.type == "currency" ? (
                                    <div className="col">
                                      <input
                                        type={"number"}
                                        className="form-control"
                                        placeholder={`Enter ${field.label}`}
                                        maxLength={field.attr.maxChar}
                                        min={"0"}
                                        value={item.value}
                                        onChange={(e) =>
                                          filterValueChange(key, e.target.value)
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <div className="col">
                                      <input
                                        type={
                                          field.type === "number"
                                            ? "number"
                                            : "text"
                                        }
                                        className="form-control"
                                        placeholder={`Enter ${field.label}`}
                                        maxLength={field.attr.maxChar}
                                        min={"0"}
                                        value={item.value}
                                        onChange={(e) =>
                                          filterValueChange(key, e.target.value)
                                        }
                                      />
                                    </div>
                                  ))}
                                <div className="col-2">
                                  <div className="col align-items-center">
                                    {filter.length === 1 && key === 0 ? (
                                      <Link
                                        to="#"
                                        className="amlAddRuleLink amlAddRule"
                                        onClick={(e) => filterAdd(e)}
                                      >
                                        {" "}
                                        +{" "}
                                      </Link>
                                    ) : (
                                      key === 0 && (
                                        <Link
                                          to="#"
                                          className="amlAddRuleLink amlRemoveRule"
                                          onClick={(e) => filterRemove(e, key)}
                                        >
                                          {" "}
                                          -{" "}
                                        </Link>
                                      )
                                    )}
                                    {key > 0 && (
                                      <React.Fragment>
                                        <Link
                                          to="#"
                                          className="amlAddRuleLink amlRemoveRule"
                                          onClick={(e) => filterRemove(e, key)}
                                        >
                                          {" "}
                                          -{" "}
                                        </Link>
                                        {filter.length == clCount && (
                                          <Link
                                            to="#"
                                            className="amlAddRuleLink amlAddRule"
                                            onClick={(e) => filterAdd(e)}
                                          >
                                            {" "}
                                            +{" "}
                                          </Link>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  )}
                  {/* TODO: for view purpose */}
                  {showReports && (
                    <Box py={2} px={0}>
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        className="p-0"
                      >
                        <StatusBar status={status} />
                        {status == "success" &&
                          filterList &&
                          filterList.length > 0 &&
                          filterList.map((item, i) => {
                            return (
                              <Grid key={i}>
                                <Grid
                                  container
                                  justify="space-between"
                                  alignItems="center"
                                >
                                  <Grid
                                    item
                                    xs={10}
                                    onClick={(e) =>
                                      applyFilter(item.UserReportId, item, e)
                                    }
                                  >
                                    <Grid container>
                                      <Grid item>
                                        <Box ml={2}>
                                          <Typography className="filter-view-title">
                                            {item.ReportName}
                                          </Typography>
                                          <Typography className="filter-view-time pt-1">
                                            Created on{" "}
                                            {moment(item.CreatedOn).format(
                                              "LL"
                                            )}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Button
                                      id="basic-button"
                                      aria-controls={
                                        openSavedFilter
                                          ? "basic-menu"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        openSavedFilter ? "true" : undefined
                                      }
                                      onClick={(e) =>
                                        savedOptionClick(e, item, i)
                                      }
                                    >
                                      <MoreHorizIcon className="more-icn" />
                                    </Button>
                                    <Menu
                                      anchorEl={savedOption}
                                      open={openSavedFilter}
                                      onClose={savedOptionClose}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                    >
                                      <MenuItem
                                        key={i}
                                        onClick={(e) =>
                                          addNewRepFilter(
                                            selectedFilter.UserReportId,
                                            selectedFilter,
                                            e
                                          )
                                        }
                                      >
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        key={i}
                                        onClick={(e) =>
                                          deleteFilter(
                                            e,
                                            selectedFilter.UserReportId
                                          )
                                        }
                                      >
                                        Delete
                                      </MenuItem>
                                    </Menu>
                                  </Grid>
                                </Grid>
                                <Box py={2}>
                                  <Divider className="" />
                                </Box>
                              </Grid>
                            );
                          })}
                        {listLoader && <StatusBar status={"process"} />}
                      </Grid>
                    </Box>
                  )}
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {!showReports && (
          <Box
            className="filter-save-box"
            p={2}
            display="flex"
            justifyContent="space-between"
          >
            <Button
              className="filter-apply-btn"
              variant="contained"
              onClick={() => applyFilter()}
            >
              Apply Filter
            </Button>

            <Button
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "20px",
                padding: "0 20px",
              }}
              onClick={(e) => showFilterName(e)}
            >
              <span className="save-filter-btn">
                {filterId == 0 ? "Save Filter" : "Update Filter"}
              </span>
              &nbsp;&nbsp;{" "}
              {/* <img src={common.loadImg("save-filter-plus.svg")} alt="plus" /> */}
            </Button>

            <Button
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "20px",
                padding: "0 20px",
              }}
              onClick={(e) => addNewFilter(e)}
            >
              <span className="save-filter-btn">{"New Filter"}</span>
              &nbsp;&nbsp;{" "}
              <img src={common.loadImg("save-filter-plus.svg")} alt="plus" />
            </Button>
          </Box>
        )}
      </Dialog>
    </Box>
  );
}
